import { observable, action, runInAction, computed } from 'mobx';
import { CodeSnippet, CodeSnippetGroup } from '../models';
import { GlobalAdministrationService } from '../service/GlobalAdministrationService';
import { getFullSnippetPath } from '../../common/Utils';

export class CodeSnippetsVisualStore {
    @observable
    formDialogVisible: boolean = false;

    @observable
    groupDialogVisible: boolean = false;

    @observable
    selectedSnippet: CodeSnippet | undefined;

    @observable
    selectedSnippetGroup: CodeSnippetGroup | undefined;

    @observable
    codeSnippetGroups: CodeSnippetGroup[] = [];

    @observable
    codeSnippets: CodeSnippet[] = [];
    
    @observable
    selectedParentGroup: CodeSnippetGroup | undefined;

    @observable
    previewItem: CodeSnippetGroup | CodeSnippet | undefined;

    @computed
    get selectedSnippetGroupChildren() {
        return this.selectedSnippetGroup ? this.getGroupChildren(this.selectedSnippetGroup) : [];
    }

    constructor(private administrationService: GlobalAdministrationService) {

    }

    @action.bound
    setFormDialogVisible(visible: boolean) {
        this.formDialogVisible = visible;
    }

    @action.bound
    setPreviewItem(item: CodeSnippetGroup | CodeSnippet | undefined) {
        this.previewItem = item;
    }

    @action.bound
    setGroupDialogVisible(visible: boolean) {
        this.groupDialogVisible = visible;
    }

    @action.bound
    setSelectedSnippet(snippet: CodeSnippet | undefined) {
        this.selectedSnippet = snippet;
    }

    @action.bound
    setSelectedSnippetGroup(group: CodeSnippetGroup | undefined) {
        this.selectedSnippetGroup = group;
    }

    @action.bound
    setSelectedParentGroup(group: CodeSnippetGroup | undefined) {
        this.selectedParentGroup = group;
    }

    @action.bound
    async loadCodeSnippetGroups() {
        var result = await this.administrationService.getCodeSnippetGroups();
        if (result) {
            runInAction(() => {
                this.codeSnippetGroups = result!;
                this.createFullCodeSnippetPaths();
            });
        }
    }

    @action.bound
    async loadCodeSnippets() {
        var result = await this.administrationService.getCodeSnippets();
        if (result) {
            runInAction(() => {
                this.codeSnippets = result!;
                this.createFullCodeSnippetPaths();
            });
        }
    }

    @action.bound
    createFullCodeSnippetPaths() {
        if (this.codeSnippets.length && this.codeSnippetGroups.length) {
            for (let snippet of this.codeSnippets) {
                snippet.fullSnippetGroupPath = getFullSnippetPath(snippet.groupId!, this.codeSnippetGroups);
            }
        }        
    }

    @action.bound
    addGroup() {
        this.setSelectedSnippetGroup(undefined);
        this.setSelectedParentGroup(undefined);
        this.setGroupDialogVisible(true);
    }

    @action.bound
    addSubgroup(parentGroupId: string) {
        let snippetGroup = this.codeSnippetGroups.find(g => g.id === parentGroupId);        
        if (snippetGroup) {
            this.setSelectedSnippetGroup(undefined);
            this.setSelectedParentGroup(snippetGroup);
            this.setGroupDialogVisible(true);
        }       
    }

    @action.bound
    addSnippet() {
        this.setSelectedSnippet(undefined);

        if (this.previewItem && Object.getOwnPropertyNames(this.previewItem).includes('code')) {
            this.setPreviewItem(undefined); // Need to unmount code preview component. Can't have 2 Monaco editors mounted
        }

        this.setFormDialogVisible(true);
    }

    @action.bound
    addSnippetToGroup(groupId: string) {
        let snippetGroup = this.codeSnippetGroups.find(g => g.id === groupId);
        if (snippetGroup) {
            this.selectedSnippetGroup = snippetGroup;
            this.setSelectedSnippet(undefined);
            
            if (this.previewItem && Object.getOwnPropertyNames(this.previewItem).includes('code')) {
                this.setPreviewItem(undefined);
            }
            
            this.setFormDialogVisible(true);
        }
    }

    @action
    editGroup(groupId: string) {
        let snippetGroup = this.codeSnippetGroups.find(g => g.id === groupId);
        if (snippetGroup) {
            this.selectedSnippetGroup = snippetGroup;
            this.setSelectedParentGroup(undefined);
            this.setGroupDialogVisible(true);
        }
    }

    @action
    editSnippet(snippetId: string) {
        let snippet = this.codeSnippets.find(s => s.id === snippetId);
        if (snippet) {
            this.selectedSnippet = snippet;
            
            if (this.previewItem && Object.getOwnPropertyNames(this.previewItem).includes('code')) {
                this.setPreviewItem(undefined);
            }
            
            this.setFormDialogVisible(true);
        }
    }

    @action
    async deleteGroup(groupId: string) {
        await this.administrationService.deleteCodeSnippetGroup(groupId);
        await this.loadCodeSnippetGroups();
    }

    @action
    async saveSnippetGroup(group: CodeSnippetGroup) {
        await this.administrationService.saveCodeSnippetGroup(group);
        await this.loadCodeSnippetGroups();
    }

    @action
    selectTreeItem(itemId: string, itemType: string, selected: boolean | undefined) {
        if (selected && !this.formDialogVisible) {
            if (itemType === 'group') {
                let group = this.codeSnippetGroups.find(g => g.id === itemId);
                this.setPreviewItem(group);
            } else if (itemType === 'snippet') {
                let snippet = this.codeSnippets.find(s => s.id === itemId);
                this.setPreviewItem(snippet);
            }
        } else {
            this.setPreviewItem(undefined);
        }        
    }

    @action
    async saveSnippet(snippet: CodeSnippet) {
        await this.administrationService.saveCodeSnippet(snippet);
        await this.loadCodeSnippets();
    }

    @action
    async deleteSnippet(snippetId: string) {
        await this.administrationService.deleteCodeSnippet(snippetId);
        await this.loadCodeSnippets();
    }

    @action.bound
    getAllChildrenOfGroup(group: CodeSnippetGroup) {
        return this.getGroupChildren(group);
    }

    @action
    getGroupNameById(groupId: string) {
        let snippetGroup = this.codeSnippetGroups.find(g => g.id === groupId);
        return snippetGroup ? snippetGroup.name : undefined;
    }

    private getGroupChildren(group: CodeSnippetGroup) {
        let children: CodeSnippetGroup[] = this.codeSnippetGroups.slice().filter(g => g.parent === group.id);

        for (let g of children) {
            children = children.concat(this.getGroupChildren(g));
        }
        return children;
    }
}