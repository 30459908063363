import { TestProjectService } from '../services';
import { STORE_TEST_PROJECT, STORE_TEST_PROJECT_WIZARD, STORE_TEST_PROJECT_DASHBOARD, STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW } from './constants';
import { RootStores } from '../../common/stores';
import TestProjectsVisualStore from './TestProjectsVisualStore';
import { STORE_PROJECTS, STORE_PROJECTS_ROOT_UI, STORE_ROUTER } from '../../common/constants';
import { ProjectsService } from '../../common/services';
import TestProjectWizardStore from './TestProjectWizardStore';
import { RulesService } from '../../rules/services';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
import TestProjectDashboardStore from './TestProjectDashboardStore';
import TestProjectRunTopicPreviewStore from './TestProjectRunTopicPreviewStore';
export { default as TestProjectsVisualStore } from './TestProjectsVisualStore';
export { default as TestProjectWizardStore } from './TestProjectWizardStore';
export { default as TestProjectDashboardStore } from './TestProjectDashboardStore';


export function injectTestProjectStores(obj: RootStores) {
    const service = new TestProjectService();
    const projectsService = new ProjectsService();
    const rulesService = new RulesService();
    const globalAdminService = new GlobalAdministrationService();
    obj[STORE_TEST_PROJECT] = new TestProjectsVisualStore(service, obj[STORE_PROJECTS_ROOT_UI], globalAdminService, projectsService, obj[STORE_ROUTER], obj[STORE_PROJECTS]);
    obj[STORE_TEST_PROJECT_WIZARD] = new TestProjectWizardStore(service, obj[STORE_TEST_PROJECT], rulesService);
    obj[STORE_TEST_PROJECT_DASHBOARD] = new TestProjectDashboardStore(obj[STORE_PROJECTS_ROOT_UI], obj[STORE_ROUTER], obj[STORE_TEST_PROJECT], service, obj[STORE_PROJECTS]);
    obj[STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW] = new TestProjectRunTopicPreviewStore();
}

export type TestProjectStores = {
    [STORE_TEST_PROJECT]?: TestProjectsVisualStore;
    [STORE_TEST_PROJECT_WIZARD]?: TestProjectWizardStore;
    [STORE_TEST_PROJECT_DASHBOARD]?: TestProjectDashboardStore;
    [STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW]?: TestProjectRunTopicPreviewStore
};