import { Button, FormInstance, Input, Modal, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { observer } from 'mobx-react-lite';
import { QuestionCircleOutlined } from '@ant-design/icons';
import * as React from 'react';
import { IotaMetadataInput } from '../types';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import { EncryptedInput } from '../../common/components';

interface Props {
    form: FormInstance;
    input: IotaMetadataInput;
    initialValue: unknown;
    disabled?: boolean
}

const ApplicationSettingsObjectInput: React.FC<Props> = ({form, input, initialValue, disabled = false}) => {
    const [hasValue, setHasValue] = React.useState(false);
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [editorValue, setEditorValue] = React.useState<string>('');
    const [jsonIsInvalid, setJsonIsInvalid] = React.useState(false);

    React.useEffect(() => {
        const val = form.getFieldValue(input.id);
        const hasVal = val !== '' && val != null;
        setHasValue(hasVal);
        if (hasVal) {
            setEditorValue(val);
        }
    }, [form, input.id]);

    const renderInput = () => {
        const isEncrypted = input.behavior && input.behavior.encrypted;

        const objectInput = (
            <Button 
                className="texteditor-btn"
                onClick={() => setDialogVisible(true)}
            >
                <div className="scrollable-container">
                    {hasValue ? editorValue : 'Set value'}
                </div>
            </Button>);

        return isEncrypted ? (
            <EncryptedInput changeHandler={handleValueChange} initialValue={initialValue}>{objectInput}</EncryptedInput>
        ) : objectInput;
    };

    const handleCancel = () => {
        const val = form.getFieldValue(input.id);
        setEditorValue(val);
        setDialogVisible(false);
    };

    const handleSave = () => {
        const jsonIsValid = validateJson();

        if (!jsonIsValid) {
            setJsonIsInvalid(true);
            return;
        }

        let obj = {};
        obj[input.id] = editorValue;
        form.setFieldsValue(obj);
        setHasValue(!!editorValue);
        setDialogVisible(false);
    };

    const validateJson = () => {
        if (editorValue == null || editorValue.trim() === '') {
            return true;
        }

        // Regex to validate json schema
        // eslint-disable-next-line no-useless-escape
        if (/^[\],:{}\s]*$/.test(editorValue.replace(/\\["\\\/bfnrtu]/g, '@').
            // eslint-disable-next-line no-useless-escape
            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
            replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
            return true;
        } else{
            return false;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleValueChange = (val: string) => {
        if (jsonIsInvalid) {
            setJsonIsInvalid(false);
        }
        setEditorValue(val);
    };

    const renderDialog = () => {
        return (
            <Modal
                visible={dialogVisible}
                className="alpha-portal-dialog two-columns"
                closable={false}
                maskClosable={false}
                centered
                width={600}
                title={input.name}
                onCancel={handleCancel}
                footer={[
                    <Button className="light" size="large" key="back" onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button size="large" key="submit" type="primary" onClick={handleSave}>
                      Save
                    </Button>
                ]}
            >
                <div className="app-setting-description">
                    {input.description}
                </div>
                <Editor
                    disabled={disabled}
                    value={editorValue}
                    onValueChange={handleValueChange}
                    highlight={val => Prism.highlight(val ?? '', Prism.languages.javascript, 'javascript')}
                    padding={10}
                    style={{
                        fontSize: 14,
                        boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)'
                    }}
                />
                {jsonIsInvalid
                    ? (<span style={{color: 'red'}}>JSON is invalid</span>)
                    : null}
            </Modal>
        );
    };

    return (
        <>
            {renderDialog()}
            <FormItem
                className='required-with-tooltip'
                name={input.id}
                label={(
                    <span>
                        {input.name}&nbsp;
                        <Tooltip title={input.description}>
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    </span>
                )}
                rules={[{
                    ...input.validation,
                    message: 'Please input value!',
                    whitespace: true,
                    type: input.behavior && input.behavior.multiselect ? 'array' : 'string'
                }]}
                initialValue={initialValue}
            >
                <div>
                    <Input type="hidden" />
                    {renderInput()}
                </div>
            </FormItem>
        </>
    );
};

export default observer(ApplicationSettingsObjectInput);