
import * as React from 'react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { IotaSessionsStores } from '../stores';
import { STORE_IOTA_AUDIT } from '../constants';
import  IotaAuditTable  from '../components/IotaAuditTable';
type Props = IotaSessionsStores;

const IotaAuditPage: React.FC<Props> = ({IotaAuditUI: store }) => {
    return (
        <FlexContentContainer 
            content={<IotaAuditTable IotaAuditUI={store!} /> }  
        />);

};
  
export default inject(STORE_IOTA_AUDIT)(observer(IotaAuditPage));