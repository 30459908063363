import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { ProjectStores } from '../stores';
import { STORE_ADMINISTRATION_PROJECT, STORE_UPLOADED_PACKAGES } from '../constants';
import ReindexingTabs from '../components/ReindexingTabs';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

type Props = ProjectStores;

export class AdministrationProjectPage extends React.Component<Props, object> {
    render() {
        const store = this.props.administrationProjectUI!;
        const lines = !store.isLoading ?
            (
                <div>          
                    <ReindexingTabs adminStore={this.props.administrationProjectUI!} uploadedPackagesStore={this.props.uploadedPackagesUI!}/>
                </div>
            ) : <LoadingIndicator/>;

        return (
            <FlexContentContainer content={lines} />
        );
    }
}

export default inject(STORE_ADMINISTRATION_PROJECT, STORE_UPLOADED_PACKAGES)(observer(AdministrationProjectPage));
