
import * as React from 'react';
import PipelineStep from '../models/PipelineStep';
import { observer } from 'mobx-react';
import ParametersEditor from './ParametersEditor';
import PipelineVisualStore from '../../rules/stores/PipelineVisualStore';
import { StepModel } from '../../rules/types';

type Props<T extends PipelineVisualStore<TPipelineStep>, TPipelineStep extends string> = {
    store: T;
    step: PipelineStep<TPipelineStep>;
    stepKey: number;
    stepModel: StepModel
};

export class PredefinedParametersCard<T extends PipelineVisualStore<TPipelineStep>, TPipelineStep extends string> extends React.Component<Props<T, TPipelineStep>, object> {
    constructor(props: Props<T, TPipelineStep>) {
        super(props);
    }

    render() {
        const { store } = this.props;
        const { step } = this.props;
        const { stepKey } = this.props;
        const { stepModel } = this.props;

        if (!stepModel.parameters.length) {
            return(
                <></>     
            );
        }

        return (
            <ParametersEditor key={stepKey} store={store} step={step} stepKey={stepKey} stepModel={stepModel} projectId={store.currentProject!.id} />
        );
    }
}

export default observer(PredefinedParametersCard);