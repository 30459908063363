import { ControlType, WorkflowInputProps } from '../../types';
import { WorkflowInputModelSelect, WorkflowInputTextArea } from '..';

type Registry = Record<Exclude<ControlType, 'Text'>, React.FC<WorkflowInputProps> | undefined>;

const WorkflowInputControlRegistry: Registry = {
    ['ModelId']: WorkflowInputModelSelect,
    ['Textarea']: WorkflowInputTextArea
};

export default WorkflowInputControlRegistry;
