import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FieldBindingsStore } from '../stores';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    store: FieldBindingsStore
};

const FieldBindingGroupCreateDialog: React.FC<Props> = ({store}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.isCreateGroupDialogVisible) {
            const uuid = uuidv4();
            const alias = uuid.toString().replace(/-/g, '');
            const obj = { alias: alias };
            form.setFieldsValue(obj);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isCreateGroupDialogVisible]);

    const handleCancel = () => {   
        store.setIsCreateGroupDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            var createdSuccessfully = await store.createFieldGroup(values.name, values.alias);
            if (createdSuccessfully) {
                handleCancel();
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const validateAlias = (value: string) => {
        if (store.bindingGroups.find(g => g.alias === value)) {
            return Promise.reject(new Error('Group with such alias already exists'));
        }

        return Promise.resolve();
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title="New field binding group"
            visible={store!.isCreateGroupDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button data-id="field-binding-add-group-dialog-cancel" className="light" key="back" size="large" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button data-id="field-binding-add-group-dialog-submit" key="submit" size="large" type="primary" onClick={handleSubmit}>
                    Add group
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item name="name" label="Name" rules={[{required: true, message: 'Provide name for the project field group, please'}]}>
                    <Input />
                </Form.Item>
                <Form.Item 
                    name="alias" 
                    label="Alias" 
                    rules={[{
                        required: true, message: 'Provide alias for the project field group, please'
                    }, {
                        validator: (_, value) => validateAlias(value)
                    }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FieldBindingGroupCreateDialog);