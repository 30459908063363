import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Checkbox, Row, Col, Alert } from 'antd';
import ProjectExportStore, { options, optionValues, ProjectExportPart } from '../stores/ProjectExportStore';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

interface Props {
    store: ProjectExportStore
}

const ProjectExportDialog: React.FC<Props> = ({ store }) => {
    const [showMlModelsMessage, setShowMlModelsMessage] = React.useState(false);

    const onChange = (selectedOptions: string[]) => {
        if (selectedOptions.includes(ProjectExportPart.InstructWorkflows) && !selectedOptions.includes(ProjectExportPart.MlModels)) {
            selectedOptions.push(ProjectExportPart.MlModels);
            setShowMlModelsMessage(true);
        } else {
            setShowMlModelsMessage(false);
        }

        store.setSelectedOptions(selectedOptions);
        store.setSelectAll(selectedOptions.length === options.length);
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        store.setSelectedOptions(e.target.checked ? optionValues : []);
        store.setSelectAll(e.target.checked);
    };

    return (
        <Modal
            centered
            width={400}
            title="Export Project"
            destroyOnClose
            closable={false}
            maskClosable={false}
            visible={store.dialogVisible}
            className="alpha-portal-dialog"
            footer={[
                <Button
                    data-id="project-export-cancel"
                    key="project-export-cancel"
                    size="large"
                    className="light"
                    onClick={store.dispose}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="project-export"
                    key="project-export"
                    size="large"
                    type="primary"
                    disabled={!store.selectedOptions.length}
                    onClick={store.exportProject}
                >
                    Export
                </Button>
            ]}
        >
            <Row style={{ marginBottom: 12 }}>
                <Col>
                    <Checkbox onChange={onCheckAllChange} checked={store.selectAll}>
                        Select all
                    </Checkbox>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Checkbox.Group
                        className="vertical-checkbox-group"
                        options={options}
                        value={store.selectedOptions}
                        onChange={onChange}
                    />
                </Col>
            </Row>

            {showMlModelsMessage && (
                <Alert
                    message="ML models are required if instruct workflows are selected"
                    type="warning"
                    closable
                    onClose={() => setShowMlModelsMessage(false)}
                    style={{ marginTop: 20 }}
                />
            )}
        </Modal>
    );
};

export default observer(ProjectExportDialog);
