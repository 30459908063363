import { InputBindingAppInput } from '.';

export interface InputBindingMetadataBaseDto {
    applicationId: string;
    applicationName: string;
    applicationType: string
}

export interface AppDefInputBindingMetadataDto extends InputBindingMetadataBaseDto {
    inputs: InputBindingAppInput[]
}

export interface AppDefConditionalInputBindingMetadataDto extends InputBindingMetadataBaseDto {
    inputGroups: AppDefInputGroupMetadataDto[]
}

export interface AppDefInputGroupMetadataDto {
    inputGroupId: string;
    inputGroupName: string;
    inputs: InputBindingAppInput[]
}

export class InputBindingMetadataBase implements InputBindingMetadataBaseDto {
    readonly applicationId: string;
    readonly applicationName: string;
    readonly applicationType: string;

    constructor(data: InputBindingMetadataBaseDto) {
        this.applicationId = data.applicationId;
        this.applicationName = data.applicationName;
        this.applicationType = data.applicationType;
    }
}

export class AppDefInputBindingMetadata extends InputBindingMetadataBase implements AppDefInputBindingMetadataDto {
    readonly inputs: InputBindingAppInput[];

    constructor(data: AppDefInputBindingMetadataDto) {
        super(data);

        this.inputs = data.inputs;
    }
}

export class AppDefConditionalInputBindingMetadata extends InputBindingMetadataBase implements AppDefConditionalInputBindingMetadataDto {
    readonly inputGroups: AppDefInputGroupMetadataDto[];

    constructor(data: AppDefConditionalInputBindingMetadataDto) {
        super(data);

        this.inputGroups = data.inputGroups;
    }
}