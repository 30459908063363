/* eslint-disable @typescript-eslint/member-ordering */
import { computed, observable, action, runInAction } from 'mobx';
import { Package, Project, PackageLine, ProjectState, PackageState } from '../../common/models';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, filter, merge, map } from 'rxjs/operators';
import { Pager, PAGE_SIZE } from '../../common/stores/Pager';
import { AutoCompleteText, PackageLinesResponse } from '../../common/types';
import _ from 'lodash';
import type { BLOCK_TYPE } from '../../common/types';
import ProjectsRootVisualStore from '../../common/stores/ProjectsRootVisualStore';
import { ContentType } from '../../common/components/PreviewContent';

export const SEARCH_DEBOUNCE_TIME = 200;

export type HighlightBlockProps = {
    height: number | string;
    width: number | string;
    bottom: number | string;
    left: number | string;
    visibility: 'hidden' | 'visible';
    position: 'absolute';
    page: number;
    packageId: string
};

export class Block {
    constructor(public packageFieldId: string, public width: number, public height: number, public x: number,
                // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
                // eslint-disable-next-line max-len
                public y: number, public text: string, public normalizedText: string, public line?: PackageLine, public blockType?: BLOCK_TYPE, public blockStartsOnCurrentPage?: boolean) {

    }
}

export class TocNode {
    constructor(public topic: string, public subItems: string[]) {
    }
}

export class InteractiveBlockEntity {
    constructor(public width: number, public height: number, public x: number,
        // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
                public y: number, public text: string, public normalizedText: string) {
    }
}

export const NONE_LABEL = 'None';

type SearchSubject = {
    pageNumber: number;
    callback?: () => void
};

abstract class PackageViewerVisualStoreBase {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promise: any;
    pageHeight: number;    
    sizeCoefficient: number;
    responsiveScale: number = 1;

    @observable
    pageCoordinates: number[];

    @observable
    selectedPackage: Package | null;

    @observable
    test: string[] = [];

    @observable
    lines: PackageLine[] = [];

    @observable
    searchTerm: string = '';

    @observable
    highlightedBlock: InteractiveBlockEntity | null;

    @observable
    highlightBlockProps: HighlightBlockProps | undefined;

    interactiveBlocks: InteractiveBlockEntity[];

    @observable
    pager: Pager<PackageLine>;

    @observable
    filterQuery: string;

    @observable
    pageWidth: number | null = null;

    @observable
    currentPage: number = 0;

    @observable
    totalPages: number = 0;

    @observable
    scrollPosition: number = 0;

    @observable
    isLoading: boolean = false;

    @observable
    isPopUpVisible: boolean = false;

    @observable
    scale: number = 1;

    @observable
    tocNodes: TocNode[];

    @observable
    selectedBlock: Block | null = null;

    @observable
    showUserNotReviewed: boolean = false;

    @observable
    autocompleteSource: AutoCompleteText[] = [];

    @observable
    blockType: BLOCK_TYPE = 'LINE_BLOCK';

    @observable
    blockTypes: BLOCK_TYPE[] = ['LINE_BLOCK'];

    @observable
    pagesLoaded: number[];

    @observable
    currentSwitchState: boolean;

    currentProblemText: string;

    problemReportPage: number | null = null;

    @observable
    currentTags: string[];

    @observable
    isProblemReportModalVisible: boolean = false;

    pdfServiceUrl: string;

    searchCurrentPage: boolean;

    highlightBlockRedrawSubject: Subject<string | null>;

    protected searchForAutocompleteCallServer: (s: string) => Promise<void>;
    protected searchSubject: Subject<SearchSubject>;
    protected textSearchSubject: Subject<string | null>;
    protected textSearchResult: Observable<PackageLinesResponse>;

    @computed
    get tags() {
        return this.projectsVisualStore.tags;
    }

    @computed
    get filteredBlockTypes() {
        // eslint-disable-next-line max-len
        return this.currentSwitchState && 'LINE_BLOCK' || 'TEXTBOX_BLOCK' || 'TABLE_BLOCK' || 'HORIZONTAL_LINE_BLOCK' || 'CLUSTER_BLOCK' || 'PARAGRAPH_BLOCK' || 'HORIZONTAL_MULTILINE_BLOCK' || 'CELL_BLOCK' || 'DOCUMENT_TABLE_BLOCK' || 'FORM_ITEM_BLOCK';
    }

    @computed
    get packageLines(): PackageLine[] {
        return this.pager.data;
    }

    get rootStore() {
        return this.projectsVisualStore.projectsStore;
    }

    constructor(protected projectsVisualStore: ProjectsRootVisualStore) {
        this.searchSubject = new Subject<SearchSubject>();
        this.textSearchSubject = new Subject<string>();
        this.pager = new Pager();
        this.pagesLoaded = [1, 2];

        this.textSearchResult = this.textSearchSubject.pipe(debounceTime(SEARCH_DEBOUNCE_TIME),
            merge(this.pager.nextPageRequest.let(map(() => this.searchTerm))),
            switchMap(x => Observable.fromPromise(this.searchInPackages(x))));
        this.textSearchResult.subscribe(r => this.setFilteredLines(r));

        const searchResult = this.searchObservable;

        searchResult.subscribe(r => runInAction(() => {
            this.isLoading = false;
            this.lines = r.lines;
        }));

        this.setProjectStateChangeObservable();
        // *Uncomment if it will be neeeded
        // reaction(() => this.currentPage, p => this.searchSubject.next(p));

        this.searchForAutocompleteCallServer = _.debounce(this.searchForAutocompleteImp, 200);
        this.highlightBlockRedrawSubject = new Subject();
    }

    get searchObservable() {
        return this.searchSubject.pipe(debounceTime(SEARCH_DEBOUNCE_TIME),
            filter(x => x.pageNumber >= 0),
            switchMap(x => this.loadLinesForPage(x.pageNumber, x.callback)));
    }

    @computed
    get blocks(): Block[] {
        return this.lines.filter(x => x.coordinates!.page === this.currentPage && x.blockType === this.blockType).map(x => {
            if (!this.pageWidth) {
                return null;
            }

            const scale = this.pageWidth / x.coordinates!.pageWidth;

            return new Block(x.id, x.coordinates!.width * scale, x.coordinates!.height * scale,
                x.coordinates!.x * scale,
                -1 * (x.coordinates!.y - x.coordinates!.pageHeight) * scale,
                x.text, x.normalizedText, x);
        }).filter(x => x).map(x => x!);
    }

    // @computed
    // get packages() {
    //     return this.project ? this.project.packages : [];
    // }

    @observable
    packages: Package[] = [];

    @computed
    get labels(): string[] {
        return [...this.project!.keywords];
    }

    @computed
    get filteredPackages() {
        if (this.project) {
            let packages = this.packages;

            if (this.currentTags && this.currentTags.length)  {
                packages = this.packages
                    .filter(x => _.intersection(x.userTags, this.currentTags).length === this.currentTags.length);
            }

            if (this.showUserNotReviewed) {
                return packages.filter(x => !x.operationState || 
                    !_.includes(x.operationState, 'UserReviewed'));
            } else {
                return packages;
            }
        } else {
            return [];
        }
    }

    abstract get project(): Project | null;

    @action.bound
    setPageCoordinates(coords: number[]) {
        this.pageCoordinates = coords;
    }

    @action.bound
    setHighlightBlockProps(props: HighlightBlockProps | undefined) {
        this.highlightBlockProps = props;
    }

    @action.bound
    setHighlightedBlock(block: InteractiveBlockEntity | null) {
        this.highlightedBlock = block;
    }

    @action
    getBlocksForPage(page: number) {
        return this.lines.filter(x => x.coordinates!.page === page && (this.blockTypes.indexOf(x.blockType) !== -1)).map(x => {
            if (!this.pageWidth) {
                return null;
            }
            
            const scale = this.pageWidth / x.coordinates!.pageWidth;
            return new Block(x.id, x.coordinates!.width * scale, x.coordinates!.height * scale,
                x.coordinates!.x * scale,
                -1 * (x.coordinates!.y - x.coordinates!.pageHeight) * scale,
                x.text, x.normalizedText, x, x.blockType);
            
        }).filter(x => x).map(x => x!);

    }

    @action
    setPageWidth(width: number) {
        if (width === this.pageWidth) {
            return;
        }

        this.pageWidth = width;
    }

    @action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async setMatch(m: any) {
        this.pageCoordinates = [];
        if (m.packageId === '0') {
            return;
        }
        const pkg = this.packages.find(p => p.id === m.packageId);
        if (pkg) {
            this.problemReportPage =  parseInt(m.pageNumber, 10) - 1;
            await this.setPackage(pkg);
        }
    }

    @action
    async setProjectStateChangeObservable() {
        const {projectsStore} = this.projectsVisualStore;
        projectsStore?.projectsStates?.observe( async() => {
            let isReady = false;
            projectsStore?.projectsStates?.forEach( (v, p) => {
                if (this.projectsVisualStore.currentProject && this.projectsVisualStore.currentProject!.id === p && v === ProjectState.Ready) {
                    isReady = true;
                }
            });
            if (isReady && this.selectedPackage) {
                const res = await this.loadLinesForPage(0);
                this.lines = res.lines;
                this.isLoading = false;
                const pkgs = this.packages.filter(p => ![PackageState.ChangedAnchors, PackageState.ChangedLabels, PackageState.ChangedManualText].includes(p.state));
                pkgs.forEach(p => p.resetAllChanges()); 
            }

        });
    }

    @action
    filterMarkedPackages(toFilter: boolean) {
        this.showUserNotReviewed = toFilter;
    }

    setSearchOptions(searchCurrentPage: boolean) {
        this.searchCurrentPage = searchCurrentPage;
    }

    @action
    setLabel(block: Block, keyword: string) {
        if (keyword === NONE_LABEL) {
            if (!block.line!.initialLabel) {
                block.line!.reset();
            } else {
                block.line!.setLabel(null);
            }

            return;
        }
        const label = this.project!.labels.find(x => x.text === keyword)!;
        block.line!.setLabel(label);
    }

    @action
    setCurrentPage(currentPage: number, useScroll: boolean = true) {
        this.currentPage = currentPage;
        this.pagesLoaded = this.pagesLoaded.concat([currentPage, currentPage + 1, currentPage + 2].filter(p => this.pagesLoaded.indexOf(p) < 0));
        if (useScroll && this.pageCoordinates) {
            this.scrollPosition = this.pageCoordinates[currentPage];
        }
    }

    @action
    setPagesInfo(pages: number) {
        this.totalPages = pages;
    }

    @action
    setIsProblemReportModalVisible(isVisible: boolean) {
        this.isProblemReportModalVisible = isVisible;
    }

    async submitProblem() {
        await this.rootStore.savePackageProblemMessage(this.selectedPackage!.id, this.currentProblemText, this.currentPage);
        this.setIsProblemReportModalVisible(false);
    }

    handleValueChange(text: string) {
        this.currentProblemText = text;
    }

    @action
    async setPackage(pkg: Package) {
        runInAction(() => this.autocompleteSource = []);
        this.selectedPackage = pkg;
        this.totalPages = 0;
        this.pagesLoaded = [1, 2];
        this.isLoading = true;
        this.searchSubject.next({ pageNumber: 0 });
        this.setCurrentPage(this.problemReportPage || 0);
        this.searchForAutocomplete('');
    }

    @action
    setScale(scale: number) {
        scale = scale < 50 ? 50 : scale;
        this.scale = scale / 100;
    }

    @action
    setScrollPosition(scrollPosition: number) {
        this.scrollPosition = scrollPosition;
    }

    @action.bound
    turnPageOver(scroll: 'Up' | 'Down') {
        if (scroll === 'Up' && this.currentPage !== 0) {
            this.setCurrentPage(this.currentPage - 1);
        } else if (scroll !== 'Up' && this.currentPage !== this.totalPages - 1) {
            this.setCurrentPage(this.currentPage + 1);
        }
    }

    @action.bound
    changePage(term: string = '') {
        let [, pageNum, position, pageHeight] = term.split('::').map(Number);
        this.checkState(this.getPosition(pageNum, pageHeight, position));
    }
    
    getPosition(pageNumber: number, pageHeight: number, initialPosition: number) {
        let position = initialPosition;

        // rare case when the position of searched text on different pages is the same 
        if (pageNumber !== this.currentPage && this.scrollPosition === position) {
            position = position + 0.1;
        }

        return this.pageCoordinates[pageNumber] + ((pageHeight - position) * (this.sizeCoefficient || 1) * this.scale);
    }

    @action.bound
    checkState = (position: number) => {
        if (this.isLoading) {
            setTimeout(this.checkState.bind(this, position), 50);
        } else {
            this.scrollPosition = position;
        }
    };

    @action.bound
    togglePopUp(state: boolean) {
        if (this.selectedPackage) {
            this.isPopUpVisible = state;
            this.searchTerm = '';
        }
    }

    @action
    async searchForAutocomplete(term: string) {
        this.searchTerm = term;
        return this.searchForAutocompleteCallServer(this.searchTerm);
    }

    @action.bound
    setSearchTerm(term: string) {
        this.searchTerm = term;
    }

    @action.bound
    changeBlockTypes(t: BLOCK_TYPE[], callback?: () => void) {
        this.blockTypes = t;
        this.pagesLoaded = [1, 2];
        if (this.selectedPackage) {
            this.searchSubject.next({ pageNumber: this.currentPage, callback });
        }
    }

    @action
    resetLoadedPages() {
        this.pagesLoaded = [1, 2];
    }

    @action.bound
    setTags(tags: string[]) {
        this.currentTags = tags;
    }

    @action.bound
    handleSwitchChange(checked: boolean) {
        this.currentSwitchState = checked;
    }

    @action.bound
    loadPackages(page: number = 0, allSources: boolean = true, pageSize: number = PAGE_SIZE, searchTerm: string | null = null) {
        return this.projectsVisualStore.searchInProject(searchTerm ?? this.searchTerm, page, this.currentTags, allSources, pageSize);
    }

    isSelectedPackagePdf(): boolean {
        return this.selectedPackage!.contentType === ContentType.Pdf;
    }

    isReadyToRender(state: {}): boolean {
        return computed(() => !!this.selectedPackage && !this.isLoading && !!state).get();
    }

    @action
    private async searchForAutocompleteImp(term: string) {
        if (!this.selectedPackage || !this.project) {
            return;
        }
        let searchPage = null;
        if (this.searchCurrentPage) {
            searchPage = this.currentPage;
        }

        const request = {
            projectId: this.project!.id,
            pkgId: this.selectedPackage ? this.selectedPackage.id : null,
            search: term,
            page: 0,
            pageSize: 15,
            searchPage: searchPage
        };

        const result = await this.rootStore.searchInPackages(this.project!, request, this.selectedPackage);

        runInAction(() => {
            this.autocompleteSource = result.lines.map(l => ({
                text: l.text,
                page: l.coordinates!.page,
                position: l.coordinates!.y,
                pageHeight: l.coordinates!.pageHeight
            }));
        });
    }

    @action
    private async loadLinesForPage(currentPage: number, callback?: () => void) {
        if (!this.selectedPackage) {
            return Promise.resolve(new PackageLinesResponse());
        }

        this.isLoading = true;

        const request = {
            projectId: this.project!.id,
            pkgId: this.selectedPackage ? this.selectedPackage.id : null,
            page: 0,
            pageSize: 999999,
            fieldsSearch: { before: { page: currentPage } },
            blockTypes: this.blockTypes
        };

        const result = await this.rootStore.searchInPackages(this.project!, request, this.selectedPackage);

        if (callback) {
            callback();
        }

        return result;
    }

    private searchInPackages(term: string | null): Promise<PackageLinesResponse> {
        if (!this.selectedPackage) {
            return Promise.resolve(new PackageLinesResponse());
        }

        const request = {
            projectId: this.project!.id,
            pkgId: this.selectedPackage ? this.selectedPackage.id : null,
            search: term,
            page: this.pager.currentPage,
            pageSize: PAGE_SIZE,
            blockTypes: this.blockTypes,
            tags: this.tags
        };

        return this.rootStore.searchInPackages(this.project!, request, this.selectedPackage);
    }

    @action
    private setFilteredLines(response: PackageLinesResponse) {
        const { lines, total } = response;

        this.pager.setData(lines, total);
        this.isLoading = false;
    }

}

export default PackageViewerVisualStoreBase;