/* eslint-disable dot-notation */
type NameMeta = {
    name: string
};

export const hasNameMeta = (meta: unknown): meta is NameMeta => !!(meta && typeof meta === 'object' && 'name' in meta);

export const formatFieldName = (value?: string) => (value ? value.replace(/([a-z])([A-Z])/g, '$1 $2') : '');

export const parseJson = (value: unknown) => (typeof value === 'string' ? JSON.parse(value) : '');

export const valueGetter = (value: unknown) => {
    // Mongo driver for C# may add "_t" and "_v" for some data types
    if (value && typeof value === 'object' && '_t' in value && '_v' in value) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        return value['_v'];
    }

    return value;
};
