import * as React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import { ConnectionsDefinitionsService } from '../../iota_connections/services';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import { ConnectionsDefinition } from '../../iota_connections/types';
import { Select, Form } from 'antd';
import { ConnectionDescription } from '../../iota_applications/types';
import { FormInstance } from 'antd/lib/form';

const Option = Select.Option;

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string>  = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number
};
export const ParametersIotaConnectionSelector: <T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string>(
    props: Props<T, TPipelineStepType>) => React.ReactElement<Props<T, TPipelineStepType>> = ({param, form, toolTip, step, store, stepKey}) => {    
    
    const [packageId, setPackageId] = React.useState<string | undefined>(form.getFieldValue('package_id'));

    const handleSubmit = async() => {
        await store.validateForm(form, step, stepKey, false);
    };
    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }
    const localState = useLocalStore(() => ({
        service: new ConnectionsDefinitionsService(),
        package: packageId,
        connection: '',
        packages: new Array<ConnectionsDefinition>(),
        connections: new Array<ConnectionDescription>(),
        setPackage(pkg: string | undefined) {
            var p = localState.packages.find(x => x.id === pkg);
            if (p) {
                runInAction(() => {
                    localState.connections = p!.connections || [];
                });
            } else {
                runInAction(() => {
                    localState.connections = [];
                });
            }
        },
        async load() {
            var res = await localState.service.getAll();
            runInAction(() => {
                const pkgId = form.getFieldValue('package_id');
                setPackageId(pkgId);
                localState.packages = res;
                localState.setPackage(pkgId);
            });
        },
    }), { package: packageId });

    React.useEffect(() => {
        localState.load();
    },              [localState]);

    React.useEffect(() => {
        localState.setPackage(packageId);
    },              [packageId, localState, form]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shouldUpdate = (prevValues: any, currentValues: any) => {
        const shouldBeUpdated = prevValues.package_id !== currentValues.package_id;
        setPackageId(currentValues.package_id);
        return shouldBeUpdated;
    };

    return (
        <div>
            <Form.Item label={toolTip}
                name={param.name}
                rules={[{
                    required:!step.isDisabled && param.required, message: 'Please select a connection',
                }]}
                initialValue={param.defaultValue}
                shouldUpdate={shouldUpdate}
            >
                <Select style={{width: '100%'}}>
                    {localState.connections.map(x => (
                        <Option key={x.id} value={x.id}>{x.name}</Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersIotaConnectionSelector);