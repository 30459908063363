import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Col, Input, Row, Select, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { AdministrationStores } from '../stores';
import { FormInstance } from 'antd/lib/form/Form';
import { LoginTypes } from '../stores/UsersManagerStore';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Utils } from '../../common/services/Utils';
import { EMAIL_REGEX_PATTERN } from '../../../modules/common/Utils';
import { UsersHelper } from '../misc/UsersHelper';

type Props = {
    form: FormInstance
} & AdministrationStores;

const Option = Select.Option;

export const UserForm: React.FC<Props> = observer(({ form, UsersManager: store }) => {
    const [currentRoles, setCurrentRoles] = React.useState(form.getFieldValue('roles') as string[]);
    const [currentAdditionalRoles, setCurrentAdditionalRoles] = React.useState([] as string[]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const similarEmailsValidator = (rule: any, value: any, callback: any) => { 
        let emailsToValidate = store!.users.filter(u => u.email && ((!store!.selectedUser || store!.selectedUser.id !== u.id))).map(u => u.email.toLowerCase());
        if (value && emailsToValidate.includes(value.toLowerCase())) {
            callback('User with the same email is already registered!');
        } else {
            callback();
        }
    };

    const isFieldDisabled = store!.selectedUser! && store!.selectedUser!.isDomainUser ? true : false;

    const updateCheckedRoles = (e: CheckboxChangeEvent, roleId: string) => {
        let newRoles = [];
        if (e.target.checked) {
            newRoles = [...currentRoles, roleId];
        } else {
            newRoles = currentRoles.filter(r => r !== roleId);
        }

        newRoles = [...new Set(newRoles)];

        setCurrentRoles(newRoles);
        let obj = {roles: newRoles};
        form.setFieldsValue(obj);
    };

    const updateCheckedAdditionalRoles = (e: CheckboxChangeEvent, roleId: string) => {
        let newRoles = [];
        if (e.target.checked) {
            newRoles = [...currentAdditionalRoles, roleId];
        } else {
            newRoles = currentAdditionalRoles.filter(r => r !== roleId);
        }

        newRoles = [...new Set(newRoles)];

        setCurrentAdditionalRoles(newRoles);
        let obj = {additionalRoles: newRoles};
        form.setFieldsValue(obj);
    };

    return (
        <>
            <Row gutter={20}>
                <Col span="12">
                    <FormItem
                        label="Login type"
                        name="loginType"
                        rules={[{
                            required: true, message: 'Please select type!',
                        }]}
                    >
                        <Select style={{ width: '100%' }} disabled size="large" >
                            {[LoginTypes.internalUser, LoginTypes.domainUser].map((x , i) => 
                                (<Option key={i} value={x}>{x}</Option>))}
                        </Select>
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem 
                        label="Username"
                        name="userName"
                        rules = {[
                            { required: true, message: 'Please input username!', whitespace: true }
                        ]}
                    >
                        <Input disabled size="large" />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem
                        label="First name"
                        name="firstName"
                        rules={[
                            { required: true, message: 'Please input first name!', whitespace: true }
                        ]}
                    >
                        <Input disabled={isFieldDisabled} size="large" />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem
                        label="Last name"
                        name="lastName"
                        rules={[
                            { required: true, message: 'Please input last name!', whitespace: true }
                        ]}
                    >
                        <Input disabled={isFieldDisabled} size="large" />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem
                        label="Email"
                        name="email"
                        rules={[    
                            { required: true, message: 'Please input email', whitespace: true },
                            {   
                                pattern: EMAIL_REGEX_PATTERN, 
                                message: 'Input valid email address please.'
                            }, {
                                validator: similarEmailsValidator
                            }
                        ]}
                    >
                        <Input disabled={isFieldDisabled} size="large" />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem
                        label="Group"
                        name="group"
                    >
                        <Select size="large" options={store!.userGroupSelectValues.map(g => {
                            return {value: g.usersGroup.id, label: g.usersGroup.name}; 
                        })} />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <div className="dialog-administation-role-form-item">
                        <FormItem
                            label="Basic roles"
                            name="roles"
                            rules={[{
                                required: true, message: 'Please select at least one basic role!'
                            }]}
                        >
                            <Checkbox.Group style={{width: '100%'}}>
                                {UsersHelper.getBasicRoles(store?.sortedRoles, store?.customRoles).map(r => (
                                    <Checkbox 
                                        className="checkbox-with-subtitle" 
                                        key={r.id} 
                                        value={UsersHelper.isCustomRole(r) ? r.id : r.name}
                                        onChange={(e) => updateCheckedRoles(e, UsersHelper.isCustomRole(r) ? r.id :r.name)}
                                    >
                                        <div>
                                            <div style={{display: 'inline'}}>
                                                {Utils.getAppRoleDisplayName(r.name)}
                                            </div>
                                            <div style={{display: 'inline-block'}}> 
                                                <Tooltip title={UsersHelper.getRolePermissions(r)}>
                                                    <i className="alpha-icon lg question-icon" style={{verticalAlign: 'middle'}} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </FormItem>
                    </div>
                </Col>
                <Col span="12">
                    <div className="dialog-administation-role-form-item">
                        <FormItem
                            label="Additional roles"
                            name="additionalRoles"
                            initialValue={[]}
                        >
                            <Checkbox.Group style={{width: '100%'}}>
                                {UsersHelper.getOtherRoles(store?.sortedRoles, store?.customRoles).map(r => (
                                    <Checkbox  
                                        className="checkbox-with-subtitle" 
                                        key={r.id} 
                                        value={UsersHelper.isCustomRole(r) ? r.id : r.name}
                                        onChange={(e) => updateCheckedAdditionalRoles(e, UsersHelper.isCustomRole(r) ? r.id :r.name)}
                                    >
                                        <div>
                                            <div style={{display: 'inline'}}>
                                                {Utils.getAppRoleDisplayName(r.name)}
                                            </div>
                                            <div style={{display: 'inline-block'}}> 
                                                <Tooltip title={UsersHelper.getAuthLevelRoleDescription(r.name)}>
                                                    <i className="alpha-icon lg question-icon" style={{verticalAlign: 'middle'}} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </FormItem>
                    </div>
                </Col>
            </Row>
        </>
    );
});