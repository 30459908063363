import * as React from 'react';
import { Button, Form, FormInstance, Input, Modal, Tooltip } from 'antd';
import { STORE_PROJECT_INTERACTIIVE_LABELS } from '../../interactive_labels/constants';
import { InteractiveLabelsVisualStore } from '../../interactive_labels/stores';
import { observer } from 'mobx-react';
import { ChangeEvent } from 'react';
import FormItem from 'antd/lib/form/FormItem';

type Props = { 
    [STORE_PROJECT_INTERACTIIVE_LABELS]: InteractiveLabelsVisualStore
};
const { TextArea } = Input;

class PackageProblemMarker extends React.Component<Props, object> {
    formRef = React.createRef() as React.RefObject<FormInstance>;
    store: InteractiveLabelsVisualStore;
    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.store = this.props[STORE_PROJECT_INTERACTIIVE_LABELS]!;
    }

    handleSubmit (e: React.FormEvent<object>) {
        e.preventDefault();
        this.formRef.current!.validateFields().then(() => {
            this.store.submitProblem();         
        });
       
    }
    handleOnChange(e: ChangeEvent<HTMLTextAreaElement>)  {
        this.store.handleValueChange(e.target.innerText);
    }
    render() {
        const store = this.props[STORE_PROJECT_INTERACTIIVE_LABELS];

        return(
            <>
                <Modal
                    title="Report a problem"
                    visible={store!.isProblemReportModalVisible}
                    onCancel={() => store.setIsProblemReportModalVisible(false)}
                    maskClosable={false}
                    destroyOnClose
                    closable={false}
                    centered
                    className="alpha-portal-dialog two-columns confirm"
                    footer={[
                        <Button size="large" className="light" data-id="iota-add-group-dialog-cancel" key="back" onClick={() => store.setIsProblemReportModalVisible(false)}>
                            Cancel
                        </Button>,
                        <Button size="large" data-id="iota-add-group-dialog-submit" key="submit" type="primary" onClick={this.handleSubmit}>
                        Submit
                        </Button>,
                    ]}
                >
                    <Form ref={this.formRef}>
                        <FormItem
                            name="problem"
                            rules={[
                                { required: true, message: 'Please input a problem!', whitespace: true }
                            ]}
                        >
                            <TextArea rows={4} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => store.handleValueChange(e.target.value)}/>
                        </FormItem>
                    </Form>
                </Modal>
                <Tooltip title="Report a problem">
                    <Button 
                        style={store.selectedPackage ? {visibility: 'visible'} : {visibility: 'hidden'}} 
                        className="report-problem-button" 
                        onClick={() => store.setIsProblemReportModalVisible(true)}
                    >
                        <i className="alpha-icon md rule-problem-icon"/>
                    </Button>
                </Tooltip>
            </>
        );
    }
}
export default observer(PackageProblemMarker);