import * as React from 'react';
import { observer, inject, Observer } from 'mobx-react';
import { Table, Select, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ProjectStores, PackagesProblemsReportVisualStore } from '../stores';
import { STORE_PACKAGE_PROBLEM_REPORTS_UI } from '../constants';
import { PackageProblemReport, ProblemReport } from '../../common/types';
import { TextPopover } from '../../common/components';
import { ReportedProblemState } from '../stores/PackagesProblemsReportVisualStore';

type Props = ProjectStores;
const {Option} = Select;
export class PackageProblemReportsTable extends React.Component<Props, {changed: boolean}> {
    private store: PackagesProblemsReportVisualStore;
    constructor(props: Props) {
        super(props);
        this.store = this.props.packageProblemsReportsUI!;
        this.expandedRowRender = this.expandedRowRender.bind(this);
        this.state = {changed: false};
    }

    render() {
        const columns: ColumnProps<PackageProblemReport>[] = [
            { title: 'Package Id ', dataIndex: 'packageId', key: 'packageId' },
            { title: 'Package Name ', dataIndex: 'packageName', key: 'packageName' },
            { title: 'Last Modified', dataIndex: 'lastModified', key: 'lastModified' }
        ];

        return (
            <Table
                loading={this.store.isLoading}
                className="components-table-demo-nested"
                columns={columns}
                rowKey={p => p.id}
                expandedRowRender={this.expandedRowRender}
                dataSource={this.store.filteredReports || []}
                size="small"
            />
        );
    }

    private cutText(text: string) {
        if (text.length > 50) {
            return text.substring(0, 50) + '...';
        } else {
            return text;
        }
    }

    private getFriendlyName(status: ReportedProblemState) {
        if (status === ReportedProblemState.InProgress) {
            return 'In Progress';
        } else {
            return status;
        }
    }

    private expandedRowRender = (report: PackageProblemReport) => {
        const columns: ColumnProps<ProblemReport>[] = [
            { title: 'Report Id', dataIndex: 'problemId', key: 'problemId', width: 100, render: (value: string) => {

                return (<span style={{marginLeft: 20}}>{value}</span>);
            }},
            { title: 'User', dataIndex: 'user', key: 'user'},
            { title: 'Create Date', dataIndex: 'createDate', key: 'createDate',
                sorter: (a, b) => {
                    return a.createDate.localeCompare(b.createDate); 
                }, sortDirections: ['ascend', 'descend'], defaultSortOrder: 'descend',
                render: (text: string, record: ProblemReport) => {
                    return(new Date(record.createDate).toLocaleString());
                }
            },
            { title: 'Page', dataIndex: 'page', key: 'page' },
            { title: 'Message', dataIndex: 'message', key: 'message',
                render: (text: string, record: ProblemReport) => {
                    return (
                        <TextPopover popoverText={record.message} text={this.cutText(record.message)}/>
                    ); 
                }
            },
            { title: 'Status', dataIndex: 'status', key: 'status', render: (__: string, record: ProblemReport) => {
                return (
                    <Observer>{() => (                
                        <Select 
                            style={{width: 120}}
                            placeholder="Select status"
                            value={this.getFriendlyName(this.store.problemStatusDict[record.problemId] as ReportedProblemState)}
                            onChange={(value: string) => {
                                this.store.setProblemStatus(value as ReportedProblemState, record.problemId); 
                                this.setState({changed: !this.state.changed});
                            }}
                        >
                            {this.store.statuses.map(s => (
                                <Option key={s} value={this.getFriendlyName(s)}>{this.getFriendlyName(s)}</Option>))}
                        </Select>)}
                    </Observer>
                );
            } },
            { title: '', dataIndex: 'view', key: 'view', render: (__: string, record: ProblemReport) => {
                return (
                    <Button onClick={() => this.store.handleViewButtonClick(record.problemId)}>View</Button>
                ); 
            }
            }

        ];

        return (
            <Observer>
                {() => (
                    <Table
                        rowKey={(r, i) => r.message + (i && i!.toString() || '')}
                        columns={columns}
                        dataSource={report.reports}
                        pagination={false}
                        // eslint-disable-next-line max-len
                        rowClassName={(record: ProblemReport) => {
                            return (this.state.changed || true) && `${this.store.problemStatusDict[record.problemId]}-report-row`;  
                        }}
                        size="small"
                    />
                )}
            </Observer>
        );
    };
}

export default inject(STORE_PACKAGE_PROBLEM_REPORTS_UI)(observer(PackageProblemReportsTable));