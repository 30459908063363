import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, Button, Table, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { ColumnProps } from 'antd/lib/table';
import { CreateEditWorkflowFormValues, WorkflowOutputSchemeDto } from '../../types';
import { CreateEditOutputSchemeDialog } from '..';
import { workflowOutputSchemeTypeOptions } from '../../misc';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';
import './WorkflowsOutputSchemes.less';

interface Props {
    workflow: InstructWorkflowModel;
    form: FormInstance<CreateEditWorkflowFormValues>
}

const WorkflowsOutputSchemes: React.FC<Props> = ({ workflow, form }) => {
    const [createSchemeDialogVisible, setCreateSchemeDialogVisible] = React.useState(false);

    const [editableOutputSchemeIndex, setEditableOutputSchemeIndex] = React.useState<number | null>(null);

    const columns: ColumnProps<WorkflowOutputSchemeDto>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: 300,
            render: (name: string) => (
                <span data-id-cells="name" className="cell-limited-max-width name">
                    {name}
                </span>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 150,
            render: (_, outputScheme: WorkflowOutputSchemeDto) => {
                const label = workflowOutputSchemeTypeOptions.find(o => o.value === outputScheme.type)?.label ?? '';
                return <span>{label}</span>;
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (_, __, index) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setEditableOutputSchemeIndex(index);
                            setCreateSchemeDialogVisible(true);
                        }}
                    >
                        Edit
                    </a>
                    <a onClick={() => workflow.removeOuputScheme(index)}>Delete</a>
                </Space>
            )
        }
    ];

    return (
        <div>
            <CreateEditOutputSchemeDialog
                form={form}
                workflow={workflow}
                editableOutputSchemeIndex={editableOutputSchemeIndex}
                isVisible={createSchemeDialogVisible}
                setIsVisible={setCreateSchemeDialogVisible}
                clearEditableOutputSchemeIndex={() => setEditableOutputSchemeIndex(null)}
            />

            <Divider orientation="left">Output Schemes</Divider>

            <Table
                className="workflows-output-schemes-table"
                rowKey={(outputScheme: WorkflowOutputSchemeDto, index: number) => outputScheme.name + index}
                dataSource={workflow.modifiedOutputSchemes.slice()}
                columns={columns}
                pagination={false}
                bordered
            />

            <Button type="dashed" onClick={() => setCreateSchemeDialogVisible(true)} block>
                + Add Output Scheme
            </Button>
        </div>
    );
};

export default observer(WorkflowsOutputSchemes);
