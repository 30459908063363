import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Dropdown, Select, Checkbox, Form, Input, Button, Popconfirm } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { SettingOutlined } from '@ant-design/icons';
import { ChatbotStore } from '../../stores';
import { ChatbotSettingsValues } from '../../types';
import { workflowOutputSchemeTypeOptions } from '../../../instruct_workflows/misc';
import './ChatbotSettings.less';

type Props = {
    store: ChatbotStore;
    settingsForm: FormInstance<ChatbotSettingsValues>
};

const ChatbotSettings: React.FC<Props> = ({ store, settingsForm }) => {
    const schemeNameDisabled = React.useMemo(
        () =>
            !!store.settings.values.instructWorkflowCustomOutputSchemeType ||
            !!store.settings.values.instructWorkflowCustomOutputScheme,
        [
            store.settings.values.instructWorkflowCustomOutputSchemeType,
            store.settings.values.instructWorkflowCustomOutputScheme
        ]
    );

    const customSchemeDisabled = React.useMemo(
        () => !!store.settings.values.instructWorkflowOutputSchemeName,
        [store.settings.values.instructWorkflowOutputSchemeName]
    );

    const onValuesChange = (changedValues: Partial<ChatbotSettingsValues>) => {
        const resetOutputSchemeName =
            'instructWorkflowId' in changedValues ||
            changedValues.instructWorkflowCustomOutputSchemeType ||
            changedValues.instructWorkflowCustomOutputScheme;

        if (resetOutputSchemeName) {
            settingsForm.setFieldsValue({ instructWorkflowOutputSchemeName: null });
        }

        if (changedValues.instructWorkflowOutputSchemeName) {
            settingsForm.setFieldsValue({ instructWorkflowCustomOutputSchemeType: null });
            settingsForm.setFieldsValue({ instructWorkflowCustomOutputScheme: null });
        }

        store.settings.setValues(settingsForm.getFieldsValue());
    };

    const menu = (
        <Form
            id="chatbot-settings-form"
            className="chatbot-settings-form"
            layout="vertical"
            form={settingsForm}
            onValuesChange={onValuesChange}
            preserve={false}
        >
            <Form.Item
                name="instructWorkflowId"
                label="Instruct workflow"
                initialValue={store.settings.values.instructWorkflowId}
                rules={[
                    {
                        required: true,
                        message: 'Please select instruct workflow'
                    }
                ]}
            >
                <Select
                    className={`instruct-workflow-select ${store.worflowsLoading ? 'loading' : ''}`}
                    placeholder="Select workflow"
                    loading={store.worflowsLoading}
                    allowClear
                    options={store.instructWorkflows.map(workflow => ({
                        value: workflow.id,
                        label: workflow.name
                    }))}
                />
            </Form.Item>

            <Form.Item
                name="instructWorkflowOutputSchemeName"
                label="Output scheme"
                initialValue={store.settings.values.instructWorkflowOutputSchemeName}
            >
                <Select
                    allowClear
                    placeholder="Select output scheme"
                    disabled={schemeNameDisabled}
                    options={
                        store.selectedInstructWorkflow
                            ? store.selectedInstructWorkflow.outputSchemes.map(scheme => ({
                                value: scheme.name,
                                label: scheme.name
                            }))
                            : []
                    }
                />
            </Form.Item>

            <Form.Item
                name="instructWorkflowCustomOutputSchemeType"
                label="Custom output scheme type"
                initialValue={store.settings.values.instructWorkflowCustomOutputSchemeType}
            >
                <Select options={workflowOutputSchemeTypeOptions} disabled={customSchemeDisabled} allowClear />
            </Form.Item>

            <Form.Item
                name="instructWorkflowCustomOutputScheme"
                label="Custom output scheme"
                initialValue={store.settings.values.instructWorkflowCustomOutputScheme}
            >
                <Input.TextArea
                    className="custom-output-scheme"
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    disabled={customSchemeDisabled}
                />
            </Form.Item>

            <Form.Item name="useContext" initialValue={store.settings.values.useContext} valuePropName="checked">
                <Checkbox className="use-context-checkbox">Use Context</Checkbox>
            </Form.Item>

            <Popconfirm
                placement="topRight"
                title="Are you sure you want to clear all chats?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => store.clearAllChats()}
                overlayStyle={{ zIndex: 2000 }}
            >
                <Button className="light">Clear all chats</Button>
            </Popconfirm>
        </Form>
    );

    return (
        <Dropdown
            placement="topRight"
            trigger={['click']}
            overlay={menu}
            visible={store.settingsVisible}
            onVisibleChange={store.setSettingsVisible}
            arrow
        >
            <SettingOutlined className="header-button" />
        </Dropdown>
    );
};

export default observer(ChatbotSettings);
