import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Select, Input, Row, Col, Checkbox, Tooltip } from 'antd';
import { AdministrationStores } from '../stores';
import Form, { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { UserModel } from '../types/UserModel';
import { LoginTypes } from '../stores/UsersManagerStore';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Utils } from '../../common/services/Utils';
import { EMAIL_REGEX_PATTERN } from '../../../modules/common/Utils';
import { UsersHelper } from '../misc/UsersHelper';

type Props = AdministrationStores;

export const AddUserDialog: React.FC<Props> = observer(({ UsersManager: store }) => {
    const [form] = useForm();
    const [currentRoles, setCurrentRoles] = React.useState([] as string[]);
    const [currentAdditionalRoles, setCurrentAdditionalRoles] = React.useState([] as string[]);

    React.useEffect(() => {
        if (store?.isAddUserDialogVisible) {
            form.resetFields();
            setCurrentRoles([]);
            setCurrentAdditionalRoles([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store?.isAddUserDialogVisible]);
    
    const handleSubmit = async () => {
        form.validateFields().then((values) => {
            values.email = values.email.toLowerCase();
            values.roles = [...values.roles, ...values.additionalRoles];
            store!.createUpdateUser(values as UserModel);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleCancel = () => {
        store!.setIsAddUserDialogVisible(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const compareToFirstPassword = (rule: any, value: any, callback: any) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords should match!');
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const similarEmailsValidator = (rule: any, value: any, callback: any) => { 
        let emailsToValidate = store!.users.filter(u => u.email).map(u => u.email.toLowerCase());
        if (value && emailsToValidate.includes(value.toLowerCase())) {
            callback('User with the same email is already registered!');
        } else {
            callback();
        }
    };

    const updateCheckedRoles = (e: CheckboxChangeEvent, roleId: string) => {
        let newRoles = [];
        if (e.target.checked) {
            newRoles = [...currentRoles, roleId];
        } else {
            newRoles = currentRoles.filter(r => r !== roleId);
        }

        newRoles = [...new Set(newRoles)];

        setCurrentRoles(newRoles);
        let obj = {roles: newRoles};
        form.setFieldsValue(obj);
    };

    const updateCheckedAdditionalRoles = (e: CheckboxChangeEvent, roleId: string) => {
        let newRoles = [];
        if (e.target.checked) {
            newRoles = [...currentAdditionalRoles, roleId];
        } else {
            newRoles = currentAdditionalRoles.filter(r => r !== roleId);
        }

        newRoles = [...new Set(newRoles)];

        setCurrentAdditionalRoles(newRoles);
        let obj = {additionalRoles: newRoles};
        form.setFieldsValue(obj);
    };

    return (
        <Modal
            visible={store!.isAddUserDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            title="New user"
            destroyOnClose
            width={660}
            centered
            className="alpha-portal-dialog two-columns"
            footer={[
                <Button className="light" data-id="add-user-dialog-cancel" size="large" key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button data-id="add-user-dialog-submit" size="large" key="submit" type="primary" onClick={handleSubmit}>
                  Add user
                </Button>
            ]}
        >
            <Form 
                layout="vertical"
                data-id="add-user-dialog-form"
                form={form} 
                initialValues={{loginType: LoginTypes.internalUser}}>
                <Row gutter={20}>
                    <Col span="12">
                        <FormItem
                            label="First name"
                            name="firstName"
                            rules={[
                                { required: true, message: 'Please input first name!', whitespace: true }
                            ]}
                        >
                            <Input size="large" />
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem
                            label="Last name"
                            name="lastName"
                            rules={[
                                { required: true, message: 'Please input last name!', whitespace: true }
                            ]}
                        >
                            <Input size="large" />
                        </FormItem>
                    </Col>

                    <Col span="24">
                        <FormItem 
                            label="Username"
                            name="userName"
                            rules = {[
                                { required: true, message: 'Please input username!', whitespace: true }
                            ]}
                        ><Input size="large"/>
                        </FormItem>
                    </Col>
                    <Col span="24">
                        <FormItem
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: 'Please input email!', whitespace: true },
                                {   
                                    pattern: EMAIL_REGEX_PATTERN, 
                                    message: 'Input valid email address please.'
                                },
                                {validator: similarEmailsValidator}
                            ]}
                        >
                            <Input size="large" />
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem
                            label="Password"
                            name="password"
                            rules={[
                                { required: true, message: 'Please input password!', whitespace: true },
                                { 
                                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/gm, 
                                    message: 'Password should be at least 6 characters long and contain at least one number, upper case and lower case character.'
                                }]
                            }
                        >
                            <Input.Password 
                                size="large" 
                                iconRender={visible => (visible 
                                    ? <span style={{height: 22}}><i className="alpha-icon lg eye-visible" /></span> 
                                    : <span style={{height: 22}}><i className="alpha-icon lg eye-invisible" /></span>)
                                }
                            />
                        </FormItem>
                    </Col>
                    
                    <Col span="12">
                        <FormItem
                            label="Confirm Password"
                            name="confirmPassword"
                            rules={[
                                { required: true, message: 'Please input password!', whitespace: true },
                                { validator: compareToFirstPassword}
                            ]
                            }
                        >
                            <Input.Password 
                                size="large" 
                                iconRender={visible => (visible 
                                    ? <span style={{height: 22}}><i className="alpha-icon lg eye-visible" /></span> 
                                    : <span style={{height: 22}}><i className="alpha-icon lg eye-invisible" /></span>)
                                }
                            />
                        </FormItem> 
                    </Col>
                    <Col span="24">
                        <FormItem
                            label="Group"
                            name="group"
                            initialValue={''}
                        >
                            <Select size="large" options={store!.userGroupSelectValues.map(g => {
                                return {value: g.usersGroup.id, label: g.usersGroup.name}; 
                            })} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                        <div className="dialog-administation-role-form-item">
                            <FormItem
                                label="Basic roles"
                                name="roles"
                                rules={[{
                                    required: true, message: 'Please select at least one basic role!'
                                }]}
                            >
                                <Checkbox.Group style={{width: '100%'}}>
                                    {UsersHelper.getBasicRoles(store?.sortedRoles, store?.customRoles).map(r => (
                                        <Checkbox 
                                            className="checkbox-with-subtitle" 
                                            key={r.id} 
                                            value={UsersHelper.isCustomRole(r) ? r.id : r.name}
                                            onChange={(e) => updateCheckedRoles(e, UsersHelper.isCustomRole(r) ? r.id :r.name)}
                                        >
                                            <div>
                                                <div style={{display: 'inline'}}>
                                                    {Utils.getAppRoleDisplayName(r.name)}
                                                </div>
                                                <div style={{display: 'inline-block'}}> 
                                                    <Tooltip title={UsersHelper.getRolePermissions(r)}>
                                                        <i className="alpha-icon lg question-icon" style={{verticalAlign: 'middle'}} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </FormItem>
                        </div>
                    </Col>
                    <Col span="12">
                        <div className="dialog-administation-role-form-item">
                            <FormItem
                                label="Additional roles"
                                name="additionalRoles"
                                initialValue={[]}
                            >
                                <Checkbox.Group style={{width: '100%'}}>
                                    {UsersHelper.getOtherRoles(store?.sortedRoles, store?.customRoles).map(r => (
                                        <Checkbox  
                                            className="checkbox-with-subtitle" 
                                            key={r.id} 
                                            value={UsersHelper.isCustomRole(r) ? r.id : r.name}
                                            onChange={(e) => updateCheckedAdditionalRoles(e, UsersHelper.isCustomRole(r) ? r.id :r.name)}
                                        >
                                            <div>
                                                <div style={{display: 'inline'}}>
                                                    {Utils.getAppRoleDisplayName(r.name)}
                                                </div>
                                                <div style={{display: 'inline-block'}}> 
                                                    <Tooltip title={UsersHelper.getAuthLevelRoleDescription(r.name)}>
                                                        <i className="alpha-icon lg question-icon" style={{verticalAlign: 'middle'}} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </FormItem>
                        </div>
                    </Col>
                </Row>
               
            </Form>
        </Modal>
    );
});

export default AddUserDialog;