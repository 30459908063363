// import Keycloak from 'keycloak-js';
// Keycloak is imported in public/index.html via <script>, because webpack can't handle it
// If you want to edit something here, you can uncomment the import above to get intellisense
// Don't forget to comment it back before commiting or trying to run, because it won't work
// @ts-nocheck
import { UnauthorizedError } from '../types/CustomErrors';

const keyConfig = require('./keycloak.json');

class SecurityService {
    // eslint-disable-next-line no-undef
    inst: Keycloak;

    get token() {
        return this.inst.token!;
    }

    constructor() {
        if (process.env.REACT_APP_AUTH_URL) {
            keyConfig.url = process.env.REACT_APP_AUTH_URL;
            console.log(keyConfig);
            // eslint-disable-next-line no-undef
            this.inst = new Keycloak(keyConfig);  
        }
    }

    init() {
        return new Promise((res, rej) => {
            const promise = this.inst.init({ onLoad: 'login-required', checkLoginIframe: false, pkceMethod: 'S256' });
            promise.then((auth) => { 
                if (auth) {
                    res({ authenticated: true });
                } else {
                    rej(new UnauthorizedError());
                }
            } ).catch((err) => rej(err));
        });      
    }

    getOrRefreshToken(): Promise<{ token: string; refreshed: boolean }> {
        if (!this.inst.isTokenExpired) {
            return Promise.resolve({ token: this.inst.token!, refreshed: false });
        }

        return new Promise<{ token: string; refreshed: boolean }>((res, rej) => {
            this.inst.updateToken(30).then((refreshed) => {
                if (refreshed) {
                    console.log('Token was updated');
                }

                res({ token: this.inst.token!, refreshed: true });
            }).catch(err => {
                console.error('Error during token refresh');
                rej(err);
            });
        });
    }

    invoke<T>(func: (token: string) => Promise<T>): Promise<T> {
        return new Promise<T>((res, rej) => {
            if (!this.inst.isTokenExpired(30)) {
                func(this.inst.token!).then(s => res(s)).catch(err => rej(err));
                return;
            }

            console.log('Token is expired');
            this.inst.updateToken(30).then((refreshed) => {
                if (refreshed) {
                    console.log('Token was updated');
                }

                func(this.inst.token!).then(s => res(s)).catch(err => rej(err));
            }).catch(err => {
                console.error('Error during token refresh');
                rej(err);
            });
        });   
    }
}

class MockedSecurityService extends SecurityService {
    // eslint-disable-next-line no-undef
    inst: Keycloak;

    get token() {
        return 'empty';
    }

    init() {
        return Promise.resolve(true);
    }

    getOrRefreshToken(): Promise<{ token: string; refreshed: boolean }> {
        return Promise.resolve({ token: this.token, refreshed: false });
    }

    invoke<T>(func: (token: string) => Promise<T>): Promise<T> {
        return new Promise<T>((res, rej) => {
            func(this.token).then(s => res(s)).catch(err => rej(err));
        });   
    }
}

let service: SecurityService;
if (process.env.REACT_APP_AUTH_URL) {
    service = new SecurityService();    
} else {
    // console.debug('Authentication is not enabled');
    service = new MockedSecurityService();
}

export default service;