import { RootStores } from '../../common/stores';
import UploadedPackagesVisualStore from './UploadedPackagesVisualStore';
import AdministrationProjectVisualStore from './AdministrationProjectVisualStore';
import ProjectMetadataVisualStore from './ProjectMetadataVisualStore';
import { AdministrationService } from '../services/AdministrationService';
import { ProjectsService } from '../../common/services';
import ProjectFormVisualStore from './ProjectFormVisualStore';
import ProjectExportStore from './ProjectExportStore';
import ProjectImportStore from './ProjectImportStore';
import {
    STORE_PROJECT_METADATA,
    STORE_ADMINISTRATION_PROJECT,
    STORE_UPLOADED_PACKAGES,
    STORE_PROJECT_FORM,
    STORE_PACKAGE_PROBLEM_REPORTS_UI,
    STORE_PROJECT_EXPORT,
    STORE_PROJECT_IMPORT
} from '../constants';
import { STORE_PROJECTS_ROOT_UI, STORE_ERRORS, STORE_ROUTER } from '../../common/constants';
import PackagesProblemsReportVisualStore from './PackagesProblemsReportVisualStore';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
import InstructWorkflowService from '../../instruct_workflows/services/InstructWorkflowService';

export { default as AdministrationProjectVisualStore } from './AdministrationProjectVisualStore';
export { default as ProjectFormVisualStore } from './ProjectFormVisualStore';
export { default as ProjectMetadataVisualStore } from './ProjectMetadataVisualStore';
export { default as UploadedPackagesVisualStore } from './UploadedPackagesVisualStore';
export { default as PackagesProblemsReportVisualStore } from './PackagesProblemsReportVisualStore';

export function injectProjectStores(obj: RootStores) {
    const adminService = new AdministrationService();
    const projService = new ProjectsService();
    const globalAdministrationService = new GlobalAdministrationService();
    const instructWorkflowService = new InstructWorkflowService();

    obj[STORE_PROJECT_METADATA] = new  ProjectMetadataVisualStore(obj[STORE_PROJECTS_ROOT_UI]);
    obj[STORE_ADMINISTRATION_PROJECT] = new  AdministrationProjectVisualStore(obj[STORE_PROJECT_METADATA], obj[STORE_ERRORS], adminService); 
    obj[STORE_UPLOADED_PACKAGES] = new  UploadedPackagesVisualStore(
        obj[STORE_PROJECT_METADATA], 
        projService, 
        obj[STORE_PROJECTS_ROOT_UI], 
        adminService, 
        obj[STORE_ERRORS], 
        globalAdministrationService
    );
    obj[STORE_PROJECT_FORM] = new  ProjectFormVisualStore(obj[STORE_PROJECTS_ROOT_UI], projService, instructWorkflowService);
    obj[STORE_PACKAGE_PROBLEM_REPORTS_UI] = new PackagesProblemsReportVisualStore(obj[STORE_PROJECTS_ROOT_UI],  obj[STORE_ROUTER], projService);
    obj[STORE_PROJECT_EXPORT] = new ProjectExportStore(projService);
    obj[STORE_PROJECT_IMPORT] = new ProjectImportStore(projService);
}

export type ProjectStores = {
    [STORE_ADMINISTRATION_PROJECT]?: AdministrationProjectVisualStore;
    [STORE_PROJECT_METADATA]?: ProjectMetadataVisualStore;
    [STORE_UPLOADED_PACKAGES]?: UploadedPackagesVisualStore;
    [STORE_PROJECT_FORM]?: ProjectFormVisualStore;
    [STORE_PACKAGE_PROBLEM_REPORTS_UI]?: PackagesProblemsReportVisualStore;
    [STORE_PROJECT_EXPORT]?: ProjectExportStore;
    [STORE_PROJECT_IMPORT]?: ProjectImportStore
};