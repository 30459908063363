import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_PYDANTIC_SCHEMA_GENERATOR } from '../stores/constants';
import { PydanticSchemaGeneratorStores } from '../stores';
import { PydanticSchemaGenerator } from '../components';

const SchemaGeneratorPage: React.FC<PydanticSchemaGeneratorStores> = ({ pydanticSchemaGeneratorUI: store }) => {
    if (!store) {
        return null;
    }

    return <PydanticSchemaGenerator store={store} />;
};

export default inject(STORE_PYDANTIC_SCHEMA_GENERATOR)(observer(SchemaGeneratorPage));
