import * as React from 'react';
import {
    Input, Form, Row, Col, FormInstance  } from 'antd';
import { inject } from 'mobx-react';
import RuleEditVisualStore from '../stores/RuleEditVisualStore';
import { ElasticSearchRawRule } from '../models';
import { STORE_RULE_EDIT } from '../constants';
import RuleEditGeneralSettings from './RuleEditGeneralSettings';
import RuleType from '../types/RuleType';
import { observer } from 'mobx-react-lite';

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance
};

export const RuleEditElasticSearchRawForm: React.FC<Props> =  ({store, selectType, form})=> {
    const rule = store.editableRule as ElasticSearchRawRule;
    React.useEffect(() => {
        form.setFieldsValue(getInitialValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, rule]);

    const getInitialValues = () => {
        return {
            name: rule.name,
            tag: rule.tag,
            query: rule.query,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority
        };
    };

    return (
        <>
            {store.error.map((e, i) => <h4 key={e + i} style={{color: 'red'}}>{e}</h4>)}
            <Form 
                form={form}
                layout="vertical"
                className="rule-edit-form"
                data-id="rule-edit-form"
            >
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form}/>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Query"
                            name="query"
                            rules={[{
                                required: true, message: 'Please input query!',
                            }]}
                            data-id="rule-query-input"
                        >
                            <Input/>
                        </Form.Item>    
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default (inject(STORE_RULE_EDIT)(observer(RuleEditElasticSearchRawForm)));