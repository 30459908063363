import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { InstructWorkflowStores } from '../stores';
import { STORE_INSTRUCT_WORKFLOW, STORE_INSTRUCT_WORKFLOW_IMPORT_EXPORT } from '../stores/constants';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { WorkflowManager } from '../components';

const InstructWorkflowsPage: React.FC<InstructWorkflowStores> = ({
    instructWorkflowUI: store,
    instructWorkflowImportExportUI: importExportStore
}) => {
    if (!store || !importExportStore) {
        return null;
    }

    return (
        <HasPermission
            permissionClaim={AppPermissions.CanEditMlStorage}
            yes={() => <WorkflowManager store={store} importExportStore={importExportStore} />}
        />
    );
};

export default inject(STORE_INSTRUCT_WORKFLOW, STORE_INSTRUCT_WORKFLOW_IMPORT_EXPORT)(observer(InstructWorkflowsPage));
