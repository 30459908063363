import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input } from 'antd';
import { WorkflowTypeMessagesTemplateDto, WorkflowTypeInputDto } from '../../types';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';

interface Props {
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof WorkflowTypeMessagesTemplateDto & keyof WorkflowTypeInputDto;
    input?: WorkflowTypeInputDto;
    workflow?: InstructWorkflowModel
}

const WorkflowTextInputCell: React.FC<Props> = ({ editable, children, dataIndex, input, workflow, ...restProps }) => {
    if (!editable) {
        return <td {...restProps}>{children}</td>;
    }

    if (dataIndex === 'value' && workflow && input) {
        const existingInputValue = workflow.getInputValueById(input.id);

        return (
            <td {...restProps}>
                <Form.Item
                    name={['inputValues', input.id]}
                    initialValue={existingInputValue ? existingInputValue.value : null}
                    rules={[{ required: true, message: 'Please enter value!' }]}
                >
                    <Input />
                </Form.Item>
            </td>
        );
    }

    return null;
};

export default observer(WorkflowTextInputCell);
