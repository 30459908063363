import * as React from 'react';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Package } from '../../common/models';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const Option = Select.Option;

interface PackagesListProps {
    size?: 'default' | 'large' | 'small';
    onPackageSelected: (p: Package | null) => void;
    packages: Package[];
    currentPackage?: Package;
    dataId?: string;
    itemHighlightCondition?: (pkgId: string) => boolean;
    loading?: boolean;
    onSearch?: (value: string) => void
}

class PackagesList extends React.Component<PackagesListProps, object> {
    render() {
        const { size, onPackageSelected, packages, currentPackage, itemHighlightCondition, onSearch, loading } = this.props;
        const allPackages = [{ key: '0', label: 'All packages', style: '' },
            ...packages.slice().sort((a, b) => new Date(a.indexDate!) > new Date(b.indexDate!) ? -1 : new Date(a.indexDate!) < new Date(b.indexDate!) ? 1 : 0).map(p => { 
                let style = '';
                if (p.operationState && _.includes(p.operationState, 'UserReviewed') || itemHighlightCondition && itemHighlightCondition(p.id)) {
                    style = 'yellow-highlight-selected';
                }
                return { key: p.id, label: p.name, style: style}; 
            })];
       
        return (
            <Select 
                // eslint-disable-next-line max-len
                defaultValue={currentPackage == null ? allPackages[0].label as string : allPackages.find(a => a.key === currentPackage.id) ? 
                    allPackages.find(a => a.key === currentPackage.id)!.label as string : allPackages[0].label as string }
                size={size as SizeType}
                showSearch
                optionFilterProp="children"
                data-id={this.props.dataId ? this.props.dataId : 'package-list-selector'}
                value={currentPackage ? currentPackage.id : allPackages[0].key}
                // eslint-disable-next-line max-len
                filterOption={(input, option) => (option?.props?.children?.props?.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value: SelectValue) => { 
                    const pkg = packages.find(p => p.id === value); 
                    onPackageSelected(pkg || null); 
                }}
                loading={loading}
                onSearch={onSearch}
            >  
                {/* eslint-disable-next-line max-len */}
                {allPackages.map((p, index) => (
                    <Option 
                        data-id={this.props.dataId ? `${this.props.dataId}-${p.key}` : `package-list-selector-${p.key}`}
                        className={p.style} 
                        key={p.key} 
                        value={p.key}
                    >
                        <span className="package-label" style={{ whiteSpace: 'pre' }}>{index === 0 ? p.label : `${index.toString()}: ${p.label}`}</span>
                    </Option>
                ))}
            </Select>
        );
    }
}

export default observer(PackagesList);