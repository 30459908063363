import * as React from 'react';
import { Modal, Button } from 'antd';
import { observer } from 'mobx-react';
import { ProjectsRootVisualStore } from '../modules/common/stores';

type Props = {
    store: ProjectsRootVisualStore
};

const ProjectDeleteDialog: React.FC<Props> = ({ store }) => {
    const deleteProject = () => {
        if (store.currentProject) {
            store.deleteProject(store.currentProject.id);
            store.setProjectDeleteDialogVisible(false);
            store.setProjectEditDialogVisible(false);
        }
    };

    return (
        <Modal
            visible={store.projectDeleteDialogVisible}
            closable={false}
            maskClosable={false}
            title="Please confirm"
            centered
            className="alpha-portal-dialog two-columns confirm"
            zIndex={2000}
            footer={[
                <Button
                    data-id="remove-project-cancel"
                    key="remove-project-cancel"
                    onClick={() => store.setProjectDeleteDialogVisible(false)}
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="remove-project-submit"
                    key="remove-project-submit"
                    size="large"
                    type="primary"
                    onClick={deleteProject}
                >
                    Remove project
                </Button>,
            ]}
        >
            Are you sure that you want to delete project
            {store.currentProject ? ` '${store.currentProject.title}'` : ''}? This action cannot be undone.
        </Modal>
    );
};

export default observer(ProjectDeleteDialog);
