import appClient from '../../common/services/AppClient';
import { ApplicationDefinitionConditionalDto } from '../../iota_applications/types/ApplicationDefinition';
import { ApplicationDefinitionConditionalPreviewBindingResponse } from '../../application_definition_conditional/types';
import security from '../../common/services/SecurityService';
import { Utils } from '../../common/services/Utils';
import { RcFile } from 'antd/lib/upload';

export default class ApplicationDefinitionConditionalService {
    async updateData(
        projectId: string,
        appDefId: string,
        data: Pick<ApplicationDefinitionConditionalDto, 'conditions' | 'inputGroups'>
    ) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota/applications/${appDefId}/application-definition-conditional`;

        return appClient.post<Omit<ApplicationDefinitionConditionalDto, 'type'>>(url, data);
    }

    async previewApplication(
        projectId: string,
        appDefId: string,
        data: Pick<ApplicationDefinitionConditionalDto, 'conditions' | 'inputGroups'> & { packageIds: string[] }
    ) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota/applications/${appDefId}/application-definition-conditional/preview-application`;

        return appClient.post<ApplicationDefinitionConditionalPreviewBindingResponse[]>(url, data);
    }

    async exportAppConditions(id: string, projectId: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'iotaConditionsPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';
        const mapIotaIdInput = document.createElement('input');
        mapIotaIdInput.type = 'text';
        mapIotaIdInput.name = 'id';
        mapIotaIdInput.value = id;
        mapForm.appendChild(mapIotaIdInput);

        await security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/${id}/export-conditions${t}`;
            Utils.downloadFile(url, mapForm, 'iotaConditionsPostForm');
            return Promise.resolve();
        });
    }

    async commitAppBindings(id: string, projectId: string, file: RcFile) {
        var formData = new FormData();
        formData.append('file', file);
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/${id}/commit-conditions`;
        return appClient.post(url, formData);
    }
}
