import * as React from 'react';
import { AdministrationStores } from '../stores';
import { observer, Observer } from 'mobx-react-lite';
import { Layout, Table, Button, Checkbox, message, Tooltip, Tag } from 'antd';
import UsersTree from '../components/UsersTree';
import { ProjectResult } from '../../common/types';
import UserGroupDialog from './UserGroupDialog';
import LayoutHeader from '../../../components/LayoutHeader';
import { AppRoles } from '../../authorization/Permissions';
import { Utils } from '../../common/services/Utils';
import UserRoleEditModal from './UserRoleEditModal';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import UsersSearch from './UsersSearch';

const { Content, Sider } = Layout;

type Props = AdministrationStores;

export const Users: React.FC<Props> = ({ ProjectAssignment: store, UsersManager: usersManagerStore}) => {
    const [initialRoles, setInitialRoles] = React.useState<string[] | null>(null);

    const initialColumns = [
        {
            title: 'Projects',
            key: 'name',
            sorter: (a: ProjectResult, b: ProjectResult) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            render: (project: ProjectResult) => (
                <span data-id-name="Projects">{project.name}</span>
            )
        },  
        {
            title: 'Default project for upload',
            key: 'defaultProject',
            render: (project: ProjectResult) => (
                <Observer>
                    {() => (
                        <Checkbox 
                            data-id-name="Default"
                            checked={project.id === store!.checkedDefaultProject} 
                            onChange={(e) => store!.handleDefautProjectCheck(e.target.checked, project.id)}  
                        />)}
                </Observer>
            )
        }
    ];
    
    const [columns, setColumns] = React.useState(initialColumns);

    React.useEffect(() => {
        if (store && store.selectedNodeIsUser) {
            let extendedColumns = [...initialColumns];
            extendedColumns.push({
                title: 'Role',
                key: 'role',
                render: (project: ProjectResult) => (
                    <span>
                        {store.selectedNodeIsUser 
                            ? store.getObjectRoleAssignments(store.currentSelectedNode, project.id)?.map(r => (
                                <Tag className="alpha-portal-tag" color={r === AppRoles.admin ? '#273C75' : '#9BA0AA'} key={r}>{Utils.getAppRoleDisplayName(r)}</Tag>
                            ))
                            : ''}
                    </span>
                )
            });
            extendedColumns.push({
                title: '',
                key: 'actions',
                render: (project: ProjectResult) => (
                    <div className="row-actions-wrapper">
                        <Tooltip title="Edit user roles in project">
                            <Button 
                                type="link" 
                                onClick={() => openRoleAssignDialog(project.id)} 
                                size="small"
                            >
                                <i className="alpha-icon lg edit-icon" />
                            </Button>
                        </Tooltip>
                    </div>
                )
            });
            setColumns(extendedColumns);
        } else {
            setColumns(initialColumns);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store?.selectedNodeIsUser]);
    
    const openRoleAssignDialog = (projectId: string) => {
        setInitialRoles(store!.getObjectRoleAssignments(store!.currentSelectedNode, projectId));
        usersManagerStore!.setSelectedProjectId(projectId);
        usersManagerStore!.setIsUserRoleEditDialogVisible(true);
    };

    const onSelect = (record: ProjectResult, isSelected: boolean) => {
        store!.setSelectedKey(record.id, isSelected);
        store!.saveProjectAssignments();
    };

    const handleOnSelectAll = (isChecked: boolean ) => {
        store!.setAllKeys(isChecked);
        store!.saveProjectAssignments();
    };

    React.useEffect(() => {
        store!.getUsersAndGroups().catch(
            err => {
                message.error(err);
                return (<></>); 
            });
    },              [store]);

    const getRowStyle = (record: ProjectResult) => {
        let style = '';
        if (store!.isProjectCheckboxDisabled(record.id)) {
            style += 'project-assignments-projects-disabled-row';
        } 
        if (store!.isProjectDefaultCheckDisabled(record.id)) {
            style += ' project-assignments-default-project-disabled-row';
        }
        return style;
    };

    // const saveButton = (<div style={{height: 80}}><Button data-id="project-assignments-save" type="primary" onClick={store!.saveProjectAssignments}>Save</Button></div>);
    const layout = (
        <Layout className="screen-size" style={{...{height: '100%', background: 'white'}}}>
            <UserGroupDialog ProjectAssignment={store}/>
            <UserRoleEditModal userId={store!.currentSelectedNode} store={usersManagerStore!} initialRoles={initialRoles} successCallback={store!.getUsers} />
            <LayoutHeader 
                title="Project assignments"
                helpMessage="You can manage user access to projects and their default projects here."
                buttons={[
                    <Button 
                        key="user-manager-add-user-button" 
                        data-id="user-manager-add-user-button" 
                        type="primary"
                        size="large"
                        onClick={() => store!.setIsAddGroupDialogVisible(true)}
                    >
                        Add group
                    </Button>
                ]} 
            />
            <Layout style={{overflowY: 'auto'}}>
                <Sider width={235} style={{background: 'white', overflow: 'auto', paddingRight: '15px'}}>
                    <UsersSearch store={store!} />
                    <UsersTree ProjectAssignment={store} UsersManager={usersManagerStore}/>
                </Sider>

                <Content style={{background: 'white'}}> 
                    <Observer>
                        {() => (
                            <Table
                                data-id="project-assignments-projects-table"
                                rowClassName={getRowStyle}
                                className={'alpha-portal-table'}
                                dataSource={store!.currentSelectedNode ? store!.projects : []}
                                columns={columns}
                                scroll={{y: 'calc(100vh - 154px)'}}
                                rowKey="id"
                                onRow={(record) => {
                                    return {
                                        id: record.id,
                                        'data-id': record.id
                                    };
                                }}
                                pagination={false}
                                // eslint-disable-next-line max-len
                                rowSelection={{ selectedRowKeys: store!.selectedKeys, onSelect: onSelect, onSelectAll: handleOnSelectAll, hideSelectAll: true}}
                                // footer={() => saveButton} 
                            />
                        )}
                    </Observer>
                </Content>
            </Layout>
        </Layout>
    );
    if (store!.isLoading) {
        return <LoadingIndicator/>;
    } else {
        return layout;
    }

};

export default observer(Users);
