import { observable, action, computed, reaction } from 'mobx';
import { last } from 'lodash';
import { ProjectsRootVisualStore } from '../../common/stores';
import { ChatbotQuestionModel, ChatbotErrorModel, ChatbotSettingsModel } from '../models';
import { QuestionPackageFieldDto } from '../types';
import { InstructWorkflowDto } from '../../instruct_workflows/types';
import { Utils } from '../../common/services/Utils';
import ChatbotService from '../services/ChatbotService';
import InteractiveLabelsVisualStore from './InteractiveLabelsVisualStore';
import InstructWorkflowService from '../../instruct_workflows/services/InstructWorkflowService';
import ChatbotHistoryService from '../services/ChatbotHistoryService';

interface UiBehaviour {
    scrollIntoViewImmediately: boolean
}

interface PackageField extends QuestionPackageFieldDto {}

export default class ChatbotStore {
    @observable
    questions: ChatbotQuestionModel[] = [];

    @observable
    errors: ChatbotErrorModel[] = [];

    @observable
    dialogVisible: boolean = false;

    @observable
    settingsVisible: boolean = false;

    @observable
    instructWorkflows: InstructWorkflowDto[] = [];

    @observable
    worflowsLoading: boolean = false;

    @observable
    lastSentMessage: string = '';

    @observable
    uiBehaviour: UiBehaviour = { scrollIntoViewImmediately: false };

    readonly settings: ChatbotSettingsModel;

    private readonly historyService: ChatbotHistoryService;

    @computed
    get isBusy() {
        return this.questions.some(q => q.processing);
    }

    @computed
    get lastQuestion() {
        return last(this.questions);
    }

    @computed
    get project() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get selectedInstructWorkflow() {
        return this.instructWorkflows.find(f => f.id === this.settings.values.instructWorkflowId);
    }

    @computed
    get displayItems() {
        return [...this.questions, ...this.errors].sort((a, b) => Utils.safeDateCompare(a.createDate, b.createDate));
    }

    @computed
    get lastDisplayItem() {
        return last(this.displayItems);
    }

    @computed
    get selectedPackage() {
        return this.interactiveLabelsStore.selectedPackage;
    }

    constructor(
        private readonly service: ChatbotService,
        private readonly instructWorkflowService: InstructWorkflowService,
        private readonly interactiveLabelsStore: InteractiveLabelsVisualStore,
        private readonly projectsRootStore: ProjectsRootVisualStore
    ) {
        this.settings = new ChatbotSettingsModel(this);
        this.historyService = new ChatbotHistoryService(this);

        const lastSentMessage = this.historyService.getLastSentMessage();

        if (lastSentMessage) {
            this.setLastSentMessage(lastSentMessage);
        }

        reaction(
            () => this.selectedPackage,
            selectedPackage => {
                this.clearChat();

                if (!selectedPackage) {
                    return;
                }

                this.changeUiBehaviour({ scrollIntoViewImmediately: true });

                this.questions = this.historyService
                    .getPackageQuestions(selectedPackage.id)
                    .map(data => new ChatbotQuestionModel(this, this.service, data));
            }
        );
    }

    @action.bound
    setDialogVisible(dialogVisible: boolean) {
        this.dialogVisible = dialogVisible;
    }

    @action.bound
    setSettingsVisible(settingsVisible: boolean) {
        this.settingsVisible = settingsVisible;
    }

    @action.bound
    setQuestions(questions: ChatbotQuestionModel[]) {
        this.questions = questions;
    }

    @action.bound
    setErrors(errors: ChatbotErrorModel[]) {
        this.errors = errors;
    }

    @action.bound
    changeUiBehaviour(uiBehaviour: UiBehaviour) {
        this.uiBehaviour = uiBehaviour;
    }

    @action.bound
    addError(message: string) {
        this.errors.push(new ChatbotErrorModel(message));
    }

    @action.bound
    setLastSentMessage(lastSentMessage: string) {
        this.lastSentMessage = lastSentMessage;
        this.historyService.updateLastSentMessage(this.lastSentMessage);
    }

    @action.bound
    async askQuestion(message: string) {
        if (!this.selectedPackage) {
            return;
        }

        if (this.uiBehaviour.scrollIntoViewImmediately) {
            this.changeUiBehaviour({ scrollIntoViewImmediately: false });
        }

        this.setLastSentMessage(message);

        const lastQuestionNodeIds = this.settings.values.useContext && this.lastQuestion ? this.lastQuestion.nodeIds : [];

        const question = new ChatbotQuestionModel(this, this.service, { message, packageId: this.selectedPackage.id });

        this.questions.push(question);

        await question.ask(lastQuestionNodeIds);

        this.scrollAndHighlightFirstBlock(question);

        this.historyService.addPackageQuestion(question);
    }

    @action.bound
    async getInstructWorkflows() {
        if (!this.project) {
            return;
        }

        try {
            this.worflowsLoading = true;
            this.instructWorkflows = await this.instructWorkflowService.getWorkflows(this.project.id);

            if (this.settings.values.instructWorkflowId && !this.selectedInstructWorkflow) {
                this.settings.resetInstructWorkflow();
            }
        } finally {
            this.worflowsLoading = false;
        }
    }

    onFieldClick(packageField: PackageField) {
        this.interactiveLabelsStore.scrollAndHighlightBlock(packageField.field.id, packageField.field.blockType);
    }

    clearChat(clearLocalStorage?: boolean) {
        this.setQuestions([]);
        this.setErrors([]);

        if (clearLocalStorage && this.selectedPackage) {
            this.historyService.removePackageQuestions(this.selectedPackage.id);
        }
    }

    clearAllChats() {
        this.clearChat();
        this.historyService.removeAllPackagesQuestions();
    }

    private scrollAndHighlightFirstBlock(question: ChatbotQuestionModel) {
        if (question.response && question.response.packageFields.length) {
            const firstPackageField = question.response.packageFields[0];
            this.interactiveLabelsStore.scrollAndHighlightBlock(
                firstPackageField.field.id,
                firstPackageField.field.blockType
            );
        }
    }
}