import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Table, Button, Popconfirm, Layout, Switch, Tooltip, Upload, message, Spin, Space, Tag } from 'antd';
import { MLStorageStores } from '../stores';
import { MLModel, MLModelRevision } from '../types';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';
import { UploadChangeParam } from 'antd/lib/upload';
import { LoadingOutlined } from '@ant-design/icons';
import MLModelRulesModal from './MLModelRulesModal';
const { Content } = Layout;

type Props = MLStorageStores;

export const MLModelList: React.FC<Props> = ({ projectMLModelsUI: store }) => {
    const mlStore = store!;
    const [expandedKeys, setExpandedKeys] = React.useState([] as string[]);

    React.useEffect(() => {
        mlStore.setHeaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mlStore.currentProject]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_: string, record: MLModel) => {
                return {
                    children: record.name,
                    props: {
                        'data-id': `ml-model-list-${record.id}`, 'data-id-name': `ml-model-list-${record.name}`, 'data-id-type': 'ml-model-level0'
                    }
                };
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (_: string, record: MLModel) => {
                return {
                    children: record.type,
                    props: {
                        'data-id': `ml-model-list-${record.id}`, 'data-id-name': `ml-model-list-${record.type}`, 'data-id-type': 'ml-model-level0'
                    }
                };
            }
        },
        { title: 'Create Date', key: 'createDate', dataIndex: 'createDate',   
            render: (createDate: string) => {
                return {
                    children: Utils.formatDateStringShort(createDate),
                    props: {
                        'data-id-name': `ml-model-list-create-date-${createDate}`, 'data-id-type': 'ml-model-level0'
                    }
                };
            } 
        },
        { title: 'Update Date', key: 'updateDate', dataIndex: 'updateDate',   
            render: (updateDate: string) => {
                return {
                    children: Utils.formatDateStringShort(updateDate),
                    props: {
                        'data-id-name': `ml-model-list-update-date-${updateDate}`, 'data-id-type': 'ml-model-level0'
                    }
                };
            } 
        },
        { title: 'Device', key: 'device', dataIndex: 'device',   
            render: (device: string) => {
                return {
                    children: device,
                    props: {
                        'data-id-name': `ml-model-list-device-${device}`, 'data-id-type': 'ml-model-level0'
                    }
                };
            } 
        },
        {
            width: '350px',
            key: 'actions',
            render: (model: MLModel) => (
                
                <div className="table-operation">
                    <HasPermission entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanEditMlStorage}>
                        <Tooltip placement="top" title="Edit model">
                            <Button 
                                data-id={`ml-storage-list-edit-${model.id}`}
                                onClick={(e) => {
                                    navigateToModelForm(model); e.stopPropagation(); 
                                }}
                                disabled={store!.busyModelIds ? store!.busyModelIds.indexOf(model.id!) >= 0 : false}
                                type="link"
                                size="small"
                            >
                                <i className="alpha-icon md edit-icon"/>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Export model">
                            <Button 
                                data-id={`ml-storage-list-edit-${model.id}`}
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    if (!model.revision || model.revision.length === 0) {
                                        message.warning('Model has no revisions to export');
                                        return;
                                    }
                                    store?.exportModel(model.id!); 
                                }}
                                disabled={store!.busyModelIds ? store!.busyModelIds.indexOf(model.id!) >= 0 : false}
                                type="link"
                                size="small"
                            >
                                <i className="alpha-icon md arrow-up"/>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Add model">
                            <Button 
                                data-id={`ml-storage-list-add-revision-${model.id}`}
                                onClick={(e) => {
                                    navigateToAddRevisionDialog(model);  e.stopPropagation(); 
                                }}
                                disabled={store!.busyModelIds ? store!.busyModelIds.indexOf(model.id!) >= 0 : false}
                                type="link"
                                size="small"
                            >
                                <i className="alpha-icon md plus-icon"/>
                            </Button>
                        </Tooltip>
                        <Popconfirm
                            title="Are you sure that you want to delete this model?"
                            onConfirm={(e) => {
                                deleteModel(model); e!.stopPropagation(); 
                            }}
                            okText="Yes"
                            okButtonProps={{id: `ml-storage-list-delete-confirm-${model.id}`}}
                            cancelText="No"
                            cancelButtonProps={{id: `ml-storage-list-delete-cancel-${model.id}`}}
                            onCancel={(e) => e!.stopPropagation()}
                        >
                            <Tooltip placement="top" title="Delete model">
                                <Button 
                                    data-id={`ml-storage-list-delete-button-${model.id}`}
                                    disabled={store!.busyModelIds ? store!.busyModelIds.indexOf(model.id!) >= 0 : false}
                                    type="link"
                                    size="small"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <i className="alpha-icon md delete-icon"/>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
       
                        
                    </HasPermission>
                    <Tooltip placement="top" title="Show rules using model">
                        <Button 
                            data-id={`ml-storage-list-show-rules-${model.id}`}
                            onClick={(e) => {
                                e.stopPropagation(); 
                                mlStore.openMLModelRulesDialog(model);
                            }}
                            type="link"
                            size="small"
                        >
                            <i className="alpha-icon md label-icon-gray"/>
                        </Button>
                    </Tooltip>
                </div>
            )
        },
    ];

    React.useEffect(() => {
        mlStore.loadProjectMLModels();
    },              [mlStore.currentProject, mlStore]);

    const deleteModel = async (model: MLModel) => {
        await store!.deleteMLModel(model);
    };

    const deleteRevision = async (model: MLModel, revision: MLModelRevision) => {    
        await store!.deleteRevision(model, revision);
        store!.loadProjectMLModels();
    };

    const expandedRowRender = (record: MLModel) => {
        const nestedColums = [
            { title: 'Storage Path', key: 'storagePath', dataIndex: 'storagePath',
                render: (storagePath: string) => {
                    return {
                        children: storagePath,
                        props: {
                            'data-id-name': `ml-model-list-storage-path-${storagePath}`, 'data-id-type': 'ml-model-level1'
                        }
                    };
                }
            },
            { title: 'Size', key: 'fileSizeKilobytes', dataIndex: 'fileSizeKilobytes',   
                width: '150px',
                render: (fileSizeKilobytes: number | null) => {
                    return {
                        children: fileSizeKilobytes ? Utils.formatKilobytes(fileSizeKilobytes) : 'Unknown',
                        props: {
                            'data-id-name': `ml-model-list-storage-size-${fileSizeKilobytes}`, 'data-id-type': 'ml-model-level1'
                        }
                    };
                } 
            },
            { title: 'Create Date', key: 'createDate', dataIndex: 'createDate',   
                width: '350px',
                render: (createDate: string) => {
                    return {
                        children: Utils.formatDateStringShort(createDate),
                        props: {
                            'data-id-name': `ml-model-list-create-date-${createDate}`, 'data-id-type': 'ml-model-level1'
                        }
                    };
                } 
            },
            {
                width: '350px',
                key: 'actions',
                render: (revision: MLModelRevision) => (
                    <HasPermission entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanEditMlStorage}>
                        <div className="ml-storage-revision-actions">
                            <div className="ml-storage-revision-actions-on-hover">
                                <Tooltip placement="top" title="Download revision">
                                    <Button 
                                        data-id={`ml-storage-list-download-revision-${record.id}-${revision.storagePath}`} 
                                        onClick={() => store!.downloadRevision(record, revision)} 
                                        type="link"
                                        size="small"
                                    >
                                        <i className="alpha-icon md download-icon"/>
                                    </Button>
                                </Tooltip>
                                <Popconfirm
                                    title="Are you sure that you want to delete this revision?"
                                    onConfirm={() => deleteRevision(record, revision)}
                                    okText="Yes"
                                    okButtonProps={{id: `ml-storage-list-delete-revision-confirm-${record.id}-${revision.storagePath}`}}
                                    cancelText="No"
                                    cancelButtonProps={{id: `ml-storage-list-delete-revision-cancel-${record.id}-${revision.storagePath}`}}
                                >
                                    <Tooltip placement="top" title="Delete revision">
                                        <Button 
                                            data-id={`ml-storage-list-delete-revision-button-${record.id}-${revision.storagePath}`}
                                            type="link"
                                            size="small"
                                        >
                                            <i className="alpha-icon md delete-icon"/>
                                        </Button>
                                    </Tooltip>
                                </Popconfirm>
                            </div>
                            <Switch 
                                data-id={`ml-storage-list-activate-revision-${record.id}-${revision.storagePath}`} 
                                checked={revision.isActive} 
                                style={{marginLeft: 30, marginBottom: 11, marginRight: 20}} 
                                onChange={(checked: boolean) => {
                                    if (checked)  {
                                        activateRevision(record, revision.storagePath); 
                                    }
                                }}
                            />
                        </div>
                    </HasPermission>
                )
            },
        ];

        return (
            <Observer>
                {() => (
                    <div style={{ marginLeft: '70px', marginBottom: '-13px', marginTop: '-12px' }}>
                        <Space wrap style={{ marginLeft: '16px', marginRight: '16px', marginTop: '24px', marginBottom: '8px' }}>
                            {record.labels.map((label) => (
                                <Tag key={label}>{label}</Tag>
                            ))}
                        </Space>

                        <Table
                            data-id="ml-model-table"
                            columns={nestedColums}
                            dataSource={record.revision}
                            rowKey={(r) => `${record.id}-${record.revision.indexOf(r)}`}
                            pagination={false}
                            rowClassName="nested-row"
                            loading={store!.busyModelIds ? store!.busyModelIds.indexOf(record.id!) >= 0 : false}
                        />
                    </div>
                )}
            </Observer>
        );
    };

    const activateRevision = async (model: MLModel, storagePath: string) => {
        await store!.updateRevisionState(model, storagePath, true); 
    };

    const navigateToModelForm = async (model: MLModel | undefined) => {
        await store!.openMlModelDialog(model);
    };

    const navigateToAddRevisionDialog = async (model: MLModel | undefined) => {
        await store!.openAddRevisionDialog(model);
    };

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        mlStore.setIsImportingModel(status === 'uploading');
        if (status === 'done') {        
            message.success(`${info.file.name} file uploaded successfully.`);
            store?.loadProjectMLModels();
        } else if (status === 'error') {
            if (info.file?.response?.status === 409) {
                message.warning(info.file.response.title);
                return;
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const handleBeforeUpload = async () => {
        return store!.setHeaders();
    };

    return (
        <Layout className="screen-size" style={{...{height: '100%', background: 'white'}}}>
            <LayoutHeader  
                subtitle={
                    <Observer>
                        {() => (
                            <span>{Utils.getSubtitle(store!.currentProject)}</span>
                        )}
                    </Observer>
                }
                title="ML Storage" 
                helpMessage= "Repository for storing machine learning models"
                buttons={[
                    <HasPermission entityId={store?.currentProject?.id} key="1" permissionClaim={AppPermissions.CanEditMlStorage}>
                        <Observer>
                            {() => (
                                <Upload 
                                    showUploadList={false}
                                    className="headerButton"
                                    key="file-uploader" 
                                    name="file" 
                                    onChange={onChange} 
                                    action={`${process.env.REACT_APP_MANAGE_URL}projects/${store!.currentProject?.id}/ml_models/import`} 
                                    headers={store!.fileImportActionHeaders} 
                                    beforeUpload={handleBeforeUpload}
                                    disabled={mlStore.isImportingModel}
                                >
                                    <span data-id="button-Import" key="import-button-container" >
                                        {mlStore.isImportingModel && <Spin indicator={<LoadingOutlined />} size="small" style={{marginRight: 10}}/>}
                                        <i className="alpha-icon xs arrow-down" style={{verticalAlign: 'middle'}}/>
                                        <span className= {'rule-import-label'} style={{marginLeft: 11}}>Import</span>
                                    </span>
                                </Upload>
                            )}
                        </Observer>
                        <Button data-id="ml-storage-add-model" type="primary" onClick={() => navigateToModelForm(undefined)} size="large">
                        Add Model
                        </Button>
                    </HasPermission>
                ]}
            />
            <Layout>
                <Content style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    <Observer>
                        {() => (
                            <MLModelRulesModal 
                                store={mlStore}
                            />
                        )}
                    </Observer>
                    <Observer>
                        {() => (
                            <Table
                                className="alpha-portal-table nested"
                                dataSource={store!.mlModels}
                                columns={columns}
                                rowClassName={(record) => expandedKeys.includes(record.id!) ? 'expanded parent-nested-row' : ' parent-nested-row'}
                                rowKey={m => m.id!}
                                expandedRowRender={expandedRowRender}
                                pagination={false}
                                expandIcon={(props) => <i className="alpha-icon xs expand-row arrow-expand" onClick={(e) => {
                                    props.onExpand(props.record, e); 
                                }}/>}
                                expandRowByClick
                                onExpandedRowsChange={(expanded: string[]) => setExpandedKeys(expanded)}
                            />
                        )}
                    </Observer>
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(MLModelList);