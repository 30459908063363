import { Alert, Button, Form, Modal, Steps } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DocumentSelectionStep, ProjectNameStep, TopicSelectionStep } from '.';
import { TestProjectWizardStore } from '../stores';

type Props = {
    store: TestProjectWizardStore
};

const TestProjectCreationWizard: React.FC<Props> = ({store}) => {
    const [current, setCurrent] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [form] = Form.useForm();
    const projectNameStep = 0;
    const documentSelectionStep = 1;

    React.useEffect(() => {
        if (!store.isCreationWizardVisible) {
            setCurrent(projectNameStep);
            form.resetFields();
            store.resetWizard();
            setErrorMessage(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isCreationWizardVisible]);

    React.useEffect(() => {
        if (store.currentTestProject) {
            form.setFieldsValue({name: store.currentTestProject.name, defaultBaselineFuzzy: store.currentTestProject.defaultBaselineFuzzy});
        } else {
            form.resetFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentTestProject]);

    const handleCancel = () => {
        store.setIsCreationWizardVisible(false);
    };

    const nextStep = () => {
        if (current === projectNameStep) { // Validate project name on the first step
            form.validateFields().then(() => {
                setCurrent(Math.min(current + 1, 2));
            }).catch(() => {
                // Do nothing or testers will get mad that there are console errors, lol ୧༼ಠ益ಠ༽୨
            });
        } else if (current === documentSelectionStep && store.selectedPackages.length === 0) { // Validate document selection on the second step
            setErrorMessage('Please select at least one document!');
        } else {
            setErrorMessage(null);
            setCurrent(Math.min(current + 1, 2));
        }
    };

    const handleCreate = () => {
        form.validateFields().then((values) => {
            if (store.currentTestProject) {
                store.updateTestProject(values.name, values.defaultBaselineFuzzy);
            } else {
                store.createTestProject(values.name, values.defaultBaselineFuzzy);
            }
        }).catch(() => {
            // Do nothing
        });
    };

    const steps = [{key: 'name', title: 'Project settings'}, {key: 'docs', title: 'Select documents'}, {key: 'topics', title: 'Select Inputs/Tags'}];


    const getContent = () => {     
        return (<>
            <div className={current === 0 ? '' : 'hidden'}><ProjectNameStep /></div>
            <div className={current === 1 ? '' : 'hidden'}><DocumentSelectionStep store={store}/></div>
            <div className={current === 2 ? '' : 'hidden'}><TopicSelectionStep store={store} /></div>
        </>);
    };

    return (
        <Modal
            visible={store.isCreationWizardVisible}
            title={store.currentTestProject ? `Edit ${store.currentTestProject.name}` : 'Create new test project'}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose
            centered
            width={850}
            footer={[
                <Button
                    key="submit" 
                    type={current === 2 ? 'primary' : 'default'}
                    onClick={handleCreate}
                    size="large"
                    className={current === 2 ? 'primary' : 'light'}
                >
                    {store.currentTestProject ? 'Save' : 'Create'}
                </Button>,
                current < 2 
                    ? <Button 
                        key="next" 
                        onClick={nextStep}
                        size="large"
                        type="primary"
                    >
                        Next
                    </Button> 
                    : null,
               
            ]}
        >
            <Steps current={current}>
                {steps.map(item => (
                    <Steps.Step key={item.key} title={item.title} />
                ))}
            </Steps>
            <Form form={form} style={{marginTop: 24}} layout="vertical">
                {errorMessage && <Alert message={errorMessage} style={{marginBottom: 12}} type="error" />}
                {getContent()}
            </Form>
        </Modal>
    );
};

export default observer(TestProjectCreationWizard);