import MLModelRevision from './MLModelRevision';

export default class MLModel {
    revision: MLModelRevision[];

    constructor(
        public name: string, 
        public projectId: string, 
        public type: string, 
        public labels: string[], 
        public createDate: string, 
        public updateDate: string, 
        public device: string, 
        public id?: string,  
        revision?: MLModelRevision[]
    ) {
        this.id = id;
        this.name = name;
        this.projectId = projectId;
        this.type = type;
        this.labels = labels;
        this.createDate = createDate;
        this.updateDate = updateDate;
        this.device = device;

        if (revision) {
            this.revision = revision;
        }
    }
}