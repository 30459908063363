import { observable, action, runInAction } from 'mobx';
import { ProjectMetadataVisualStore } from './ProjectMetadataVisualStore';
import { AdministrationService } from '../../project_management/services/AdministrationService';
import ErrorStore from '../../common/stores/ErrorStore';
import { ProcessType } from '../../common/types';

export const SEARCH_DEBOUNCE_TIME = 500;

export default class AdministrationProjectVisualStore {
    @observable
    isLoading: boolean = false;

    constructor(private rootStore: ProjectMetadataVisualStore, private errorStore: ErrorStore, private service: AdministrationService) {
        
    }
    
    @action
    async indexProject(processType: ProcessType) {
        this.isLoading = true;
        const resp = await this.service.indexByProject(this.rootStore.currentProject!.id, processType);
        resp.mapErr(err => this.errorStore.addError(err));
        runInAction(() => this.isLoading = false);
    }

    @action
    async indexByValue(value: string, type: 'ByState' | 'ByUserTag', processType: ProcessType) {
        this.isLoading = true;
        await this.service.indexPackagesByValue(value, type, processType,  this.rootStore.currentProject ? this.rootStore.currentProject.id : undefined); 

        runInAction(() => this.isLoading = false);
    }

    @action
    async cleanupProject() {
        this.isLoading = true;
        const resp = await this.service.cleanupByProject(this.rootStore.currentProject!.id);
        resp.mapErr(err => this.errorStore.addError(err));
        runInAction(() => this.isLoading = false);
    }
}