/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Select } from 'antd';
import { InferenceModelsService } from '../services';
import InferenceModel from '../types';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';

type Props = {
    value?: string[];
    onChange?: (value: string[]) => void;
    service: InferenceModelsService;
    selectedModelId?: string | null;
    isDisabled?: boolean;
    dataId?: string;
    defaultValues?: string[]
};

const Option = Select.Option;

const RuleLabelsSelect: React.FC<Props> = ({ value, onChange, service, selectedModelId, isDisabled, dataId, defaultValues }) => {
    const populateLabelsList = () => {        
        if (models.length && selectedModelId) {
            var model = models.filter(m => m.id === selectedModelId)[0];
            const labelsObj = {};
            if (model) {
                model.labels.forEach(x => labelsObj[x] = x);
            }
            setLabels(model ? labelsObj : {});
        } else {
            setLabels(defaultValues ? getLabels()! : {});
        }
    };

    /* Clears labels which are not in new selected model */
    const clearLabelsNotInModel = () => {                
        if (Object.keys(labels).length && selectedModelId && models.length && onChange) {
            const selectedModel = models.find(m => m.id === selectedModelId);
            const selectedModelLabels = selectedModel ? selectedModel.labels : undefined;
            if (value && selectedModelLabels && value.every(v => selectedModelLabels.includes(v))) {
                return;
            }
            if (selectedModelLabels && selectedModelLabels.length && value) {
                onChange(Object.values(labels).filter(l => selectedModelLabels.includes(l) && value.includes(l)));
            } else {
                onChange([]);
            }
        }
    };

    const getLabels = () => {
        if (Array.isArray(defaultValues)) {
            const obj = {};
            defaultValues.forEach(x => obj[x] = x);
            return obj;
        } else {
            return defaultValues;
        }
    };

    const initalModels: InferenceModel[] = [];
    const initialLabels:  {[key: string]: string} = selectedModelId ? {} : defaultValues ? getLabels()! : {};
    const [models, setModels] = useState(initalModels);
    const [labels, setLabels] = useState(initialLabels);

    useEffect(() => {
        let hasCancelled = false;
        service.getModels().then(
            val => hasCancelled ? () => {
                return; 
            } : setModels(val || []),
            error => hasCancelled ? () => {
                return; 
            } : console.log(error)
        );
        

        return () => {
            hasCancelled = true;
        };
    },        []);

    useEffect(() => {
        populateLabelsList();
    },        [models]);

    useEffect(() => {
        clearLabelsNotInModel();
        populateLabelsList();
    },        [selectedModelId]);

    return (
        <div>
            <SelectWhenScroll
                data-id={dataId ? dataId : 'rule-label-select'}
                data-testid={dataId ? dataId : 'rule-label-select'}
                showSearch
                mode="multiple"
                placeholder="Select labels"
                optionFilterProp="children"
                onChange={onChange}
                value={value}
                disabled={isDisabled}
                style={{minWidth: 150}}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                filterOption={(input, option) => (option!.props.children as any).toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {labels && Object.getOwnPropertyNames(labels).map(l =>
                    <Option data-id={dataId ? `${dataId}-${l}` : `rule-model-select-${l}`} key={l + Math.random()} value={l}>{labels[l]}</Option>
                )}
            </SelectWhenScroll>
        </div>
    );
};

export class RuleLabelsSelectWrapped extends React.Component<Props> {
    render() {
        return <RuleLabelsSelect {...this.props} />;
    }
}

export default RuleLabelsSelect;