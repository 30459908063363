export default class FormBlock {
    regionId: string;
    blockId: string;
    isChanging?: boolean;
    isNew?: boolean;
    data: Object;
    useContours: boolean;
    x1: number;
    y1: number;  
    x2: number; // x, y, width, height are percentages, off the upper left corner
    y2: number; // (0, 0, 50, 50) is a rectangle in the north western corner of the image

    constructor(x1: number, y1: number, x2: number, y2: number, regionId: string, blockId: string, data: Object, useContours: boolean, isChanging: boolean) { 
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
        this.regionId = regionId;
        this.blockId = blockId;
        this.data = data;
        this.useContours = useContours;
        this.isChanging = isChanging;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromJson(json: any): FormBlock {
        return new FormBlock(json.x1, json.y1, json.x2, json.y2, json.regionId, json.blockId, json.data, json.useContours, json.isChanging);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromRegionBlock(regionBlock: any, regionId: string): FormBlock {
        return new FormBlock(
            regionBlock.x, 
            regionBlock.y, 
            regionBlock.x + regionBlock.width, 
            regionBlock.y + regionBlock.height, 
            regionId, 
            regionBlock.blockId, 
            regionBlock.data, 
            regionBlock.useContours, 
            regionBlock.isChanging
        );
    }

    // Added getters and setters so region-select could work with this class
    get width(): number {
        return this.x2 - this.x1;
    }

    set width(width: number) {
        this.x2 = this.x1 + width;
    }

    get height(): number {
        return this.y2 - this.y1;
    }

    set height(height: number) {
        this.y2 = this.y1 + height;
    }

    get x(): number {
        return this.x1;
    }

    set x(x: number) {
        this.x1 = x;
    }

    get y(): number {
        return this.y1;
    }

    set y(y: number) {
        this.y1 = y;
    }
}