import TagRuleBase from './TagRuleBase';
import { observable } from 'mobx';
import { ElasticSearchMatchRuleResult } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export type OperatorType = 'And' | 'Or';

export default class ElasticSearchMatchRule extends TagRuleBase<ElasticSearchMatchRule, ElasticSearchMatchRuleResult> {
    @observable
    query: string;

    @observable
    operator: OperatorType;

    @observable
    minimumPercentageShouldMatch: number;

    @observable
    excludedBlockTypes: string[];

    constructor(
        id: string | null, 
        projectId: string | null, 
        name: string, 
        tagId: string | null, 
        tag: string, 
        query: string, 
        minimumPercentageShouldMatch: number, 
        operator: OperatorType, 
        pipeline: PipelineStep<PipelineStepType>[] = [],
        groupId: string | null,
        updateDate?: Date,
        priority: number = 1,
        description?: string,
        state?: 'Enabled' | 'Disabled',
        status: RuleStatus = RuleStatus.Valid,
        overridePriority: boolean = false,
        excludedBlockTypes: string[] = []
    ) {
        super(id, projectId, name, tagId, tag, 'ElasticSearchMatchRule', pipeline, groupId, updateDate, priority, description, state, status, overridePriority);
        this.query = query;
        this.minimumPercentageShouldMatch = minimumPercentageShouldMatch;
        this.operator = operator;
        this.excludedBlockTypes = excludedBlockTypes || [];
    }

    clone(): ElasticSearchMatchRule {
        return new ElasticSearchMatchRule(this.id, this.projectId, this.name, this.tagId, this.tag,
            this.query, this.minimumPercentageShouldMatch, 
            this.operator, 
            this.clonePipeline(), this.groupId,
            this.updateDate, this.priority, this.description, this.state, this.status, this.overridePriority, this.excludedBlockTypes);
    }

    validateState(): string[] {
        if (!this.name || !this.query || !this.operator || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): ElasticSearchMatchRuleResult {
        const json: ElasticSearchMatchRuleResult = {
            ruleType: this.ruleType,
            id: this.id,
            groupId: this.groupId,
            name: this.name,
            operator: this.operator,
            minimumPercentageShouldMatch: this.minimumPercentageShouldMatch,
            query: this.query,
            tag: this.tag,
            tagId: this.tagId,
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority,
            excludedBlockTypes: this.excludedBlockTypes
        };

        return json;
    }
}