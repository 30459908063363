import BaseHistoryRecordModel from '../BaseHistoryRecordModel';
import { HistoryRecord } from '../../services/types';

export default class ProjectHistoryRecordModel extends BaseHistoryRecordModel {
    constructor(data: HistoryRecord) {
        super(data);
    }

    get objectLabel() {
        return 'Project';
    }
}
