import * as React from 'react';
import { SelectValue } from 'antd/lib/select';
import { useState, useEffect } from 'react';
import { Select, Tooltip } from 'antd';
import { InferenceModelsService } from '../services';
import InferenceModel from '../types';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';

type Props = {
    value?: string;
    onChange?: (value: SelectValue) => void;
    service: InferenceModelsService;
    projectId?: string;
    showTextDescription?: boolean;
    isDisabled?: boolean;
    dataId?: string;
    type?: string[]
};

const Option = Select.Option;

const RuleModelSelect: React.FC<Props> = ({ value, onChange, service, showTextDescription, isDisabled, dataId, projectId, type }) => {
    const initalModels: InferenceModel[] = [];
    const [models, setModels] = useState(initalModels);
    const [modelDescription, setModelDescription] = useState('');

    useEffect(() => {
        let hasCancelled = false;
        service.getModels(projectId, type).then(
            val => hasCancelled ? () => {
                return; 
            } : setModels(val || []),
            error => hasCancelled ? () => {
                return; 
            } : console.log(error)
        );

        return () => {
            hasCancelled = true;
        };
    },        [projectId, service, type]);

    useEffect(() => {        
        let model = models.find(m => m.id === value);
        setModelDescription(model && model.description ? model.description.toString() : 'No description provided');
    },        [value, models]);

    return (
        <div>
            <SelectWhenScroll
                data-id={dataId ? dataId : 'rule-model-select'}
                data-testid={'rule-model-select'}
                showSearch
                style={{ display: 'inline-block', marginRight: 8 }}
                placeholder="Select a model"
                optionFilterProp="children"
                onChange={onChange}
                value={value}
                disabled={isDisabled}
                virtual={false}
                filterOption={(input, option) => option!.props.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {models.map(m =>
                    <Option data-id={dataId ? `${dataId}-${m.name}` : `rule-model-select-${m.name}`} key={m.id} value={m.id}>{m.name}</Option>
                )}
            </SelectWhenScroll>
            {value && !showTextDescription &&
                <Tooltip title={modelDescription} placement="top">
                    <i className="alpha-icon md question-icon" style={{position: 'absolute', bottom: 46, left: 42}}/>
                </Tooltip>
            }
            {value && showTextDescription &&
                <Tooltip title={modelDescription} placement="bottom" defaultVisible={false}>
                    <div>{modelDescription}</div>
                </Tooltip>
            }
        </div>
    );
};

export class RuleModelSelectWrapped extends React.Component<Props> {
    render() {
        return <RuleModelSelect {...this.props} />;
    }
}

export default RuleModelSelect;