import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Switch, Tooltip, Popconfirm, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ApplicationDefinitionConditionalEditStore, ApplicationDefinitionConditionalBindingPreviewStore } from '../../stores';
import { InputGroupModel } from '../../models';
import './InputGroupActionsRenderer.less';

interface Props {
    inputGroup: InputGroupModel;
    store: ApplicationDefinitionConditionalEditStore;
    previewStore: ApplicationDefinitionConditionalBindingPreviewStore
}

const InputGroupActionsRenderer: React.FC<Props> = ({ inputGroup, store, previewStore }) => {
    const onDefaultChange = (e: CheckboxChangeEvent) => {
        const defaultInputGroup = store.inputGroups.find(g => g.id !== inputGroup.id && g.isDefault);

        if (defaultInputGroup) {
            defaultInputGroup.update({ isDefault: false });
        }

        inputGroup.update({ isDefault: e.target.checked });

        // See https://github.com/ant-design/ant-design/issues/13144 tab underline size doesn't update properly without key change
        store.setActiveTab(inputGroup.tabKey);
    };

    return (
        <div className="input-group-actions-renderer">
            <Checkbox className="is-default-checkbox" checked={inputGroup.isDefault} onChange={onDefaultChange}>
                Basic
            </Checkbox>

            <Tooltip title="Preview Input Group">
                <Button
                    className="preview-input-group-button"
                    type="link"
                    size="small"
                    onClick={() => previewStore.previewInputGroup(inputGroup)}
                >
                    <i className="alpha-icon lg play-icon green" />
                </Button>
            </Tooltip>

            <Popconfirm
                placement="topRight"
                title="Are you sure you want to delete input group?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                    inputGroup.delete();
                    store.setActiveTab('conditions');
                }}
            >
                <Button type="link" className="delete-button">
                    <DeleteOutlined />
                </Button>
            </Popconfirm>

            <Button
                type="link"
                className="edit-button"
                onClick={() => {
                    inputGroup.setEditable(true);
                    store.setCreateEditInputGroupDialogVisible(true);
                }}
            >
                <EditOutlined />
            </Button>

            <div onClick={e => e.stopPropagation()}>
                <Switch
                    className="sm-switch"
                    checked={inputGroup.enabled}
                    onChange={enabled => inputGroup.update({ enabled })}
                />
            </div>
        </div>
    );
};

export default observer(InputGroupActionsRenderer);
