import TagRuleBase from './TagRuleBase';
import { observable } from 'mobx';
import { InferenceRuleResult } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export default class InferenceRule extends TagRuleBase<InferenceRule, InferenceRuleResult> {
    @observable
    modelId: string;

    @observable
    labels: string[];

    @observable
    blockType: string;

    @observable
    pageRange: string;

    @observable
    threshold?: number;

    constructor(
        id: string | null, 
        projectId: string | null, 
        name: string, 
        tagId: string | null,
        tag: string,  
        modelId: string, 
        labels: string[],         
        pipeline: PipelineStep<PipelineStepType>[] = [], 
        groupId: string | null,
        pageRange: string, 
        blockType: string,
        threshold?: number, 
        updateDate?: Date,
        priority: number = 1,
        description?: string,
        state?: 'Enabled' | 'Disabled',
        status: RuleStatus = RuleStatus.Valid,
        overridePriority: boolean = false
    ) {
        super(id, projectId, name, tagId, tag, 'InferenceRule', pipeline, groupId, updateDate, priority, description, state, status, overridePriority);
        this.modelId = modelId;
        this.labels = labels;
        this.threshold = threshold;
        this.pageRange = pageRange;
        this.blockType = blockType;
    }

    clone(): InferenceRule {
        // eslint-disable-next-line max-len
        return new InferenceRule(
            this.id, 
            this.projectId, 
            this.name, 
            this.tagId, 
            this.tag,
            this.modelId, 
            this.labels, 
            this.clonePipeline(), 
            this.groupId, 
            this.pageRange, 
            this.blockType, 
            this.threshold,
            this.updateDate, 
            this.priority,
            this.description,
            this.state,
            this.status,
            this.overridePriority
        );
    }

    validateState(): string[] {
        if (!this.name || !this.labels || !this.labels.length || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): InferenceRuleResult {
        const json: InferenceRuleResult = {
            ruleType: this.ruleType,
            id: this.id,
            groupId: this.groupId,
            name: this.name,      
            blockType: this.blockType,
            pageRange: this.pageRange,
            modelId: this.modelId,                  
            labels: this.labels,
            threshold: this.threshold,
            tagId: this.tagId,
            tag: this.tag,
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority
        };

        return json;
    }
}