import BaseHistoryRecordModel from './BaseHistoryRecordModel';
import { HistoryRecord } from '../services/types';

export default class GenericHistoryRecordModel extends BaseHistoryRecordModel {
    constructor(data: HistoryRecord) {
        super(data);
    }

    get objectLabel() {
        return `${this.objectName}`;
    }
}
