import * as React from 'react';
import { Input, Form, Divider } from 'antd';
import { observer } from 'mobx-react';
import PipelineStep from '../models/PipelineStep';
import PipelineVisualStore from '../../rules/stores/PipelineVisualStore';
import { FormInstance } from 'antd/lib/form';

type Props<T extends PipelineVisualStore<TPipelineStep>, TPipelineStep extends string> = {
    step: PipelineStep<TPipelineStep>;
    store: T;
    paramKey: string;
    stepKey: number;
    index: number;
    form?: FormInstance
};

export class UserDefinedParametersEditor<T extends PipelineVisualStore<TPipelineStep>, TPipelineStep extends string> extends React.Component<Props<T, TPipelineStep>, object> { 
    private form: FormInstance;
    private step: PipelineStep<TPipelineStep>;

    constructor(props: Props<T, TPipelineStep>) {
        super(props);
        this.step = this.props.step;
        this.form = this.props.form!;
    }

    handleParametersNameInputChange(ev: React.ChangeEvent<HTMLInputElement>, index: number) {
        this.step.deleteParameter(ev.target.defaultValue);
        const value =  this.form.getFieldValue(`value${index}`);
        this.props.store.removeEmptyParamForUserDefinedCard(this.props.stepKey);
        this.step.addParameter(ev.target.value, value);
    }

    handleParametersValueInputChange(ev: React.ChangeEvent<HTMLInputElement>, index: number) {
        const key =  this.form.getFieldValue(`name${index}`);
        this.step.addParameter(key, ev.target.value );
    }

    render() {
        const { index } = this.props;
        return( 
            <>
                <Form.Item 
                    label={'name'}
                    name={`name${index}`}
                    rules={[{
                        required: true, message: 'Please input parameter name',
                    }]}
            
                >
                    <Input 
                        onChange={(ev) => this.handleParametersNameInputChange(ev, index)}
                        placeholder="name" 
                    />
                </Form.Item>
                <Form.Item 
                    label={'value'}
                    name={`value${index}`}
                    rules={[{
                        required: true, message: 'Please input parameter value',
                    }]}
                >
                    <Input 
                        onChange={(ev) => this.handleParametersValueInputChange(ev, index)}
                        placeholder="value" 
                    />
                </Form.Item>    
                <Divider />
            </>   
        );   
    }
}

export default observer(UserDefinedParametersEditor);