import * as React from 'react';
import { CodeSnippetsVisualStore } from '../stores/CodeSnippetsVisualStore';
import { observer } from 'mobx-react-lite';
import { Button, Table, Tooltip } from 'antd';
import { CodeSnippetGroup, CodeSnippet } from '../models';

type Props = {
    store: CodeSnippetsVisualStore
};

const CodeSnippetGroupPreview: React.FC<Props> = ({store}) => {
    if (!store.previewItem) {
        return null;
    }

    const previewGroup = store.previewItem as CodeSnippetGroup;

    const snippetColumns = [{
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    }, {
        key: 'type',
        dataIndex: 'type',
        title: 'Type'
    }, {
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (snippet: CodeSnippet) => {
            return (
                <div className="table-operation" style={{float: 'right'}}>
                    <Tooltip title="View snippet" placement="top">
                        <Button type="link" size="small" onClick={() => store.selectTreeItem(snippet.id!, 'snippet', true)} style={{paddingLeft: 0}}>
                            <i className="alpha-icon md eye-visible"/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Edit snippet" placement="top">
                        <Button type="link" size="small"  onClick={() => store.editSnippet(snippet.id!)}>
                            <i className="alpha-icon md edit-icon"/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Delete snippet" placement="top">
                        <Button type="link" size="small" onClick={() => store.deleteSnippet(snippet.id!)}>
                            <i className="alpha-icon md delete-icon"/>
                        </Button>
                    </Tooltip>
                </div>
            );
        }
    }];

    return(
        <>
            {
                store.codeSnippets.slice().filter(s => s.groupId === previewGroup.id).length
                    ? (
                        <Table 
                            className="alpha-portal-table"
                            rowClassName="parent-nested-row"
                            rowKey={(r, i) => {
                                return r.id || `temp-${i}`; 
                            }}
                            columns={snippetColumns}
                            dataSource={store.codeSnippets.slice().filter(s => s.groupId === previewGroup.id)}
                        />
                    )
                    : null
            }        
        </>
    );
};

CodeSnippetGroupPreview.displayName = 'CodeSnippetGroupPreview';
export default observer(CodeSnippetGroupPreview);