import { execQuery } from '../../common/services/DataService';
import { GetConnectionsResult } from '../../../modules/rules/types';
import { gql } from 'apollo-boost';
import { Connection } from '../../../modules/rules/models';
import security from '../../common/services/SecurityService';
import { Utils } from '../../common/services/Utils';
import appClient, { ResultApi } from '../../common/services/AppClient';
import ReferenceDataUsageModel from '../models/ReferenceDataUsageModel';
import { RefDataTableGroupDto } from '../models/RefDataTableGroupModel';

export type ValueMapping = {
    Id: string
};

export type RefTableData = {
    data: ValueMapping[];
    fields: string []
};

export type RefDataUpdateResponse = {
    id: string;
    updatedBy: string;
    updateDate: Date
};

export default class ReferenceDataService {
    async getConnections() {
        const result = await execQuery<GetConnectionsResult>({
            query: gql`query getConnections {   
             getConnections {
                 id,
                 projectId,
                 name,
                 connectionType,
                 connectionString,
                 updateDate,
                 updatedBy
             }
         }`,
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            throw result.errors[0];
        }

        return result.data.getConnections.map(x => new Connection(x));
    }

    getAuthToken() {
        return security.invoke((token) => {
            return Promise.resolve(token);
        });
    }

    async getRefDataByConnectionId(projectId: string, id: string) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/tables/${id}`;
        return appClient.get<RefTableData>(url);
    }

    async getRefDataTableNames(projectId: string, id: string) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/tables/${id}`;
        const resp = await appClient.get<string[]>(url);
        return resp.unwrapOr([]);
    }

    async getRefDataByTableName(projectId: string, connectionId: string, tableName: string) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/table/${connectionId}/${tableName}`;
        return appClient.get<RefTableData>(url);
    }

    async updateRecords(projectId: string, connectionId: string, tableName: string, recordId: string | null, values: {}) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/dbrecords`;
        const data = {
            connectionId: connectionId,
            tableName: tableName,
            recordId: recordId,
            values: values
        };
        return appClient.post<RefDataUpdateResponse>(url, data);
    }

    async deleteRecords(projectId: string, connectionId: string, tableName: string, recordId: string) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/dbrecords/${connectionId}/${tableName}/${recordId}/delete`;
        return appClient.delete(url);
    }

    async createTable(projectId: string, name: string, connectionId: string, newTableFields: string[]) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/tables/new`;
        const data = {
            name: name,
            connectionId: connectionId,
            tableFields: newTableFields
        };
        return appClient.post(url, data);
    }

    async deleteTable(projectId: string, connectionId: string, name: string) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/tables/delete`;
        const data = {
            name: name,
            connectionId: connectionId
        };
        return appClient.post(url, data);
    }

    async renameTable(projectId: string, connectionId: string, oldTableName: string, newTableName: string) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/tables/rename`;
        const data = {
            connectionId: connectionId,
            oldTableName: oldTableName,
            newTableName: newTableName
        };
        return appClient.update(url, data);
    }

    async executeQuery(projectId: string, connectionId: string, query: string) {
        const request = {
            connectionId: connectionId,
            query: query
        };

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/refdata/execute-query`;
        return appClient.post(url, request);
    }

    async saveImportedData(projectId: string, connectionId: string, tableName: string, values: {}) {
        let url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/tables/bulk-insert`;
        const data = {
            connectionId: connectionId,
            tableName: tableName,
            values: values
        };
        return appClient.post(url, data);
    }

    async exportTable(projectId: string, connectionId: string, name: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'postForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';
        await security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/export/${connectionId}/${name}${t}`;
            Utils.downloadFile(url, mapForm, 'postForm');
            return Promise.resolve();
        });

    }

    
    async getRefDataUsage(
        projectId: string,
        refDataConnectionId: string,
        refDataTableNames: string[],
        forSingleProject: boolean = false
    ): Promise<ResultApi<ReferenceDataUsageModel[]>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/connections/check-usage`;

        const data = {
            refDataConnectionId,
            refDataTableNames,
            projectId: forSingleProject ? projectId : null
        };

        return await appClient.post(url, data);
    }

    async getTableGroups(projectId: string, refDataConnectionId: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/table-groups/${refDataConnectionId}`;

        const resp = await appClient.get<RefDataTableGroupDto[]>(url);

        return resp.unwrapOr([]);
    }

    async createTableGroup({
        name,
        projectId,
        refDataConnectionId
    }: Pick<RefDataTableGroupDto, 'name' | 'projectId' | 'refDataConnectionId'>): Promise<ResultApi<RefDataTableGroupDto>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/table-group`;

        const data = {
            name,
            projectId,
            refDataConnectionId
        };

        return appClient.post(url, data);
    }

    async updateTableGroup({
        id,
        name,
        projectId,
        tableNames
    }: Pick<RefDataTableGroupDto, 'id' | 'name' | 'projectId' | 'tableNames'>): Promise<ResultApi<RefDataTableGroupDto>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/table-group`;

        const data = {
            id,
            name,
            projectId,
            tableNames
        };

        return appClient.update(url, data);
    }

    async deleteTableGroup({ id, projectId }: Pick<RefDataTableGroupDto, 'id' | 'projectId'>) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/refdata/table-group/${id}`;
        
        return appClient.delete(url);
    }
}
