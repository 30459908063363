import * as React from 'react';
import { Tooltip, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { CommentOutlined } from '@ant-design/icons';
import { ChatbotStore } from '../../stores';
import './ChatbotButton.less';

type Props = {
    store: ChatbotStore
};

const ChatbotButton: React.FC<Props> = ({ store }) => {
    return (
        <Tooltip title="Ask a question">
            <Button
                className="chatbot-ask-button"
                type={store.dialogVisible ? 'primary' : 'default'}
                icon={<CommentOutlined className={!store.dialogVisible ? 'gray' : ''} />}
                onClick={() => store.setDialogVisible(!store.dialogVisible)}
            />
        </Tooltip>
    );
};

export default observer(ChatbotButton);
