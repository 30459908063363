import * as Highlight from 'react-highlighter';
import * as React from 'react';
import { SearchSessionsAutoCompleteSourceItem } from '../types';
import { SessionsVisualStore } from '../stores';

export default function (store: SessionsVisualStore) {
    return function(item: SearchSessionsAutoCompleteSourceItem, idx: number) {
        return (
            <div key={idx + item.packageName} className="global-search-item-container">
                <div className="global-search-item-result">
                    <Highlight search={store.searchTerm}>
                        {item.packageName}
                    </Highlight>
                </div>
            </div>
        );
    };
}