import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Layout, Tabs, Tooltip, Upload, message } from 'antd';
import { HasPermission } from '../../../authorization/components/HasPermission';
import { AppPermissions } from '../../../authorization/Permissions';
import { Utils } from '../../../common/services/Utils';
import {
    ApplicationDefinitionConditionalEditStore,
    ApplicationDefinitionConditionalBindingPreviewStore
} from '../../stores';
import {
    ConditionsRenderer,
    InputGroupRenderer,
    CreateEditInputGroupDialog,
    ConditionsImportModal,
    InputGroupTabHeader
} from '..';
import { ProjectApplicationDefinitionEditVisualStore } from '../../../iota_applications/stores';
import LayoutHeader from '../../../../components/LayoutHeader';
import './ApplicationDefinitionConditionalEditor.less';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { Prompt } from 'react-router';

interface Props {
    store: ApplicationDefinitionConditionalEditStore;
    previewStore: ApplicationDefinitionConditionalBindingPreviewStore;
    applicationDefinitionEditStore: ProjectApplicationDefinitionEditVisualStore
}

const ApplicationDefinitionConditionalEditor: React.FC<Props> = ({
    store,
    previewStore,
    applicationDefinitionEditStore
}) => {
    React.useEffect(() => {
        store.setIsDataChanged(false);
        return () => {
            store.setIsDataChanged(false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        function beforeUnload(e: BeforeUnloadEvent) {
            if (!store.isDataChanged) {
                return;
            }
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [store.isDataChanged]);

    if (!store.project || !store.applicationDefinition) {
        return null;
    }

    const getLastUpdateTimeLabel = () => {
        const dataIsEmpty = !store.applicationDefinition?.lastUpdatedBy && !store.applicationDefinition?.lastUpdatedTime;
        if (!store.project || !store.applicationDefinition || dataIsEmpty) { // store.isLoadingUsers
            return null;
        }

        const updatedBy = store.getUserName(store.applicationDefinition.lastUpdatedBy ?? '');
        const updatedAt = Utils.formatDateStringShort(store.applicationDefinition.lastUpdatedTime);

        return <>Updated at: {updatedAt} by {updatedBy}</>;
    };

    const onFileChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status === 'done') {        
            const uploadResponse = info.file.response;
            store.handleImportResponse(uploadResponse);
        } else if (status === 'error') {
            console.log(info.file.response);
            if (info.file?.response?.status === 409) {
                message.warning(info.file.response.title);
                return;
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const handleBeforeUpload = async (file: RcFile) => {
        if (!file.name.endsWith('.iota_conditions')) {
            message.error('Invalid file format. Please upload a .iota_conditions file');
            return Promise.reject();
        }
        store.setCurrentImportFile(file);
        return store.setHeaders();
    };

    const handleCancel = () => {
        store.setIsDataChanged(false);
        store.navigateToList();
    };

    return (
        <Layout>
            <LayoutHeader
                title={store.applicationDefinition.name}
                subtitle={Utils.getSubtitle(store.project)}
                buttons={[
                    <span key="updated-data" style={{marginRight: 12, color: '#9BA0AA'}}>
                        {getLastUpdateTimeLabel()}
                    </span>,
                    <Tooltip 
                        key="export-button-container" 
                        title="Export"
                    >
                        <span 
                            data-id="button-Export" 
                            className={'headerButton rule-export'} 
                            onClick={() => store.exportAppBindings()}
                        >
                            <i className="alpha-icon xs arrow-up" style={{verticalAlign: 'text-top'}}/>
                        </span>
                    </Tooltip>,
                    <Tooltip
                        key="file-uploader" 
                        title="Import"
                    >
                        <Upload 
                            showUploadList={false}
                            className="headerButton"
                            name="file" 
                            onChange={onFileChange} 
                            action={store.importBindingsActionUrl} 
                            headers={store.fileImportActionHeaders} 
                            beforeUpload={(file) => handleBeforeUpload(file)}
                        >
                            <span data-id="button-Import" key="import-button-container" >
                                <i className="alpha-icon xs arrow-down" style={{verticalAlign: 'text-top'}}/>
                            </span>
                        </Upload>
                    </Tooltip>,
                    <Button
                        key="application-definition-conditional-preview-app"
                        data-id="application-definition-conditional-preview-app"
                        onClick={() => previewStore.previewApplication()}
                        loading={previewStore.isLoading}
                        disabled={previewStore.isLoading}
                        htmlType="button"
                        className="light"
                        size="large"
                    >
                        Preview Application
                    </Button>,
                    <Button
                        key="application-definition-conditional-add-input-group"
                        data-id="application-definition-conditional-add-input-group"
                        onClick={() => store.setCreateEditInputGroupDialogVisible(true)}
                        htmlType="button"
                        className="light"
                        size="large"
                    >
                        Add Input Group
                    </Button>,
                    <Button
                        key="application-definition-conditional-cancel-editing"
                        data-id="application-definition-conditional-cancel-editing"
                        onClick={handleCancel}
                        htmlType="button"
                        className="light"
                        size="large"
                    >
                        Cancel
                    </Button>,
                    <HasPermission
                        key="application-definition-conditional-save"
                        entityId={store.project.id}
                        permissionClaim={AppPermissions.CanEditIotaApplications}
                        yes={() => (
                            <Button
                                data-id="application-definition-conditional-save"
                                type="primary"
                                size="large"
                                onClick={store.updateData}
                                loading={store.isUpdating}
                                disabled={store.isUpdating}
                            >
                                Save changes
                            </Button>
                        )}
                        no={() => (
                            <Button
                                data-id="application-definition-conditional-save"
                                type="primary"
                                size="large"
                                disabled
                            >
                                Save changes
                            </Button>
                        )}
                    />
                ]}
            />
            <Layout.Content className="application-definition-conditional-editor">
                <CreateEditInputGroupDialog store={store} />
                <ConditionsImportModal store={store} />
                <Prompt 
                    when={store.isDataChanged}
                    message="You have unsaved changes. Are you sure you want to leave?"
                />
                <Tabs activeKey={store.activeTab} onChange={store.setActiveTab}>
                    <Tabs.TabPane tab={<span style={{ userSelect: 'none' }}>Conditions</span>} key="conditions">
                        <ConditionsRenderer
                            store={store}
                            applicationDefinitionEditStore={applicationDefinitionEditStore}
                        />
                    </Tabs.TabPane>

                    {store.inputGroups.map(inputGroup => (
                        <Tabs.TabPane key={inputGroup.tabKey} tab={<InputGroupTabHeader inputGroup={inputGroup} />}>
                            <InputGroupRenderer
                                store={store}
                                previewStore={previewStore}
                                inputGroup={inputGroup}
                                applicationDefinitionEditStore={applicationDefinitionEditStore}
                            />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Layout.Content>
        </Layout>
    );
};

export default observer(ApplicationDefinitionConditionalEditor);
