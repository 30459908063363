import * as React from 'react';
import { Modal, Button, Tooltip, Input, List, Row, Col, Form } from 'antd';
import { observer, Observer } from 'mobx-react-lite';
import FormItem from 'antd/lib/form/FormItem';
import { ReferenceDataVisualStore } from '../stores';
import { STORE_REFERENCE_DATA } from '../constant';

type Props =  {   
    [STORE_REFERENCE_DATA]: ReferenceDataVisualStore
};

export const TablefieldValdationRules = [
    {   required: true, message: 'Please input value', whitespace: true
    },
    {   required: false, message: 'Please input characters without spaces!', whitespace: true, 
        pattern: new RegExp(/^((?! ).)*$/, 'i')
    },
    {   required: false, message: 'Please use only alphanumeric or #, @, _ characters !', whitespace: true, 
        pattern: new RegExp(/^[a-zA-Z0-9@# _]*$/, 'i')
    },
];

export const NewTableModal: React.FC<Props> = ({  ReferenceDataUI: store }) => {
    const [form] = Form.useForm();
    const [isFieldsReadoOnly, setIsFieldsReadoOnly] = React.useState(false);

    React.useEffect(() => {
        if (store.currentConnection?.connectionType === 'MongoDb') {
            setIsFieldsReadoOnly(true);
            store.setNewTableFields(['Code', 'Value']);
        } else {
            setIsFieldsReadoOnly(false);
            store.setNewTableFields([]);
        }
    }, [store, store.currentConnection]);

    const handleSubmit = (e: React.FormEvent<object>) => {
        e.preventDefault();
        form.validateFields(['tableName']).then((values) => {
            store!.handleNewTableFormSubmit(values.tableName);
            form.resetFields();
        });
    };

    React.useEffect(() => {
        if (!store.currentFieldInputValue) {
            form.setFieldsValue({
                fieldNames: store.currentFieldInputValue
            });
        }
    } , [store.currentFieldInputValue, form]);
    
    const handleCancel = () => {
        store!.setAddNewTableDialogVisible(false);
    };


    const checkReservedNames = (_: unknown, value: string) => {
        if (!store.reservedFieldNames.some(s => s === value.toLowerCase().trim()) && !store.newTableFields.some(s => s === value.toLowerCase().trim())) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('This name is already reserved. Use different name, please.'));
    };

    return(
        <Modal
            title="New reference data table"
            visible={store!.addNewTableDialogVisible}
            onCancel={handleCancel}
            closable={false}
            maskClosable={false}
            destroyOnClose
            centered
            className="alpha-portal-dialog add-ref-data-table"
            getContainer={false}
            footer={[
                <Button 
                    key="back" 
                    onClick={handleCancel} 
                    size="large" 
                    className="light"
                    data-id="new-reference-data-dialog-cancel"
                >
                    Cancel
                </Button>,
                <Button 
                    className="dark" 
                    key="submit" 
                    size="large" 
                    type="primary" 
                    onClick={handleSubmit} disabled={store.hasInvalidFieldNameInput}
                    data-id="new-reference-data-dialog-submit"
                >
                    Submit
                </Button>
            ]}
        >
            <Form form={form} layout="vertical" data-id="new-reference-data-dialog-form">
                <FormItem
                    label={(
                        <span>
                            Table name&nbsp;
                            <Tooltip title="Reference table name">
                                <i className="alpha-icon md question-icon" style={{verticalAlign: 'middle'}} />
                            </Tooltip>
                        </span>
                    )}
                    name="tableName"
                    rules={TablefieldValdationRules}
                >
                    <Input size="large" />
                </FormItem>
                <FormItem
                    label="Field Names"
                    name="fieldNames"
                    rules={[...TablefieldValdationRules, {
                        validator: checkReservedNames
                    }]}
                >  
                    <Input
                        size="large"
                        className="suffix-push-right"
                        onChange={store.setCurrentFieldInputValue}
                        readOnly={isFieldsReadoOnly}
                        suffix={
                            // eslint-disable-next-line max-len
                            <Button 
                                type="primary" 
                                style={{ position: 'absolute', right: 0, height: 38 }} 
                                onClick={store.addNewFieldValue} 
                                disabled={!store.isCurrentFieldInputValueValid || !store.currentFieldInputValue || isFieldsReadoOnly}
                            >
                                Add
                            </Button>}
                    />
                </FormItem>
                <div>
                    <List
                        itemLayout="vertical"
                        dataSource={store.newTableFields}
                        renderItem={(item: string) => (
                            <Observer>
                                {() => (
                                    <List.Item>
                                        <Row>
                                            <Col span={20}>
                                                <Input
                                                    size="small"
                                                    className="borderless"
                                                    value={item}
                                                    onChange={(e) => store.renameField(item, e.target.value)}
                                                    readOnly={isFieldsReadoOnly}
                                                />
                                            </Col>
                                            <Col span={4} style={{textAlign: 'right'}}>
                                                <Tooltip title="Remove keyword from list" placement="right">
                                                    <Button 
                                                        type="link" 
                                                        size="small" 
                                                        onClick={() => store.removeItem(item)}
                                                        disabled={isFieldsReadoOnly}
                                                    >
                                                        <i className="alpha-icon sm cross middle"/>
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </List.Item>)}
                            </Observer>
                        )}
                    />
                </div>
            </Form>
        </Modal>);

};

export default observer(NewTableModal);