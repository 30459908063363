import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroupModel } from '../../models';
import './InputGroupTabHeader.less';

interface Props {
    inputGroup: InputGroupModel
}

const InputGroupTabHeader: React.FC<Props> = ({ inputGroup }) => {
    return (
        <div className="input-group-tab-header-container">
            <span className={`input-group-label ${!inputGroup.enabled ? 'disabled' : ''}`}>{inputGroup.name}</span>

            {inputGroup.isDefault && <span className="default-group-label">B</span>}
        </div>
    );
};

export default observer(InputGroupTabHeader);
