import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Form, Button } from 'antd';
import { ApplicationDefinitionConditionalEditStore } from '../../stores';
import { CreateEditConditionFormValues } from '../../types';
import { ProjectApplicationDefinitionEditVisualStore } from '../../../iota_applications/stores';
import { ConditionFormField } from '..';

interface Props {
    store: ApplicationDefinitionConditionalEditStore;
    applicationDefinitionEditStore: ProjectApplicationDefinitionEditVisualStore
}

const CreateEditConditionDialog: React.FC<Props> = ({ store, applicationDefinitionEditStore }) => {
    const [form] = Form.useForm<CreateEditConditionFormValues>();

    const onFinish = (formValues: CreateEditConditionFormValues) => {
        if (store.editableCondition) {
            store.editableCondition.update(formValues);
        } else {
            store.addNewCondition(formValues);
        }

        store.setIsDataChanged(true);
        onClose();
    };

    const onClose = () => {
        store.setCreateEditConditionDialogVisible(false);

        if (store.editableCondition) {
            store.editableCondition.setEditable(false);
        }
    };

    return (
        <Modal
            className="create-edit-condition-dialog"
            title="Condition Wizard"
            visible={store.createEditConditionDialogVisible}
            width={600}
            onCancel={onClose}
            maskClosable={false}
            closable={false}
            destroyOnClose
            centered
            footer={[
                <Button
                    form="create-edit-condition-form"
                    data-id="create-edit-condition-cancel"
                    key="create-edit-condition-cancel"
                    className="light"
                    size="large"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    form="create-edit-condition-form"
                    data-id="create-edit-input-group"
                    key="create-edit-input-group"
                    size="large"
                    type="primary"
                    htmlType="submit"
                >
                    {store.editableCondition ? 'Update' : 'Create'}
                </Button>
            ]}
        >
            <Form id="create-edit-condition-form" layout="vertical" form={form} onFinish={onFinish} preserve={false}>
                <ConditionFormField
                    formFieldKey="name"
                    label="Condition name"
                    store={store}
                    applicationDefinitionEditStore={applicationDefinitionEditStore}
                    condition={store.editableCondition}
                />

                <ConditionFormField
                    formFieldKey="tagId"
                    label="Step 1: Select tag"
                    store={store}
                    applicationDefinitionEditStore={applicationDefinitionEditStore}
                    condition={store.editableCondition}
                />

                <ConditionFormField
                    formFieldKey="searchText"
                    label="Step 2: Specify words or phrases&nbsp;"
                    store={store}
                    applicationDefinitionEditStore={applicationDefinitionEditStore}
                    condition={store.editableCondition}
                />

                <ConditionFormField
                    formFieldKey="inputGroupId"
                    label="Step 3: Specify input group to apply"
                    store={store}
                    applicationDefinitionEditStore={applicationDefinitionEditStore}
                    condition={store.editableCondition}
                />
            </Form>
        </Modal>
    );
};

export default observer(CreateEditConditionDialog);
