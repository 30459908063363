import { PackageLine } from '../../common/models';
import PipelineStepType from './PipelineStepType';
import RuleType from './RuleType';
import ConnectionTypes from '../models/ConnectionTypes';
import { RuleStatus } from '../models/RuleBase';
import { Dictionary } from 'lodash';

export type GetRulesResult = {
    getRules: RuleResult[]
};

export type RuleBaseResult<TRuleType, TPipeLineStep> = {
    id: string | null;
    name: string;
    tag: string;
    tagId: string | null;
    ruleType: TRuleType;
    pipeline: PipelineStepResult<TPipeLineStep>[];
    projectId: string | null;
    groupId: string | null;
    updateDate?: Date;
    priority: number;
    description?: string;
    state: 'Enabled' | 'Disabled' | undefined;
    status: RuleStatus;
    overridePriority: boolean;
    pipelineExecutionErrorMsg?: string
};

export type RefDataRuleResult = {
    sqlQuery: string;
    connectionId: string;
    connection: ConnectionResult
} & RuleBaseResult<RuleType, PipelineStepType>;

export type ElasticSearchMatchRuleResult = {
    query: string;
    operator: 'Or' | 'And';
    minimumPercentageShouldMatch: number;
    excludedBlockTypes: string[]
} & RuleBaseResult<RuleType, PipelineStepType>;

export type ElasticSearchMatchPhraseRuleResult = {
    query: string;
    slope: number;
    excludedBlockTypes: string[]
} & RuleBaseResult<RuleType, PipelineStepType>;

export type ElasticSearchQueryRuleResult = {
    query: string;
    operator: 'Or' | 'And';
    minimumShouldMatch?: number;
    phraseSlope: number;
    excludedBlockTypes: string[]
} & RuleBaseResult<RuleType, PipelineStepType>;

export type ElasticSearchRawRuleResult = {
    query: string
} & RuleBaseResult<RuleType, PipelineStepType>;

export type InferenceRuleResult = {
    modelId: string;
    labels: string[];
    threshold?: number;
    blockType: string;
    pageRange: string
} & RuleBaseResult<RuleType, PipelineStepType>;

export type NamedEntitiesRecognitionRuleResult = {
    nerModelId: string | null;
    entities: string[];
    blockType: string;
    pageRange: string
} & RuleBaseResult<RuleType, PipelineStepType>;

export type SmartIndexRuleResult = {
    instructWorkflowId: string;
    prompt: string;
    outputSchemeName?: string
} & RuleBaseResult<RuleType, PipelineStepType>;

export type RuleTagResult = {
    id: string;
    rules: RuleResult[];
    name: string;
    projectId: string
};

export type RuleResult =
    | RefDataRuleResult
    | ElasticSearchMatchRuleResult
    | ElasticSearchMatchPhraseRuleResult
    | ElasticSearchQueryRuleResult
    | ElasticSearchRawRuleResult
    | InferenceRuleResult
    | NamedEntitiesRecognitionRuleResult
    | SmartIndexRuleResult;

export type GetConnectionsResult = {
    getConnections: ConnectionResult[]
};

export type GetConnectionResult = {
    getConnection: ConnectionResult
};

export type ConnectionResult = {
    id: string;
    name: string;
    connectionType: ConnectionTypes;
    connectionString: string;
    projectId: string;
    updateDate?: Date;
    updatedBy?: string
};

export type ConnectionEditResult = {
    id: string;
    name: string;
    type: string;
    connectionString: string;
    projectId: string
};

export type PipelineStepResult<TStepType> = {
    stepId: string | undefined;
    name: string;
    type: TStepType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parameters: [any];
    isDisabled: boolean
};

export type PreviewResult = {
    rule: RuleResult;
    entries: PreviewResultEntry[]
};

export type PreviewResultWithPackageId = {
    packageId: string;
    previewResult: PreviewResult
};

export type TagPreviewResultWithPackageId = {
    packageId: string;
    previewResults: PreviewResult[]
};

export type TagPreviewResultWithConditionName = TagPreviewResultWithPackageId & {
    conditionName: string
};

export type PreviewResultEntry = {
    field: PackageLine;
    tokens: IndexToken[];
    tag: PackageTag;
    resultTokens: PreviewIndexToken[]
};

type IndexTokenFields = {
    id: string;
    token: string;
    start: number;
    end: number;
    srcToken: IndexTokenFields;
    type: 'Text' | 'Table'
};

type IndexTokenIndexer =  { [P in keyof IndexTokenFields]: IndexTokenFields[P] };

export type IndexToken = IndexTokenFields & IndexTokenIndexer;

export type PreviewIndexToken = {
    token: IndexToken;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: any
};

export type PackageTag = {
    tagId: string;
    values: string[]
};

export type GuessedNamedEntities = {
    entities: [{
        text: string;
        label: string;
        start: number;
        length: number
    }]
};

export type GuessedNamedEntitiesResponse = {
    guessedNamedEntities: GuessedNamedEntities[]
};

export default class InferenceModel {
    labels: string[];

    constructor(public id: string, 
                public type: string, 
                public version: string, 
                public storagePath: string, 
                public name: string, 
                public description?: string) {
        this.type = type;
        this.name = name;

        if (description) {
            this.description = description;
        }
    }
}

export type AllModelsResult = {
    allInferenceModels: InferenceModel[]
};

export type InferenceModelsForProjectResult = {
    inferenceModelsForProject: InferenceModel[]
};

export enum CompileDummyCodeType {
    CodeStep = 'CodeStep',
    BusinessRule = 'BusinessRule'
}

export enum WidgetType {
    Dropdown = 'dropdown',
    Input = 'input',
    Checkbox = 'checkbox',
    InferenceModelSelect = 'inferenceModelSelect',
    LabelsSelect = 'labelsSelect',
    NERModelSelect = 'NERModelSelect'
}

export type Parameter = {
    'defaultValue': string;
    'required': boolean;
    'type': string;
    'widgetType': WidgetType;
    'name': string;
    'values': string[];
    'friendlyName': string;
    'description': string;
    'displayConditions'?: DisplayCondition[]
};

export type DisplayCondition = {
    parameterName: string;
    parameterValue?: unknown;
    condition: unknown
};

export type StepModel = {
    'description': string;
    'type': 'predefined' | 'userdefined';
    'parameters': [Parameter];
    'parametersDescription'?: string;
    'group': string;
    'friendlyName': string
};

export type EditorModel<T extends string>  = Partial<{
    [key in T]: StepModel;
}>;

export type RuleName = {
    name: string;
    id: string
};

export type RulesImportMappingData = {
    id: string;
    nativeName: string;
    exportedName: string;
    tableType: 'MlModel' | 'IOTAConnectionModel' | 'RefDataConnectionModel' | 'InstructWorkflowModel'
};

export type ImportMapperTableModel = {
    id: string;
    tableType: 'MlModel' | 'IOTAConnectionModel' | 'RefDataConnectionModel' | 'InstructWorkflowModel';
    name: string;
    type: string;
    bestValue: string | null;
    altOptions: string[]
};

export type RulesToUploadData = {
    generatedFileName: string;
    importedRules: Dictionary<RuleName[]>;
    mapperTableData: ImportMapperTableModel[];
    projectId?: string
};

export type RulesToUploadDataForIotaImport = {
    ruleData: RulesToUploadData;
    exportedIotaFileName: string
};

export type RulesToUploadDataForProjectBindingsImport = {
    ruleData: RulesToUploadData;
    exportedBindingsFileName: string
};