import { Button, Checkbox, Collapse, FormInstance, Modal} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectApplicationDefinitionEditVisualStore } from '../stores';
import { InputBinding } from '../types';


type Props = {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    store: ProjectApplicationDefinitionEditVisualStore;
    add: (defaultValue?: unknown, insertIndex?: number | undefined) => void;
    remove: (index: number | number[]) => void;
    inputId: string;
    form: FormInstance
};

const SelectMultipleTagsDialog: React.FC<Props> = ({isVisible, setIsVisible, store, add, remove, inputId, form}) => {
    const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
    const [initialBindings, setInitialBindings] = React.useState<InputBinding[]>([]);

    React.useEffect(() => {
        if (isVisible) {    
            const formInputs = form.getFieldValue(inputId);
            setInitialBindings(formInputs);
            setSelectedTags(formInputs.filter((x: InputBinding) => x.type === 'tag').map((x: InputBinding) => x.value));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const handleCancel = () => {
        setSelectedTags([]);
        setIsVisible(false);
    };

    const handleSubmit = () => {
        for (let binding of initialBindings) {
            if (binding.type === 'tag' && !selectedTags.includes(binding.value as string)) {
                const bindings = form.getFieldValue(inputId);
                remove(bindings.indexOf(binding));
            }
        }
        
        for (let tag of selectedTags) {
            if (initialBindings.some(x => x.type === 'tag' && x.value === tag)) {
                continue;
            } else if (initialBindings.filter(x => x.type === 'tag').every(x => x.value !== tag)) {
                add({
                    type: 'tag',
                    value: tag
                });  
            }
        }

        handleCancel();
    };

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setSelectedTags([...selectedTags, e.target.value]);
        } else {
            setSelectedTags(selectedTags.filter(t => t !== e.target.value));
        }
    };

    const getInput = () => {
        const groups = store!.tagGroups.sort((a, b) => a.position - b.position);
        const grpoupsWithSelectedTags = groups.filter(g => store!.getTagsForGroup(g.id).some(x => selectedTags.includes(x))).map(x => x.id ?? '');
        return (
            <Collapse defaultActiveKey={grpoupsWithSelectedTags || []}>
                {groups.map(g => (
                    <Collapse.Panel key={g.id!} header={g.name} data-id={`iota-select-multiple-tags-dialog-panel-${g.name}`} >
                        {store!.getTagsForGroup(g.id).map(x => (
                            <div key={x} style={{marginBottom: 6}}>
                                <Checkbox 
                                    data-id={`iota-select-multiple-tags-dialog-checkbox-${x}`} 
                                    value={x}
                                    checked={selectedTags.includes(x)}
                                    onChange={handleCheckboxChange}
                                >
                                    {x}
                                </Checkbox>
                            </div>
                            
                        ))}
                    </Collapse.Panel>
                ))}
            </Collapse>
               
        );
    };

    return (
        <Modal
            width={600}
            title="Select Tags"
            visible={isVisible}
            maskClosable={false}
            destroyOnClose
            closable={false}
            className="alpha-portal-dialog"
            centered
            footer={[
                <Button className="light" data-id="iota-application-select-tags-cancel" size="large" key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button 
                    data-id="iota-application-select-tags-submit" 
                    size="large" 
                    key="submit" 
                    type="primary" 
                    onClick={handleSubmit}
                    disabled={selectedTags.length === 0 && initialBindings.every(x => x.type === 'tag')}
                >
                    Select
                </Button>
            ]}
        >
            <div style={{minHeight: 300}}>{getInput()}</div>
        </Modal>
    );
};

export default observer(SelectMultipleTagsDialog);