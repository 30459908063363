/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action } from 'mobx';
import PackageViewerVisualStoreBase from '../../viewer_base/stores/PackageViewerVisualStoreBase';
import ProjectsRootVisualStore from '../../common/stores/ProjectsRootVisualStore';
import Package from '../../common/models/Package';

export const FILTER_DEBOUNCE_TIME = 500;
interface LoadedPackagesObj {
    [key: string]: Package []
}

export default abstract class PreviewVisualStore extends PackageViewerVisualStoreBase {
    @observable
    loadedPackages: LoadedPackagesObj = {};
    
    @observable
    isPdfLoaded: boolean = false;
    
    @observable
    docNameInputValue: string = '';

    @observable
    isLoadingPackages: boolean = false;

    @action
    setPdfIsLoaded(isPdfLoaded: boolean) {
        this.isPdfLoaded = isPdfLoaded;
    }

    @action
    setDocNameInputValue(query: string) {
        this.docNameInputValue = query;
    }

    @action.bound
    setIsLoadingPackages(isLoadingPackages: boolean) {
        this.isLoadingPackages = isLoadingPackages;
    }

    abstract setUrl(): void;

    constructor(projectStore: ProjectsRootVisualStore) {
        super(projectStore);
    }

}