import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { ProjectAssignmentVisualStore } from '../stores';

type Props = {
    store: ProjectAssignmentVisualStore
};

const UsersSearch: React.FC<Props> = ({ store }) => {
    const onChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        store.setUsernameFilter(e.target.value);
    }, 500);

    React.useEffect(() => {
        return () => store.setUsernameFilter('');
    }, [store]);

    return (
        <Input.Search
            data-id="input-search"
            className="alpha-search-input"
            placeholder="Search for username..."
            style={{ width: 220, marginBottom: 15 }}
            onChange={onChange}
            onSearch={store.setUsernameFilter}
            allowClear
        />
    );
};

export default observer(UsersSearch);
