/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from 'react';
import { observer, inject, Observer } from 'mobx-react';
import { Modal, Button, Alert } from 'antd';
import { RulesStores } from '../stores';
import { STORE_RULES_IMPORT } from '../constants';
import { RulesImportTable } from '../components/RulesImportTable';
import { RulesImportMapperTable } from '../components/RulesImportMapperTable';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

type Props = {
    importCallback?: () => Promise<void>;
    warningMsg?: string
} & RulesStores;
@inject(STORE_RULES_IMPORT)
@observer
export default class RulesImportDialog extends React.Component<Props, object> {
    render() {
        const rulesImportStore = this.props.RulesImportUI!;
        const isImportForIota = !!this.props.importCallback;
        const content = !rulesImportStore.isLoading ?       

            (!rulesImportStore.isMapperTableVisible ?
                <RulesImportTable RulesImportUI={rulesImportStore} importForIota={isImportForIota}/> :
                <RulesImportMapperTable RulesImportUI={rulesImportStore}/>
            ) : <LoadingIndicator/>;
    
        const callBack = this.props.importCallback || function async() {
            return Promise.resolve();
        };
        return  (
            <Modal
                title="Imported Results"
                visible={rulesImportStore.isDialogVisible}
                onCancel={() => rulesImportStore.setIsDialogVisible(false)}
                maskClosable={false}
                destroyOnClose={false}
                closable={false}
                width={1300}
                className="alpha-portal-dialog rule-import table scrollable full-screen"
                centered
                footer={[
                    <Button data-id="iota-add-group-dialog-cancel" size="large" className="light" key="back" onClick={rulesImportStore.resetImportParams}>
                        Cancel
                    </Button>,
                    <Observer key="next">
                        {() => (
                            <Button 
                                className="dark"
                                data-id="rules-import-page-import-button" 
                                size="large" 
                                key="submit" 
                                type="primary" 
                                onClick={() => rulesImportStore.handleImportClick(callBack)}
                                disabled={!rulesImportStore.isMapperTableVisible &&  !((isImportForIota && rulesImportStore.AllGroupsSelected) 
                                    || (rulesImportStore.IsRuleSelected && rulesImportStore.AllGroupsSelected))}   
                            >
                                {rulesImportStore.isMapperTableVisible || !rulesImportStore.mapperTableData.length ? 'Import' : 'Next'}
                            </Button>)}
                    </Observer>
                ]}>
                {this.props.warningMsg ? <Alert type="warning" message={this.props.warningMsg} style={{margin: '12px 0'}} /> : null}
                {content}
            </Modal>);
        
    }
}