import * as React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { AuthConsumer } from '../../authorization/AuthContext';
import { STORE_TECHNICAL_INFORMATION } from '../constants';
import { AdministrationStores } from '../stores';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import TechnicalInformation from '../components/TechnicalInformation';

type Props = AdministrationStores;

const TechnicalInformationPage: React.FC<Props> = ({ TechnicalInformation: store }) => {
    if (!store) {
        return null;
    }

    const content = <AuthConsumer>{() => <TechnicalInformation store={store} />}</AuthConsumer>;

    return <FlexContentContainer content={content} contentStyle={{ overflow: 'hidden' }} />;
};

export default inject(STORE_TECHNICAL_INFORMATION)(observer(TechnicalInformationPage));
