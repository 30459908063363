import * as React from 'react';
import security from '../../common/services/SecurityService';

export default class NoRolesError extends Error {
    errorScreen: React.ReactElement;
    
    constructor() {
        super();
        this.errorScreen = (
            <div className="no-access-to-portal">No roles assigned to current user. Please report to Administrator... 
                <span onClick={this.logout}>&nbsp;Log out</span>
            </div>);
    }

    logout() {
        security.inst.logout();
    }
}

export class NetworkError extends Error {
    static type: string = 'NETWORK_ERROR';

    errorScreen: React.ReactElement;
    
    constructor() {
        super();
        this.errorScreen = (
            <div className="no-access-to-portal">Could not fetch roles due to network error. Please, report to your administrator... 
                <span onClick={this.logout}>&nbsp;Log out</span>
            </div>);
    }

    logout() {
        security.inst.logout();
    }
}

export class UnauthorizedError extends Error {
    errorScreen: React.ReactElement;
    
    constructor() {
        super();
        this.errorScreen = (
            <div className="no-access-to-portal">User is not authorized. Please report to Administrator... 
                <span onClick={this.logout}>&nbsp;Log out</span>
            </div>);
    }

    logout() {
        security.inst.logout();
    }
}