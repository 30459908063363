import * as React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import { STORE_PROJECT_FORM } from '../../project_management/constants';
import { ProjectsRootVisualStore } from '../../common/stores';
import { ProjectFormVisualStore } from '../../project_management/stores';
import { FlexContentContainer } from '../../common/components';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import ProjectSettingsForm from '../components/ProjectSettingsForm';

type Props = {
    projectsRootUI?: ProjectsRootVisualStore;
    projectForm?: ProjectFormVisualStore
};

const ProjectSettingsPage: React.FC<Props> = ({ projectsRootUI, projectForm }) => {
    if (!projectsRootUI || !projectForm) {
        return null;
    }

    return (
        <HasPermission
            entityId={projectsRootUI.currentProject?.id}
            permissionClaim={AppPermissions.CanAddEditDeleteAssignProject}
            yes={() => (
                <FlexContentContainer
                    content={<ProjectSettingsForm store={projectsRootUI} formStore={projectForm} />}
                />
            )}
        />
    );
};

export default inject(STORE_PROJECTS_ROOT_UI, STORE_PROJECT_FORM)(observer(ProjectSettingsPage));
