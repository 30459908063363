import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { WorkflowTypeMessagesTemplateDto, CreateEditWorkflowFormValues } from '../../types';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';

const maxRowCount = 3;

const isValid = (value?: string) => {
    const lines = value ? value.split('\n').length : 0;
    return lines <= maxRowCount;
};

interface Props {
    editable: boolean;
    children: React.ReactNode;
    form?: FormInstance<CreateEditWorkflowFormValues>;
    dataIndex: keyof WorkflowTypeMessagesTemplateDto;
    messagesTemplate?: WorkflowTypeMessagesTemplateDto;
    workflow?: InstructWorkflowModel
}

const WorkflowMessageTemplateCell: React.FC<Props> = ({
    form,
    editable,
    children,
    dataIndex,
    messagesTemplate,
    workflow,
    ...restProps
}) => {
    if (!editable) {
        return <td {...restProps}>{children}</td>;
    }

    if (dataIndex === 'message' && workflow && messagesTemplate) {
        const existingMessagesTemplate = workflow.getMessagesTemplateByType(messagesTemplate.type);

        const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            const value = form?.getFieldsValue().messagesTemplates[messagesTemplate.type];

            if (e.key === 'Enter' && !isValid(value)) {
                e.preventDefault();
            }
        };

        const validateTextArea = (_: unknown, value: string) => {
            if (!isValid(value)) {
                return Promise.reject(`Maximum ${maxRowCount} rows allowed`);
            }

            return Promise.resolve();
        };

        return (
            <td {...restProps}>
                <Form.Item
                    className="messages-template-textarea"
                    name={['messagesTemplates', messagesTemplate.type]}
                    initialValue={existingMessagesTemplate ? existingMessagesTemplate.message : messagesTemplate.message}
                    rules={[{ required: true, message: 'Please enter message!' }, { validator: validateTextArea }]}
                >
                    <Input.TextArea
                        rows={4}
                        autoSize={{ minRows: 1, maxRows: maxRowCount }}
                        onKeyDown={handleKeyDown}
                    />
                </Form.Item>
            </td>
        );
    }

    return null;
};

export default observer(WorkflowMessageTemplateCell);
