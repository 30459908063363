import { observable, action, computed, reaction, runInAction } from 'mobx';
import { Project, Package, PackageLine, PackageState } from '../../common/models';
import { Observable, Subject, Subscription } from 'rxjs';
import { PackageLinesResponse, AutoCompleteSourceItem, PackageChange } from '../../common/types';
import { ProjectsRootVisualStore } from '../../common/stores';
import { Pager, PAGE_SIZE } from '../../common/stores/Pager';
import { debounceTime, merge, switchMap, map } from 'rxjs/operators';
import _ from 'lodash';
import type { BLOCK_TYPE } from '../../common/types/BlockType';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PackageSource } from '../../common/models/PackageSource';
import { message } from 'antd';

export const SEARCH_DEBOUNCE_TIME = 500;

export default class ProjectLabelsVisualStore {
    @observable
    selectedPackage: Package | null;

    @computed
    get packageLines(): PackageLine[] {
        return this.pager.data;
    }

    @computed
    get tags() {
        return this.projectsRootStore.tags;
    }

    @observable
    currentTags: string[] = [];

    @observable
    searchTerm: string = '';

    @observable
    isLoading: boolean = false;

    @observable
    pager: Pager<PackageLine>;

    @observable
    filterQuery: string = '';

    @observable
    visibleProperties: string[] = [];

    @observable
    blockTypes: BLOCK_TYPE[] = ['LINE_BLOCK'];

    @observable
    isPackageSourceChecked: boolean = false; 

    @observable
    isLoadingProject: boolean = false;

    @computed
    get project(): Project {
        return this.projectsRootStore.currentProject!;
    }

    @computed
    get filteredPackages() {
        if (this.project) {
            let packages = this.project!.packages;

            if (this.currentTags && this.currentTags.length)  {
                packages = this.project!
                    .packages
                    .filter(x => _.intersection(x.userTags, this.currentTags).length === this.currentTags.length);
            }
    
            if (this.showUserNotReviewed) {
                packages = packages.filter(x => !x.operationState || 
                    !_.includes(x.operationState, 'UserReviewed'));
            } 
            
            const filteredPackages = this.isPackageSourceChecked ? packages.filter(p => p.source === PackageSource.Portal || p.source === null) : packages;

            return filteredPackages.filter(p => p.state === PackageState.Ready);
        } else { 
            return [];
        }
    }

    @observable
    autocompleteSource: AutoCompleteSourceItem[] = [];

    @observable
    showUserNotReviewed: boolean = false;

    private searchForAutocompleteCallServer: (s: string) => Promise<void>;
    private searchSubject: Subject<string | null>;
    private searchResult: Observable<PackageLinesResponse>;
    private reactionDisposer: Function;
    private subscription: Subscription | null = null;

    constructor(private projectsRootStore: ProjectsRootVisualStore) {
        this.searchSubject = new Subject<string>();
        this.pager = new Pager();

        this.searchResult = this.searchSubject.pipe(debounceTime(SEARCH_DEBOUNCE_TIME),
            merge(this.pager.nextPageRequest.let(map(() => this.searchTerm))),
            switchMap(x => Observable.fromPromise(this.searchInPackages(x))));

        this.searchResult.subscribe(r => this.setFilteredLines(r));
        reaction(() => this.projectsRootStore.currentProject, (p) => {
            this.selectedPackage = null;
            this.visibleProperties = [];
            this.pager.resetPager();
            if (this.reactionDisposer) {
                this.reactionDisposer();
            }

            if (p) { 
                this.reactionDisposer = reaction(() => p.packages.length, () => {
                    this.performSearch(this.searchTerm);    
                });

                this.performSearch(this.searchTerm);
            }
        },       { 
            fireImmediately: true 
        });

        this.searchForAutocompleteCallServer = _.debounce(this.searchForAutocompleteImp, 200);
    }

    @action
    public selectPackage(pkg: Package | null) {
        runInAction(() => this.autocompleteSource = []);
        this.pager.resetPager();

        if (this.project == null) {
            return;
        }

        if (pkg == null) {
            this.selectedPackage = null;
        } else {
            const found = this.project.packages.find(p => p.id === pkg.id);
            this.selectedPackage = found || null;
        }

        this.performSearch(this.searchTerm);
    }

    @action
    filterMarkedPackages(toFilter: boolean) {
        this.showUserNotReviewed = toFilter;
    }

    @action
    performSearch(term: string = '') {
        if (!this.selectedPackage && term === '') {
            return;
        }

        this.searchTerm = term;
        this.filterQuery = `${this.searchTerm} + ${this.selectedPackage ? this.selectedPackage.name : ''}`;
        this.pager.resetPager();
        this.isLoading = true;
        this.searchSubject.next(this.searchTerm);
    }

    @action
    clearFilter() {
        this.selectedPackage = null;
        this.clearSearch();
    }

    @action.bound
    clearSearch() {
        this.searchTerm = '';
        this.visibleProperties = [];
        this.performSearch();
    }

    @action.bound
    handlePackageSourceCheck (e: CheckboxChangeEvent) {
        this.isPackageSourceChecked = e.target.checked;
    }

    @action
    showAll(show: boolean) {
        this.visibleProperties = show ? this.project.packageProperties : [];
    }

    @action
    showProperty(props: string[]) {
        this.visibleProperties = props;
    }

    @action
    loadNextPage() {
        this.pager.loadNextPage();
    }

    @action
    async searchForAutocomplete(term: string) {
        this.searchTerm = term;
        return this.searchForAutocompleteCallServer(this.searchTerm);
    }

    @action
    changeBlockType(t: BLOCK_TYPE) {
        this.blockTypes = [t];
        this.performSearch(this.searchTerm);
        this.searchForAutocompleteImp(this.searchTerm);
    }

    @action.bound
    setTags(tags: string[]) {
        this.currentTags = tags;
        this.performSearch(this.searchTerm);
    }

    @action.bound
    setIsLoadingProject(isLoading: boolean) {
        this.isLoadingProject = isLoading;
    }

    @action.bound
    handlePackageChanges(packageChange: PackageChange) {
        if (!this.project) {
            return;
        }
        
        if (this.project.id === packageChange.projectId && packageChange.state === PackageState.Ready) {
            this.loadProjectPackages();
        }
    }

    @action.bound
    async loadProjectPackages() {
        try {
            this.setIsLoadingProject(true);
            const response = await this.projectsRootStore.searchInProject(null, 0, [], true, 9999);

            runInAction(() => {
                this.projectsRootStore.setCurrentProjectPackages(response.lines);
            });

        } catch(ex) {
            message.error('Failed to load project packages');
            console.error(ex);
        } finally {
            this.setIsLoadingProject(false);
        }
    }

    @action
    private async searchForAutocompleteImp(term: string) {
        if (!this.selectedPackage) {
            return;
        }

        const request = {
            projectId: this.projectsRootStore.currentProject!.id,
            pkgId: this.selectedPackage ? this.selectedPackage.id : null,
            search: term,
            page: 0,
            pageSize: 15,
            blockTypes: this.blockTypes
        };

        const result = await this.projectsRootStore.searchInPackages(request);

        runInAction(() => {
            this.autocompleteSource = result.lines.map(l => ({
                text: this.getAutocompleteText(l, term),
                imagePath: l.imagePath,
                packageName: l.packageName
            }));
        });
    }

    subscribeToPackageChanges() {
        this.subscription = this.projectsRootStore.projectsStore.packageChanges.subscribe(p => this.handlePackageChanges(p));
    }

    unsubscribeFromPackageChanges() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private getAutocompleteText(line: PackageLine, term: string) {
        var index = line.text.search(new RegExp(term, 'i'));
        if (index >= 0) {
            const startIndex = index > 30 ? index - 30 : index;
            const endIndex = startIndex + 120;
            let itemText = startIndex > 0 ? '...' + line.text.substring(startIndex, endIndex) : line.text.substring(startIndex, endIndex);
            itemText = endIndex < line.text.length ? itemText + '...' : itemText;
            return itemText;
        } else {
            return line.text.substring(0, 150);
        }
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    @action
    private setFilteredLines(response: PackageLinesResponse) {
        const { lines, total } = response;

        this.pager.setData(lines, total);
        this.isLoading = false;
    }

    private searchInPackages(term: string | null): Promise<PackageLinesResponse> {        
        // if (!this.selectedPackage) {
        //     return Promise.resolve(new PackageLinesResponse());
        // }
        
        const request = {
            projectId: this.projectsRootStore.currentProject!.id,
            pkgId: this.selectedPackage ? this.selectedPackage.id : null,
            search: term ? term.replace(/\.\.\./gi, '') : term,
            page: this.pager.currentPage,
            pageSize: PAGE_SIZE,
            blockTypes: this.blockTypes,
            tags: this.currentTags
        };

        return this.projectsRootStore.searchInPackages(request);
    }
}