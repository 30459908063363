/* eslint-disable @typescript-eslint/member-ordering */
import { ProjectsRootVisualStore, ErrorStore, RouterStore } from '../../common/stores';
import { ApplicationDefinitionsService, EnvironmentVariablesService } from '../services';
import { computed, action, observable, runInAction, reaction } from 'mobx';
import { InputBinding, EnvironmentVariable, InputMeta, BindingType } from '../types';
import { ApplicationDefinitionBase, ApplicationDefinition } from '../types/ApplicationDefinition';
import { Dictionary } from 'lodash';
import { RulesStore } from '../../rules/stores';
import { ProjectApplicationDefinitionsTreeVisualStore } from '.';
import TagsGroupVisualStore from '../../rules/stores/TagsGroupVisualStore';
import ProjectApplicationDefinitionsVisualStore from './ProjectApplicationDefinitionsVisualStore';
import BindingPreviewVisualStore from './BindingPreviewVisualStore';
import { IotaAppPagesNavigation } from '../routes';
import { RcFile } from 'antd/lib/upload';
import { message } from 'antd';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
import { UserModel } from '../../administration/types/UserModel';
import { ImportInputsResponse } from '../../common/types/types';

type LocalBindings = {
    [key: string]: InputBinding[]
};

export type FormField = { type?: string; value?: string | null };

export default class ProjectApplicationDefinitionEditVisualStore {
    @observable
    currentAppDef: ApplicationDefinitionBase | undefined;

    @observable
    inputs: InputMeta[] = [];

    @observable
    initialBindings: InputBinding[] = [];

    @observable
    localBindings: LocalBindings = {};

    @observable
    environmentVariables: EnvironmentVariable[];

    @observable
    isLoading: boolean = false;

    @observable
    currentImportFile: RcFile | undefined = undefined;

    @observable
    fileImportActionHeaders: {};

    @observable
    isImportConfirmDialogVisible: boolean = false;

    @observable
    isImportingBindings: boolean = false;

    @observable
    isLoadingUsers: boolean = false;

    @observable
    users: UserModel[];

    @observable
    isFormChanged: boolean = false;
    
    @computed
    get isInputDisabled() {
        return this.appDefStore.isInputDisabled && this.currentAppDef?.state === 'Published';
    }

    @computed
    get currentProject() {
        return this.rootStore.currentProject!;
    }

    @computed
    get tags() {
        return Object.getOwnPropertyNames(this.ruleStore.groupedByTag).slice().sort((a, b) => a.localeCompare(b));
    }

    @computed
    get ruleTags() {
        return this.ruleStore.ruleTags;
    }
    
    @computed
    get tagGroups() {
        return this.tagsGroupStore.tagsGroups ?? [];
    }

    @computed
    get importBindingsActionUrl() {
        if (!this.currentProject || !this.currentAppDef) {
            return '';
        }

        return process.env.REACT_APP_MANAGE_URL + `projects/${this.currentProject.id}/iota/applications/${this.currentAppDef.id}/import-bindings`;
    }

    constructor(
        private rootStore: ProjectsRootVisualStore,
        private appDefService: ApplicationDefinitionsService,
        private ruleStore: RulesStore,
        private tagsGroupStore: TagsGroupVisualStore,
        private envVariablesService: EnvironmentVariablesService,
        public errorStore: ErrorStore,
        private groupTreeStore: ProjectApplicationDefinitionsTreeVisualStore,
        private appDefStore: ProjectApplicationDefinitionsVisualStore,
        private routerStore: RouterStore,
        private previewStore: BindingPreviewVisualStore,
        private adminService: GlobalAdministrationService
    ) {
        this.loadEnvironmentVariables();
        this.getUsers();
    }

    @action.bound
    setIsUsersLoading(isLoading: boolean) {
        this.isLoadingUsers = isLoading;
    }

    @action.bound
    async getUsers() {
        this.isLoadingUsers = true;
        this.users = await this.adminService.getAppUsers();
        this.isLoadingUsers = false;
    }

    @action.bound
    getUserNameById(id: string) {
        if (!this.users) {
            return id;
        }

        const user = this.users.find(u => u.id === id);

        if (!user){
            return id;
        }

        return user.firstName ? `${user.firstName} ${user.lastName}` : user.userName;
    }

    @action.bound
    async setHeaders() {
        const token = await this.appDefService.getAuthToken();
        this.fileImportActionHeaders = {
            'Authorization': 'Bearer ' + token
        };
        return Promise.resolve();
    }

    @action.bound
    setCurrentImportFile(file: RcFile | undefined) {
        this.currentImportFile = file;
    }

    @action.bound
    getTagsForGroup(groupId: string | null) {
        return this.ruleStore.ruleTags.filter(x => x.groupId === groupId).map(y => y.name);
    }

    getTagById(id: string) {
        const tag = this.ruleStore.ruleTags.find(x => x.id === id);
        return tag && tag.name || '';
    }

    getTagIdByName(name: string) {
        const tag = this.ruleStore.ruleTags.find(x => x.name === name);
        return tag ? tag.id : '';
    }

    @action.bound
    async loadApplication(id: string) {
        if (this.currentProject!) {
            this.isLoading = true;

            const promises = [
                this.getRules(),
                this.appDefService.getApplicationDefinition(this.currentProject.id, id)
            ] as [Promise<void>, Promise<ApplicationDefinitionBase | null>];

            const resp = await Promise.all(promises);
            const appDef = resp[1];
             
            runInAction(() => {
                this.currentAppDef = appDef!;

                if (appDef instanceof ApplicationDefinition) {
                    this.initialBindings = appDef!.bindings;
                }

                this.processMeta();
                this.setLocalBindings();
                this.isLoading = false;
            });
        } else {
            reaction(() => this.currentProject, (p, r) => {
                this.loadApplication(id);
                r.dispose();
            });
        }
    }

    getRules() {
        if (!this.ruleStore.ruleTags?.length) {
            return this.ruleStore.getRules();
        }
        return Promise.resolve();
    }

    setLocalBindings() {
        this.localBindings = {};
        this.inputs.forEach(x => {
            const bindings = this.initialBindings === null ? [] : this.initialBindings.filter(y => y.inputId === x.id);
            if (bindings.length) {
                this.localBindings[x.id] = bindings;
            } else {
                this.localBindings[x.id] = [{inputId: x.id, type: BindingType.constant, value: null}];
            }
        });
   
    }

    @action.bound
    setEnvironmentVariables(envVars: EnvironmentVariable[]) {
        this.environmentVariables = envVars;
    }

    @action.bound
    async loadEnvironmentVariables() {
        const envVars = await this.envVariablesService.getAllEnvironmentVariables();
        this.setEnvironmentVariables(envVars);
    }

    @action
    updateLocalBindings(inputId: string, fields: Array<FormField>) {
        if (this.localBindings[inputId]) {
            this.localBindings[inputId] = fields.map(field => this.createInputBinding(inputId, field));
        }
    }

    @action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async setMatch(m: any) {
        const ignorePaths = ['bindingPreview', 'applicationPreview', 'applicationDefinitionConditionalPreview'];

        if (this.routerStore.prevPathname && ignorePaths.some(path => this.routerStore.prevPathname.includes(path))) {
            return;
        }

        this.currentAppDef = undefined;
        const id = m.appId;
        await this.loadApplication(id);
    }

    @action.bound
    navigateToList() {
        this.rootStore.navigateToApplicationDefinitionsPage(this.currentProject);
    }

    createInputBinding(inputId: string, field?: FormField): InputBinding {
        if (field === undefined || field.type === undefined || field.value === undefined) {
            return {
                inputId,
                type: BindingType.constant,
                value: null
            };
        }

        return {
            inputId,
            type: field.type,
            value: field.type === BindingType.tag && field.value ? this.ruleStore.ruleTags.find(y => y.name === field.value)?.id : field.value
        };
    }

    @action.bound
    async save(inputs: Dictionary<FormField[]>) {
        try {
            const bindings: InputBinding[] = Object.entries(inputs).reduce(
                (acc, [inputId, fields]) => [...acc, ...fields.map(field => this.createInputBinding(inputId, field))],
                []
            );

            const resp = await this.appDefService.updateBindings(this.currentProject.id, this.currentAppDef!.id, bindings);

            resp.map(() => {
                const appToUpdate = this.groupTreeStore.filteredApplicationDefinitions.find(a => a.id === this.currentAppDef!.id);

                if (appToUpdate instanceof ApplicationDefinition) {
                    let newList = this.groupTreeStore.filteredApplicationDefinitions.slice();
                    const index = newList.indexOf(appToUpdate);
                    const app = newList[index] as ApplicationDefinition;
                    app.bindings = bindings;
                    this.groupTreeStore.setFilteredApplicationDefinitions(newList);
                }
            });
            this.setIsFormChanged(false);
            this.navigateToList();
        } catch (err) {
            this.errorStore.addError(err);
        }
    }

    @action.bound
    previewApplication() {
        if (!this.currentAppDef) {
            return;
        }

        const inputBindings = Object.values(this.localBindings).reduce((acc, bindings) => [...acc, ...bindings], []).filter(binding => !!binding.value);

        this.previewStore.setPreviewData(this.currentAppDef.id, inputBindings);

        this.routerStore.pushToHistory(
            IotaAppPagesNavigation.ApplicationDefinitionPreview
                .replace(':id', this.currentProject.id)
                .replace(':appId', this.currentAppDef.id)
        );
    }

    @action
    previewInput(inputId: string, index: number) {
        if (!this.currentAppDef) {
            return;
        }

        const inputBinding = this.localBindings[inputId][index];

        if (!inputBinding) {
            return;
        }

        this.previewStore.setPreviewData(this.currentAppDef.id, [inputBinding]);

        this.routerStore.pushToHistory(
            IotaAppPagesNavigation.ApplicationDefinitionInputBindingPreview
                .replace(':id', this.currentProject.id)
                .replace(':appId', this.currentAppDef.id)
                .replace(':inputId', inputBinding.inputId)
                .replace(':index', index.toString())
        );
    }

    isTagDisabled(tag: string) {
        const rules = this.ruleStore.rules.filter(r=> r.tag === tag);
        return !rules.length ? false : !rules.some(r=> r.state === 'Enabled');
    }

    @action
    exportAppBindings() {
        if (!this.currentAppDef || !this.currentProject) {
            console.error('No current app def or project');
            return;
        }

        this.appDefService.exportAppBindings(this.currentAppDef.id, this.currentProject!.id);
    }

    @action.bound
    setIsImportingBindings(isImporting: boolean) {
        this.isImportingBindings = isImporting;
    }

    @action.bound
    handleImportResponse(response: ImportInputsResponse) {
        if (response.importedSuccessfully) {
            const appDefId = this.currentAppDef!.id;
            this.currentAppDef = undefined;

            // Needed for correct UI update
            setTimeout(() => {
                message.success('You have successfully updated the application Inputs bindings.');
                this.setCurrentImportFile(undefined);
                this.loadApplication(appDefId);
            }, 0);
            return;
        }

        if (!response.canProceed) {
            message.error(response.responseMessage);
            return;
        }

        if (response.canProceed) {
            this.setIsImportConfirmDialogVisible(true);
        }
    }

    @action.bound
    setIsImportConfirmDialogVisible(visible: boolean) {
        this.isImportConfirmDialogVisible = visible;
    }

    @action.bound
    handleImportDialogCancel() {
        this.setIsImportConfirmDialogVisible(false);
        this.setCurrentImportFile(undefined);
    }

    @action.bound
    async handleImportDialogConfirm() {
        try {
            this.setIsImportingBindings(true);
            const resp = await this.appDefService.commitAppBindings(this.currentAppDef!.id, this.currentProject!.id, this.currentImportFile!);

            if (resp.isOk()) {
                const appDefId = this.currentAppDef!.id;
                this.currentAppDef = undefined;
                
                // Needed for correct UI update
                setTimeout(() => {
                    this.loadApplication(appDefId);
                    this.handleImportDialogCancel();
                    message.success('You have successfully updated the application Inputs bindings.');
                }, 0);
            } else {
                message.error(resp.error.data);
                console.error(resp.error.data);
            }
        } catch (err) {
            message.error(err);
            console.error(err);
        } finally {
            this.setIsImportingBindings(false);
        }
    }

    @action.bound
    setCurrentAppDef(appDef: ApplicationDefinitionBase | undefined) {
        this.currentAppDef = appDef;
    }

    @action.bound
    setIsFormChanged(isChanged: boolean) {
        this.isFormChanged = isChanged;
    }

    private processMeta() {
        if (this.currentAppDef) {
            this.inputs = JSON.parse(this.currentAppDef!.meta).inputs;            
        } else {
            this.inputs = [];
        }
    }
}
