import { observable, action } from 'mobx';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';

export const PAGE_SIZE = 250;
export const LIST_PAGE_SIZE = 50;

export class Pager<T> {
    @observable
    currentPage: number;

    @observable
    isNextPageLoading: boolean = false;

    @observable
    hasNextPage: boolean = false;

    @observable
    data: T[] = [];

    get nextPageRequest(): Observable<number> {
        return this.pagerSubject.asObservable();
    }

    private pagerSubject: Subject<number>;

    constructor() {
        this.currentPage = 0;
        this.pagerSubject = new Subject<number>();
    }

    @action
    loadNextPage() {
        if (!this.hasNextPage) {
            return;
        }

        this.currentPage += 1;
        this.isNextPageLoading = true;
        this.pagerSubject.next(1);
    }

    @action
    resetPager() {
        this.currentPage = 0;
        this.data = [];
    }

    @action
    setData(data: T[], total: number) {
        if (this.currentPage > 0) {
            this.data = this.data.slice().concat(data);
        } else {
            this.data = data;
        }

        this.hasNextPage = total - (this.currentPage + 1) * PAGE_SIZE > 0;
        this.isNextPageLoading = false;
    }
    
    @action
    addData(data: T) {
        this.data.unshift(data);
        this.hasNextPage = this.data.length + 1 - (this.currentPage + 1) * PAGE_SIZE > 0;
        this.isNextPageLoading = false;

    }
}