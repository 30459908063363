import { RootStores } from '../../common/stores';
import IotaAuditVisualStore from './IotaAuditVisualStore';
import { STORE_IOTA_AUDIT } from '../constants';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import IotaAuditService from '../services/IotaAuditService';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
export { default as IotaAuditVisualStore } from './IotaAuditVisualStore';

export function injectIotaAuditStores(obj: RootStores) {
    const service = new IotaAuditService();
    const adminService = new GlobalAdministrationService();

    obj[STORE_IOTA_AUDIT] = new IotaAuditVisualStore(obj[STORE_PROJECTS_ROOT_UI], service, adminService);
}

export type IotaSessionsStores = {
    [STORE_IOTA_AUDIT]?: IotaAuditVisualStore
};