import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import ProjectUploadVisualStore from './ProjectUploadVisualStore';
import { RootStores } from '../../common/stores';
import { STORE_PROJECT_UPLOAD } from '../constants';

export { default as ProjectUploadVisualStore } from './ProjectUploadVisualStore';

export function injectUploadStores(obj: RootStores) {
    obj[STORE_PROJECT_UPLOAD] = new  ProjectUploadVisualStore(obj[STORE_PROJECTS_ROOT_UI]);
}

export type UploadStores = {
    [STORE_PROJECT_UPLOAD]?: ProjectUploadVisualStore
};