
import * as React from 'react';
import Card from 'antd/lib/card';
import { Tooltip, Button, Form } from 'antd';
import PipelineStep from '../models/PipelineStep';
import { observer } from 'mobx-react-lite';
import PipelineVisualStore from '../../rules/stores/PipelineVisualStore';
import { StepModel } from '../../rules/types';
import UserDefinedParametersEditor from './UserDefinedParametersEditor';
import { QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons';

type Props<T extends PipelineVisualStore<TPipelineStep>, TPipelineStep extends string> = {
    store: T;
    step: PipelineStep<TPipelineStep>;
    stepKey: number;
    stepModel: StepModel
}; 

export const UserDefinedParametersCard: <T extends PipelineVisualStore<TPipelineStep>, TPipelineStep extends string>(
    props: Props<T, TPipelineStep>) => React.ReactElement<Props<T, TPipelineStep>> = ({store, step, stepKey, stepModel}) => {
    const [form] = Form.useForm();
    React.useEffect(() => {
        return () => {
            store.removeEmptyParamForUserDefinedCard(stepKey);
        };
    }, [store, stepKey]);
    const handleSubmit = async() => {
        store.validateForm(form, step, stepKey, true);
    };

    if (store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const handleClick = (stepKeyIndex: number) => {
        store.addEmptyParamForUserDefinedCard(stepKeyIndex);
    };

    const getInitialValues = () => {
        const keys = step.parameterKeys;
        const obj = {};
        keys.forEach((key, index) => {
            obj[`name${index}`] =  key;
            obj[`value${index}`] = step.getParameterValues(key);
        });
        return obj;
    };

    const toolTip = () => (
        <div style={{display: 'inline'}}>
            {'Parameters  '}
            <Tooltip  title={stepModel.parametersDescription}>
                <QuestionCircleOutlined />
            </Tooltip>
        </div>
    ); 
    const marginStyle = { marginTop: 20, marginBottom: 20 };
    return(
        <Card
            type="inner"
            title={toolTip()}
            style={marginStyle}
        >     
            <Form
                form={form}
                initialValues={getInitialValues()}
            >
                {step.parameterKeys.concat(store.emptyParametersArr[stepKey] || []).map((key: string, index: number) => (
                    <UserDefinedParametersEditor 
                        index={index} 
                        key={index}
                        step={step} 
                        store={store} 
                        paramKey={key} 
                        stepKey={stepKey} 
                        form={form}
                    />
                ))
                }
                <Form.Item>
                    <div style={{position: 'relative', float: 'right'}} >
                        <Button 
                            data-id={`pipeline-edit-step-add-button-${stepKey}`}
                            type="primary"
                            onClick={() => handleClick(stepKey)}
                        ><PlusOutlined />
                        </Button>
                    </div>
                </Form.Item>   
            </Form>
        </Card> 
    );
};

export default observer(UserDefinedParametersCard);