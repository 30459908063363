import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import SessionsPage from '../screens/SessionsPage';

export const SessionsPagesNavigation = {
    SessionsPage: '/projects/:id/sessions'
};

export default () => {
    return (<ProtectedRoute component={SessionsPage} path={SessionsPagesNavigation.SessionsPage} />);
};