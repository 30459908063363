import { action, observable, runInAction, computed } from 'mobx';
import { message } from 'antd';
import PreviewVisualStoreBase from '../../rules/stores/PreviewVisualStoreBase';
import ProjectApplicationDefinitionEditVisualStore from './ProjectApplicationDefinitionEditVisualStore';
import { ProjectsRootVisualStore, RootStores } from '../../common/stores';
import { RulesStore } from '../../rules/stores';
import { InputBinding, BindingType } from '../types';
import { ApplicationDefinitionsService } from '../services';
import { PackageLine } from '../../common/models';
import { RuleResult } from '../../rules/types';
import { InputBindingPreview } from '../types/ApplicationDefinitionPreviewBindingModel';
import { ApplicationDefinition } from '../types/ApplicationDefinition';

type Binding = InputBinding;

export default class BindingPreviewVisualStore extends PreviewVisualStoreBase {
    @observable
    appId: string;

    @observable
    inputId: string;

    @observable
    index: number;

    @observable
    inputBindings: Binding[] = [];

    isEditable: boolean = false;

    @computed
    get applicationDefinitionStore(): ProjectApplicationDefinitionEditVisualStore | null {
        return this.stores.projectApplicationDefinitionEditUI ?? null;
    }

    constructor(
        private stores: RootStores,
        public projectStore: ProjectsRootVisualStore,
        public ruleStore: RulesStore,
        private service: ApplicationDefinitionsService
    ) {
        super(projectStore, ruleStore);
    }

    @action.bound
    async initUsingProjectId(projectId: string) {
        if (!this.appId || !this.applicationDefinitionStore) {
            return;
        }

        await this.rootStore.loadProjects();

        const project = this.rootStore.projects.find(p => p.id === projectId);

        if (!project) {
            return;
        }

        await this.projectStore.setupCurrentProject(project);
        await this.applicationDefinitionStore.loadApplication(this.appId);

        if (!this.applicationDefinitionStore.currentAppDef) {
            return;
        }

        if (this.applicationDefinitionStore.currentAppDef instanceof ApplicationDefinition) {
            const bindings = this.applicationDefinitionStore.currentAppDef.bindings;
            const inputBindings = bindings && this.inputId && typeof this.index === 'number' ? [bindings.filter(b => b.inputId === this.inputId)[this.index]] : bindings;

            if (inputBindings) {
                runInAction(() => {
                    this.inputBindings = inputBindings.filter(i => !!(i && i.value));
                });
            }
        }
    }

    @action.bound
    async loadPreviewResults() {
        if (!this.appId || !this.project || !this.inputBindings.length || !this.selectedPackageIds.length) {
            return;
        }

        runInAction(() => {
            this.isExecuting = true;
        });

        const resp = await this.service.applicationDefinitionPreviewBinding(
            this.project.id,
            this.appId,
            this.inputBindings,
            this.selectedPackageIds
        );

        runInAction(() => {
            this.isExecuting = false;
        });

        if (!resp.isOk()) {
            message.error(`Error: ${resp.error.data ? resp.error.data.title : resp.error.text}`);
            return;
        }

        resp.map(result => {
            const previewResults = result.map(item => {
                return {
                    packageId: item.packageId,
                    previewResults: item.inputs.map(input => {
                        const entry = {
                            field: {} as PackageLine,
                            tokens: [],
                            tag: {
                                tagId: '',
                                values: input.previewValues.map(v => v.value)
                            },
                            resultTokens: []
                        };

                        return {
                            entries: [entry],
                            rule: {
                                name: this.getInputPreviewLabel(input)
                            } as RuleResult
                        };
                    })
                };
            });

            runInAction(() => {
                this.previewResults = previewResults;
            });
        });
    }

    @action.bound
    setPreviewData(appId: string, inputBindings: Binding[]) {
        this.appId = appId;
        this.inputBindings = inputBindings;
    }

    @action.bound
    goToInitialList() {
        if (this.project && this.appId) {
            this.projectStore.navigateToEditApplicationDefinitionPage(this.project, this.appId);
        }
    }

    @action.bound
    setItem(params: { appId: string; inputId?: string; index?: string }) {
        runInAction(() => {
            this.appId = params.appId;

            if (params.inputId) {
                this.inputId = params.inputId;
            }

            if (params.index) {
                this.index = +params.index;
            }
        });
    }

    getInputPreviewLabel(inputBinding: InputBindingPreview) {
        if (!this.applicationDefinitionStore) {
            return inputBinding.inputId;
        }

        const input = this.applicationDefinitionStore.inputs.find(i => i.id === inputBinding.inputId);

        const name = input ? input.name : inputBinding.inputId;

        if (inputBinding.type.toLocaleLowerCase() === BindingType.constant) {
            return `${name} - Constant - ${inputBinding.value}`;
        }

        if (inputBinding.type === BindingType.tag) {
            const tag = this.rulesStore.ruleTags.find(t => t.id === inputBinding.value);
            return `${name} - Rule Tag - ${tag ? tag.name : ''}`;
        }

        if (inputBinding.type === BindingType.variable) {
            const variable = this.applicationDefinitionStore.environmentVariables.find(v => v.id === inputBinding.value);
            return `${name} - Environment Variable - ${variable ? variable.name : ''}`;
        }

        if (inputBinding.type === BindingType.codeRule) {
            return `${name} - Code Business Rule`;
        }

        return name;
    }

    setUrl() {
        return;
    }
}
