/* eslint-disable @typescript-eslint/member-ordering */
import {  action, observable } from 'mobx';
import RuleEditVisualStore from './RuleEditVisualStore';
import type { RuleTypes } from '../models';
import { PipelineStep } from '../models';
import { RouterStore } from '../../common/stores';
import RulesStore from './RulesStore';
import PipelineStepType from '../types/PipelineStepType';
import RuleNewVisuaStoreBase from './RuleNewVisuaStoreBase';
import RuleType from '../types/RuleType';

export default class CopiedPipelineVisualStore extends RuleNewVisuaStoreBase {
    @observable
    editableRule?: RuleTypes;

    copiedPipeline: PipelineStep<PipelineStepType>[] = [];

    groupId: string;

    constructor(public store: RulesStore, routerStore: RouterStore, editStore: RuleEditVisualStore) {
        super(store, routerStore, editStore);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setMatch(m: any) {
        const rule = this.store.rules.find(r => r.id === m.ruleId);
        this.copiedPipeline =  rule!.pipeline;
        this.groupId = m.groupId;
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCurrentGroupId(m: any) {
        this.editStore.setCurrentGroupId(m);
    }

    selectType(v?: RuleType) {
        this.selectNewType(this.copiedPipeline, v);
    }

    get currentGroupId () {
        return this.groupId;
    }
}