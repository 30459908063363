import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Table, InputNumber, Button, Tooltip, Popconfirm } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { ApplicationDefinitionConditionalEditStore } from '../../stores';
import { ProjectApplicationDefinitionEditVisualStore } from '../../../iota_applications/stores';
import { ConditionModel } from '../../models';
import './ConditionsTable.less';

interface Props {
    store: ApplicationDefinitionConditionalEditStore;
    applicationDefinitionEditStore: ProjectApplicationDefinitionEditVisualStore
}

const ConditionsTable: React.FC<Props> = ({ store, applicationDefinitionEditStore }) => {
    const columns: ColumnProps<ConditionModel>[] = [
        {
            title: 'Input Condition',
            key: 'name',
            dataIndex: 'name',
            width: '30%',
            render: (_, condition: ConditionModel) => (
                <Observer>{() => <span className="cell-limited-max-width">{condition.name}</span>}</Observer>
            )
        },
        {
            title: 'Input Group',
            key: 'inputGroupId',
            dataIndex: 'inputGroupId',
            width: '30%',
            render: (_, condition: ConditionModel) => (
                <Observer>
                    {() => (
                        <span className="cell-limited-max-width">
                            {condition.inputGroup ? condition.inputGroup.name : ''}
                        </span>
                    )}
                </Observer>
            )
        },
        {
            title: 'Priority',
            key: 'priority',
            dataIndex: 'priority',
            width: '25%',
            render: (_, condition: ConditionModel) => (
                <Observer>
                    {() => (
                        <div className="priority-container" onClick={e => e.stopPropagation()}>
                            <InputNumber
                                className="priority-input"
                                min={1}
                                max={store.conditions.length}
                                value={condition.priority}
                                onChange={priority => {
                                    if (typeof priority === 'number') {
                                        condition.setPriority(priority);
                                    }
                                }}
                            />
                        </div>
                    )}
                </Observer>
            )
        },
        {
            title: '',
            key: 'actions',
            dataIndex: 'actions',
            width: '10%',
            render: (_, condition: ConditionModel) => (
                <div className="row-actions-wrapper" onClick={e => e.stopPropagation()}>
                    <Tooltip title="Edit Condition">
                        <Button
                            type="link"
                            size="small"
                            data-id="edit-condition"
                            icon={<i className="alpha-icon lg edit-icon" />}
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                condition.setEditable(true);
                                store.setCreateEditConditionDialogVisible(true);
                            }}
                        />
                    </Tooltip>

                    <Popconfirm
                        title="Are you sure you want to delete this condition?"
                        onConfirm={() => condition.delete()}
                    >
                        <Tooltip title="Delete Condition">
                            <Button
                                type="link"
                                size="small"
                                data-id="delete-condition"
                                icon={<i className="alpha-icon lg delete-icon" />}
                            />
                        </Tooltip>
                    </Popconfirm>
                </div>
            )
        },
        {
            title: '',
            key: 'extra',
            dataIndex: 'extra',
            width: '5%',
            render: (_, condition: ConditionModel) => (
                <Observer>
                    {() => {
                        const conditionTag = applicationDefinitionEditStore.ruleTags.find(
                            t => t.id === condition.tagId
                        );

                        if (!conditionTag) {
                            return (
                                <Tooltip title="Mandatory setting is missing, please check" placement="topLeft">
                                    <ExclamationCircleOutlined className="exclamation-icon" />
                                </Tooltip>
                            );
                        }

                        return <></>;
                    }}
                </Observer>
            )
        }
    ];

    const selectedCondition = React.useMemo(() => store.selectedCondition, [store.selectedCondition]);

    return (
        <Table
            className="alpha-portal-table conditions-table"
            rowKey={(condition: ConditionModel) => condition.id}
            rowClassName={(condition: ConditionModel) =>
                `condition-row ${selectedCondition && selectedCondition.id === condition.id ? 'selected' : ''}`
            }
            onRow={(condition: ConditionModel) => ({
                onClick: () => {
                    if (selectedCondition && selectedCondition.id !== condition.id) {
                        selectedCondition.setSelected(false);
                    }

                    condition.setSelected(!condition.selected);
                }
            })}
            dataSource={store.conditions.slice().sort((a, b) => a.priority - b.priority)}
            columns={columns}
            pagination={false}
        />
    );
};

export default observer(ConditionsTable);
