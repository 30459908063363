export const STORE_PROJECT_APPLICATION_DEFINITIONS = 'projectApplicationDefinitionsUI';
export type STORE_PROJECT_APPLICATION_DEFINITIONS = typeof STORE_PROJECT_APPLICATION_DEFINITIONS;

export const STORE_PROJECT_APPLICATION_DEFINITION_EDIT = 'projectApplicationDefinitionEditUI';
export type STORE_PROJECT_APPLICATION_DEFINITION_EDIT = typeof STORE_PROJECT_APPLICATION_DEFINITION_EDIT;

export const STORE_PROJECT_APPLICATION_DEFINITION_TREE = 'projectApplicationDefinitionsTreeUI';
export type STORE_PROJECT_APPLICATION_DEFINITION_TREE = typeof STORE_PROJECT_APPLICATION_DEFINITION_TREE;

export const STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW = 'projectApplicationDefinitionsBindingPreviewUI';
export type STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW = typeof STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW;