import { Button, Collapse, Empty, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ProjectMLModelsVisualStore from '../stores/ProjectMLModelsVisualStore';

type Props = {
    store: ProjectMLModelsVisualStore
};

const MlModelRulesModal: React.FC<Props> = ({store}) => {
    const handleClose = () => {
        store.setIsModelRulesDialogVisible(false);
    };

    const getContent = () => {
        if (store.isLoadingModelRules) {
            return (<Spin />);
        }

        if (store.currentRulesGroups.length === 0) {
            return (<Empty description="No rules using this model were found" />);
        }

        const ruleDefaultActiveKeys = store.currentRulesGroups.map(r => r.id);

        return (
            <Collapse defaultActiveKey={ruleDefaultActiveKeys}>
                {store.currentRulesGroups.map((group) => {
                    const tagDefaultActiveKeys = store.currentRuleTags.map(t => t.id);
                    return (
                        <Collapse.Panel header={group.name} key={group.id}>
                            <Collapse defaultActiveKey={tagDefaultActiveKeys}>
                                {store.currentRuleTags.filter((tag) => tag.groupId === group.id).map((tag) => (
                                    <Collapse.Panel header={tag.name} key={tag.id}>
                                        {store.currentModelRules.filter((rule) => rule.tagId === tag.id).map((rule) => (
                                            <div key={rule.id}>
                                                <a target="_blank" rel="noreferrer" href={`rules/edit/${rule.id}/false`}>{rule.name}</a>
                                            </div>
                                        ))}
                                    </Collapse.Panel>
                                ))}
                            </Collapse>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        );
    };

    return (
        <Modal
            title={`Rules for ${store.currentModel?.name}`}
            visible={store.isModelRulesDialogVisible}
            className="alpha-portal-dialog two-columns"
            closable={false}
            maskClosable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    key="mlmodel-rules-dialog-close"
                    data-id="mlmodel-rules-dialog-close"
                    className="light"
                    size="large"
                    onClick={handleClose}
                >
                    Close
                </Button>
            ]}
            onCancel={handleClose}
        >
            {getContent()}
        </Modal>
    );
};

export default observer(MlModelRulesModal);