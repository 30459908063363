import { message } from 'antd';
import { action, computed, observable, runInAction } from 'mobx';
import { Package } from '../../common/models';
import { ProjectsService } from '../../common/services';
import { PAGE_SIZE } from '../../common/stores/Pager';
import { SearchPackagesRequest } from '../../common/types';
import { ProjectsRootVisualStore } from '../../common/stores';
import { FormTypesService } from '../services';
import { FormBlockPreviewModel, FormPartAdjustedBlock, FormPartDetailsModel } from '../types';

export default class FormPartPreviewVisualStore {
    @observable
    packageId: string | undefined;

    @observable
    formPartDetails: FormPartDetailsModel | undefined;

    @observable
    displayBlocks: FormBlockPreviewModel[] = [];

    @observable
    isLoadingAdjustments: boolean = false;

    @observable
    packages: Package[] = [];

    @observable
    isLoadingPackages: boolean = false;

    @observable
    adjustedBlocks: FormBlockPreviewModel[] | undefined = undefined;

    @computed
    get validPackages() {
        return this.packages.filter(p => p.state === 'ready');
    }

    @computed
    get currentProject() {
        return this.projectsRootStore.currentProject;
    }

    constructor(private projectsRootStore: ProjectsRootVisualStore, private service: FormTypesService, private projectsService: ProjectsService) {
    }
    
    @action.bound
    setIsLoadingPackages(isLoading: boolean) {
        this.isLoadingPackages = isLoading;
    }

    @action.bound
    async loadFormPartData(formPartId: string) {
        try {
            const resp = await this.service.getFormPartDetails(formPartId);

            if (resp.isOk()) {
                this.formPartDetails = resp.value;
                this.packageId = resp.value.packageId;
                this.initDisplayBlocks();
            } else {
                console.error(resp.error);
            }
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async loadAllProjectPackages() {
        if (!this.projectsRootStore.currentProject) {
            return;
        }

        try {
            this.setIsLoadingPackages(true);
            let request: SearchPackagesRequest = {
                page: 0,
                pageSize: PAGE_SIZE,
                projectId: this.projectsRootStore.currentProject.id,
                allSources: true,
            };
            const firstResponse = await this.projectsService.searchPackages(this.projectsRootStore.currentProject, request);

            runInAction(() => {
                this.packages = firstResponse.lines;
            });

            if (firstResponse.lines.length < firstResponse.total) {
                const pages = Math.ceil(firstResponse.total / PAGE_SIZE);
                for (let i = 1; i < pages; i++) {
                    request.page = i;
                    const response = await this.projectsService.searchPackages(this.projectsRootStore.currentProject, request);
                    runInAction(() => {
                        this.packages = this.packages.concat(response.lines);
                    });
                }
            }

        } catch(err) {
            console.error(err);
        } finally {
            this.setIsLoadingPackages(false);
        }
    }

    @action.bound
    setIsLoadingAdjustments(isLoading: boolean) {
        this.isLoadingAdjustments = isLoading;
    }

    @action.bound
    async setPackageId(packageId: string | undefined) {
        runInAction(() => {
            this.packageId = packageId;
        });

        if (!this.formPartDetails || !packageId) {
            return 0;
        }

        this.setIsLoadingAdjustments(true);
        runInAction(() => {
            this.displayBlocks = [];
        });
        const resp = await this.service.getFormPartAdjustmentsForPackage(this.formPartDetails?.id ?? '', packageId ?? '');

        if (resp.isOk()) {
            this.calculateDisplayBlocks(resp.value);
            this.setIsLoadingAdjustments(false);

            var blocks = resp.value;

            return blocks && blocks.length > 0 ? blocks[0].page_idx : 0;
        } else {
            console.log(resp.error);
            message.error('Failed to load adjustments for package');
        }

        this.setIsLoadingAdjustments(false);
        return 0;
    }

    @action.bound
    initDisplayBlocks() {
        if (!this.formPartDetails || !this.formPartDetails.regions?.length) {
            return;
        }

        const regions = this.formPartDetails.regions;
        this.displayBlocks = [];
        const pageHeight = this.formPartDetails.coordinatesReference.height;
        const pageWidth = this.formPartDetails.coordinatesReference.width;
        regions.forEach(r => {
            r.regionBlocks.forEach(rb => {
                const blocks: FormBlockPreviewModel[] = rb.bboxes.map(b => {
                    return {
                        regionName: r.name,
                        blockName: rb.blockTitle,
                        regionId: r.regionId,
                        page: rb.blockPage + 1, // Convert to 1-based
                        x1: b.bbox[0] / pageWidth * 100,
                        y1: b.bbox[1] / pageHeight * 100,
                        x2: b.bbox[2] / pageWidth * 100,
                        y2: b.bbox[3] / pageHeight * 100,
                        height: (b.bbox[3] - b.bbox[1]) / pageHeight * 100,
                        width: (b.bbox[2] - b.bbox[0]) / pageWidth * 100,
                        useContours: b.useContours
                    };
                });
                this.displayBlocks = this.displayBlocks.concat(blocks);
            });
        });
    }

    @action.bound
    calculateDisplayBlocks(adjustedBlocks: FormPartAdjustedBlock[]) {
        if (!this.formPartDetails || !this.formPartDetails.regions?.length) {
            return;
        }

        this.displayBlocks = [];
        let previewBlocks: FormBlockPreviewModel[] = adjustedBlocks.map(ab => {
            const pageHeight = ab.page.height;
            const pageWidth = ab.page.width;

            return {
                regionName: ab.region,
                blockName: ab.title,
                x1: ab.x / pageWidth * 100,
                y1: (pageHeight - ab.y) / pageHeight * 100,
                x2: (ab.x + ab.width) / pageWidth * 100,
                y2: (pageHeight - ab.y - ab.height) / pageHeight * 100,
                height: ab.height / pageHeight * 100,
                width: ab.width / pageWidth * 100,
                page: ab.page_idx + 1,
                regionId: ab.region,
                useContours: false
            };
        });
        this.displayBlocks = this.displayBlocks.concat(previewBlocks);
    }
}