import * as React from 'react';
import {
    Input, InputNumber, Form, Row, Col, FormInstance,
} from 'antd';
import { inject } from 'mobx-react';
import { ElasticSearchMatchPhraseRule } from '../models';
import RuleEditVisualStore from '../stores/RuleEditVisualStore';
import { STORE_RULE_EDIT } from '../constants';
import RuleEditGeneralSettings from './RuleEditGeneralSettings';
import RuleType from '../types/RuleType';
import { observer } from 'mobx-react-lite';
import { BlockTypesDropdown } from '../../common/components/BlockTypesDropdown';

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance
};

export const RuleEditElasticSearchMatchPhraseForm: React.FC<Props> =  ({store, selectType, form})=> {
    const rule = store.editableRule as ElasticSearchMatchPhraseRule;
    React.useEffect(() => {
        form.setFieldsValue(getInitialValues());
        if (!rule.id) {
            form.setFieldsValue({excludedBlockTypes: ['WORD_BLOCK']});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, rule]);

    const getInitialValues = () => {
        return {
            name: rule.name,
            tag: rule.tag,
            query: rule.query,
            slope: rule.slope,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            excludedBlockTypes: rule.excludedBlockTypes
        };
    };

    return (
        <>
            {store.error.map((e, i) => <h4 key={e + i} style={{color: 'red'}}>{e}</h4>)}
            <Form 
                form={form}
                className="rule-edit-form"
                layout="vertical"
                data-id="rule-edit-form"
            >
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form}/>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Match query"
                            name="query"
                            rules= {[{
                                required: true, message: 'Please input query!',
                            }]}
                            data-id="rule-match-query-input"
                        >
                            <Input />
                        </Form.Item>  
                    </Col>  
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Slope"
                            name="slope"
                            rules={[{
                                required: true, message: 'Please input slope!',
                            }]}
                            data-id="rule-slope-input"
                        >
                            <InputNumber max={999} min={0} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <BlockTypesDropdown mode='multiple'/>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditElasticSearchMatchPhraseForm));