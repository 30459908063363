import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Form } from 'antd';
import { InputGroupModel } from '../../models';
import { InputGroupActionsRenderer, SectionExtraRenderer, EditSectionDialog } from '..';
import { InputBindingFormField } from '../../types';
import { ApplicationDefinitionConditionalEditStore, ApplicationDefinitionConditionalBindingPreviewStore } from '../../stores';
import { BindingType } from '../../../iota_applications/types';
import { ApplicationInputBindingsRenderer } from '../../../iota_applications/components';
import { ProjectApplicationDefinitionEditVisualStore } from '../../../iota_applications/stores';
import './InputGroupRenderer.less';

const formItemLayout = {
    labelCol: {
        xs: { span: 5 },
        sm: { span: 7 }
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 }
    }
};

type FormValues = Record<string, InputBindingFormField[]>;

interface Props {
    inputGroup: InputGroupModel;
    store: ApplicationDefinitionConditionalEditStore;
    previewStore: ApplicationDefinitionConditionalBindingPreviewStore;
    applicationDefinitionEditStore: ProjectApplicationDefinitionEditVisualStore
}

const InputGroupRenderer: React.FC<Props> = ({ inputGroup, store, previewStore, applicationDefinitionEditStore }) => {
    const [form] = Form.useForm<FormValues>();

    const formId = React.useMemo(() => `input-group-form-${inputGroup.id}`, [inputGroup]);

    const onValuesChange = (formValues: FormValues) => {
        Object.keys(formValues).forEach(inputId => handleInputValuesChange(inputId));
    };

    const handleInputValuesChange = (inputId: string) => {
        const fields: InputBindingFormField[] = form.getFieldValue(inputId);
        const input = inputGroup.inputs.find(i => i.meta.id === inputId);

        if (!input) {
            return;
        }

        const bindings = fields.map(field => ({
            ...field,
            value:
                field.type === BindingType.tag && typeof field.value === 'string'
                    ? applicationDefinitionEditStore.getTagIdByName(field.value)
                    : field.value
        }));

        input.updateBindings(bindings);
        store.setIsDataChanged(true);
    };

    const getInitialValues = () =>
        inputGroup.inputs.reduce<FormValues>(
            (acc, input) => ({
                ...acc,
                [input.meta.id]: input.bindings.map(binding => ({
                    value:
                        binding.type === BindingType.tag && typeof binding.value === 'string'
                            ? applicationDefinitionEditStore.getTagById(binding.value)
                            : binding.value,
                    type: binding.type
                }))
            }),
            {}
        );

    return (
        <div className="input-group-renderer">
            <EditSectionDialog inputGroup={inputGroup} store={store} />

            <InputGroupActionsRenderer inputGroup={inputGroup} store={store} previewStore={previewStore} />

            <Form
                id={formId}
                form={form}
                initialValues={getInitialValues()}
                onValuesChange={onValuesChange}
                preserve={false}
                {...formItemLayout}
            >
                <Collapse activeKey={inputGroup.openSectionIds} onChange={inputGroup.setOpenSectionIds}>
                    {inputGroup.sections.map(section => (
                        <Collapse.Panel
                            className={`input-group-section ${!section.enabled ? 'disabled' : ''}`}
                            key={section.id}
                            header={section.displayName}
                            extra={<SectionExtraRenderer section={section} />}
                        >
                            <Collapse activeKey={section.openInputIds} onChange={section.setOpenInputIds}>
                                {section.inputs.map((input, index) => (
                                    <Collapse.Panel key={input.meta.id} header={input.meta.name}>
                                        <ApplicationInputBindingsRenderer
                                            store={applicationDefinitionEditStore}
                                            form={form}
                                            input={input.meta}
                                            bindings={input.bindings}
                                            index={index}
                                            handleInputValuesChange={handleInputValuesChange}
                                            previewInput={(_: string, bindingIndex: number) =>
                                                previewStore.previewInput(
                                                    inputGroup.id,
                                                    input.bindings[bindingIndex],
                                                    bindingIndex
                                                )
                                            }
                                        />
                                    </Collapse.Panel>
                                ))}
                            </Collapse>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Form>
        </div>
    );
};

export default observer(InputGroupRenderer);
