import * as React from 'react';
import { Button, Layout, message, Popconfirm, Tooltip, Upload } from 'antd';
import  ConnectionEditorStore  from '../stores/ConnectionEditorStore';
import { inject, observer } from 'mobx-react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Connection } from '../models';
import { RulesStores } from '../stores';
import { STORE_RULES_CONNECTIONS } from '../constants';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import LayoutHeader from '../../../components/LayoutHeader';
import ConnectionEditDialog from './ConnectionEditDialog';
import ConnectionAddDialog from './ConnectionAddDialog';
import { Utils } from '../../common/services/Utils';
import { UploadChangeParam } from 'antd/lib/upload';

type Props = RulesStores;

export class ConnectionEditor extends React.Component<Props, object> {

    private store: ConnectionEditorStore;

    constructor(props: Props) {
        super(props);
        this.store = this.props.RuleConnection!;
    }

    render() {
        return(
            <Layout className="screen-size" style={{...{height: '100%', background: 'white'}}}>
                <ConnectionEditDialog  store={this.store!}/>
                <ConnectionAddDialog  store={this.store!}/>
                <LayoutHeader 
                    subtitle={Utils.getSubtitle(this.store!.currentProject)}
                    title="RD Connections"
                    buttons={[
                        <HasPermission entityId={this.store?.currentProject?.id} key="rd-connection-import-wrapper" permissionClaim={AppPermissions.CanEditRefDataConnections}>
                            <Upload 
                                showUploadList={false}
                                className="headerButton"
                                key="file-uploader" 
                                name="file" 
                                onChange={this.onChange.bind(this)} 
                                action={`${process.env.REACT_APP_MANAGE_URL}projects/${this.store!.currentProject?.id}/rules/refData/connections/import`} 
                                headers={this.store.fileImportActionHeaders} 
                                beforeUpload={() => this.store.setHeaders()}
                            >
                                <span data-id="button-Import" key="import-button-container" >
                                    <i className="alpha-icon xs arrow-down" style={{verticalAlign: 'middle'}}/>
                                    <span style={{marginLeft: 11}}>Import</span>
                                </span>
                            </Upload>
                        </HasPermission>,
                        <span key="help-message" className="tooltip-container">
                            <Tooltip title='Reference data connection Management'>
                                <i className="alpha-icon lg question-icon" style={{verticalAlign: 'middle'}} />
                            </Tooltip>
                        </span>,
                        <HasPermission key="rd-connection-add-wrapper" permissionClaim={AppPermissions.CanEditRefDataConnections}>
                            <Button 
                                type="primary" 
                                size="large"
                                onClick={this.store.add}
                            >
                            Add connection
                            </Button>
                        </HasPermission>
                    ]}
                />
                <Layout.Content>
                    <Table 
                        data-id="table-RDConnection-List"
                        rowKey="id"
                        className="alpha-portal-table"
                        columns={this.columns()} 
                        dataSource={this.store.connections} 
                        pagination={false}
                    />
                </Layout.Content>
            </Layout>
        );
    }

    private columns = () => { 
        const columns: ColumnProps<Connection>[] =  [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                render: (id: string) => (
                    <span data-id-cells="Id">{id}</span>)
            }, 
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => {
                    return a.name.localeCompare(b.name); 
                },
                render: (name: string) => (
                    <span data-id-cells="Name">{name}</span>)
            }, 
            {
                title: 'Connection Type',
                dataIndex: 'connectionType',
                key: 'connectionType',
                sorter: (a, b) => {
                    return a.connectionType.localeCompare(b.connectionType); 
                },
                render: (name: string) => (
                    <span data-id-cells="Connection Type">{name}</span>)

            },
            {
                width: 200,
                render: (t: string , record: Connection) => {
                    return (
                        <HasPermission entityId={this.store?.currentProject?.id} permissionClaim={AppPermissions.CanEditRefDataConnections}>
                            <div className="row-actions-wrapper" style={{textAlign: 'right'}}>
                                <Button  
                                    size="small" 
                                    type="link" 
                                    onClick={() => {
                                        this.store.edit(record); 
                                    }}
                                    data-id-cells='Edit connection'
                                >
                                    <Tooltip title="Edit connection" placement="bottom">
                                        <i className="alpha-icon md edit-icon" />
                                    </Tooltip>
                                </Button>
                                <Button  
                                    size="small" 
                                    type="link" 
                                    onClick={() => {
                                        this.store.exportConnection(record.id); 
                                    }}
                                    data-id-cells='Export'
                                >
                                    <Tooltip title="Export" placement="bottom">
                                        <i className="alpha-icon md arrow-up" />
                                    </Tooltip>
                                </Button>
                                <Popconfirm 
                                    title="Are you sure delete this task?" 
                                    onConfirm={() => this.store.delete(record.id)} 
                                    okText="Yes" 
                                    cancelText="No"
                                    id="data-id-popconfirm-box-DeleteConfirm"
                                >
                                    <Button
                                        size="small" 
                                        type="link" 
                                        data-id-cells='Delete connection'
                                    >
                                        <Tooltip title="Delete connection" placement="bottom">
                                            <i className="alpha-icon md delete-icon" />
                                        </Tooltip>
                                    </Button>
                                </Popconfirm>
                            </div> 
                        </HasPermission>
                    );
                }
            }
        ]; 

        return columns;
    };

    private onChange (info: UploadChangeParam) {
        const status = info.file.status;
        if (status === 'done') {        
            message.success(`${info.file.name} file uploaded successfully.`);
            this.store.loadConnections();
        } else if (status === 'error') {
            if (info.file?.response?.status === 409) {
                message.warning(info.file.response.title);
                return;
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    }
}

export default inject(STORE_RULES_CONNECTIONS)(observer(ConnectionEditor));