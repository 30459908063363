import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Input, Modal, Button, Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { IotaConnStores } from '../stores';

type Props = IotaConnStores;

interface Hash {
    [details: string]: string
}

export const ConnectionsDefinitionEdit: React.FC<Props> = observer(({ projectConnectionsDefinitionsUI: store }) => {
    const connDefStore = store!;
    const [form] = Form.useForm();

    React.useEffect(() => {
        store!.loadIotaInputsMetadata();
    },              [store]);

    React.useEffect(() => {
        if (connDefStore.current && connDefStore.current.name) {
            form.setFieldsValue({ name: connDefStore.current.name });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },              [connDefStore.current, connDefStore, form]);

    const handleSubmit = (e: React.FormEvent<object>) => {
        e.preventDefault();
        form.validateFields().then(async (values: Hash) => {
            await store!.edit(store!.current!.id, values.name);
        }).catch((err) => {
            console.log(err);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const confirmNameIsUnique = (rule: any, value: any, callback: any) => {
        if (value && !store!.checkIfNameIsUnique(value)) {
            callback('Name is not unique!');
        }
        callback();
    };

    const handleCancel = () => {
        store!.setEditDefinitionDialogVisible(false);
        store!.setCurrent(undefined);
    };

    return (
        <Modal
            title="Edit application name"
            className="alpha-portal-dialog"
            visible={store!.editDefinitionDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose
            closable={false}
            centered
            footer={[
                <Button data-id="iota-edit-app-name-cancel" key="back" onClick={handleCancel} className="light" size="large">
                    Cancel
                </Button>,
                <Button data-id="iota-edit-app-name-submit" key="submit" type="primary" onClick={handleSubmit} size="large">
                  Submit
                </Button>,
            ]}
        >
            <Form layout="vertical" form={form} data-id="iota-edit-app-name-form">
                <FormItem
                    label={(
                        <span>
                            Application name&nbsp;
                            <Tooltip title="Application name for alpha">
                                <i className="alpha-icon md question-icon" style={{verticalAlign: 'middle'}}/>
                            </Tooltip>
                        </span>
                    )}
                    name="name"
                    rules={[
                        { required: true, message: 'Please input application name!', whitespace: true },
                        { validator: confirmNameIsUnique }
                    ]}
                >
                    <Input />
                </FormItem>
            </Form>
        </Modal>
    );
});

export default ConnectionsDefinitionEdit;