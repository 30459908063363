/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, runInAction, computed, reaction } from 'mobx';
import ApplicationDefinitionsGroupModel from '../types/ApplicationDefinitionsGroupModel';
import { ProjectsRootVisualStore } from '../../../modules/common/stores';
import { ApplicationDefinitionsService } from '../services';
import { OnHitEventArgs } from 'react-drag-drop-container';
import { ApplicationDefinitionSaveSuccessModel } from '../types';
import { ApplicationDefinitionBase } from '../types/ApplicationDefinition';

import { RcFile } from 'antd/lib/upload/interface';

export default class ProjectApplicationDefinitionsTreeVisualStore {

    @observable
    folders: ApplicationDefinitionsGroupModel [] = [];

    @observable
    appDefinitions: ApplicationDefinitionBase[] = [];

    folderToInsertId: string;

    currentHighlightedNodeId: string = '';

    @observable
    addEditGroupDialogVisible: boolean;

    @observable
    expandedKeys: string[] = [];

    @observable
    filteredApplicationDefinitions: ApplicationDefinitionBase[] = [];

    @observable
    currentAppGroup: ApplicationDefinitionsGroupModel | undefined;

    selectedKey: string | null;

    constructor(private rootStore: ProjectsRootVisualStore, private appDefService: ApplicationDefinitionsService) {
        reaction(() => this.currentProject, () => {            
            this.setFilteredApplicationDefinitions([]);
            this.selectedKey = null;
        });             
    }

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    @action
    async loadApplicationDefinitionGroups() {
        if (this.currentProject) {
            const groups = await this.appDefService.getApplicationDefinitionGroupsForProject(this.currentProject!.id);

            const setRootGroupId = (rootGroupId: string, subGroups: ApplicationDefinitionsGroupModel[]) => {
                subGroups.forEach(g => {
                    g.rootGroupId = rootGroupId;
                    setRootGroupId(rootGroupId, g.subGroups);
                });
            };

            runInAction(() => {
                groups.forEach(g => setRootGroupId(g.id, g.subGroups));
                this.folders = groups;
            });
        }        
    }

    @action.bound
    setAddEditGroupDialogVisible(visible: boolean) {
        this.addEditGroupDialogVisible = visible;
    }

    @action.bound
    handleExpandedNode(model: ApplicationDefinitionsGroupModel) {
        this.folders.push(model);
    }

    @action.bound
    setFolders(folders: ApplicationDefinitionsGroupModel[]) {
        this.folders = folders;
    }

    @action
    expand(keys: string[]) {
        this.expandedKeys = keys;
    }

    @action.bound
    setAppDefinitions(appDefs: ApplicationDefinitionBase[]) {
        this.appDefinitions = appDefs;
    }

    @action.bound
    setFilteredApplicationDefinitions(appDefs: ApplicationDefinitionBase[]) {
        this.filteredApplicationDefinitions = appDefs;
    }
    
    @action.bound
    async addFolder(name: string) {
        const isSubGroupCreated = !!this.folderToInsertId;
        const id = Math.random().toString(36);
        let arrayIndex = 0;
        const findNodeToInsert  = (folders: ApplicationDefinitionsGroupModel[], groupId: string, index: number) => {
            if (!folders ) {
                return;
            }
            const folder = folders.find(subGroup => subGroup.id === groupId);
            if (folder) {
                runInAction( () => {
                    folder!.subGroups.push(new ApplicationDefinitionsGroupModel(id, name, this.currentProject!.id, [], []));
                });
                arrayIndex = index;
            } else {
                for ( let f of folders) {
                    findNodeToInsert(f.subGroups, groupId, index);
                }
            }
        };

        if (this.folderToInsertId) {
            for (let [index, f] of this.folders.entries()) {
                if (f.id === this.folderToInsertId) {
                    runInAction(() => {
                        f.subGroups.push(new ApplicationDefinitionsGroupModel(id, name, this.currentProject!.id, [], []));
                    });
                    arrayIndex = index;
                    break;
                } else {
                    findNodeToInsert([f], this.folderToInsertId, index);
                }
            }
            this.folderToInsertId = '';
        } else {
            const model = new ApplicationDefinitionsGroupModel(id, name, this.currentProject!.id, [], []);
            runInAction(() => {                 
                this.folders.push(model); 
            });
            arrayIndex = this.folders.length - 1;
        }

        await this.appDefService.createApplicationDefinitionGroup(this.folders[arrayIndex], this.currentProject!.id, isSubGroupCreated);  
    }

    @action.bound
    setCurrentApplicationDefinitionGroup(group: ApplicationDefinitionsGroupModel | undefined) {
        this.currentAppGroup = group;
    }

    @action.bound
    editApplicationDefinitionGroup(appDefGr: ApplicationDefinitionsGroupModel) {
        this.setCurrentApplicationDefinitionGroup(appDefGr);
        this.setAddEditGroupDialogVisible(true);
    }

    @action.bound
    async updateApplicationDefinitionGroup(id: string, name: string) {
        var resp = await this.appDefService.updateApplicationDefinitionGroup(this.currentProject!.id, id, name);

        resp.map(() => {
            let foundFolder = this.findFolderById(id);
            runInAction(() => {
                foundFolder!.name = name; 
            });
            const clone = this.expandedKeys.slice();
            this.expand([]);
            this.expand(clone);
        });
    }

    @action
    openGroupFormDialog(id?: string, callback?: () => void) {
        if (id) {
            const folder = this.findFolderById(id);    
            this.setCurrentApplicationDefinitionGroup(folder!);        
            this.setAddEditGroupDialogVisible(true);
        } else {
            this.setCurrentApplicationDefinitionGroup(undefined);        
            this.setAddEditGroupDialogVisible(true);
        }

        if (callback) {
            callback();
        }
    }

    async saveApplicationDefinitionGroup(name: string) {
        if (this.currentAppGroup) {
            await this.updateApplicationDefinitionGroup(this.currentAppGroup.id, name);
        } else {
            await this.addFolder(name);
        }
        this.setAddEditGroupDialogVisible(false);
    }

    async addAplicationDefinition(id: string) {
        let arrayIndex = 0;
        const findNodeToInsert  = (folders: ApplicationDefinitionsGroupModel[], groupId: string, index: number) => {
            if (!folders ) {
                return;
            }
            const folder = folders.find(subGroup => subGroup.id === groupId);
            if (folder) {
                runInAction( () => folder!.applicationDefinitionIds.push(id));
                arrayIndex = index;
            } else {
                for ( let f of folders) {
                    findNodeToInsert(f.subGroups, groupId, index);
                }
            }
        };

        for (let [index, f] of this.folders.entries()) {
            if (f.id === this.folderToInsertId) {
                runInAction(() => f.applicationDefinitionIds.push(id));
                arrayIndex = index;
                break;
            } else {
                findNodeToInsert([f], this.folderToInsertId, index);
            }
        }
        
        await this.appDefService.createApplicationDefinitionGroup(this.folders[arrayIndex], this.currentProject!.id, true);
        this.folderToInsertId = '';
    }

    async deleteApplicationDefinition(appDefId: string) {

        let arrayIndex = 0;
        const findToDelete  = (folders: ApplicationDefinitionsGroupModel[], index: number) => {
            if (!folders ) {
                return;
            }
            for ( let f of folders) {
                let idIndex = f.applicationDefinitionIds.indexOf(appDefId);
                if (idIndex !== -1) {
                    runInAction(() => f.applicationDefinitionIds.splice(idIndex, 1));
                    arrayIndex = index;
                    break;
                } else {
                    findToDelete(f.subGroups, index);
                }
            }
        };

        for (let [index, f] of this.folders.entries()) {
            let idIndex = f.applicationDefinitionIds.indexOf(appDefId);
            if (idIndex !== -1) {
                runInAction(() => f.applicationDefinitionIds.splice(idIndex, 1));
                arrayIndex = index;
                break;
            } else {
                findToDelete(f.subGroups, index);
            }
        }
        await this.appDefService.createApplicationDefinitionGroup(this.folders[arrayIndex], this.currentProject!.id, true);

        let appDefinitionToDelete = this.appDefinitions.filter(a => a.id === appDefId);
        if (appDefinitionToDelete.length > 0) {
            let newList = this.appDefinitions.slice();
            const index = newList.indexOf(appDefinitionToDelete[0]);
            newList.splice(index, 1);
            this.setAppDefinitions(newList);   
        }

        appDefinitionToDelete = this.filteredApplicationDefinitions.filter(a => a.id === appDefId);
        if (appDefinitionToDelete.length > 0) {
            let newList = this.filteredApplicationDefinitions.slice();
            const index = newList.indexOf(appDefinitionToDelete[0]);
            newList.splice(index, 1);
            this.setFilteredApplicationDefinitions(newList);   
        }   
    }

    async moveApplicationDefinition(e: OnHitEventArgs , groupId: string) {
        const appDefIds = this.getAppDefIdsForGroup(groupId);
        const isSameFolder = !!appDefIds.find(a => a === e.dragData.appDefId);
        if (isSameFolder) {
            return;
        }
        
        await this.appDefService.moveApplicationDefinition(this.currentProject!.id, e.dragData.appDefId, groupId);
        this.loadApplicationDefinitionGroups();
    }

    @action
    editAppDefinition(model: ApplicationDefinitionSaveSuccessModel, newIcon: RcFile | undefined, newName: string) {
        const appDefinitionToRename = this.filteredApplicationDefinitions.filter(a => a.id === model.id);
        let newFilteredList = this.filteredApplicationDefinitions.slice();
        const listIndex = newFilteredList.indexOf(appDefinitionToRename[0]);
        newFilteredList[listIndex].name = newName;

        if (model.iconFileId && newIcon) {
            newFilteredList[listIndex].iconFileId = model.iconFileId;
            newFilteredList[listIndex].iconUrl = URL.createObjectURL(newIcon);
        }

        this.setFilteredApplicationDefinitions(newFilteredList);
    }

    @action.bound
    async handleNodeSelection(selectedKey: string | null) {
        if (!selectedKey) {
            return;
        }
        this.selectedKey = selectedKey;
        const appDefIds = this.getAppDefIdsForGroup(selectedKey);
        
        let appDefs = [] as ApplicationDefinitionBase[];
        if (!appDefIds[0]) {
            const selectedAppDef = this.appDefinitions.find(appDef => appDef.id === selectedKey);
            appDefs = [];
            if (selectedAppDef) {
                appDefs.push(selectedAppDef);
            }
        } else {      
            appDefs = appDefIds.map(id => {
                return this.appDefinitions.find( appDef => appDef.id === id)!;
            });
        }
        runInAction(() => this.setFilteredApplicationDefinitions(appDefs!));
        
    }

    findFolderById = (id: string) => {               
        const getFolders = (folders: ApplicationDefinitionsGroupModel[]) => {
            let allFolders = folders.slice();
            for (var f of folders) {
                allFolders = allFolders.concat(getFolders(f.subGroups));
            }
    
            return allFolders;
        };
        const flatFoldersArray = getFolders(this.folders);
        const foundFolder = flatFoldersArray.find(f => f.id === id);
        return foundFolder;
    };    

    private getAppDefIdsForGroup(groupId: string) {
        let appDefIds = [''];
        const findId = (subFolders: ApplicationDefinitionsGroupModel[]) => {
            const folder = subFolders.find(subGroup => subGroup.id === groupId);
            if (folder) {
                appDefIds = folder.applicationDefinitionIds;
            } else {
                for ( let f of subFolders) {
                    findId(f.subGroups);
                }
            }
        };

        for (let f of this.folders) {
            if (f.id === groupId) {
                appDefIds = f.applicationDefinitionIds;
                break;
            } else {
                findId([f]);
            }
        }

        return appDefIds;
    }

}