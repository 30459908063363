/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, computed, runInAction, reaction, IReactionDisposer } from 'mobx';
import { Dictionary } from 'lodash';
import RulesStore from './RulesStore';
import { RouterStore } from '../../common/stores';
import type { RuleTypes } from '../models';
import { RulesPagesNavigation } from '../routes';
import _ from 'lodash';
import { SearchRuleAutoCompleteSourceItem } from '../types/SearchRuleAutoCompleteSourceItem';
import { RulesImportVisualStore } from '.';
import { message } from 'antd';
import { TagModel } from '../models/TagModel';
import { ProjectTagsVersionInfo } from '../../common/types/ProjectTagsVersionInfo';
import { PinnedRules } from '../../common/types/PinnedRules';

type SelectedRule = {
    [key: string]: string
};

type DisabledRule = {
    [key: string]: string
};

type DeleteConfirmationText = {
    [key: string]: string
};

export default class RulesListVisualStore {
    @observable
    isLoading: boolean = true;

    @observable
    isTableLoding: boolean;

    @observable
    isModalVisible: boolean = false;

    @observable
    curentRuleIdForCopy: string | null;

    @observable
    tagAutocompleteSource: string[];

    @observable
    tagsFilteredValue: string = '';

    @observable
    rulesFilteredValue: string = '';

    @observable
    currentRulesInputValue: string = '';

    @observable
    currentTagsInputValue: string = '';

    @observable
    selectedRulesKeys: string[] = [];

    @observable
    selectedRulesKeysByTag: string[] = [];

    @observable
    deleteConfirmationText: DeleteConfirmationText = {};

    @observable
    isNewTagFormVisible: boolean = false;

    @observable
    tagRenameDialog: boolean = false;

    @observable
    tagsVersionDialogVisible = false;

    @observable
    currentTagGroupId: string | null = null;

    @observable
    pinnedRules: PinnedRules | undefined = undefined;

    selectedRule: SelectedRule = {};

    disabledRule: DisabledRule = {};

    expandedRows: string[] = [];
    
    tagToRename: string;

    disposer: IReactionDisposer;

    @observable
    fileImportActionHeaders: {};

    @computed
    get pinnedRulesInProject() {
        if (!this.currentProject || !this.pinnedRules?.ruleIds) {
            return [];
        }

        return this.store.rules.filter(r => r.id && this.pinnedRules!.ruleIds.includes(r.id) && r.projectId === this.currentProject!.id).map(r => r.id);
    }
    
    @computed
    get currentProject() {
        return this.store.currentProject;
    }

    @computed
    get tags(): TagModel[] {
        return this.store.ruleTags;
    }

    @computed
    get ruleNames(): SearchRuleAutoCompleteSourceItem[] {
        return this.store.rules.map(r => ({name: r.name}
        ));
    }

    @computed
    get rules() {
        return this.store.rules;
    }

    @computed
    get usedTags() {
        return this.store.usedTags;
    }

    @computed
    get rulesAutocompletSource(): SearchRuleAutoCompleteSourceItem[] {
        return this.store.rules.map((r) => ({ name: r.name }));
    }
    
    @computed 
    get filteredRulesAutocompletSource(): SearchRuleAutoCompleteSourceItem[] {
        return this.tagsFilteredValue && this.data[this.tagsFilteredValue] 
            ? this.data[this.tagsFilteredValue].map((t) => {
                return ({name: t.name}); 
            }) 
            : this.rulesAutocompletSource;
    }

    @computed
    get filteredTags(): TagModel[] {
        if (this.tagsFilteredValue) {
            return this.tags.filter(t => t.name.toLowerCase().trim().includes(this.tagsFilteredValue.toLowerCase().trim()));
        }

        if (this.rulesFilteredValue) {
            const filteredTags = this.getTagNameForFilteredRule().map(x => x.name);
            return this.store.ruleTags.filter(x => filteredTags.includes(x.name));
        }
        return this.tags;
        
    }

    @computed
    get filteredRules():  Dictionary<RuleTypes[]> {
        const filteredData = () => {
            return Object.entries(this.data).map((value)  => { 
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let arr = [] as any;
                arr[0] = value[0];
                arr[1] = value[1].filter(v => v.name.toLowerCase().trim().includes(this.rulesFilteredValue.toLowerCase().trim()));
                return arr;
            }).reduce((accum, [k, v]) => {
                accum[k as string] = v; return accum; 
            }, {});
        };
        return this.rulesFilteredValue ? filteredData() : this.data;
    }
    
    @computed
    get data(): Dictionary<RuleTypes[]> {
        return this.store.groupedByTag;
    }

    @computed
    get previewedTag() {
        return this.store.previewedTag;
    }

    @computed
    get isRuleSelected() {
        return !!(_.union(this.selectedRulesKeys, this.selectedRulesKeysByTag)).length;
    }

    get disabledRules() {
        return this.store.rules.filter(r => r.state === 'Disabled').map(r => r.id);
    }


    constructor(private store: RulesStore, private routerStore: RouterStore, private rulesImportStore: RulesImportVisualStore) {
        reaction(() => this.store.currentProject, async (c) => {
            if (c) {
                await this.loadData();
                this.getAppDefinitions();
            }
        });

        this.disposer = reaction(() => this.store.usedTags, () => {
            this.setDeleteConfirmationText();
        });

        this.store.newRulesSubject.subscribe((rule) => {            
            if (rule.id) {
                this.handleRowOnExpand(true, rule.tagId!);
                this.setSelectedRuleHighlight(rule.id);
            }
        });

        this.store.rulesEditSubject.subscribe((ruleId) => {
            const ruleToEdit = this.store.rules.find(r => r.id === ruleId);
            if (ruleToEdit) {
                this.edit(ruleId);
            }
        });

        this.store.groupDeletionSubject.subscribe(() => this.loadData());
    }

    @action.bound
    setPreviewedTag(tagId: string | undefined) {
        this.store.setPreviewedTagId(tagId);
    }

    @action.bound
    setCurrentTagGroupId(tagGroupId: string | null) {
        this.currentTagGroupId = tagGroupId;
    }
    
    @action
    updateRulePriority(ruleId: string, priority: number) {        
        let ruleToUpdate = this.store.rules.find(r => r.id === ruleId);
        if (ruleToUpdate) {
            const index = this.store.rules.indexOf(ruleToUpdate);
            runInAction(() => {
                this.store.rules[index].priority = priority;
            });            
        }
    }
    
    @action
    previewTag(tagName: string) {
        // this.setSelectedTag(tagName);
        if (this.currentProject) {
            const encodedName = encodeURIComponent(tagName);
            this.routerStore.pushToHistory(RulesPagesNavigation.TagPreviewPage.replace(':id', this.currentProject.id).replace(':tag', encodedName));
        }        
    }

    @action.bound
    setTagsVersionDialogVisible(visible: boolean) {
        this.tagsVersionDialogVisible = visible;
    }

    @action
    handleTagEditClick(tagName: string) {
        this.setTagRenameDialog(true);
        this.tagToRename = tagName;
    }

    @action
    async renameTag(values: {tagRename: string}) {
        this.isLoading = true;
        this.setTagRenameDialog(false);
        const tagId = this.store.ruleTags.find(r => r.name === this.tagToRename)!.id!;
        await this.updateTag(tagId, values.tagRename);
        this.isLoading = false;
    }

    @action
    setTagRenameDialog(val: boolean) {
        this.tagRenameDialog = val;
    }

    getDeleteTagConfirmationText(tagId: string) {
        if (this.store!.usedTags?.find(tag => tag === tagId)) {
            return 'You are trying to delete tag assigned to Active Application. It will be deactivated...';
        } else {
            return 'Are you sure you want to delete';
        }
    }

    @action
    async saveRulePriority(ruleId: string, priority: number) {
        const resp = await this.store.updateRulePriority(ruleId, priority);
        resp.map(() => {
            message.success('Rule priority updated successfully');
            this.updateRulePriority(ruleId, priority);
        }).mapErr(() => message.error('Error updating rule priority'));
    }

    @action
    async loadData() {
        this.isLoading = true;
        await this.store.getRules();
        this.setDeleteConfirmationText();
        runInAction(() => this.isLoading = false);
    }

    getAppDefinitions() {
        this.store.getAppDefinitions();
    }

    setDeleteConfirmationText() {
        if (this.store!.usedTags) {
            const tags = Object.keys(this.store!.groupedByTag);
            tags.forEach(t => {
                if (this.store!.groupedByTag[t].length === 1 && this.store!.usedTags.find(tag => tag === t)) {
                    this.deleteConfirmationText[t] = 'You are trying to delete the Rule assigned to Active Application. It will be deactivated...';
                } else {
                    this.deleteConfirmationText[t] = 'Are you sure you want to delete';
                }
            });
        }
    }

    @action
    edit(ruleId: string, isCopy: boolean = false) {
        this.setSelectedRuleHighlight(ruleId);
        this.routerStore.pushToHistory(
            RulesPagesNavigation.RulesEditPage.replace(':id', ruleId).replace(':projectId', this.store!.currentProject!.id).replace(':isCopy', isCopy.toString())
        );
    }

    @action
    setSelectedRuleHighlight(id: string) {
        this.selectedRule = {};
        this.selectedRule[id] = ' highlight-selected';
    }

    @action.bound
    clearRuleHighlight() {
        this.selectedRule = {};
    }

    @action
    async delete(record: RuleTypes) {
        await this.store.delete(record.id!);
        await this.loadData();
    }

    @action
    async deleteTag(id: string) {
        await this.store.deleteTag(id);
        await this.loadData();
    }

    @action
    handleCopyRuleClick(record: RuleTypes) {
        this.setSelectedRuleHighlight(record.id!);
        this.curentRuleIdForCopy = record.id!;
        this.setIsModalVisible(true);
    }

    @action
    handleCopyPipelineClick(record: RuleTypes) {
        this.setSelectedRuleHighlight(record.id!);
        this.curentRuleIdForCopy = record.id!;
        this.copyPipeline();
    }
    
    @action.bound
    handleRulesSelection(isChecked: boolean, recordId: string) {
        if (isChecked) {
            this.selectedRulesKeys.push(recordId);
        } else {
            this.selectedRulesKeys = this.selectedRulesKeys.filter(s => s !== recordId);
        }
    }

    @action
    handleTagsSelection(selectedKeys: string[] | number[] ) {
        runInAction(() => this.selectedRulesKeysByTag = selectedKeys.length ? _.difference(this.selectedRulesKeysByTag, selectedKeys as string[]) : []);
        selectedKeys.forEach( (s: string | number) => { 
            const tagName = this.store.ruleTags.find(t => t.id === s)!.name;
            if (!this.data[tagName]) {
                return;
            }
            this.data[tagName].forEach(d => this.selectedRulesKeysByTag.push(d.id!)); 
        });
        this.selectedRulesKeys = this.selectedRulesKeys.filter(s => this.selectedRulesKeysByTag.indexOf(s) < 0);
    }

    @action
    handleDisabedRuleClick(record: RuleTypes) {
        let state = 'Disabled';
        if (record.state === 'Disabled') {
            state = 'Enabled';
        } 
        this.store.updateRuleState(record.id!, state);
    }

    @action
    handleTagsDropdownSelect(key: string) {
        if (key === 'CREATE NEW TAG') {
            this.setNewTagFormVisible(true);
        }
    }

    @action
    setIsModalVisible(isModalVisible: boolean) {
        this.isModalVisible = isModalVisible;
        if (!isModalVisible) {
            this.setNewTagFormVisible(false);
        }
    }

    @action
    setNewTagFormVisible(isModalVisible: boolean) {
        this.isNewTagFormVisible = isModalVisible;
    }

    @action
    async save(edit: RuleTypes) {
        const res = await this.store.save(edit);
        await this.loadData();  
        return res;
    }

    @action
    async updateTag(tagId: string, tagName: string) {
        await this.store.updateTag(tagId, tagName);
        await this.loadData();  
    }

    @action
    async copyPipeline() {
        const rule = this.store.rules.find(r => r.id === this.curentRuleIdForCopy)!;
        runInAction(() => this.setIsModalVisible(false));
        this.edit(rule.id!, true);
        this.curentRuleIdForCopy = null;
    }

    @action
    async copyRule(tagName: {tag: string; newTag: string}) {
        const rule = this.store.rules.find(r => r.id === this.curentRuleIdForCopy)!;
        if (this.curentRuleIdForCopy) {       
            const newRule = rule.clone();
            const copiedRules =  this.ruleNames.filter(r => r.name.startsWith('Copy_') && r.name.endsWith(`_${rule.name}`)).map(r => r.name);
            const maxIndex = copiedRules.length ? Math.max(...copiedRules.map(c => parseInt(c.match(/\d+/)![0], 10))) : 0;
            newRule.name = `Copy_${maxIndex + 1}_of_${rule.name}`;
            newRule.tag = tagName.newTag || tagName.tag;
            newRule.id = null;
            newRule.tagId =  tagName.newTag ? null : this.store.ruleTags.find(x => x.name ===  newRule.tag)!.id;
            if (!tagName.newTag) {
                newRule.tagId = this.store.ruleTags.find(x => x.name ===  newRule.tag)!.id;
            } else {
                const tagId = this.store.rules.find(r => r.id === this.curentRuleIdForCopy)!.tagId;
                newRule.groupId = this.tags.find(t => t.id === tagId)!.groupId;
            }
            await this.store.save(newRule);
            this.curentRuleIdForCopy = null;
            runInAction(() => this.setIsModalVisible(false));
            await this.loadData();  
        }
    }

    @action
    newRule(groupId: string, tagId?: string) {
        if (tagId) {
            this.routerStore.pushToHistory(RulesPagesNavigation.RulesNewPageFromTag
                .replace(':projectId', this.store!.currentProject!.id)
                .replace(':groupId', groupId)
                .replace(':tagId', tagId));

        } else {
            this.routerStore.pushToHistory(RulesPagesNavigation.RulesNewPage.replace(':projectId', this.store!.currentProject!.id).replace(':groupId', groupId));
        }
    }
    
    @action
    newRuleWithCopiedPipeline(groupId: string, ruleId: string) {
        // eslint-disable-next-line max-len
        this.routerStore.pushToHistory(RulesPagesNavigation.RulesNewPageWithCopiedPipeline.replace(':projectId', this.store!.currentProject!.id).replace(':ruleId', ruleId).replace(':groupId', groupId));
    }
    
    @action
    setTagsFilteredValue(filteredValue: string) {
        this.tagsFilteredValue = filteredValue;
        this.store.setHiddenTagGroupIds(this.getEmptyGroups() || []);
        runInAction(() => this.currentTagsInputValue = filteredValue);
    }

    @action
    setRulesFilteredValue(filteredValue: string) {
        this.rulesFilteredValue = filteredValue;
        this.store.setHiddenTagGroupIds(this.getEmptyGroups() || []);
        runInAction(() => this.currentRulesInputValue = filteredValue);
    }

    @action
    handleTagsOnSearch(value: string) {
        this.currentTagsInputValue = value;
        if (!value) {
            this.setTagsFilteredValue(value);
        }
    }

    @action
    handleRulesOnSearch(value: string) {
        this.currentRulesInputValue = value;
        if (!value) {
            this.setRulesFilteredValue(value);
        }
    }

    @action
    async handleExportClick() {
        this.isLoading = true;
        if (this.isRuleSelected) {
            const filteredRulesIds = _.flatten(Object.values(this.filteredRules)).map(s => s.id);
            const filtererSelectedRulesKeysByTag = _.intersection(this.selectedRulesKeysByTag, filteredRulesIds);
            const idsArray = _.union(this.selectedRulesKeys, filtererSelectedRulesKeysByTag) as string[];
        
            await this.store.exportRules(idsArray);
            runInAction(() => this.selectedRulesKeys = []);
            runInAction(() => this.selectedRulesKeysByTag = []);
        } else {
            await this.store.exportAllProjectRules();
        }
       
        runInAction(() => this.isLoading = false);
    }

    @action.bound
    async handleDropedFile(acceptedFiles: File[], dropIndex?: number) {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        this.isLoading = true;
        const resp = await this.store.importRules(formData);
        runInAction(() => this.isLoading = false);
        this.rulesImportStore.setTableData(resp!, dropIndex);

    }    

    @action
    async updateTagRowPosition(destGroupId: string, dragIndex: number, dropIndex: number) {
        this.isTableLoding = true;
        await this.store.updateTagRowPosition(destGroupId, dragIndex, dropIndex);
        runInAction(() => this.isTableLoding = false);
    }

    @action.bound
    async updateTagsVersion(version: string) {
        if (!this.currentProject) {
            return;
        }

        var resp = await this.store.updateTagsVersion(this.currentProject.id, version);
        if (resp.isOk()) {
            this.setTagsVersionDialogVisible(false);
            message.success('Updated project tags version successfully.');
            const tagsVersionInfo = resp.unwrapOr(undefined);
            this.currentProject.setTagsVersion(tagsVersionInfo as ProjectTagsVersionInfo);
        } else {
            message.error('Error occured during the project tags version update.');
        }
    }

    @action.bound
    async getPinnedRules() {
        const resp = await this.store.getPinnedRules();

        runInAction(() => {
            this.pinnedRules = resp;
        });
    }

    @action.bound
    async pinRule(ruleId: string | null) {
        if (!ruleId) {
            return;
        }

        try {
            const resp = await this.store.pinRule(ruleId);

            if (resp?.isOk()) {
                this.getPinnedRules();
            } else {
                message.error('Error occured during the rule pinning.');
            }
        } catch (err) {
            console.error(err);
            message.error('Error occured during the rule pinning.');
        }
    }

    @action.bound
    async unpinRule(ruleId: string) {
        try {
            const resp = await this.store.unpinRule(ruleId);

            if (resp?.isOk()) {
                this.getPinnedRules();
            } else {
                message.error('Error occured during the rule pinning.');
            }
        } catch (err) {
            console.error(err);
            message.error('Error occured during the rule pinning.');
        }
    }

    async moveTagToAnotherGroup(draggedRecord: TagModel, destGroupId: string) {
        this.isTableLoding = true;
        await this.store.moveTagToAnotherGroup(draggedRecord, destGroupId);
        runInAction(() => this.isTableLoding = false);
    }

    handleRowOnExpand(expanded: boolean, name: string) {
        if (expanded) {
            this.expandedRows.push(name);
        } else {
            this.expandedRows = this.expandedRows.filter(s => s !== name);
        }
    }

    async setHeaders() {
        const token = await this.store.getAuthToken();
        this.fileImportActionHeaders = {
            'Authorization': 'Bearer ' + token
        };
        return Promise.resolve();
    }
    
    tagHasBrokenRules (tag: string) {
        return this.store.tagHasBrokenRules(tag);
    }

    private getEmptyGroups() {
        let groupsWithElements: string[] = [];
        if (this.tagsFilteredValue) {
            groupsWithElements = this.filteredTags.filter(t => !!t.groupId).map(t => t.groupId!);       
            return this.store.tagsGroups.filter(g => g.id && !groupsWithElements.find(gId => gId === g.id)).map(g => g.id!);
        }

        if (this.rulesFilteredValue) {
            const tagsGroups = this.filteredTags.map(t => t.groupId!);
            return this.store.tagsGroups.filter(g => g.id && !tagsGroups.includes(g.id)).map(g => g.id!);
        }
        return [];
    }

    private getTagNameForFilteredRule() {
        return Object.entries(this.data).filter((value) => value[1].filter(
            v =>  v.name.toLowerCase().trim().includes(this.rulesFilteredValue.toLowerCase().trim())).length !== 0).map( c => {
            return({name: c[0], rule: c[1]}); 
        });
    }

}