import { Col, Input, Row, Select } from 'antd';
import { runInAction } from 'mobx';
import Form, { FormInstance } from 'antd/lib/form';
import { inject } from 'mobx-react';
import { observer, useLocalStore } from 'mobx-react-lite';
import * as React from 'react';
import { RuleEditGeneralSettings } from '.';
import { STORE_RULE_EDIT } from '../constants';
import SmartIndexRule from '../models/SmartIndexRule';
import { RuleEditVisualStore } from '../stores';
import RuleType from '../types/RuleType';
import InstructWorkflowService from '../../instruct_workflows/services/InstructWorkflowService';
import { InstructWorkflowDto } from '../../instruct_workflows/types';

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance
};

const RuleEditSmartIndexForm: React.FC<Props> = ({ store, selectType, form }) => {
    const rule = store.editableRule as SmartIndexRule;

    const localState = useLocalStore(() => ({
        loading: false,
        service: new InstructWorkflowService(),
        workflows: new Array<InstructWorkflowDto>(),
        async load() {
            if (!store.currentProject) {
                return;
            }

            runInAction(() => {
                localState.loading = true;
            });

            const workflows = await this.service.getWorkflows(store.currentProject.id);

            runInAction(() => {
                localState.workflows = workflows;
                localState.loading = false;
            });
        }
    }));

    const [selectedWorkflowId, setSelectedWorkflowId] = React.useState<string>();

    const workflow = React.useMemo(
        () => localState.workflows.find(f => f.id === selectedWorkflowId),
        [localState.workflows, selectedWorkflowId]
    );

    const onValuesChange = (values: Record<string, unknown>) => {
        if ('instructWorkflowId' in values) {
            form.setFieldsValue({ outputSchemeName: undefined });
            setSelectedWorkflowId(values.instructWorkflowId as string | undefined);
        }
    };

    const getInitialValues = React.useCallback(() => {
        return {
            name: rule.name,
            tag: rule.tag,
            instructWorkflowId: rule.instructWorkflowId,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            prompt: rule.prompt,
            outputSchemeName: rule.outputSchemeName
        };
    }, [rule]);

    React.useEffect(() => {
        const initialValues = getInitialValues();
        form.setFieldsValue(initialValues);
        setSelectedWorkflowId(initialValues.instructWorkflowId);
    }, [form, rule, getInitialValues]);

    React.useEffect(() => {
        localState.load();
    }, [localState]);

    return (
        <>
            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form
                form={form}
                layout="vertical"
                className="rule-edit-form"
                data-id="rule-edit-form"
                onValuesChange={onValuesChange}
            >
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Instruct Wokflow"
                            name="instructWorkflowId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Instruct Wokflow!'
                                }
                            ]}
                            data-id="rule-query-input"
                        >
                            <Select loading={localState.loading} allowClear>
                                {localState.workflows.map(f => (
                                    <Select.Option key={f.id} value={f.id}>
                                        {f.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Prompt"
                            name="prompt"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Prompt!'
                                }
                            ]}
                            data-id="rule-query-input"
                        >
                            <Input.TextArea style={{ height: 'unset' }} rows={4} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item label="Output Scheme" name="outputSchemeName" data-id="rule-query-input">
                            <Select allowClear>
                                {(workflow ? workflow.outputSchemes : []).map((s, i) => (
                                    <Select.Option key={i + s.name} value={s.name}>
                                        {s.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditSmartIndexForm));
