import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import { SearchHistoryRequest, SearchHistoryResult } from './types';

export default class HistoryService {
    async searchHistory({
        projectId,
        searchEntities = [],
        action = null,
        orderBy = null,
        page = 0,
        pageSize = 50
    }: SearchHistoryRequest) {
        const result = await execQuery<SearchHistoryResult>({
            query: gql`
                query SearchHistory(
                    $projectId: String!
                    $action: String
                    $searchEntities: [String]!
                    $orderBy: Json
                    $pageSize: Int
                    $page: Int
                ) {
                    searchHistory(
                        projectId: $projectId
                        action: $action
                        searchEntities: $searchEntities
                        orderBy: $orderBy
                        pageSize: $pageSize
                        page: $page
                    ) {
                        historyRecords {
                            id
                            entityId
                            entityName
                            action
                            createDate
                            fields
                            objectName
                            projectId
                            userId
                            userName
                            meta
                        }
                        total
                    }
                }
            `,
            variables: { projectId, searchEntities, action, orderBy, page, pageSize },
            fetchPolicy: 'network-only'
        });

        return {
            historyRecords: result.data.searchHistory.historyRecords,
            total: result.data.searchHistory.total
        };
    }
}
