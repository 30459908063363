import * as React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Form, Select } from 'antd';
import { MLService } from '../../../ml_storage/services';
import { WorkflowInputProps } from '../../types';
import MLModel from '../../../ml_storage/models/MLModel';
import './WorkflowInputModelSelect.less';

const WorkflowInputModelSelect: React.FC<WorkflowInputProps> = ({ store, workflow, input }) => {
    const existingInputValue = React.useMemo(() => workflow.getInputValueById(input.id), [workflow, input]);

    const modelTypes = React.useMemo(() => {
        const modelControlAttributes = input.controlAttributes.find(c => c.id === 'ModelTypes');
        return modelControlAttributes ? modelControlAttributes.values : [];
    }, [input]);

    const localState = useLocalStore(() => ({
        loading: false,
        service: new MLService(),
        models: new Array<MLModel>(),
        async load() {
            if (!store.project) {
                return;
            }

            runInAction(() => {
                localState.loading = true;
            });

            const mlModels = await this.service.getMLModelsForProject(store.project.id);

            runInAction(() => {
                localState.models = mlModels.filter(m => modelTypes.includes(m.type));
                localState.loading = false;
            });
        }
    }));

    React.useEffect(() => {
        localState.load();
    }, [localState]);

    return (
        <Form.Item
            label="Model"
            name={['inputValues', input.id]}
            initialValue={existingInputValue ? existingInputValue.value : null}
            rules={[{ required: true, message: 'Please select model!' }]}
        >
            <Select
                className={`workflow-input-model-select ${localState.loading ? 'loading' : ''}`}
                placeholder="Select model..."
                loading={localState.loading}
                allowClear
            >
                {localState.models.map(m => (
                    <Select.Option key={m.id!} value={m.id!}>
                        {m.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default observer(WorkflowInputModelSelect);
