import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Block } from '../stores/InteractiveLabelsVisualStore'; // , NONE_LABEL
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { InteractiveLabelsVisualStore } from '../stores';
import { STORE_PROJECT_INTERACTIIVE_LABELS } from '../constants';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

interface InteractiveBlockProps {
    block: Block;
    position?: {
        x: number;
        y: number
    }
}

type Props = { [STORE_PROJECT_INTERACTIIVE_LABELS]?: InteractiveLabelsVisualStore } & InteractiveBlockProps;

type State = {
    reCalculateColor: boolean
};

export class InteractiveBlock extends React.Component<Props, State> {
    contentRef: HTMLDivElement;

    constructor(props: Props) {
        super(props);

        this.state = {
            reCalculateColor: false
        };

        this.highlightBlockIfNeeded = this.highlightBlockIfNeeded.bind(this);
    }

    highlightBlockIfNeeded() {
        const store = this.props.projectInteractiveLabelsUI!;
        const block = this.props.block;
        const blockToHighlight = store.blockToHighlight;

        if (blockToHighlight === block.packageFieldId && !this.state.reCalculateColor) {
            this.setState({ reCalculateColor: true });
            store.clearHighlightedBlock();
            return;
        }

        if (blockToHighlight !== block.packageFieldId && this.state.reCalculateColor) {
            this.setState({ reCalculateColor: false });
            store.clearHighlightedBlock();
            return;
        }
    }

    componentDidMount() {
        this.highlightBlockIfNeeded();
    }

    componentDidUpdate() {
        this.highlightBlockIfNeeded();
    }

    render() {
        const store = this.props.projectInteractiveLabelsUI!;
        const block = this.props.block;
        const highlighted = store.blockToHighlight === block.packageFieldId;
        // const selected = block.line!.labelText || NONE_LABEL;
        let menuVisible = false;
        let divFocused = false;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let style: any = {
            top: block.y, left: block.x,
            position: 'absolute',
            width: block.width, height: block.height
        };

        if (block.line!.labelText) {
            switch (block.blockType) {
            case 'LINE_BLOCK':
                style.backgroundColor = 'rgba(84, 197, 251, 0.42)';
                break;
            case 'TEXTBOX_BLOCK':
                style.backgroundColor = 'rgba(197, 197, 251, 0.42)';
                break;
            case 'TABLE_BLOCK':
                style.backgroundColor = 'rgba(120, 197, 251, 0.42)';
                break;
            case 'CELL_BLOCK':
                style.backgroundColor = 'rgba(125, 197, 251, 0.42)';
                break;
            case 'HORIZONTAL_LINE_BLOCK':
                style.backgroundColor = 'rgba(120, 50, 251, 0.42)';
                break;
            case 'CLUSTER_BLOCK':
                style.backgroundColor = 'rgba(120, 50, 251, 0.42)';
                break;
            case 'PARAGRAPH_BLOCK':
                style.backgroundColor = 'rgba(120, 50, 251, 0.42)';
                break;
            case 'HORIZONTAL_MULTILINE_BLOCK':
                style.backgroundColor = 'rgba(120, 50, 251, 0.42)';
                break;
            case 'PAGE_BLOCK':
                style.backgroundColor = 'rgba(250, 25, 0, 0.1)';
                break;
            case 'WORD_BLOCK':
                style.backgroundColor = 'rgba(250, 75, 0, 0.1)';
                break;
            case 'DOCUMENT_TABLE_BLOCK':
                style.backgroundColor = 'rgba(120, 197, 251, 0.42)';
                break;
            case 'FORM_ITEM_BLOCK':
                style.backgroundColor = 'rgba(120, 197, 251, 0.42)';
                break;
            case 'SMART_BLOCK':
                style.backgroundColor = 'rgba(120, 197, 251, 0.42)';
                break;
            default: 

            }
        } else {
            style.border = '2px black dotted';
            style.backgroundColor = 'rgba(162, 162, 162, 0.1)';
        
        }

        const calculateColor = () => {
            if (menuVisible || divFocused || highlighted) {
                switch (block.blockType) {
                case 'LINE_BLOCK':
                    this.contentRef.style.backgroundColor = '#33f14a52';
                    break;
                case 'TEXTBOX_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(168, 234, 0, 0.3)';
                    break;
                case 'TABLE_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(120, 234, 0, 0.3)';
                    break;
                case 'CELL_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(125, 234, 0, 0.3)';
                    break;
                case 'HORIZONTAL_LINE_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(120, 50, 0, 0.3)';
                    break;
                case 'CLUSTER_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(120, 50, 0, 0.3)';
                    break;
                case 'HORIZONTAL_MULTILINE_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(120, 50, 0, 0.3)';
                    break;
                case 'PARAGRAPH_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(120, 50, 0, 0.3)';
                    break;
                case 'PAGE_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(250, 25, 253, 0.1)';
                    break;
                case 'WORD_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(250, 75, 253, 0.1)';
                    break;
                case 'DOCUMENT_TABLE_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(120, 234, 0, 0.3)';
                    break;
                case 'FORM_ITEM_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(120, 234, 0, 0.3)';
                    break;
                case 'SMART_BLOCK':
                    this.contentRef.style.backgroundColor = 'rgba(120, 234, 0, 0.3)';
                    break;
                default: 
                }
            } else {
                this.contentRef.style.backgroundColor = style.backgroundColor;
            }
        };

        const copyToClipBoard = (textBlock: Block) => {
            const el = document.createElement('textarea');
            el.value = textBlock.normalizedText;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        };

        const MyMenu = () => (
            <ContextMenu
                id={block.line!.rowId.toString()} 
                onShow={() => {
                    menuVisible = true; calculateColor(); 
                }}
                onHide={() => {
                    menuVisible = false; calculateColor(); 
                }}
            >
                {/* <div className={NONE_LABEL === selected ? 'yellow-highlight-selected' : ''}>
                    <MenuItem
                        key={NONE_LABEL} 
                        onClick={() => store.setLabel(block, NONE_LABEL)} 
                        disabled={NONE_LABEL === selected}
                    >{NONE_LABEL}
                    </MenuItem>
                </div>
                {store.labels.map(l => (
                    <div key={l} className={l === selected ? 'yellow-highlight-selected' : ''}>
                        <MenuItem
                            key={l}
                            onClick={() => store.setLabel(block, l)}
                            disabled={l === selected}
                        >
                            {l}
                        </MenuItem>
                    </div>
                ))} */}
                <MenuItem
                    key="COPY"
                    onClick={() => copyToClipBoard(block)} 
                >Copy
                </MenuItem>
            </ContextMenu>
        );

        let RenderText = <span>{block.line!.labelText}</span>;
        
        let zIndex = 10;
        if (block.blockType === 'TEXTBOX_BLOCK') {
            zIndex = 100;
        }

        if (this.state.reCalculateColor) {
            calculateColor();
        }
        
        return (
            <>
                <ContextMenuTrigger id={block.line!.rowId.toString()} holdToDisplay={1}>
                    <div
                        className="interactiveBlock"
                        style={{ ...style, zIndex: zIndex }}
                        ref={r => this.contentRef = r!}
                        onMouseEnter={() => {
                            divFocused = true; calculateColor();  
                        }}
                        onMouseLeave={() => {
                            divFocused = false; calculateColor(); 
                        }}
                    ><div className="blockCoordinates">x: {block.x.toFixed(1)} y: {block.y.toFixed(1)}</div>
                        {block.line!.labelText ?
                            <div 
                                data-testid={'interactiveBlock-assigned-label'}
                                style={{backgroundColor: style.backgroundColor, top: block.y > 21 ? -21 : 0}} 
                                className={'interactiveBlock-assigned-label'}
                            >
                                {RenderText}
                            </div>
                            : <></>}
                    </div>
                </ContextMenuTrigger>
                <HasPermission entityId={store.project?.id}  permissionClaim={AppPermissions.CanEditImportExportLabels}>
                    <MyMenu/>
                </HasPermission>
            </>);
    }
}

export default inject(STORE_PROJECT_INTERACTIIVE_LABELS)(observer(InteractiveBlock));