import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Dropdown, Menu, Popconfirm, Tree } from 'antd';
import { DataNode } from 'rc-tree/lib/interface';
import { FormTypesStore, FormTypesVisualStore } from '../stores';
import { FormPart, FormTemplateRegion, FormType, FormVariation } from '../types';
import _ from 'lodash';
import { FileTextOutlined, GatewayOutlined, LayoutOutlined, ProfileOutlined, WarningOutlined } from '@ant-design/icons';
import { Subscription } from 'rxjs';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore;
    documentLoaded: boolean;
    pageNumber: number
};

type CallbackArguments = {
    deleteCallback: (id: string) => void;
    editCallback?: () => void;
    exportCallback?: (id: string) => void;
    createCallback?: {
        entityName: string;
        callback: (id: string) => void
    };
    previewCallback?: (id: string) => void;
    createCopyCallback?: {
        entityName: string;
        callback: () => void
    }
};

const WarningIcon = () => <WarningOutlined style={{ color: 'red', marginRight: 4 }} />;

const FormTypesTree: React.FC<Props> = ({store, visualStore, documentLoaded, pageNumber}) => {
    const [treeData, setTreeData] = React.useState<DataNode[]>([]);
    const selectedTreeNodeStorageKey = 'selectedFormTypesTreeNode';
    let newSubentitiesSub = React.useRef<Subscription>();

    React.useEffect(() => {
        newSubentitiesSub.current = store.newEntinetiesSubject.subscribe(handleEntitiesUpdate);

        const formTypesTreeExpanded = visualStore.getStoredFormTypesTreeExpanded();

        if (formTypesTreeExpanded) {
            visualStore.setTreeExpandedKeys(formTypesTreeExpanded.keys);
        }

        return () => {
            newSubentitiesSub?.current?.unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setNodeSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject]);

    React.useEffect(() => {
        setTreeData(renderTreeNodes());

        if (store.formParts.length > 0 && store.filteredFormTypes.length > 0) { 
            const selectedNode = sessionStorage.getItem(selectedTreeNodeStorageKey);
            handleTreeNodeSelection(selectedNode);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.filteredFormTypes, store.formParts, store.formTypePackageLines, documentLoaded]);

    React.useEffect(() => {
        function beforeUnload(e: BeforeUnloadEvent) {
            if (!visualStore.hasFormTypeChanges) {
                return;
            }
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [visualStore.hasFormTypeChanges]);

    const handleEntitiesUpdate = (entityId: string) => {
        if (!visualStore.treeExpandedKeys.includes(entityId)) {
            visualStore.setTreeExpandedKeys([...visualStore.treeExpandedKeys, entityId]);
        }
    };

    const addFormVariation = (formTypeId: string, referenceFormVariation?: FormVariation) => {
        handleTreeNodeSelection(formTypeId);
        visualStore.setIsFormVariationCreateDialogVisible(true);
        store.setRefernceFormVariation(referenceFormVariation);
    };

    const addFormPart = (formVariationId: string, referenceFormPart?: FormPart) => {
        handleTreeNodeSelection(formVariationId);
        visualStore.setIsFormPartCreateDialogVisible(true);
        store.setRefernceFormPart(referenceFormPart);
    };

    const addFormRegion = (formPartId: string) => {
        handleTreeNodeSelection(formPartId);
        visualStore.setIsFormTemplateRegionCreateDialogVisible(true);
    };

    const nodeMenu = (id: string, title: string, callbacks: CallbackArguments) => {
        return(
            <Menu data-id-type="popup-menu-list-root" data-id-name={title}>
                <Menu.Item
                    data-id-type="popup-menu-list-item"
                    data-id-name="Edit"
                    key="1"
                    onClick={(info) => {
                        info.domEvent.stopPropagation();
                        if (callbacks.editCallback) {
                            callbacks.editCallback();
                        }
                    }}
                >
                    Edit
                </Menu.Item>

                {callbacks.createCallback && (
                    <Menu.Item
                        data-id-type="popup-menu-list-item"
                        data-id-name="Create"
                        key="2"
                        onClick={(info) => {
                            info.domEvent.stopPropagation();
                            callbacks.createCallback!.callback(id);
                        }}
                    >
                        Create {callbacks.createCallback.entityName}
                    </Menu.Item>
                )}

                {callbacks.exportCallback &&
                    <Menu.Item
                        data-id-type="popup-menu-list-item"
                        data-id-name="Export"
                        key="0"
                        onClick={(info) => {
                            info.domEvent.stopPropagation();
                            callbacks.exportCallback!(id);
                        }}
                    >
                        Export
                    </Menu.Item>
                }

                {callbacks.previewCallback &&
                    <Menu.Item
                        data-id-type="popup-menu-list-item"
                        data-id-name="Preview"
                        key="0"
                        onClick={(info) => {
                            info.domEvent.stopPropagation();
                            callbacks.previewCallback!(id);
                        }}
                    >
                        Preview
                    </Menu.Item>
                }

                {callbacks.createCopyCallback &&
                    <Menu.Item
                        data-id-type="popup-menu-list-item"
                        data-id-name={`Create ${callbacks.createCopyCallback?.entityName || 'entity'} Copy`}
                        key="0"
                        onClick={(info) => {
                            info.domEvent.stopPropagation();
                            callbacks.createCopyCallback!.callback();
                        }}
                    >
                        Create {callbacks.createCopyCallback?.entityName || 'entity'} Copy
                    </Menu.Item>
                }

                <Popconfirm
                    title="Are you sure delete this item?"
                    onConfirm={() => callbacks.deleteCallback(id)}
                >
                    <Menu.Item 
                        data-id-type="popup-menu-list-item"
                        data-id-name="Delete"
                        key="2"
                        danger
                        onClick={(info) => {
                            info.domEvent.stopPropagation();
                        }}
                    >
                        Delete
                    </Menu.Item>
                </Popconfirm>
            </Menu>
        );
    };

    const treeNodeMenu = (id: string, title: string, callbacks: CallbackArguments, icon?: JSX.Element) => {
        return (
            <>
                <Dropdown key={id + 'title'} overlay={() => nodeMenu(id, title, callbacks)} trigger={['contextMenu']}>
                    <span onClick={() => handleEntitiesUpdate(id)}> 
                        <span className="node-title with-menu" data-id={`field-bindings-group-${id}`} data-id-name={title} data-id-type="node-0" >
                            {icon}{title}
                        </span>
                    </span>
                </Dropdown>
            
                <Dropdown key={id + 'settings'} overlay={() => nodeMenu(id, title, callbacks)} trigger={['click']}>
                    <i className="alpha-icon md more-icon node-action" onClick={(e) =>  e.stopPropagation()} />
                </Dropdown>
            </>          
        );
    };

    const renderTreeNodes = () => {
        const getCallbacks = (type: FormType) => {
            return  {
                deleteCallback: store.deleteFormType,
                exportCallback: store.exportFormType,
                editCallback: () => {
                    store.setFormTypeToEdit(type);
                    visualStore.setIsFormTypeEditDialogVisible(true);
                },
                createCallback: {
                    entityName: 'Form Variation',
                    callback: addFormVariation
                }
            };
        };
        
        return store.filteredFormTypes.map(type => { 
            const isBroken = store.broken.formTypeIds.includes(type.id);
            return {
                key: type.id,
                selectable: true,
                id: type.id,
                title: treeNodeMenu(
                    type.id,
                    type.name,
                    getCallbacks(type),
                    <React.Fragment>
                        <ProfileOutlined style={{ marginRight: 4 }} />
                        {isBroken && <WarningIcon />}
                    </React.Fragment>
                ),
                children: renderVariations(type)
            }; 
        });
    };

    const renderVariations = (type: FormType) => {
        if (!type.variations?.length) {
            return [];
        }

        const getCallbacks = (variation: FormVariation) => {
            return  {
                deleteCallback: store.deleteFormVariation,
                editCallback: () => {
                    store.setFormVaritaionToEdit(variation);
                    visualStore.setIsFormVariationEditDialogVisible(true);
                },
                createCallback: {
                    entityName: 'Form Part',
                    callback: addFormPart
                },
                createCopyCallback: {
                    entityName: 'Form Variation',
                    callback: () => {
                        addFormVariation(type.id, variation);
                    }
                }
            };
        };

        return type.variations.map(variation => { 
            const name = variation.isScanned ? variation.name + ' (scanned)' : variation.name;
            const isBroken = store.broken.formVariationIds.includes(variation.variationId);
            return {
                key: variation.variationId,
                selectable: true,
                id: variation.variationId,
                title: treeNodeMenu(
                    variation.variationId,
                    name,
                    getCallbacks(variation),
                    <React.Fragment>
                        <FileTextOutlined style={{ marginRight: 4 }} />
                        {isBroken && <WarningIcon />}
                    </React.Fragment>
                ),
                children: renderVariationParts(variation)
            }; 
        });
    };

    const renderVariationParts = (variation: FormVariation) => {
        const variationParts = store.formParts.filter(part => part.formVariationId === variation.variationId);
        if (!variationParts?.length) {
            return [];
        }

        const getCallbacks = (part: FormPart): CallbackArguments => {
            return  {
                deleteCallback: store.deleteFormPart,
                editCallback: () => {
                    store.setEditableFormPart(part);
                },
                createCallback: {
                    entityName: 'Form Region',
                    callback: addFormRegion
                },
                previewCallback: (id: string) => {
                    if (!store.currentProject) {
                        return;
                    }
            
                    const url = `${window.location.origin}/projects/${store.currentProject.id}/form-parts/${id}/preview`;
                    const previewWindow = window.open(url, `${id}-preview`, 'height=768,width=1024');
            
                    if (previewWindow) {
                        previewWindow.focus();
                    }
                },
                createCopyCallback: {
                    entityName: 'Form Part',
                    callback: () => {
                        addFormPart(variation.variationId, part);
                    }
                }
            };
        };

        return variationParts.map(part => { 
            const isBroken = store.broken.formPartIds.includes(part.id);
            return {
                key: part.id,
                selectable: true,
                id: part.id,
                title: treeNodeMenu(
                    part.id,
                    part.name,
                    getCallbacks(part),
                    <React.Fragment>
                        <LayoutOutlined style={{ marginRight: 4 }} />
                        {isBroken && <WarningIcon />}
                    </React.Fragment>
                ),
                children: renderVariationPartRegions(part)
            }; 
        });
    };

    const renderVariationPartRegions = (part: FormPart) => {
        if (!part?.regions?.length) {
            return [];
        }


        const getCallbacks = (region: FormTemplateRegion) => {
            return  {
                deleteCallback: store.deleteFormTemplateRegion,
                editCallback: () => {
                    store.setFormRegionToEdit(region);
                    visualStore.setIsFormRegionEditDialogVisible(true);
                }
            };
        };

        return part.regions.map(region => { 
            return {
                key: region.regionId,
                selectable: true,
                id: region.regionId,
                title: treeNodeMenu(region.regionId, region.name, getCallbacks(region), <GatewayOutlined style={{marginRight: 4}} />),
                children: renderRegionBlocks(region)
            }; 
        });
    };

    const renderRegionBlocks = (region: FormTemplateRegion) => {
        if (!region?.regionBlocks?.length) {
            return [];
        }

        return region.regionBlocks.map(block => { 
            return {
                key: block.blockId,
                selectable: false,
                id: block.blockId,
                title: <div style={{width: '100%'}} onClick={() => visualStore.changePage(block.blockPage + 1)}>{block.blockTitle}</div>
            }; 
        });
    };

    const setNodeSelection = async (type?: string, variation?: string, part?: string, region?: string) => {
        if (await visualStore.confirmFormTypeChanges(pageNumber, 'Discard changes and navigate to another node?')) {
            store.setSelectedFormType(type);
            store.setSelectedFormVariation(variation);
            store.setSelectedFormPart(part);
            store.setSelecteFormTemplateRegion(region);
            visualStore.clearFormTypeEvents();
            store.setCurrentRegionBlocks(_.cloneDeep(store.lastSavedBlocksState));
        }
    };

    const handleTreeNodeSelection = (key: string | null) => {
        if (key === undefined) {
            return;
        }
        
        if (!key) {
            sessionStorage.removeItem(selectedTreeNodeStorageKey);
            return;
        }

        const typeIds = store.formTypes.map(type => type.id);
        const variationIds = _.flatten(store.formTypes.map(type => type.variations)).map(variation => variation.variationId);
        const formParts = store.formParts.map(part => part.id);
        const regions = _.flatten(store.formParts.map(part => part.regions)).map(region => region.regionId);

        sessionStorage.setItem(selectedTreeNodeStorageKey, key);
        if (typeIds.includes(key)) {
            setNodeSelection(key);
        } else if (variationIds.includes(key)) {
            setNodeSelection(undefined, key);
        } else if (formParts.includes(key)) {
            setNodeSelection(undefined, undefined, key);
        } else if (regions.includes(key)) {
            setNodeSelection(undefined, undefined, undefined, key);
        } else {
            setNodeSelection();
            sessionStorage.removeItem(selectedTreeNodeStorageKey);
        }
    };

    return (
        <Tree 
            showLine={false}
            treeData={treeData}
            className="alpha-portal-tree without-line with-leaf-icons sm-labels"
            onSelect={(keys: string[]) => handleTreeNodeSelection(keys[0])}
            expandedKeys={visualStore.treeExpandedKeys}
            selectedKeys={[store.selectedFormType ?? store.selectedFormVariation ?? store.selectedFormPart ?? store.selectedFormTemplateRegion ?? '']}
            onExpand={(keys: string[]) => visualStore.setTreeExpandedKeys(keys)}
        />
    );
};

export default observer(FormTypesTree);