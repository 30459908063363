import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { STORE_USERS_MANAGER_UI } from '../../../modules/administration/constants';
import { AdministrationStores } from '../../../modules/administration/stores';
import UsersManager from '../components/UsersManager';
import { AuthConsumer } from '../../authorization/AuthContext';

type Props =  AdministrationStores;
@inject(STORE_USERS_MANAGER_UI)
@observer
export default class SecurityPage extends React.Component<Props, object> {
    
    render() {
        const usersManagerStore = this.props.UsersManager!;

        const content = (
            <AuthConsumer>
                {() => (
                    <UsersManager UsersManager={usersManagerStore}/>
                )}
            </AuthConsumer>
        );

        return (
            <FlexContentContainer content={content}/>
        );
    }
}