import * as React from 'react';

export type Size = {
    width: number;
    height: number
};

type Args = {
    ref: React.RefObject<HTMLDivElement>;
    initialWidth: number;
    initialHeight: number;
    minWidth?: number;
    minHeight?: number;
    maxWidth?: number;
    maxHeight?: number
};

export default ({
    ref,
    initialWidth,
    initialHeight,
    minWidth = initialWidth,
    minHeight = initialHeight,
    maxWidth = initialWidth * 2,
    maxHeight = initialHeight * 2
}: Args) => {
    const [size, setSize] = React.useState<Size>({ width: initialWidth, height: initialHeight });
    const [isResizing, setIsResizing] = React.useState(false);
    const [initialMouseX, setInitialMouseX] = React.useState(0);
    const [initialMouseY, setInitialMouseY] = React.useState(0);

    React.useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (isResizing && ref.current) {
                const offsetX = initialMouseX - event.clientX;
                const offsetY = initialMouseY - event.clientY;

                const newWidth = Math.max(size.width + offsetX, minWidth);
                const newHeight = Math.max(size.height + offsetY, minHeight);

                const limitedWidth = Math.min(newWidth, maxWidth);
                const limitedHeight = Math.min(newHeight, maxHeight);

                setSize({ width: limitedWidth, height: limitedHeight });

                setInitialMouseX(event.clientX);
                setInitialMouseY(event.clientY);
            }
        };

        const handleMouseUp = () => setIsResizing(false);

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [
        ref,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        initialMouseX,
        initialMouseY,
        size.width,
        size.height,
        isResizing
    ]);

    const handleResize = (event: React.MouseEvent) => {
        setIsResizing(true);
        setInitialMouseX(event.clientX);
        setInitialMouseY(event.clientY);
    };

    return {
        size,
        isResizing,
        handleResize,
        setSize
    };
};
