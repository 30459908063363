import { computed } from 'mobx';
import { WorkflowTypeMessagesTemplateDto, WorkflowTypeInputDto, InstructWorkflowTypeDto } from '../types';

export default class InstructWorkflowTypeModel {
    readonly id: string;
    readonly type: string;
    readonly description: string;
    readonly messagesTemplates: WorkflowTypeMessagesTemplateDto[];
    readonly inputs: WorkflowTypeInputDto[];

    @computed
    get textInputs() {
        return this.inputs.filter(i => i.control === 'Text');
    }

    @computed
    get registeredInputs() {
        return this.inputs.filter(i => i.control !== 'Text');
    }

    constructor(data: InstructWorkflowTypeDto) {
        this.id = data.id;
        this.type = data.type;
        this.description = data.description;
        this.messagesTemplates = data.messagesTemplates;
        this.inputs = data.inputs;
    }
}
