/* eslint-disable @typescript-eslint/member-ordering */
import { observable } from 'mobx';
import cuid from 'cuid';
import { Label } from '../../common/models';

export default class PredefinedLabel {
    @observable
    links: PredefinedLink[] = [];

    constructor(public label: Label, links: PredefinedLink[]) {
        this.links = links;
    }
}

export class PredefinedLink {
    id: string = cuid();

    @observable
    text: string;

    constructor(text: string) {
        this.text = text;
    }
}