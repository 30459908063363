import { action, computed, observable } from 'mobx';
import { ProjectsRootVisualStore } from '../../common/stores';
import { OrderBy } from '../../common/types';
import { HistoryAction } from '../services/types';
import BaseHistoryRecordModel from '../models/BaseHistoryRecordModel';
import HistoryService from '../services/HistoryService';
import HistoryRecordFactory from '../misc/HistoryRecordFactory';
import { flatten } from 'lodash';

export const HISTORY_PAGE_SIZE = 20;

const projectHistoryAreaOptions = [
    {
        value: 'projects',
        label: 'Project',
        entities: ['Project']
    },
    {
        value: 'tags',
        label: 'Tags',
        entities: ['RuleTag']
    },
    {
        value: 'rules',
        label: 'Rules',
        entities: ['RuleBase']
    },
    {
        value: 'refDataConnections',
        label: 'RD Connections',
        entities: ['RefDataConnection']
    },
    {
        value: 'testProjects',
        label: 'Test projects',
        entities: ['TestProject', 'TestProjectBaseline']
    },
    {
        value: 'applicationDefinitions',
        label: 'IOTA applications',
        entities: ['ApplicationDefinitionBase']
    },
    {
        value: 'connectionsDefinitions',
        label: 'WF connections',
        entities: ['ConnectionsDefinition']
    },
    {
        value: 'MLModels',
        label: 'ML storage',
        entities: ['MLModel']
    },
    {
        value: 'projectFields',
        label: 'Project Fields',
        entities: ['ProjectFieldGroup']
    },
    {
        value: 'formTemplate',
        label: 'Templates',
        entities: ['FormPart', 'FormType']
    },
    {
        value: 'instructWorkflows',
        label: 'Instruct Workflows',
        entities: ['InstructWorkflow']
    },
    {
        value: 'packages',
        label: 'Packages',
        entities: ['Package']
    }
];

const globalHistoryAreaOptions = [
    {
        value: 'referenceData',
        label: 'Reference Data',
        entities: ['ReferenceDataTable']
    }
];

export default class HistoryStore {
    @observable
    historyRecords: BaseHistoryRecordModel[] = [];

    @observable
    total: number = 0;

    @observable
    historyAction: HistoryAction | null = null;

    @observable
    orderBy: OrderBy | null = null;

    @observable
    selectedAreaOption: string | null = null;

    @observable
    page: number = 0;

    @observable
    loading: boolean = false;

    @observable
    searchOnlyProjectRelated: boolean = false;

    @computed
    get project() {
        return this.projectsRootStore.currentProject;
    }
    
    @computed
    get historyAreaOptions() {
        return this.searchOnlyProjectRelated ? projectHistoryAreaOptions : [...projectHistoryAreaOptions, ...globalHistoryAreaOptions];
    }

    @computed
    get searchEntities() {
        if (this.searchOnlyProjectRelated) {
            const projectEntities = flatten(this.historyAreaOptions.filter(o => o.value !== 'referenceData').map(o => o.entities));
            return this.historyAreaOptions.find(o => o.value === this.selectedAreaOption)?.entities ?? projectEntities;
        }

        return this.historyAreaOptions.find(o => o.value === this.selectedAreaOption)?.entities ?? [];
    }

    constructor(readonly projectsRootStore: ProjectsRootVisualStore, readonly historyService: HistoryService) {}

    @action.bound
    setHistoryRecords(historyRecords: BaseHistoryRecordModel[]) {
        this.historyRecords = historyRecords;
    }

    @action.bound
    setTotal(total: number) {
        this.total = total;
    }

    @action.bound
    setHistoryAction(historyAction: HistoryAction | null) {
        this.historyAction = historyAction;
    }

    @action.bound
    setOrderBy(orderBy: OrderBy | null) {
        this.orderBy = orderBy;
    }

    @action.bound
    setSelectedAreaOption(selectedAreaOption: string | null) {
        this.selectedAreaOption = selectedAreaOption;
    }

    @action.bound
    setPage(page: number) {
        this.page = page;
    }

    @action.bound
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action.bound
    async searchHistory() {
        if (!this.project) {
            return;
        }

        try {
            this.setLoading(true);

            const request = {
                projectId: this.project.id,
                action: this.historyAction,
                searchEntities: this.searchEntities,
                orderBy: this.orderBy,
                page: this.page,
                pageSize: HISTORY_PAGE_SIZE
            };

            const response = await this.historyService.searchHistory(request);

            this.setHistoryRecords(response.historyRecords.map(HistoryRecordFactory.createHistoryRecord));
            this.setTotal(response.total);
        } finally {
            this.setLoading(false);
        }
    }

    @action.bound
    setSearchOnlyProjectRelated(searchOnlyProjectRelated: boolean) {
        if (searchOnlyProjectRelated && this.selectedAreaOption === 'referenceData') {
            this.setSelectedAreaOption(null);
        }

        this.searchOnlyProjectRelated = searchOnlyProjectRelated;
    }

    dispose() {
        this.setHistoryRecords([]);
        this.setTotal(0);
        this.setOrderBy(null);
        this.setPage(0);
        this.setSelectedAreaOption(null);
    }
}
