import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import IotaAuditPage from '../screens/IotaAuditPage';

export const IotaAuditPagesNavigation = {
    IotaAuditPage: '/projects/:projectId/audit'
};

export default () => {
    return (<ProtectedRoute component={IotaAuditPage} path={IotaAuditPagesNavigation.IotaAuditPage} />);
};