/* eslint-disable no-unused-vars */
import * as React from 'react';
import * as monaco from 'monaco-editor';
import { SharedInstanceManager } from '../services/MonacoEditorBinder';
import { CompletionItem } from 'monaco-languageclient';
import { CodeSnippet } from '../../administration/models';

type Props = Partial<typeof defaultProps>;

const defaultProps = {
    width: '100%',
    height: '100%',
    value: '',
    language: 'C#',
    theme: 'vs-light',
    options: {
        readOnly: false
    },
    // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
    editorDidMount: (editor: monaco.editor.IStandaloneCodeEditor) => { },
    // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
    editorWillMount: () => {
        return {}; 
    },
    // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
    onChange: (code: string, event: monaco.editor.IModelContentChangedEvent) => { },
    defaultValue: '',
    dataId: 'monaco-editor',
    snippets: new Array<CodeSnippet>()
};

function processSize(size: string) {
    return !/^\d+$/.test(size) ? size : `${size}px`;
}

export default class MonacoEditor extends React.Component<Props> {
    static defaultProps = defaultProps;
    private containerElement?: HTMLElement;
    private currentValue?: string;
    private editor?: monaco.editor.IStandaloneCodeEditor;
    private preventTriggerChangeEvent: boolean = false;
    private manager: SharedInstanceManager;
    private eventToken: monaco.IDisposable;

    constructor(props: Props) {
        super(props);
        this.currentValue = props.value;
        this.manager = new SharedInstanceManager();
    }

    componentDidMount() {
        this.initMonaco();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.value !== this.currentValue) {
            // Always refer to the latest value
            this.currentValue = this.props.value;
            // Consider the situation of rendering 1+ times before the editor mounted
            if (this.editor) {
                this.preventTriggerChangeEvent = true;
                this.editor.setValue(this.currentValue || '');
                this.preventTriggerChangeEvent = false;
            }
        }

        if (prevProps.language !== this.props.language) {
            monaco.editor.setModelLanguage(this.editor!.getModel(), this.props.language || 'C#');
        }

        if (prevProps.theme !== this.props.theme) {
            monaco.editor.setTheme(this.props.theme || 'vs-light');
        }
        if (this.editor && (this.props.width !== prevProps.width || this.props.height !== prevProps.height)) {
            this.editor.layout();
        }

        if (prevProps.options !== this.props.options) {
            this.editor!.updateOptions(this.props.options || {});
        }
    }

    componentWillUnmount() {
        this.destroyMonaco();
    }

    assignRef = (container: HTMLDivElement) => {
        if (!container) {
            return;
        }

        this.containerElement = this.manager.getOrCreateDomElement(container);
    };

    render() {
        const { width, height } = this.props;

        const fixedWidth = processSize(width || '100%');
        const fixedHeight = processSize(height || '100%');
        const style = {
            width: fixedWidth,
            height: fixedHeight
        };

        return (
            <div 
                data-id={this.props.dataId ? `${this.props.dataId}-code-editor` : 'monaco-editor-container'} 
                ref={this.assignRef} 
                style={style}
                className="react-monaco-editor-container" 
            />
        );
    }

    private initMonaco() {
        const value = this.props.value !== null ? this.props.value : this.props.defaultValue;
        const { language, theme, options, snippets } = this.props;        
        if (this.containerElement) {            
            Object.assign(options, this.editorWillMount());

            this.editor = this.manager.getOrCreateEditor(() => {
                let suggestionsLang = !language || language === 'C#' ? 'csharp' : language;
                // Before initializing monaco editor
                monaco.languages.registerCompletionItemProvider(suggestionsLang, {                    
                    // @ts-ignore
                    // eslint-disable-next-line
                    provideCompletionItems: function(document, position, token, context) {                        
                        var completionItems: CompletionItem[] =  [];

                        if (snippets && snippets.length) {
                            for (let snippet of snippets) {
                                completionItems.push({
                                    label: `${snippet.type}.${snippet.fullSnippetGroupPath}${snippet.name}`,
                                    insertText: snippet.code,
                                    kind: monaco.languages.CompletionItemKind.Snippet,
                                    documentation: snippet.description
                                });
                            }
                        }                        

                        return completionItems;
                    }
                });

                const editor = monaco.editor.create(this.containerElement!, {
                    language,
                    readOnly: options ? options.readOnly : false
                });      
                
                return editor;
            });

            this.editor!.setValue(value!);
            this.editor!.updateOptions(options!);            

            if (theme) {
                monaco.editor.setTheme(theme);
            }            
            
            // After initializing monaco editor
            this.editorDidMount(this.editor!);
        }
    }

    private destroyMonaco() {
        if (this.editor) {
            this.manager.detachEditor();
            this.editor = undefined;
            this.eventToken.dispose();
        }
    }

    private editorWillMount() {
        const { editorWillMount } = this.props;
        if (editorWillMount) {
            const options = editorWillMount();
            return options || {};
        }

        return {};
    }

    private editorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {        
        if (this.props.editorDidMount) {
            this.props.editorDidMount(editor);
        }

        this.eventToken = editor.onDidChangeModelContent((event) => {
            const value = editor.getValue();
    
            // Always refer to the latest value
            this.currentValue = value;
    
            // Only invoking when user input changed
            if (!this.preventTriggerChangeEvent && this.props.onChange) {
                this.props.onChange(value, event);
            }
        });
    }    
}