import * as React from 'react';
import { observer } from 'mobx-react-lite';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import { InferenceModelsService } from '../services';
import { RuleModelSelectWrapped } from './RuleModelSelect';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import Form, { FormInstance } from 'antd/lib/form';
export const NRE_MODEL_TYPE = ['ner_flair', 'ner_transformers'] ;
type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string>  = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
    dataId: string
};
export const ParametersNERModelEditor: <T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string>(
    props: Props<T, TPipelineStepType>) => React.ReactElement<Props<T, TPipelineStepType>> = ({param, form, toolTip, step, store, stepKey, dataId}) => {
    
    const handleSubmit = async() => {
        await store.validateForm(form, step, stepKey, false);
    };

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }
    const service = new InferenceModelsService();
    return (
        <div>
            <Form.Item 
                label={toolTip}
                name={param.name}
                rules={[{
                    required:!step.isDisabled && param.required, message: 'Please select a model',
                }]}
                initialValue={param.defaultValue}
            >
                <RuleModelSelectWrapped 
                    dataId={dataId}
                    service={service}
                    showTextDescription
                    projectId={store.currentProject ? store.currentProject.id : undefined}
                    type={NRE_MODEL_TYPE}
                />
            </Form.Item>
        </div>
    );
};

export default observer(ParametersNERModelEditor);