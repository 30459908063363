import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Popconfirm } from 'antd';
import { ReferenceDataVisualStore } from '../../stores';
import { HasPermission } from '../../../authorization/components/HasPermission';
import { AppPermissions } from '../../../authorization/Permissions';
import RefDataTableGroupModel from '../../models/RefDataTableGroupModel';

interface Props {
    store: ReferenceDataVisualStore;
    tableGroup: RefDataTableGroupModel
}

const TableGroupTitle: React.FC<Props> = ({ store, tableGroup }) => {
    return (
        <div className="title-item">
            <span data-testid={tableGroup.name}>{tableGroup.name}</span>

            <HasPermission entityId={store.currentProject.id} permissionClaim={AppPermissions.CanEditIotaConnections}>
                <div className="title-item-actions">
                    <Button
                        size="small"
                        type="link"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();

                            tableGroup.setIsEditable(true);
                        }}
                    >
                        <i className="alpha-icon lg edit-icon" />
                    </Button>

                    <Popconfirm
                        title="Sure to delete?"
                        onCancel={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                        onConfirm={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();

                            store.deleteTableGroup(tableGroup.id);
                        }}
                    >
                        <Button
                            id={tableGroup.name}
                            onClick={e => e.stopPropagation()}
                            type="link"
                            size="small"
                            data-testid={`delete-table-group-${tableGroup.name}`}
                        >
                            <i className="alpha-icon lg delete-icon" />
                        </Button>
                    </Popconfirm>
                </div>
            </HasPermission>
        </div>
    );
};

export default observer(TableGroupTitle);
