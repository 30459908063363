import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { BindingGroup, FieldBinding } from '../types';
import { Button, Collapse, Modal } from 'antd';
import { DragDropContainer, DropTarget, OnHitEventArgs } from 'react-drag-drop-container';
import { MoreOutlined } from '@ant-design/icons';
import { FieldBindingsStore } from '../stores';
import _ from 'lodash';

type Props = {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    fieldBindingGroup: BindingGroup | undefined;
    saveLayout: (fieldBindingGroup: BindingGroup) => void;
    store: FieldBindingsStore
};

const FieldBindingGroupLayoutDialog: React.FC<Props> = ({isVisible, setIsVisible, fieldBindingGroup, saveLayout, store}) => {
    const [currentGroup, setCurrentGroup] = React.useState<BindingGroup | undefined>(undefined);

    React.useEffect(() => {
        setCurrentGroup(isVisible ? fieldBindingGroup : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    React.useEffect(() => {
        setCurrentGroup(fieldBindingGroup);
    }, [fieldBindingGroup]);

    const onCancel = () => {
        setIsVisible(false);
    };

    const onSave = () => {
        if (!currentGroup) {
            return;
        }

        saveLayout(currentGroup);
        setIsVisible(false);
    };

    const sectionExtra = () => {
        return (
            <MoreOutlined className="drag-handle" />
        );
    };

    const handleOnSectionHit = (args: OnHitEventArgs, toIndex: number) => {
        if (!currentGroup) {
            return;
        }

        let {index} = args.dragData;
        const sections = [...currentGroup.sections];
        const idToMove = sections.splice(index, 1)[0];
        sections.splice(toIndex, 0, idToMove);

        var newGroup = {...currentGroup, sections: sections};
        setCurrentGroup(newGroup);
    };

    const handleInputOnHit = (args: OnHitEventArgs, toIndex: number, toSection: string) => {
        if (!currentGroup) {
            return;
        }

        let { index, sectionId } = args.dragData;

        let fieldIds = currentGroup.fields.filter(f => f.sectionId === sectionId).map(f => f.inputId);
        const uniqueFieldIds = [... new Set(fieldIds)];

        if (sectionId !== toSection) {
            const idToMove = uniqueFieldIds.splice(index, 1)[0];
            const targetSectionFieldIds = currentGroup.fields.filter(f => f.sectionId === toSection).map(f => f.inputId);
            const uniqueTargetSectionFieldIds = [... new Set(targetSectionFieldIds)];
            uniqueTargetSectionFieldIds.splice(toIndex, 0, idToMove);

            const concattedFieldIds = uniqueFieldIds.concat(uniqueTargetSectionFieldIds);

            let fields = _.flatten(concattedFieldIds.map(fId => {
                let groupFields = currentGroup.fields.filter(f => f.inputId === fId);

                if (fId === idToMove) {
                    groupFields = groupFields.map(f => ({...f, sectionId: toSection}));
                }

                return groupFields;
            }));

            var newGroup = {...currentGroup, fields: [...currentGroup.fields.filter(f => f.sectionId !== sectionId && f.sectionId !== toSection), ...fields]};
            setCurrentGroup(newGroup);

        } else {
            const idToMove = uniqueFieldIds.splice(index, 1)[0];
            uniqueFieldIds.splice(toIndex, 0, idToMove);
    
            let fields: FieldBinding[] = [];
    
            for (let id of uniqueFieldIds) {
                fields = [...fields, ...currentGroup.fields.filter(f => f.inputId === id)];
            }
    
            var newGroup = {...currentGroup, fields: [...currentGroup.fields.filter(f => f.sectionId !== sectionId), ...fields]};
            setCurrentGroup(newGroup);
        }      
    };

    const handleOnBindingHit  = (args: OnHitEventArgs, toIndex: number) => {
        if (!currentGroup) {
            return;
        }

        let {index, inputId} = args.dragData;
        let fieldIds = currentGroup.fields.map(f => f.inputId);
        const uniqueFieldIds = [... new Set(fieldIds)];
        var currentInputFields = currentGroup.fields.filter(f => f.inputId === inputId);
        const idToMove = currentInputFields.splice(index, 1)[0];
        currentInputFields.splice(toIndex, 0, idToMove);

        let fields: FieldBinding[] = [];

        for (let id of uniqueFieldIds) {
            const fieldBindings = id === inputId ? currentInputFields : currentGroup.fields.filter(f => f.inputId === id);
            fields = [...fields, ...fieldBindings];
        }

        var newGroup = {...currentGroup, fields: fields};
        setCurrentGroup(newGroup);
    };

    const renderSectionFields = (sectionId: string) => {
        if (!currentGroup) {
            return;
        }

        const getFieldValue = (field: FieldBinding) => {
            switch(field.type) {
            case 'tag':
                return 'Tag - ' + store.getTagById(field.value ?? '');
            case 'coderule':
                return 'Code Rule';
            default: 
                return field.type + ' - ' + field.value;
            }
        };

        const fields = currentGroup.fields.filter(i => i.sectionId === sectionId);
        const fieldGroups = [ ...new Set(fields.map(i => i.inputId))];

        return (
            <div className="section-inputs-layout">
                {fieldGroups.map((inputId, index) => {
                    const fieldBindings = fields.filter(f => f.inputId === inputId);
                    return (
                        <DragDropContainer 
                            key={inputId} 
                            targetKey="field-bindings-section"
                            dragData={{index, sectionId}} 
                            dragHandleClassName="drag-handle"
                        >
                            <DropTarget
                                targetKey="field-bindings-section"
                                onHit={(args) => handleInputOnHit(args, index, sectionId)}
                                highlightClassName="drag-highlight"
                            >
                                <div className="input">
                                    <div className="input-name">{fieldBindings[0]?.name} : 
                                        <MoreOutlined style={{float: 'left', marginLeft: -14, marginTop: 4}} className="drag-handle" />
                                    </div>
                                    {fieldBindings.map((binding, bindingIndex) => (
                                        <DragDropContainer 
                                            key={`${binding.inputId}-${bindingIndex}`} 
                                            targetKey={inputId} 
                                            dragData={{index: bindingIndex, inputId: binding.inputId}} 
                                            dragHandleClassName="drag-handle-input"
                                        >
                                            <DropTarget
                                                targetKey={inputId}
                                                onHit={(args) => handleOnBindingHit(args, bindingIndex)}
                                                highlightClassName="drag-highlight-input"
                                            >
                                                <div className="input-value">
                                                    {getFieldValue(binding)}
                                                    <MoreOutlined style={{float: 'right'}} className="drag-handle-input" />
                                                </div>
                                            </DropTarget>
                                        </DragDropContainer>
                                    ))}
                                </div>
                            </DropTarget>
                        </DragDropContainer> 
                    );
                })}
            </div>
        );
    };

    return (
        <Modal
            className="alpha-portal-dialog two-columns"
            onCancel={onCancel}
            onOk={onSave}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            visible={isVisible}
            title={`Layout for ${fieldBindingGroup?.name}`}
            footer={[
                <Button data-id="field-binding-edit-layout-dialog-cancel" className="light" key="back" size="large" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button data-id="field-binding-edit-layout-dialog-submit" key="submit" size="large" type="primary" onClick={onSave}>
                    Save layout
                </Button>
            ]}
        >
            {currentGroup && currentGroup.sections.map((section, sectionIndex) => (
                <div key={section.sectionId} className="bindings-layout-configuration">
                    <DragDropContainer targetKey="section" dragHandleClassName="drag-handle" dragData={{index: sectionIndex, sectionId: section.sectionId}}>
                        <DropTarget targetKey="section" highlightClassName="section-highlight" onHit={(args) => handleOnSectionHit(args, sectionIndex)}>
                            <Collapse style={{width: 560}}>
                                <Collapse.Panel header={section.name} key={section.sectionId} extra={sectionExtra()}>
                                    {renderSectionFields(section.sectionId)}
                                </Collapse.Panel>
                            </Collapse>
                        </DropTarget>
                    </DragDropContainer>
                </div>
            ))}
        </Modal>
    );
};

export default observer(FieldBindingGroupLayoutDialog);