import * as React from 'react';
import { DragElementWrapper, DragSourceOptions, DropTarget, DragSource, DropTargetMonitor } from 'react-dnd';
import { TagsGroupModel } from '../models/TagsGroupModel';
import { NativeTypes } from 'react-dnd-html5-backend';
import { TagModel } from '../models/TagModel';

let dragingIndex = -1;
let draggableRecord = {} as TagsGroupModel | TagModel;

type BeginDragProps = {
    index: number;
    record: TagsGroupModel | TagModel
};

type RowTargetProps = {
    moveTagRow: (dragingIndex: number, hoverIndex: number, groupId: string) => void;
    moveRow: (dragingIndex: number, hoverIndex: number, dragRecord: TagsGroupModel | TagModel) => void;
    handleFileDrop: (files: File[], hoverIndex: number) => void;
    index: number
};

const rowSource = {
    beginDrag(props: BeginDragProps) {
        dragingIndex = props.index;
        draggableRecord = props.record;
        return {
            index: props.index,
            record: props.record
        };
    },
};
  
const rowTarget = {
    drop(props: RowTargetProps, monitor: DropTargetMonitor) {
        const files = monitor.getItem().files;
        const dragIndex = monitor.getItem().index;
        const dragRecord = monitor.getItem().record;
        const hoverIndex = props.index;
        
        if (files) {
            props.handleFileDrop(files, hoverIndex);
            return;
        }

        if (!dragRecord) {
            return;
        }

        if (props.moveTagRow) {
            props.moveTagRow(dragIndex, hoverIndex, (dragRecord as TagModel).groupId!);
        } else if (props.moveRow) {
            props.moveRow(dragIndex, hoverIndex, dragRecord);
        }

        monitor.getItem().index = hoverIndex;
        draggableRecord = {} as TagModel;
    },
};

type Props = {
    isOver: boolean;
    connectDragSource: DragElementWrapper<DragSourceOptions>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    connectDropTarget: DragElementWrapper<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style: any;
    className: string;
    index: number;
    record: TagsGroupModel | TagModel;
    moveRow: () => void;
    moveTagRow: () => void;
    handleFileDrop: () => void
};

export class ItemDraggableRow extends React.Component<Props> {
    isTagsGroupModel(value:  TagsGroupModel | TagModel): value is TagsGroupModel {
        return (value as TagsGroupModel).description !== undefined;
    }

    highlightTags(record: TagsGroupModel | TagModel ) {
        if (!this.isTagsGroupModel(draggableRecord) && !this.isTagsGroupModel(record)) {
            const onHoverItemGroupId = (record as TagModel).groupId;
            const draggableItemGroupId = (draggableRecord as TagModel).groupId;
            if (onHoverItemGroupId === draggableItemGroupId) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
     
    }

    render() {
        const { isOver, connectDragSource, connectDropTarget, ...restProps } = this.props;
        const style = { ...restProps.style};
        let { className } = restProps;
        if (isOver && restProps.record && draggableRecord) {
            if (this.highlightTags(restProps.record) || this.isTagsGroupModel(draggableRecord) && this.isTagsGroupModel(restProps.record)) {
                if (restProps.index > dragingIndex ) {
                    className += ' drop-over-downward';
                }        
                if (restProps.index < dragingIndex ) {
                    className += ' drop-over-upward';
                }
            }
            if (this.isTagsGroupModel(restProps.record) && !this.isTagsGroupModel(draggableRecord)) {
                className += ' drop-over';
            }
        } 
        // eslint-disable-next-line no-unused-vars
        const {moveRow, moveTagRow, handleFileDrop, ...props} = restProps;
        return connectDragSource(
            connectDropTarget(<tr {...props} className={className} style={style} />),
        );
    }
}

export const DragableBodyRow = DropTarget(
    ['row', NativeTypes.FILE], rowTarget, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    }))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(ItemDraggableRow),
);