import * as React from 'react';
import RuleEditRefDataForm from './RuleEditRefDataForm';
import RuleEditElasticSearchMatchForm from './RuleEditElasticSearchMatchForm';
import RuleEditElasticSearchMatchPhraseForm from './RuleEditElasticSearchMatchPhraseForm';
import RuleEditElasticSearchQueryForm from './RuleEditElasticSearchQueryForm';
import RuleEditElasticSearchRawForm from './RuleEditElasticSearchRawForm';
import RuleEditInferenceRuleForm from './RuleEditInferenceForm';
import RuleEditNamedEntitiesRecognitionRuleForm from './RuleEditNamedEntitiesRecognitionForm';
import { inject } from 'mobx-react';
import { FormInstance } from 'antd';
import { RulesStores } from '../stores';
import { STORE_RULE_EDIT } from '../constants';
import { observer } from 'mobx-react-lite';
import RuleType from '../types/RuleType';
import RuleEditSmartIndexForm from './RuleEditSmartIndexForm';

type Props = RulesStores & {
    isNew?: boolean;
    selectType: (type: RuleType) => void;
    form: FormInstance;
    isValidationFailed?: boolean
};

export const RuleEditForm: React.FC<Props> = ({RuleEditUI, isNew, selectType, form, isValidationFailed}) => {  
    const isMounted = React.useRef(true);

    const store = RuleEditUI!;
    React.useEffect(() => {
        store.setCurrentTabsKey('1');
        if (isNew) {
            selectType!('ElasticSearchMatchRule');
        }

        return (() => {
            // store.currentTagId = null;
            isMounted.current = false;
            store.resetError();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function makeContent() {
        if (!store.editableRule) {
            return <span />;
        }

        switch (store.editableRule.ruleType) {
        case 'RefDataRule':                
            return (<RuleEditRefDataForm store={store} selectType={selectType} form={form}/>);
        case 'ElasticSearchMatchRule':                
            return (<RuleEditElasticSearchMatchForm store={store} selectType={selectType} form={form} />);
        case 'ElasticSearchMatchPhraseRule':                
            return (<RuleEditElasticSearchMatchPhraseForm store={store} selectType={selectType} form={form} />);
        case 'ElasticSearchQueryRule':                
            return (<RuleEditElasticSearchQueryForm store={store} selectType={selectType} form={form}/>);
        case 'ElasticSearchRawRule':     
            return (<RuleEditElasticSearchRawForm store={store} selectType={selectType} form={form}/>);
        case 'InferenceRule':     
            return (<RuleEditInferenceRuleForm store={store} selectType={selectType} form={form}/>);
        case 'NamedEntitiesRecognitionRule': 
            return (<RuleEditNamedEntitiesRecognitionRuleForm store={store} selectType={selectType} form={form}/>);
        case 'SmartIndexRule':
            return (<RuleEditSmartIndexForm store={store} selectType={selectType} form={form}/>);
        default:
            return <span />;
        }
    }

    return (
        <div data-id={`${isNew ? 'rule-create-dialog-content' : 'rule-edit-dialog-content'}`}>
            <div className="validation-message" style={{display: isValidationFailed && !isNew ? 'block' : 'none'}}>
                <div>
                    <i className="alpha-icon md rule-problem-icon"/>
                    <span>Please check the general settings and fill all mandatory fields correctly</span>
                </div>
            </div>
            {makeContent()}
        </div>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditForm));