import * as React from 'react';
import { Input, Select, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { RulesStores } from '../stores';
import { Connection } from '../models';
import { ConnectionResult } from '../types';
import { FormInstance } from 'antd/lib/form';

type Props = {
    form: FormInstance;
    tempId: string
} & RulesStores;
const TextArea =  Input.TextArea;
export const ConnectionAddPage: React.FC<Props> = ({RuleConnection, form, tempId }) => {
    const store = RuleConnection;

    const handleSubmit = () => {    
        form.validateFields().then((values: ConnectionResult) => {
            const conn = new Connection(values);
            store!.save(conn, tempId);
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <Form 
            form={form} 
            onFinish={handleSubmit} 
            layout="vertical"
            id="add-rd-connection-form"
            data-id="add-rd-connection-dialog-form"
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{
                    required: true, message: 'Please input connection name!',
                }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Connection Type"
                name="connectionType"
                rules={[{
                    required: true, message: 'Please input connection type!',
                }]}
            >
                <Select>
                    <Select.Option value={'SqlServer'}>SqlServer</Select.Option>
                    <Select.Option value={'MongoDb'}>MongoDb</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Connection String"
                name="connectionString"
                rules={[{
                    required: true, message: 'Please input connection string!',
                }]}
            >
                <TextArea />
            </Form.Item>            
        </Form>
    );
    
};

export default observer(ConnectionAddPage);
