import { RootStores } from '../../common/stores';
import ConnectionsDefinitionsVisualStore from './ConnectionsDefinitionsVisualStore';
import { ConnectionsDefinitionsService } from '../services';
import { STORE_PROJECT_CONNECTIONS_DEFINITIONS } from '../constants';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';

export { default as ConnectionsDefinitionsVisualStore } from './ConnectionsDefinitionsVisualStore';

export function injectIotaConnStores(obj: RootStores) {
    const service = new ConnectionsDefinitionsService();
    obj[STORE_PROJECT_CONNECTIONS_DEFINITIONS] = new  ConnectionsDefinitionsVisualStore(obj[STORE_PROJECTS_ROOT_UI], service);
}

export type IotaConnStores = {
    [STORE_PROJECT_CONNECTIONS_DEFINITIONS]?: ConnectionsDefinitionsVisualStore
};