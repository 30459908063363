import * as React from 'react';
import TestProjectsPage from '../screens/TestProjectsPage';
import TestProjectDashboardPage from '../screens/TestProjectDashboardPage';
import { monitorRouterMatch, ProtectedRoute } from '../../common/components';
import { STORE_TEST_PROJECT_DASHBOARD } from '../stores/constants';
import TestProjectBaselinesPage from '../screens/TestProjectBaselinesPage';
import TestProjectRunResultPage from '../screens/TestProjectRunResultPage';
import TestProjectRunTopicPreviewPage from '../screens/TestProjectRunTopicPreviewPage';

export const TestProjectsNavigation = {
    TestProjectsPage: '/projects/:projectId/test-projects',
    TestProjectDashboardPage: '/projects/:projectId/test-project-dashboard/:testProjectId',
    TestProjectBaselinesPage: '/projects/:projectId/test-project-baselines/:testProjectId',
    TestProjectRunResultsPage: '/projects/:projectId/test-project/:testProjectId/run-results/:runId',
    TestProjectRunTopicPreviewsPage: '/projects/:projectId/test-project/:testProjectId/preview/',
};

export default () => {
    return (
        <>
            <ProtectedRoute component={TestProjectsPage} path={TestProjectsNavigation.TestProjectsPage} />
            <ProtectedRoute 
                component={monitorRouterMatch(
                    STORE_TEST_PROJECT_DASHBOARD, 
                    (s, m) => s[STORE_TEST_PROJECT_DASHBOARD].matchCurrentTestProjectData(m!.params))(TestProjectDashboardPage)
                }
                path={TestProjectsNavigation.TestProjectDashboardPage} 
            />
            <ProtectedRoute 
                component={monitorRouterMatch(
                    STORE_TEST_PROJECT_DASHBOARD, 
                    (s, m) => s[STORE_TEST_PROJECT_DASHBOARD].matchCurrentTestProjectData(m!.params))(TestProjectBaselinesPage)
                }
                path={TestProjectsNavigation.TestProjectBaselinesPage} 
            />
            <ProtectedRoute 
                component={monitorRouterMatch(
                    STORE_TEST_PROJECT_DASHBOARD, 
                    (s, m) => s[STORE_TEST_PROJECT_DASHBOARD].matchCurrentTestProjectData(m!.params))(TestProjectRunResultPage)
                }
                path={TestProjectsNavigation.TestProjectRunResultsPage} 
            />
            <ProtectedRoute component={TestProjectRunTopicPreviewPage} path={TestProjectsNavigation.TestProjectRunTopicPreviewsPage} />
        </>);
};