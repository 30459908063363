import { action, computed, observable, reaction } from 'mobx';
import { Subject } from 'rxjs';
import BlockChangeHistoryModel from '../types/BlockChangeHistoryModel';
import { ProjectsRootVisualStore } from '../../common/stores';
import { BlockInfo, FormTypeEvent } from '../types';
import FormTypesStore from './FormTypesStore';

const formTypesTreeExpandedKey = 'formTypesTreeExpanded';

type FormTypeEventKey = FormTypeEvent;

export default class FormTypesVisualStore {
    @observable
    isFormTypeCreateDialogVisible: boolean = false;

    @observable
    isFormVariationCreateDialogVisible: boolean = false;

    @observable
    isFormTemplateRegionCreateDialogVisible: boolean = false;

    @observable
    isFormPartCreateDialogVisible: boolean = false;

    @observable
    isFormBlockCreateDialogVisible: boolean = false;

    @observable
    isFormTypeEditDialogVisible: boolean = false;
    
    @observable
    isFormVariationEditDialogVisible: boolean = false;

    @observable
    isFormRegionEditDialogVisible: boolean = false;

    @observable
    treeExpandedKeys: string[] = [];

    @observable
    showAnnotations: boolean = true;

    @observable
    changesHistory: BlockChangeHistoryModel[] = [];

    @observable
    coordinateDelta: number = 0;

    @observable
    formTypeEvents = new Set<FormTypeEventKey>();

    pageChangeSubject: Subject<number> = new Subject();

    highlightedBlocksSubject: Subject<BlockInfo[]> = new Subject();

    @computed
    get arrowkeysStep() {
        if (this.coordinateDelta > 100) {
            return 5;
        }

        if (this.coordinateDelta > 20) {
            return 3;
        }

        return 1;
    }

    @computed
    get hasFormTypeChanges() {
        return this.formTypeEvents.size > 0;
    }

    constructor(private projectsRootStore: ProjectsRootVisualStore, private formTypesStore: FormTypesStore) {
        reaction(
            () => this.projectsRootStore.currentProject,
            () => {
                if (!this.projectsRootStore.currentProject) {
                    return;
                }

                const formTypesTreeExpanded = this.getStoredFormTypesTreeExpanded();

                if (formTypesTreeExpanded && formTypesTreeExpanded.projectId !== this.projectsRootStore.currentProject.id) {
                    this.setTreeExpandedKeys([]);
                }
            }
        ); 
    }

    @action.bound
    toggleAnnotations() {
        this.showAnnotations = !this.showAnnotations;
    }

    @action.bound
    setIsFormTypeCreateDialogVisible(isVisible: boolean) {
        this.isFormTypeCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormVariationCreateDialogVisible(isVisible: boolean) {
        this.isFormVariationCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormPartCreateDialogVisible(isVisible: boolean) {
        this.isFormPartCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormTemplateRegionCreateDialogVisible(isVisible: boolean) {
        this.isFormTemplateRegionCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormBlockCreateDialogVisible(isVisible: boolean) {
        this.isFormBlockCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormTypeEditDialogVisible(isVisible: boolean) {
        this.isFormTypeEditDialogVisible = isVisible;
    }

    @action.bound
    setIsFormVariationEditDialogVisible(isVisible: boolean) {
        this.isFormVariationEditDialogVisible = isVisible;
    }

    @action.bound
    setIsFormRegionEditDialogVisible(isVisible: boolean) {
        this.isFormRegionEditDialogVisible = isVisible;
    }

    @action.bound
    setTreeExpandedKeys(keys: string[]) {
        this.treeExpandedKeys = keys;

        if (!this.projectsRootStore.currentProject) {
            return;
        }

        sessionStorage.setItem(formTypesTreeExpandedKey, JSON.stringify({ projectId: this.projectsRootStore.currentProject.id, keys }));
    }

    @action.bound
    logFormTypeEvent(formTypeEvent: FormTypeEventKey) {
        if (!this.formTypeEvents.has(formTypeEvent)) {
            this.formTypeEvents.add(formTypeEvent);
        }
    }

    @action.bound
    clearFormTypeEvents() {
        this.formTypeEvents.clear();
    }

    @action.bound
    setBlockChangesHistory(history: BlockChangeHistoryModel[]) {
        this.changesHistory = history;
    }

    @action.bound
    addBlockChangeHistory(history: BlockChangeHistoryModel) {
        if (this.changesHistory.length >= 10) {
            this.changesHistory.shift();
        }

        this.changesHistory.push(history);
    }

    @action.bound
    getPreviousHistoryItem() {
        if (this.changesHistory.length <= 1) {
            return this.changesHistory[0];
        }

        return this.changesHistory.pop();
    }

    @action.bound
    setCoordinateDelta(delta: number) {
        this.coordinateDelta = delta;
    }
    
    @action.bound
    changePage(page: number) {
        this.pageChangeSubject.next(page);
    }

    getStoredFormTypesTreeExpanded() {
        const formTypesTreeExpanded = sessionStorage.getItem(formTypesTreeExpandedKey);

        return formTypesTreeExpanded ? JSON.parse(formTypesTreeExpanded) : null;
    }

    shouldConfirmUnsavedRegionBlocks(page: number) {
        return this.formTypeEvents.has('region_block_added') && this.formTypesStore.getUnsavedRegionBlocksInfo(page - 1).length > 0;
    }

    confirmUnsavedRegionBlocks(page: number, actionMessage: string): Promise<boolean> {
        return new Promise(resolve => {
            if (!this.shouldConfirmUnsavedRegionBlocks(page)) {
                resolve(true);
                return;
            }

            const unsavedRegionBlocks = this.formTypesStore.getUnsavedRegionBlocksInfo(page - 1);
            this.formTypesStore.setSelectedBlockInfo(undefined);
            this.highlightedBlocksSubject.next(unsavedRegionBlocks);
            
            setTimeout(() => {
                resolve(confirm(`Highlighted region ${unsavedRegionBlocks.length === 1 ? 'block' : 'blocks'} have not been created or assigned. ${actionMessage}`));
                this.highlightedBlocksSubject.next([]);
            }, 300);
        });
    }

    async confirmFormTypeChanges(page: number, actionMessage: string): Promise<boolean> {
        if (!this.hasFormTypeChanges) {
            return true;
        }
    
        if (this.shouldConfirmUnsavedRegionBlocks(page)) {
            return await this.confirmUnsavedRegionBlocks(page, actionMessage);
        }
    
        return confirm(actionMessage);
    }
}