// @ts-ignore
import ReconnectingWebSocket from 'reconnectingwebsocket';
import { listen } from '@sourcegraph/vscode-ws-jsonrpc';
import {
    MonacoLanguageClient, CloseAction, ErrorAction,
    MonacoServices, createConnection, IConnection,
    LogMessageNotification
} from 'monaco-languageclient';
import normalizeUrl from 'normalize-url';
import { VersionedTextDocumentIdentifier } from 'vscode-languageserver-types';
import { ProtocolToMonacoConverter } from 'monaco-languageclient/lib/monaco-converter';
// @ts-ignore
import uuid from 'uuid/v1';
import _ from 'lodash';
import * as monaco from 'monaco-editor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const orig = ProtocolToMonacoConverter.prototype.asMarkdownString;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
ProtocolToMonacoConverter.prototype.asMarkdownString = function (content: any) {
    if (!content) {
        return { value: '' };
    }

    return orig(content);
};

type State = {
    domElement?: HTMLDivElement;
    editor?: monaco.editor.IStandaloneCodeEditor;
    binder?: MonacoEditorBinder
};

class MonacoEditorBinder {
    private static servicesIndex?: symbol;

    private boundEditor?: monaco.editor.IStandaloneCodeEditor;

    private connection: IConnection;

    private model: monaco.editor.ITextModel;

    private disposed: boolean = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private webSocket: any;

    private readyState: Promise<void>;

    bindEditor(editor: monaco.editor.IStandaloneCodeEditor, code: string): Promise<void> {
        this.readyState = new Promise<void>((resolve) => {
            this.boundEditor = editor;

            if (MonacoEditorBinder.servicesIndex) {
                window[MonacoEditorBinder.servicesIndex] = null;
                MonacoServices.install(editor);
            } else {
                const props = Object.getOwnPropertySymbols(window);
                MonacoServices.install(editor);
                const props2 = Object.getOwnPropertySymbols(window);
                const diff = _.difference(props2, props);
                MonacoEditorBinder.servicesIndex = diff[0];
            }        

            // create the web socket
            const url = this.createUrl();
            const webSocket = this.createWebSocket(url);
            // listen when the web socket is opened
            listen({
                webSocket,
                onConnection: connection => {
                    if (this.disposed) {
                        return;
                    }

                    // create and start the language client
                    const languageClient = this.createLanguageClient(connection);
                    languageClient.onReady().then(() => {
                        if (this.disposed) {
                            return;
                        }

                        languageClient.onNotification(LogMessageNotification.type, r => {                                    
                            if (this.disposed || this.model) {
                                return;
                            }
                                
                            if (r.message === 'Update project: JE.Alpha.DotNet.Shared.EntryPoint') {
                                this.createModel(code);
                                resolve();
                            }
                        });
                    }              
                    );
                
                    const disposable = languageClient.start();
                    connection.onClose(() => {
                        disposable.dispose();
                    });
                }
            });
        });

        return this.readyState;
    }

    loadCode(code: string): Promise<void> {
        return this.readyState.then(() => this.createModel(code));
    }

    dispose() {
        this.disposed = true;
        if (this.model) {
            this.model.dispose();
        }       

        this.webSocket.close();

        // if (this.connection) {
        //     this.connection.dispose();
        // }
    }

    private createModel(code: string) {
        if (this.disposed) {
            return;
        }

        // const uri =  monaco.Uri.parse('file:///Users/Sman/Projects/Alpha/solution/dotnetcore/JE.Alpha.DotNet.Shared/src/JE.Alpha.DotNet.Shared.Exceptions/ErrorMessage1.cs');
        const name = uuid();
        const uri =  monaco.Uri.parse(`file:///src/JE.Alpha.DotNet.Shared/src/JE.Alpha.DotNet.Shared.EntryPoint/${name}.cs`);
        
        this.connection.didSaveTextDocument({
            textDocument: VersionedTextDocumentIdentifier.create(uri.toString(), null),
            text: code
        });

        const prev = this.boundEditor!.getModel();
        if (prev) {
            this.connection.didSaveTextDocument({
                textDocument: VersionedTextDocumentIdentifier.create(prev.uri.toString(), null),
                text: ''
            });

            this.connection.didCloseTextDocument({
                textDocument: VersionedTextDocumentIdentifier.create(prev.uri.toString(), null)
            });

            prev.dispose();
        }
        
        this.model = monaco.editor.createModel(code, 'csharp', uri);       
        this.boundEditor!.setModel(this.model);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private createLanguageClient(connection: any): MonacoLanguageClient {
        return new MonacoLanguageClient({
            name: 'C# Language Client',
            clientOptions: {
                // use a language id as a document selector
                documentSelector: ['C#'],
                // disable the default error handler
                errorHandler: {
                    error: () => ErrorAction.Continue,
                    closed: () => CloseAction.DoNotRestart
                }
            },
            // create a language client connection from the JSON RPC connection on demand
            connectionProvider: {
                get: (errorHandler, closeHandler) => {
                    this.connection = createConnection(connection, errorHandler, closeHandler);
                    return Promise.resolve(this.connection);
                }
            }
        });
    }
    
    private createUrl(): string {
        const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
        const url = (process.env.REACT_APP_LSP_URL as string).replace(location.protocol, protocol);
        return normalizeUrl(url);
    }
    
    private createWebSocket(url: string): WebSocket {
        const socketOptions = {
            maxReconnectionDelay: 10000,
            minReconnectionDelay: 1000,
            reconnectionDelayGrowFactor: 1.3,
            connectionTimeout: 10000,
            maxRetries: Infinity,
            debug: false
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.webSocket = new ReconnectingWebSocket(url, undefined, socketOptions);
        return this.webSocket;
    }    
}

const editorInstance = Symbol('SharedEditorInstance');

export class SharedInstanceManager {
    getOrCreateDomElement(container: HTMLDivElement) {
        const { domElement } = this.globalState;

        if (domElement) {
            container.appendChild(domElement);
            return domElement;
        } else {
            const editorElement = document.createElement('div');
            editorElement.style.width = '100%';
            editorElement.style.height = '100%';

            container.appendChild(editorElement);

            global[editorInstance] = {
                domElement: editorElement,
                ...global[editorInstance]
            };

            return editorElement;
        }
    }   

    getOrCreateEditor(creator: () => monaco.editor.IStandaloneCodeEditor): monaco.editor.IStandaloneCodeEditor {
        let { domElement, editor } = this.globalState;

        if (!editor) {
            editor = creator();
            global[editorInstance] = {
                domElement: domElement,
                editor: editor,
                ...global[editorInstance]
            };
        }

        return editor;
    }

    loadCodeIntoEditor(code: string): Promise<void> {
        let { binder, editor } = this.globalState;
        code = code || '';

        if (binder) {
            return binder.loadCode(code);
        }

        binder = new MonacoEditorBinder();
        global[editorInstance] = {
            binder: binder,
            ...global[editorInstance]
        };

        return binder.bindEditor(editor!, code);
    }

    detachEditor() {
        const { domElement } = this.globalState;

        if (domElement) {
            const div = domElement as HTMLDivElement;
            if (div.parentNode) {                
                div.parentNode!.removeChild(div);
            }
        }
    }

    get globalState(): State {
        return global[editorInstance] || {};
    }    
}