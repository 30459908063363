import * as React from 'react';

export interface FlexContentContainerProps {
    header?: React.ReactNode;
    content?: React.ReactNode;
    style?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    contentCssClass?: string;
    id?: string
}

type Props = FlexContentContainerProps;

export default class FlexContentContainer extends React.Component<Props, object> {
    render() {
        const { header, content, style, contentStyle, contentCssClass, id } = this.props;
        return (
            <div className={'flexbox-parent'} style={style}>
                {header && <div className={'flexbox-item header'}>{header}</div>}
                <div className={'flexbox-item fill-area content flexbox-item-grow'}>
                    <div id={id} className={contentCssClass ? `${contentCssClass} flexbox-item-grow` : 'fill-area-content flexbox-item-grow'} style={contentStyle}>
                        {content || this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
