import { ColumnProps } from 'antd/lib/table';
import * as React from 'react';
import {  observer, Observer } from 'mobx-react-lite';
import {  ReferenceDataVisualStore } from '../stores';
import { STORE_REFERENCE_DATA, STORE_REFERENCE_DATA_USAGE } from '../constant';
import { Popconfirm, Button, Layout, Row, Col, Tooltip, Form } from 'antd';
import type { ValueMapping } from '../services/ReferenceDataService';
import  NewTableModal  from './NewTableModal';
import  NewRecordModal  from './NewRecordModal';
import SplitterLayout from 'react-splitter-layout';
import  EditTableModal from './EditTableModal';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import ImportRefData from './ImportRefData';
import ImportedDataDialog from './ImportedDataDialog';
import  ReferenceDataHeader from './ReferenceDataHeader';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';
import { RefDataConstants } from '../stores/ReferenceDataVisualStore';
import { VirtualTable } from '../../common/components';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { MutipleProjectsRefDataUsageDialog } from '.';
import { SingleProjectRefDataUsageDialog } from '.';
import { ReferenceDataTree } from '.';
import { CreateEditTableGroupDialog } from '.';
import ReferenceDataUsageStore from '../stores/ReferenceDataUsageStore';
import useTableScrollHeight from '../../common/hooks/useTableScrollHeight';

type Props = {
    [STORE_REFERENCE_DATA]: ReferenceDataVisualStore;
    [STORE_REFERENCE_DATA_USAGE]: ReferenceDataUsageStore
};

const { Content } = Layout;

export const ReferenceDataTable: React.FC<Props> = ({ ReferenceDataUI: store, ReferenceDataUsageUI: referenceDataUsageStore }) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = React.useState<string | undefined>(undefined);

    // const isEditing = (record: ValueMapping) => {
    //     return record.Id === editingKey;
    // };

    const save = (id: string) => {
        form.validateFields().then((values) => {
            const matched = Object.values(values as object)[0].match(new RegExp('[^ ]'));
            if (!matched) {
                return;
            }
            store.updateTableRecord(id, values);
            setEditingKey(undefined);
        }).catch(e => {
            console.log(e);
        });
    };

    const columns: ColumnProps<ValueMapping>[] = [
        ...store.columns.map((k: string) => {
            return ({ title: k, key: k, dataIndex: k,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                sorter: (a: any, b: any) => { 
                    return a[k] && a[k].localeCompare(b[k]); 
                }
            });
              
        }),{
            title: 'Last update date',
            dataIndex: 'updDate',
            sorter: (a, b) => { 
                const aDate = new Date(`${a[RefDataConstants.ALPHA_SYSTEM_UPDATE_DATE]} UTC`);
                const bDate = new Date(`${b[RefDataConstants.ALPHA_SYSTEM_UPDATE_DATE]} UTC`);

                return aDate && bDate && aDate.getTime() - bDate.getTime(); 
            },
            render: (value, record) => {
                return(
                    <div className="update-info">
                        {record[RefDataConstants.ALPHA_SYSTEM_UPDATED_BY] ?
                            // eslint-disable-next-line max-len
                            `Updated at: ${new Date(`${record[RefDataConstants.ALPHA_SYSTEM_UPDATE_DATE]} UTC`).toLocaleString()} by ${record[RefDataConstants.ALPHA_SYSTEM_UPDATED_BY]}` : ''}
                    </div>);
            },
            width:300

        },
        {
            dataIndex: 'operation',
            render: (text, record) => {
                const editable = record.Id === editingKey;
                return editable ? (
                    <span style={{float: 'right', marginTop: -8}}>
                        <Button
                            style={{marginRight: 8}}
                            type="primary"
                            onClick={() => save(record.Id)}
                        >
                        Save
                        </Button>
                        <Button
                            onClick={() => {
                                setEditingKey(undefined); store.setNewRecordMode(false); 
                            }}
                        >
                        Cancel
                        </Button>
                    </span>
                ) : (
                    <HasPermission entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanEditIotaConnections}>
                        <div className="table-operation">
                            <Button
                                size="small"
                                type="link"
                                onClick={() => {
                                    form.setFieldsValue(record);
                                    setEditingKey(record.Id); 
                                }}
                            >
                                <i className="alpha-icon lg edit-icon" />
                            </Button>
                            <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => store.deleteRecord(record.Id)}
                            >
                                <Button
                                    size="small"
                                    type="link"
                                >
                                    <i className="alpha-icon lg delete-icon" />
                                </Button>
                            </Popconfirm>
                        </div>
                    </HasPermission>
                );
            },
        }
    ];

    const editableColumns = columns
        .filter(c => store.selectedTable || c.dataIndex !== 'updDate')
        .map(col => {
            if (col.dataIndex === 'operation') {
                col.width = 180;
                return col;
            }
            return {
                ...col,
            };
        });

    const { scrollHeight } = useTableScrollHeight();

    const layout = (
        <Layout className="screen-size" style={{...{height: '100%', overflowX: 'hidden'}}}>
            <LayoutHeader  
                subtitle={Utils.getSubtitle(store!.currentProject)}
                title="Reference Data" 
                helpMessage="A repository of important information for smart decision-making"
                buttons={[  
                    <HasPermission entityId={store?.currentProject?.id} key="add-button" permissionClaim={AppPermissions.CanEditIotaConnections}>
                        <Button
                            size="large"
                            disabled={!store!.currentConnectionId}
                            type="primary"
                            onClick={() => store.setCreateTableGroupDialogVisible(true)}
                        >
                            Create group
                        </Button>
                        
                        <Button 
                            size="large"
                            disabled={!store!.currentConnectionId} 
                            type="primary" 
                            onClick={() => store.setAddNewTableDialogVisible(true)} 
                        >Add reference table
                        </Button>
                    </HasPermission>]}
            />
            <Layout>
                <Content>
                    <ReferenceDataHeader ReferenceDataUI={store} ReferenceDataUsageUI={referenceDataUsageStore}/>
                    <MutipleProjectsRefDataUsageDialog
                        store={referenceDataUsageStore}
                        selectedTable={store.selectedTable}
                    />
                    <SingleProjectRefDataUsageDialog
                        store={referenceDataUsageStore}
                        connectionName={store.currentConnection?.name ?? ''}
                        onExport={tableName => store.exportTable(tableName)}
                    />
                    <EditTableModal ReferenceDataUI={store} />
                    <CreateEditTableGroupDialog store={store} />
                    {store!.addNewTableDialogVisible && <NewTableModal ReferenceDataUI={store}/>}
                    {store.isLoading ? <LoadingIndicator/> :
                        <div className="reference-data-table">
                            <SplitterLayout percentage secondaryInitialSize={80}>
                                <ReferenceDataTree store={store} />
                                {store.isTableLoading ? <LoadingIndicator/> : <div style={{paddingLeft: 12}}> 
                                    <Observer>
                                        {() => (
                                            <>
                                                <Row style={{marginBottom: 20}}>
                                                    <Col span={16}>
                                                        <div className="table-header-title">{store.selectedTable}</div>
                                                    </Col>
                                                    <Col span={8}>
                                                        <HasPermission entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanEditIotaConnections}>
                                                            <div className="ref-data-table-action-controls">
                                                                <Tooltip title="Reference data usage in rules">
                                                                    <Button 
                                                                        disabled={!store!.selectedTable || !store!.currentConnectionId}
                                                                        type="link" 
                                                                        size="small"
                                                                        onClick={() => referenceDataUsageStore.setMutipleProjectsDialogVisible(true)}
                                                                    > 
                                                                        <i className="alpha-icon lg label-icon-gray" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title="Add New Record">
                                                                    <Button 
                                                                        disabled={!store!.selectedTable || !store!.currentConnectionId}
                                                                        type="link" 
                                                                        size="small"
                                                                        onClick={() => store!.setAddNewRecordDialogVisible(true)}
                                                                    > 
                                                                        <i className="alpha-icon lg plus-icon" />
                                                                    </Button>
                                                                </Tooltip>

                                                                <Tooltip title="Export">
                                                                    <Button 
                                                                        data-testid="export-refdata"
                                                                        size="small"
                                                                        type="link" 
                                                                        onClick={() => store.exportTable(store!.selectedTable)} 
                                                                        disabled={!store!.selectedTable}
                                                                    > 
                                                                        <i className="alpha-icon lg export-gray-icon" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <ImportRefData  ReferenceDataUI={store}/>
                                                                <ImportedDataDialog  ReferenceDataUI={store}/>
                                                                <NewRecordModal ReferenceDataUI={store}/>
                                                            </div>
                                                        </HasPermission>
                                                    </Col>
                                                </Row>
                                                <Form form={form}>
                                                    <VirtualTable
                                                        data-testid="ref-data-record-table"
                                                        className="ref-data-record-table alpha-portal-table"
                                                        dataSource={store.filteredValues}
                                                        rowClassName={() => 'editable-row'}
                                                        columns={editableColumns as ColumnProps<ValueMapping>[]}
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        rowKey={m => (m as any).Id!}
                                                        pagination={false}
                                                        scroll={{y: scrollHeight}}
                                                        editableRowKey={editingKey}
                                                        editableKeys={store.currentTableFields}
                                                        rowKeyColumn="Id"
                                                    />
                                                </Form>
                                            </>
                                        )}
                                    </Observer>
                                </div>}
                            </SplitterLayout>
                        </div>}
                </Content>
            </Layout>
        </Layout>
    );

    return layout;
    
};
export default observer(ReferenceDataTable);
