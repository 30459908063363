import TagRuleBase from './TagRuleBase';
import { observable } from 'mobx';
import { ElasticSearchQueryRuleResult } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export default class ElasticSearchQueryRule extends TagRuleBase<ElasticSearchQueryRule, ElasticSearchQueryRuleResult> {
    @observable
    query: string;

    @observable
    operator: 'Or' | 'And';

    @observable
    minimumShouldMatch: number;

    @observable
    phraseSlope: number;

    @observable
    excludedBlockTypes: string[];

    constructor(id: string | null, projectId: string | null, name: string, tagId: string | null, tag: string, query: string, 
                minimumShouldMatch: number, operator: 'Or' | 'And', phraseSlope: number, 
                // eslint-disable-next-line max-len
                pipeline: PipelineStep<PipelineStepType>[] = [], groupId: string | null, updateDate?: Date, priority: number = 1, description?: string, state?: 'Enabled' | 'Disabled',
                status: RuleStatus = RuleStatus.Valid, overridePriority: boolean = false, excludedBlockTypes: string[] = []
    ) {
        super(id, projectId, name, tagId, tag, 'ElasticSearchQueryRule', pipeline, groupId, updateDate, priority, description, state, status, overridePriority);
        this.query = query;
        this.minimumShouldMatch = minimumShouldMatch;
        this.operator = operator;
        this.phraseSlope = phraseSlope;
        this.excludedBlockTypes = excludedBlockTypes || [];
    }

    clone(): ElasticSearchQueryRule {
        return new ElasticSearchQueryRule(this.id, this.projectId, this.name, this.tagId, this.tag, this.query, this.minimumShouldMatch, 
            // eslint-disable-next-line max-len
            this.operator, this.phraseSlope, this.clonePipeline(), this.groupId, this.updateDate, this.priority, this.description, 
            this.state, this.status, this.overridePriority, this.excludedBlockTypes);
    }

    validateState(): string[] {
        if (!this.name || !this.query || !this.operator || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): ElasticSearchQueryRuleResult {
        const json: ElasticSearchQueryRuleResult = {
            ruleType: this.ruleType,
            id: this.id,
            groupId: this.groupId,
            name: this.name,
            phraseSlope: this.phraseSlope,
            operator: this.operator,
            minimumShouldMatch: this.minimumShouldMatch,
            query: this.query,
            tagId: this.tagId,
            tag: this.tag,
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority,
            excludedBlockTypes: this.excludedBlockTypes
        };

        return json;
    }
}