import { Button, Modal, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AlphaConfig } from '../../administration/models/AlphaConfig';
import { UploadDocuments } from '.';

type Props = {
    visible: boolean;
    changeVisible: (visible: boolean) => void;
    alphaConfigs: AlphaConfig[]
};

const UploadDocumentsDialog: React.FC<Props> = ({visible, changeVisible, alphaConfigs}) => {
    const [form] = Form.useForm();
    return(
        <Modal
            title="Upload documents"
            visible={visible}
            className="alpha-portal-dialog two-columns"
            centered
            closable={false}
            maskClosable={false}
            onCancel={() => changeVisible(false)}
            width={600}
            footer={[
                <Button
                    key="upload-documents-dialog-close"
                    data-id="upload-documents-dialog-close"
                    className="light"
                    size="large"
                    onClick={() => changeVisible(false)}
                >
                    Close
                </Button>
            ]}
        >
            <UploadDocuments form={form} alphaConfigs={alphaConfigs} />
        </Modal>
    );
};

export default observer(UploadDocumentsDialog);