import * as React from 'react';
import './MatchedConditionLabel.less';

interface Props {
    conditionName: string
}

const MatchedConditionLabel: React.FC<Props> = ({ conditionName }) => {
    return (
        <div className="matched-condition-label-container">
            <span>{`Condition '${conditionName}' matched`}</span>
        </div>
    );
};

export default MatchedConditionLabel;
