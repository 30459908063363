import { RootStores } from '../../common/stores';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import { STORE_HISTORY } from './constants';
import HistoryStore from './HistoryStore';
import HistoryService from '../services/HistoryService';

export function injectHistoryStores(obj: RootStores) {
    const historyService = new HistoryService();

    obj[STORE_HISTORY] = new HistoryStore(obj[STORE_PROJECTS_ROOT_UI], historyService);
}

export type HistoryStores = {
    [STORE_HISTORY]?: HistoryStore
};
