import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tree, Dropdown, Menu, Popconfirm, Spin } from 'antd';
import { AdministrationStores } from '../stores';

const { TreeNode } = Tree;

type Props = AdministrationStores;

export const UsersTree: React.FC<Props> = ({ ProjectAssignment: store }) => {
    if (!store || !store?.users || !store?.groups || !store?.groupUsersDict) {
        return store?.isLoading ? <Spin /> : null;
    }

    const menu = (id: string, name: string) => {
        return (
            <Menu data-id-name={`context-menu-${name}`} data-id={`iota-tree-node-appDef-context-menu-${id}`}>
                <Menu.Item
                    data-id={`users-tree-node-context-menu-${id}-rename`}
                    key="1"
                    onClick={() => store.handleRenameGroupClick(id)}
                >
                    Rename
                </Menu.Item>
                <Menu.Item
                    data-id={`users-tree-node-context-menu-${id}-rename-delete`}
                    key="2"
                    style={{ color: 'red' }}
                >
                    <Popconfirm
                        title="Are you sure that you want to delete this group?"
                        okText="Yes"
                        onConfirm={() => {
                            store.deleteGroup(id);
                        }}
                        cancelText="No"
                    >
                        Delete
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );
    };

    const getGroupName = (id: string) => {
        const groupName = store.groups.find(g => g.usersGroup.id === id)!.usersGroup.name;
        return (
            <Dropdown key={id + 'd'} overlay={() => menu(id, groupName)} trigger={['contextMenu']}>
                <span data-id={id} data-id-name={groupName}>
                    {groupName}
                </span>
            </Dropdown>
        );
    };

    const getUserName = (userName: string) => {
        return (
            <span data-id={store.getUserIdByUsername(userName)} data-id-name={userName}>
                {userName}
            </span>
        );
    };

    return (
        <Tree
            data-id="project-assignments-users-tree"
            className="alpha-portal-tree no-indent"
            key="1"
            draggable
            onSelect={keys => store.handleNodeSelection(keys.length ? (keys[0] as string) : null)}
            onDrop={store.handleNodeDrop}
            selectedKeys={store && store.currentSelectedNode ? [store.currentSelectedNode] : undefined}
            switcherIcon={<i className="alpha-icon lg expand-icon" />}
            expandedKeys={store.treeExpandedKeys}
            onExpand={(keys: string[]) => store.setTreeExpandedKeys(keys)}
        >
            {Object.keys(store.filteredGroupUsersDict).map(k => {
                return (
                    <TreeNode title={getGroupName(k)} key={k}>
                        {store.filteredGroupUsersDict[k]
                            .filter(id => store.filteredUsers.some(u => u.id === id))
                            .map(x => store.getUserName(x))
                            .map(userName => {
                                return (
                                    <TreeNode key={store.getUserIdByUsername(userName)} title={getUserName(userName)} />
                                );
                            })}
                    </TreeNode>
                );
            })}

            {!!store.ungroupedUsernames.length && (
                <TreeNode title="Ungrouped users" key="ungGrouped" selectable={false}>
                    {store.ungroupedUsernames.map(userName => {
                        return <TreeNode key={store.getUserIdByUsername(userName)} title={getUserName(userName)} />;
                    })}
                </TreeNode>
            )}
        </Tree>
    );
};

export default observer(UsersTree);
