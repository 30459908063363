import { Progress, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

type Props = {
    isLoadingPackages: boolean;
    loadingPackagesProgress: number;
    loadingPackagesErrorMessage?: string
};

const ProjectPackagesLoadingIndicator: React.FC<Props> = ({isLoadingPackages, loadingPackagesProgress, loadingPackagesErrorMessage}) => {
    if (!isLoadingPackages) {
        return null;
    }

    return (
        <Tooltip title="Loading packages... Please wait">
            <div className="packages-loading-indicator">
                <Progress percent={loadingPackagesProgress} size="small" status={loadingPackagesErrorMessage ? 'exception' : undefined} />
                <br />
            </div>
        </Tooltip>
    );
};

export default observer(ProjectPackagesLoadingIndicator);