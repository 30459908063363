import MLStoragePage from '../screens/MLStoragePage';
import * as React from 'react';
import { ProtectedRoute } from '../../common/components';

export const MlStoragePagesNavigation = {
    MLStoragePage: '/projects/:id/mlstorage'
};

export default () => {
    return (
        <React.Fragment>
            <ProtectedRoute component={MLStoragePage} path={MlStoragePagesNavigation.MLStoragePage} />       
        </React.Fragment>
    );
};