import { Button, Checkbox, Form } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import UsersManagerStore from '../stores/UsersManagerStore';

type Props = {
    store: UsersManagerStore;
    userId: string | null;
    initialRoles: string[] | null;
    successCallback: () => void
};

export const UserRoleEditModal: React.FC<Props> = ({store, userId, initialRoles, successCallback}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!store.roles) {
            store.init();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        form.setFieldsValue({roles: initialRoles});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialRoles]);

    const handleCancel = () => {
        form.resetFields();
        store.setIsUserRoleEditDialogVisible(false);
    };

    const handleSubmit = () => {
        const roles = form.getFieldValue('roles');
        const projectId = store.selectedProjectId;
        if (!userId || !projectId) {
            return; 
        }

        store.updateObjectRoleAssignments(userId, projectId, 'project', roles);      
        handleCancel();
        successCallback();
    };

    return (
        <Modal 
            title="Edit roles"
            closable={false}
            visible={store.isUserRoleEditDialogVisible}
            className="alpha-portal-dialog"
            onCancel={handleCancel}
            centered
            maskClosable={false}
            destroyOnClose
            footer={[
                <Button 
                    data-id="object-role-assignments-dialog-cancel" 
                    key="back" 
                    onClick={handleCancel}
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="object-role-assignments-dialog-submit" 
                    key="submit" 
                    type="primary" 
                    onClick={handleSubmit}
                    size="large"
                >
                    Save
                </Button>
            ]}
        >
            <Form form={form} layout='vertical' colon={false} data-id="object-role-assignments-form">
                <Form.Item name="roles" label="Roles">
                    <Checkbox.Group 
                        className="vertical-checkbox-group"
                        options={store.roles ? store.roles.map(r => r.name) : []}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(UserRoleEditModal);