import * as React from 'react';
import { IReactionDisposer, reaction } from 'mobx';
import { observer, inject, Observer } from 'mobx-react';
import { Row, Col, Pagination, InputNumber, Checkbox, Select, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import PackagesList from '../../project_management/components/PackagesList';
import SearchText from './SearchText';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { InteractiveLabelsVisualStore } from '../stores';
import { STORE_PROJECT_INTERACTIIVE_LABELS } from '../constants';
import _ from 'lodash';
import { PackageSource } from '../../common/models/PackageSource';
import { PackageState } from '../../common/models';
import { BLOCK_TYPE } from '../../common/types';

const FormItem = Form.Item;
const Option = Select.Option;

type Props = { [STORE_PROJECT_INTERACTIIVE_LABELS]: InteractiveLabelsVisualStore };

class InteractiveLabelerFilter extends React.Component<Props, {scale: number}> {
    private store: InteractiveLabelsVisualStore;
    private formRef: React.RefObject<FormInstance> = React.createRef();
    private reactions: IReactionDisposer[] = [];

    constructor(props: Props) {
        super(props);
        
        this.state = {scale: this.props[STORE_PROJECT_INTERACTIIVE_LABELS].scale * 100};
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handlePackageSourceCheck = this.handlePackageSourceCheck.bind(this);
        this.handleSearch = _.debounce(this.handleSearch.bind(this), 500);
        this.handleTagsChange = this.handleTagsChange.bind(this);
    }

    componentDidMount() {
        this.store.loadProjectPackages();
        this.store.subscribeToPackageChanges();

        this.reactions.push(
            reaction(
                () => this.store.blockTypes,
                blockTypes => {
                    if (this.formRef.current) {
                        this.formRef.current.setFieldsValue({ blockTypes });
                    }
                }
            )
        );
    }

    componentWillUnmount() {
        this.store.unsubscribeFromPackageChanges();
        this.reactions.forEach(disposer => disposer());
    }

    handleOnChange(checkedValue: CheckboxChangeEvent) {
        this.store.filterMarkedPackages(checkedValue.target.checked);
        const store = this.props.projectInteractiveLabelsUI!;
        store.loadProjectPackages();
    }

    handleSearchOptionOnChange(checkedValue: CheckboxChangeEvent) {
        this.store.setSearchOptions(checkedValue.target.checked);
    }

    handlePackageSourceCheck(checkedValue: CheckboxChangeEvent) {
        this.store.handlePackageSourceCheck(checkedValue.target.checked);
        const store = this.props.projectInteractiveLabelsUI!;
        store.loadProjectPackages();
    }

    getInitialValues() {
        const store = this.props.projectInteractiveLabelsUI!;
  
        return {
            blockTypes: store.blockTypes,
            tags: store.currentTags
        };
      
    }

    handleSearch(term: string) {
        const store = this.props.projectInteractiveLabelsUI!;
        store.setSearchTerm(term);

        if (term.length > 2 || term.length === 0) {
            store.loadProjectPackages();
        }
    }

    handleTagsChange(tags: string[]) {
        const store = this.props.projectInteractiveLabelsUI!;
        store.setTags(tags);
        store.loadProjectPackages();
    }

    render() { 
        const store = this.props.projectInteractiveLabelsUI!;
        this.store = store;
        const tags = store.tags;
        const searchTextDisabled = this.store.selectedPackage == null;

        const handleScaleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                try {                    
                    const scale = parseInt(e.currentTarget.value, 10) ? _.clamp(parseInt(e.currentTarget.value, 10), 50, 100) : 100;
                    store.setScale(scale);
                    this.setState({scale: scale});
                } catch {
                    console.log('Incorrect input format');
                }                
            }
        };
        
        return (
            <Form
                ref={this.formRef}
                className="ant-advanced-search-form"
                initialValues={this.getInitialValues()}
                style={{borderBottom: '1px solid #eae2e2'}}
            >
                <Row gutter={10} style={{marginBottom: 10}}>
                    <Col span={9} style={{ display: 'block'}}>
                        <FormItem name="package">
                            <PackagesList
                                dataId="interactive-labels-package-select"
                                size="default"
                                currentPackage={store.selectedPackage!}
                                packages={store!.filteredPackages
                                    .filter(p =>
                                        store!.isPackageSourceChecked
                                            ? p.source === PackageSource.Portal || p.source === null
                                            : true
                                    )
                                    .filter(x => x.serverState === PackageState.Ready)}
                                onPackageSelected={p => store.setPackage(p!)}
                                loading={store.loading}
                                onSearch={this.handleSearch}
                            />         
                        </FormItem>
                    </Col>
                    <Col span={5} style={{ display: 'block' }}>
                        <FormItem 
                            name="blockTypes"
                            rules={[ {
                                validator: (rule, value, callback) => {
                                    if (value) {
                                        if (value.length > 2) {
                                            value.splice();
                                            callback('No more than 2 tags');
                                        } else if (value.length <= 2) {
                                            callback();
                                        }
                                    }
                                    return;
                                }
                            }]}
                        >
                            <Select mode="tags" onChange={(values: BLOCK_TYPE[]) => store.changeBlockTypes(values)} virtual={false} placeholder="Blocks...">
                                <Option value="LINE_BLOCK">LINE</Option>
                                <Option value="TEXTBOX_BLOCK">TEXTBOX</Option>
                                <Option value="TABLE_BLOCK">TABLE</Option>
                                <Option value="CELL_BLOCK">CELL</Option>
                                <Option value="HORIZONTAL_LINE_BLOCK">HORIZONTAL</Option>
                                <Option value="CLUSTER_BLOCK">CLUSTER</Option>
                                <Option value="HORIZONTAL_MULTILINE_BLOCK">HORIZONTAL_MULTILINE</Option>
                                <Option value="PARAGRAPH_BLOCK">PARAGRAPH</Option>
                                <Option value="PAGE_BLOCK">PAGE</Option>
                                <Option value="WORD_BLOCK">WORD</Option>
                                <Option value="DOCUMENT_TABLE_BLOCK">DOCUMENT_TABLE</Option>
                                <Option value="FORM_ITEM_BLOCK">FORM_ITEM_BLOCK</Option>
                                <Option value="SMART_BLOCK">SMART</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        <FormItem
                            name="tags"
                        >
                            <Observer>
                                {() => (
                                    <Select 
                                        value={store.currentTags} 
                                        data-id="interactive-labels-tags-filter-select" 
                                        mode="tags" 
                                        onChange={this.handleTagsChange} 
                                        placeholder="Tags..."
                                    >
                                        {tags.map((k, i) => (
                                            <Option data-id={`interactive-labels-tags-filter-select-${k}`} key={`kw-${i}`} value={k}>{k}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Observer>
                           
                        </FormItem>
                    </Col>
                    <Col span={2}>
                        <FormItem >
                            <Checkbox checked={store.showUserNotReviewed} onChange={this.handleOnChange}>Not reviewed</Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={3}>
                        <FormItem >
                            <Checkbox checked={store.isPackageSourceChecked} onChange={this.handlePackageSourceCheck}>Source Portal (Only)</Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={10} style={{marginBottom: 10}}>
                    <Col span={9}>
                        <FormItem>
                            <SearchText 
                                changePage={store.changePage}
                                searchForAutocomplete={store.searchForAutocomplete.bind(store)}
                                autocompleteSource={store.autocompleteSource}
                                searchTerm={store.searchTerm}
                                selectedPackage={store.selectedPackage}
                                disabled={searchTextDisabled}
                            />
                        </FormItem>
                    </Col>         
                    <Col span={5} style={{textAlign: 'left'}}>
                        <FormItem >
                            <Checkbox
                                onChange={(checkedValue) => this.handleSearchOptionOnChange(checkedValue)}
                            >
                                    Current Page only
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={8} style={{textAlign: 'right'}}>
                        <FormItem name="search">
                            <Pagination 
                                current={store!.currentPage + 1} 
                                total={store!.totalPages} 
                                defaultPageSize={1}
                                style={{display: store.totalPages !== 0 ? 'block' : 'none'}}
                                onChange={p => store!.setCurrentPage(p - 1)}
                            />
                        </FormItem>
                    </Col>         
                    <Col span={2} style={{textAlign: 'right'}}>
                        <FormItem>
                            <InputNumber
                                value={this.state.scale}
                                min={50}
                                max={100}
                                precision={0}
                                formatter={value => `${value}%`}
                                onKeyUp={handleScaleKeyUp}
                                onChange={(v) => this.setState({scale: (v as number) || this.store.scale * 100})}
                                parser={value => Number(value!.replace('%', ''))}
                                style={{display: store.totalPages !== 0 ? 'inline-block' : 'none', width: 'unset'}}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default inject(STORE_PROJECT_INTERACTIIVE_LABELS)(observer(InteractiveLabelerFilter));