import { Button, Collapse, Popconfirm, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { HasPermission } from '../../authorization/components/HasPermission';
import permissions, { AppPermissions } from '../../authorization/Permissions';
import UsersManagerStore from '../stores/UsersManagerStore';

type Props = {
    store: UsersManagerStore
};

const CustomerRoleList: React.FC<Props> = ({store}) => {
    const openRoleEditDialog = (event: React.MouseEvent, id: string, name: string, rolePermissions: string[]) => {
        event.stopPropagation();
        store.setCustomRoleDialogModel({id, name, permissions: rolePermissions});
        store.setCustomRoleDialogVisible(true);
    };

    const copyRole = (name: string, rolePermissions: string[]) => {
        store.saveCustomRole(name + '-Copy', rolePermissions);
    };

    const deleteRole = (id: string) => {
        store.deleteCustomRole(id);
    };

    const extra = (id: string, name: string, rolePermissions: string[]) => (
        <HasPermission permissionClaim={AppPermissions.CanManageUsers}>
            <Tooltip title="Edit role">
                <Button 
                    type="link" 
                    onClick={(e) => openRoleEditDialog(e, id, name, rolePermissions)} 
                    size="small"
                    data-id-cells="Edit role"
                    data-id-name={name} 
                >
                    <i className="alpha-icon lg edit-icon" />
                </Button>
            </Tooltip>
            
            <Tooltip title="Copy role">
                <span onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                        id="data-id-popconfirm-box-Copy role"
                        title={`Are you sure you want to create copy of ${name}?`}
                        onConfirm={() => copyRole(name, rolePermissions)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button 
                            type="link" 
                            size="small"
                            data-id-cells="Copy role"
                            data-id-name={name} 
                        >
                            <i className="alpha-icon lg copy-icon" />
                        </Button>
                    </Popconfirm>
                </span>
            </Tooltip>

            <Tooltip title="Delete role">
                <span onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                        id="data-id-popconfirm-box-Delete role"
                        title={`Are you sure you want to delete role ${name}?`}
                        onConfirm={() => deleteRole(id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button 
                            type="link" 
                            size="small"
                            data-id-cells="Delete role"
                            data-id-name={name} 
                        >
                            <i className="alpha-icon lg delete-icon" />
                        </Button>
                    </Popconfirm>
                </span>
            </Tooltip>
        </HasPermission>
    );

    const defaultRoleExtra = (name: string, rolePermissions: string[]) => (    
        <HasPermission permissionClaim={AppPermissions.CanManageUsers}>  
            <Tooltip title="Copy role">
                <span onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                        id="data-id-popconfirm-box-Copy role"
                        title={`Are you sure you want to create copy of ${name}?`}
                        onConfirm={() => copyRole(name, rolePermissions)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button 
                            type="link" 
                            size="small"
                            data-id-cells="Copy role"
                            data-id-name={name} 
                        >
                            <i className="alpha-icon lg copy-icon" />
                        </Button>
                    </Popconfirm>
                </span>
            </Tooltip>
        </HasPermission>
    );

    return (
        <div data-id="table-Roles-management">
            <Collapse>
                <Collapse.Panel  header={<span data-id-name="Group Name">Admin</span>} key='Admin' extra={defaultRoleExtra('Admin', permissions.admin)}>
                    <div data-id-name="Role list">
                        {permissions.admin.map(p => (
                            <div key={'Admin' + p}>{p}</div>
                        ))}
                    </div>
                </Collapse.Panel>
                <Collapse.Panel header={<span data-id-name="Group Name">Editor</span>} key='Editor' extra={defaultRoleExtra('Editor', permissions.editor)}>
                    <div data-id-name="Role list">
                        {permissions.editor.map(p => (
                            <div key={'Editor' + p}>{p}</div>
                        ))}
                    </div>
                </Collapse.Panel>
                <Collapse.Panel header={<span data-id-name="Group Name">User</span>} key='User' extra={defaultRoleExtra('User', permissions.user)}>
                    <div data-id-name="Role list">
                        {permissions.user.map(p => (
                            <div key={'User' + p}>{p}</div>
                        ))}
                    </div>
                </Collapse.Panel>
                <Collapse.Panel header={<span data-id-name="Group Name">Client App User</span>} key='ClientApp' extra={defaultRoleExtra('Client App User', permissions.client_app)}>
                    <div data-id-name="Role list">
                        {permissions.client_app.map(p => (
                            <div key={'ClientApp' + p}>{p}</div>
                        ))}
                    </div>
                </Collapse.Panel>
                <Collapse.Panel 
                    header={<span data-id-name="Group Name">Isolated user</span>} 
                    key='IsolatedUser' 
                    extra={defaultRoleExtra('Isolated user', permissions.isolated_user)}
                >
                    <div data-id-name="Role list">
                        {permissions.isolated_user.map(p => (
                            <div key={'IsolatedUser' + p}>{p}</div>
                        ))}
                    </div>
                </Collapse.Panel>
                {store.customRoles.map(r => (
                    <Collapse.Panel header={<span data-id-name="Group Name">{r.name}</span>} key={r.id} extra={extra(r.id, r.name, r.permissions)}>
                        <div data-id-name="Role list">
                            {r.permissions.map(p => (
                                <div key={r.id + p}>{p}</div>
                            ))}
                        </div>
                    </Collapse.Panel>
                ))}
            </Collapse>
        </div>
    );
};

export default observer(CustomerRoleList);