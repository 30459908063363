import * as React from 'react';
import {
    Input, Select, Form, Row, Col, FormInstance
} from 'antd';
import { inject } from 'mobx-react';
import { NamedEntitiesRecognitionRule } from '../models';
import { RuleEditVisualStore } from '../stores';
// @ts-ignore
import entityTypesData from './ner.json';
import { RuleModelSelectWrapped } from './RuleModelSelect';
import { NRE_MODEL_TYPE } from './ParametersNERModelEditor';
import { InferenceModelsService } from '../services';
import { RuleLabelsSelectWrapped } from './RuleLabelsSelect';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';
import RuleEditGeneralSettings from './RuleEditGeneralSettings';
import { STORE_RULE_EDIT } from '../constants';
import RuleType from '../types/RuleType';
import { observer } from 'mobx-react-lite';
import { FieldData } from 'rc-field-form/lib/interface';

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance
};

const RuleEditNamedEntitiesRecognitionRuleForm: React.FC<Props> =  ({store, selectType, form})=> {
    const rule = store.editableRule as NamedEntitiesRecognitionRule;
    const [selectedModelId, setSelectedModelId] = React.useState(rule.modelId);
    React.useEffect(() => {
        if (!rule.id) {
            form.setFieldsValue({blockType:  'LINE_BLOCK',pageRange: '0-4', entities: []});
        }
        form.setFieldsValue(getInitialValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, rule]);
    const modelService = new InferenceModelsService();

    const handleFieldsChange = (changedFields: FieldData[]) => {
        const modelIdChange = changedFields.find(f => f.name[0] === 'modelId');
        if (modelIdChange) {
            setSelectedModelId(modelIdChange.value);
        }
    };

    const getInitialValues = () => {
        if (!rule.id && !rule.blockType && !rule.pageRange) {
            rule.blockType = 'LINE_BLOCK';
            rule.pageRange = '0-4';
        }

        return {
            name: rule.name,
            tag: rule.tag,
            blockType: rule.blockType,
            pageRange: rule.pageRange,
            modelId: rule.modelId,
            entities: rule.entities,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority
        };
    };

    return (
        <>
            {store.error.map((e, i) => <h4 key={e + i} style={{ color: 'red' }}>{e}</h4>)}
            <Form 
                form={form}
                layout="vertical"
                className="rule-edit-form"
                data-id="rule-edit-form"
                onFieldsChange={handleFieldsChange}
            >
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form}/>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Block Type"
                            name="blockType"
                            rules={[{
                                required: true, message: 'Please input block type!',
                            }]}
                            data-id="rule-block-type-select"
                        >
                            <SelectWhenScroll virtual={false}>
                                <Select.Option value="LINE_BLOCK">LINE</Select.Option>
                                <Select.Option value="TEXTBOX_BLOCK">TEXTBOX</Select.Option>
                                <Select.Option value="TABLE_BLOCK">TABLE</Select.Option>
                                <Select.Option value="CELL_BLOCK">CELL</Select.Option>
                                <Select.Option value="HORIZONTAL_LINE_BLOCK">HORIZONTAL</Select.Option>
                                <Select.Option value="CLUSTER_BLOCK">CLUSTER</Select.Option>
                                <Select.Option value="HORIZONTAL_MULTILINE_BLOCK">HORIZONTAL_MULTILINE</Select.Option>
                                <Select.Option value="PARAGRAPH_BLOCK">PARAGRAPH</Select.Option>
                                <Select.Option value="PAGE_BLOCK">PAGE</Select.Option>
                                <Select.Option value="WORD_BLOCK">WORD</Select.Option>
                                <Select.Option value="DOCUMENT_TABLE_BLOCK">DOCUMENT_TABLE</Select.Option>
                                <Select.Option value="FORM_ITEM_BLOCK">FORM_ITEM_BLOCK</Select.Option>
                                <Select.Option value="SMART_BLOCK">SMART</Select.Option>
                            </SelectWhenScroll>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Page range"
                            name="pageRange"
                            rules={[{
                                pattern: new RegExp('^[0-9]*([,-][0-9]*)*$'), message: 'Please input valid page range! (Numbers, hyphens and commas only)'
                            }]}
                            data-id="rule-page-range-input"
                        >
                            <Input />
                        </Form.Item>   
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Model"
                            name="modelId"
                            rules={[{
                                required: true, message: 'Please input model id!',
                            }]}
                            data-id="rule-model-select"
                        >
                            <RuleModelSelectWrapped 
                                type={NRE_MODEL_TYPE} 
                                dataId="inference-rule-model-select" 
                                service={modelService} 
                                projectId={store.currentProject ? store.currentProject.id : undefined}
                            />
                        </Form.Item>       
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Entities"
                            name="entities"
                            rules={[{
                                required: true, message: 'Please input entities!',
                            }]}
                            data-id="rule-entities-select"
                        >
                            <RuleLabelsSelectWrapped 
                                dataId="rule-entities-select" 
                                service={modelService} 
                                selectedModelId={selectedModelId} 
                                defaultValues={entityTypesData} 
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default (inject(STORE_RULE_EDIT)(observer(RuleEditNamedEntitiesRecognitionRuleForm)));