import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Input, Upload, message, Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { IotaAppStores } from '../stores';
import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons';

type Props = IotaAppStores;

interface Hash {
    [details: string]: string
}

export const ApplicationDefinitionEdit: React.FC<Props> = observer(({ projectApplicationDefinitionsUI: store }) => {
    const [form] = Form.useForm();
    const [fileLoading, setFileLoading] = React.useState(false);
    const [imageUrl, setimageUrl] = React.useState('');
    const appDefStore = store!;

    React.useEffect(() => {
        store!.loadIotaInputsMetadata();
    },              [store]);

    React.useEffect(() => {
        if (appDefStore.currentAppDef && appDefStore.currentAppDef.name) {
            form.setFieldsValue({ name: appDefStore.currentAppDef.name });
        }
    }, [appDefStore.currentAppDef, form, store]);

    React.useEffect(() => {
        let hasCancelled = false;       
        if (store!.currentAppDef && store!.currentAppDef!.iconFileId) {     
            setFileLoading(true);
            store!.getApplicationIcon().then(
                val => hasCancelled ? () => {
                    return; 
                } : setimageUrl(URL.createObjectURL(val) || ''),
                error => hasCancelled ? () => {
                    return; 
                } : () => console.log(error)
            );      
            setFileLoading(false);
        }

        return () => {
            hasCancelled = true;
            setimageUrl('');
        };
    },              [store]);

    const handleSubmit = async(values: Hash) => {
        await store!.editApplicationDefinition(store!.currentAppDef!.id, values.name);
    };

    const beforeUpload = (file: RcFile) => {
        store!.setNewIcon(file);
        var reader = new FileReader();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reader.onload = function (e: any) {
            setimageUrl(e.target.result);
        };

        reader.readAsDataURL(file);
        return false;
    };

    const handleFileChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        setFileLoading(true);
        if (status !== 'uploading') {
            setFileLoading(false);
        }

        if (status === 'done') {
            setFileLoading(false);
        } else if (status === 'error') {
            setFileLoading(false);
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const uploadButton = (
        <div>
            {fileLoading ? <LoadingOutlined /> : <i className="alpha-icon lg plus-icon" />}
        </div>
    );

    return (
        <Form id="edit-iota-app-form" data-id="iota-application-edit-form" layout="vertical" form={form} onFinish={handleSubmit}>
            <FormItem
                label={(
                    <span>
                            Application name&nbsp;
                        <Tooltip title="Application name for alpha">
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    </span>
                )}
                name="name"
                rules={[
                    { required: true, message: 'Please input application name!', whitespace: true }
                ]}
            >
                <Input />
            </FormItem>
            <FormItem
                label={(
                    <span>
                            Application icon&nbsp;
                    </span>
                )}
                valuePropName="fileList"
            > { process.env.NODE_ENV === 'test' ? '' :
                    <Upload
                        name="application-icon"
                        accept=".jpeg,.jpg,.png"
                        listType="picture-card"
                        className="icon-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleFileChange}
                        
                    >
                        {imageUrl && imageUrl.trim() !== '' ? <img src={imageUrl} alt="application-icon" /> : uploadButton}
                    </Upload>}
            </FormItem>
        </Form>
    );
});

export default ApplicationDefinitionEdit;
