import { observable, action, reaction } from 'mobx';
import { GlobalAdministrationService } from '../service/GlobalAdministrationService';
import { ProjectDocumentCountModel } from '../types';

export const timeOptions = [
    {
        value: 5000,
        label: '5 seconds'
    },
    {
        value: 10000,
        label: '10 seconds'
    },
    {
        value: 20000,
        label: '20 seconds'
    }
];

export const defaultIntervalTime = timeOptions[0].value;

export class TechnicalInformationStore {
    @observable
    documentCountLoading: boolean = false;

    @observable
    projectsDocumentCount: ProjectDocumentCountModel[] = [];

    @observable
    jobsCountLoading: boolean = false;

    @observable
    jobsCount: number = 0;

    @observable
    clearingQueue: boolean = false;

    @observable
    fetchIntervalTime: number = defaultIntervalTime;

    fetchInterval: ReturnType<typeof setInterval> | null = null;

    constructor(private readonly service: GlobalAdministrationService) {
        reaction(
            () => this.fetchIntervalTime,
            () => {
                if (this.fetchInterval) {
                    this.clearFetchInterval();
                    this.setupFetchInterval();
                }
            }
        );
    }

    @action.bound
    initialize() {
        this.documentCountLoading = true;
        this.jobsCountLoading = true;
        Promise.all([this.getProjectDocumentsCount(), this.getJobsCount()]).then(() => this.setupFetchInterval());
    }

    @action.bound
    dispose() {
        this.documentCountLoading = false;
        this.jobsCountLoading = false;
        this.projectsDocumentCount = [];
        this.jobsCount = 0;
        this.fetchIntervalTime = defaultIntervalTime;
        this.clearFetchInterval();
    }

    @action.bound
    setupFetchInterval() {
        this.fetchInterval = setInterval(() => {
            this.getProjectDocumentsCount();
            this.getJobsCount();
        }, this.fetchIntervalTime);
    }

    @action.bound
    clearFetchInterval() {
        if (this.fetchInterval) {
            clearInterval(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    @action.bound
    setFetchIntervalTime(fetchIntervalTime: number) {
        this.fetchIntervalTime = fetchIntervalTime;
    }

    @action.bound
    async getProjectDocumentsCount() {
        try {
            const resp = await this.service.getProjectDocumentsCount();

            if (!resp.isOk) {
                return;
            }

            resp.map(projectsDocumentCount => (this.projectsDocumentCount = projectsDocumentCount));
        } finally {
            this.documentCountLoading = false;
        }
    }

    @action.bound
    async getJobsCount() {
        try {
            const resp = await this.service.getJobsCount();

            if (!resp.isOk) {
                return;
            }

            resp.map(jobsCount => (this.jobsCount = jobsCount));
        } finally {
            this.jobsCountLoading = false;
        }
    }

    @action.bound
    async clearQueue() {
        this.clearingQueue = true;

        try {
            await this.service.clearQueue();

            this.jobsCount = 0;
        } finally {
            this.clearingQueue = false;
        }
    }
}
