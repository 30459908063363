import { Alert, Button, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TopicSelectionStep } from '.';
import { TestProjectDashboardStore, TestProjectWizardStore } from '../stores';

type Props = {
    store: TestProjectWizardStore;
    dashboardStore: TestProjectDashboardStore
};

const TestProjectTopicsEditDialog: React.FC<Props> = ({store, dashboardStore}) => {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const handleCancel = () => {  
        store.setTopicsEditDialogVisible(false);
        store.resetWizard();
        setErrorMessage(null);
    };

    const handleSave = () => {
        const noTagsSelected = store.selectedTopicTags.length === 0 && store.topicInputsType === 'Tags';
        const noTopicsSelected = store.selectedInputBindings.length === 0 && store.topicInputsType === 'Bindings';
        if (noTagsSelected && noTopicsSelected) {
            setErrorMessage('Please select at least one topic!');
        } else {
            setErrorMessage(null);
            store.updateTestProjectTopics().then((successful) => {
                if (successful) {
                    handleCancel();
                    dashboardStore.loadTestProject();
                }
            }).catch(() => {
                // Do nothing
            });
        }
    };

    return (
        <Modal
            visible={store.topicsEditDialogVisible}
            title={store.currentTestProject ? `Edit topics for ${store.currentTestProject.name}` : 'Edit topics'}
            onCancel={handleCancel}
            maskClosable={false}
            centered
            destroyOnClose
            width={850}
            footer={[
                <Button
                    key="save-topics" 
                    type="primary"
                    onClick={handleSave}
                    size="large"
                >
                    Save
                </Button>
            ]}
        >
            {errorMessage && <Alert message={errorMessage} style={{marginBottom: 12}} type="error" />}
            <TopicSelectionStep store={store}/>
        </Modal>
    );
};

export default observer(TestProjectTopicsEditDialog);