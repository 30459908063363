/* eslint-disable @typescript-eslint/no-shadow */
import { Table, Checkbox, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import * as React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { STORE_RULES_IMPORT } from '../constants';
import { RulesImportVisualStore } from '../stores';
import { RuleName } from '../types';
import { TagModel } from '../models/TagModel';

type Props = {
    [STORE_RULES_IMPORT]: RulesImportVisualStore;
    importForIota: boolean
};

const Option = Select.Option;
export const RulesImportTable: React.FC<Props> = ({ RulesImportUI: store, importForIota }) => {

    const onTagSelectChange = ( selectedKeys: string[]) => {
        store!.handleTagsSelection(selectedKeys);
    };

    const onRuleSelectChange = (isChecked: boolean, recordId: string) => {
        store!.handleRulesSelection(isChecked, recordId);
    };

    const expandedRowRender = (tag: TagModel) => {
        const expandedColumns: ColumnProps<RuleName>[] = [
            {  key: 'check', render: (_: string, record: RuleName) => ( 
                <Observer>
                    {() => (
                        <Checkbox 
                            checked={([...store!.selectedRulesKeys, ...store!.selectedRulesKeysByTag]).includes(record.id)} 
                            onChange={(e) => onRuleSelectChange(e.target.checked, record.id!)}
                            disabled={store.selectedTagKeys.includes(tag.name)}
                            style={importForIota ? {display: 'none'} : {marginRight: 20}}
                        />)}
                </Observer>
            ), width: 10},
            { title: 'Rule', key: 'name', dataIndex: 'name'},
            { dataIndex: 'warning', width: 50, render: (_: string, record: RuleName) =>  {
                return (
                    <i className="alpha-icon md rule-problem-icon tag-with-broken-rule" style={{display: store.duplicatedRules.includes(record.name) 
                        ? 'block' : 'none'}}/>
                );
            }}
        ];

        return (
            <Observer>
                {() => (
                    <Table
                        className="rule-import-nested-rule-table expanded-table"
                        rowKey="id"
                        columns={expandedColumns}
                        dataSource={store!.rules[tag.name]}
                        pagination={false}
                        showHeader={false}
                    />
                )}
            </Observer>
        );
    };
    
    const getGroupsDropdown = (tag: string | null) => {
        const groups = store.getGroups();
        const group = store.getGroupIfExists(tag);
        const groupName = group?.name || store.assignedGroupName;
        return (
            <div id="rule-import-table" onClick={(e) => e.stopPropagation()}>
                <Observer>
                    {() => (
                        <Select 
                            disabled={!!groupName} 
                            style={{width: 250}} 
                            placeholder={groupName ?  groupName : 'Please select'} 
                            showSearch
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onSelect={(_: string, option: any) => store.handleGroupSelection(tag, option.value as string)}
                            value={tag && store.tagGroupDict[tag] != null ? store.tagGroupDict[tag]! : undefined}
                            filterOption={(input, option) => option?.title && option.title.toLowerCase().includes(input.toLowerCase())}   
                            options={!group ? groups.map(x => ({value: x.id!, label: x.name, title: x.name})) : [{value: group.id!, label: group.name, title: group.name}]}
                        />
                    )}
                </Observer>
                
            </div>
        );

    };

    const getActionsDropdown = (tagName: string) => {
        return (
            <Observer>{() => (
                <div onClick={(e) => e.stopPropagation()}>
                    <Select 
                        disabled={store.isActionDisabled(tagName)}
                        style={{width: 150}} 
                        value={store.tagActionDict[tagName]}
                        onChange={(val: 'Add'|'Replace') => store.handleActionSelection(tagName, val)}
                    >
                        {['Add', 'Replace'].map(x => <Option key={x} value={x}>{x}</Option>)}
                    </Select>
                </div>)}
            </Observer>
        );

    };

    const columns: ColumnProps<TagModel>[] = [
        { title: 'Tag', key: 'name', dataIndex: 'name', width: 450},
        { title: 'Status', key: 'status', dataIndex: 'status', render: (_: string, record: TagModel) => (
            store.getRuleTags().find(x => x.name === record.name) ? <i className="alpha-icon md status-existing"/> : <i className="alpha-icon md status-new"/>  
        ), width: 100},
        { title: getGroupsDropdown(null), key: 'groupName', dataIndex: 'groupName',  render: (_: string, record: TagModel) => {
            return {children: getGroupsDropdown(record.name), props: {
                style: {paddingTop: 0, paddingBottom: 0}
            } }; 
        }
        },
        { title: 'Action', key: 'action', dataIndex: 'action',  render: (_: string, record: TagModel) => {
            return {children: getActionsDropdown(record.name), props: {
                style: {paddingTop: 0, paddingBottom: 0}
            } }; 
        }},
        { dataIndex: 'warning', width: 50,
            render: (_: string, record: TagModel) =>  {
                return (
                    <i className="alpha-icon md rule-problem-icon tag-with-broken-rule" style={{display: store.tagsWithDuplicatedRules.includes(record.name) 
                        ? 'block' : 'none'}}/>
                );
            }
        }
    ];

    return (
        <Observer>{() => (
            <>
                <div className="validation-message" style={{display: store.tagsWithDuplicatedRules.length ? 'block' : 'none', marginBottom: 10}}>
                    <div>
                        <i className="alpha-icon md rule-problem-icon"/>
                        <span>Some rules have duplicated names</span>
                    </div>
                </div>
                <Table
                    className="alpha-portal-table rule-import-table"
                    columns={columns}
                    rowKey="name"
                    rowClassName={(record) => store.expandedKeys.includes(record.name!) ? 'expanded rule-import-table-row' : 'rule-import-table-row'}
                    rowSelection={ !importForIota ? { selectedRowKeys: store.selectedTagKeys, onChange: onTagSelectChange, columnWidth: 39} : undefined}
                    pagination={false}
                    expandIcon={(props) => <i className="alpha-icon xs expand-row arrow-expand"  onClick={(e) => {
                        props.onExpand(props.record, e); 
                    }}/>}
                    expandRowByClick
                    onExpand={(expanded: boolean, record: TagModel) => store.setExpandedKeys(expanded, record)}
                    dataSource={store!.tags}
                    expandedRowRender={expandedRowRender}
                />
            </>)}
        </Observer>
    );
};
export default observer(RulesImportTable);
