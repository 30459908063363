import { History } from 'history';
import { RouterStore as BaseRouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { action, observable, reaction, runInAction } from 'mobx';
import { matchPath } from 'react-router';

export class RouterStore extends BaseRouterStore {
    @observable 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any;

    @observable
    sideCollapsed: boolean = false;

    @observable 
    path?: string;

    @observable 
    url?: string;

    @observable
    isFooterHidden: boolean = false;
    
    pathname: string = '';

    prevPathname: string = '';

    constructor(history?: History) {
        super();
        if (history) {
            this.history = syncHistoryWithStore(history, this);
            this.history.listen(listener => {
                this.prevPathname = this.pathname;
                this.pathname = listener.pathname;
            });
        }

        reaction(() => this.location, (l) => {
            const match = matchPath(l.pathname, '', null);

            runInAction(() => {
                if (match) {
                    this.params = match.params;
                    this.url = match.url;
                    this.path = match.path;
                } else {
                    this.params = undefined;
                    this.path = undefined;
                    this.url = undefined;
                }
            });
        });
    }

    @action.bound
    setSideCollapsed(collapsed: boolean) {
        this.sideCollapsed = collapsed;
    }

    @action.bound
    setIsFooterHidden(isHidden: boolean) {
        this.isFooterHidden = isHidden;
    }


    @action
    pushToHistory(path: string) {
        this.push(path);
    }

    @action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setRouter(params: any, path: string, url: string) {
        this.params = params;
        this.path = path;
        this.url = url;
    }
}

export default RouterStore;