import { observable, computed, action } from 'mobx';
import { ProjectsService } from '../../common/services';

export enum ProjectExportPart {
    Packages = 'Packages',
    Rules = 'Rules',
    FormTemplates = 'FormTemplates',
    PackagesForFormTemplates = 'PackagesForFormTemplates',
    ProjectBindings = 'ProjectBindings',
    MlModels = 'MlModels',
    IOTAApps = 'IOTAApps',
    WFConnections = 'WFConnections',
    RDConnections = 'RDConnections',
    InstructWorkflows = 'InstructWorkflows'
}

export const options = [
    {
        label: 'Packages',
        value: ProjectExportPart.Packages
    },
    {
        label: 'Rules',
        value: ProjectExportPart.Rules
    },
    {
        label: 'Form Templates',
        value: ProjectExportPart.FormTemplates
    },
    {
        label: 'Project Bindings',
        value: ProjectExportPart.ProjectBindings
    },
    {
        label: 'ML Models',
        value: ProjectExportPart.MlModels
    },
    {
        label: 'IOTA Applications',
        value: ProjectExportPart.IOTAApps
    },
    {
        label: 'WF Connections',
        value: ProjectExportPart.WFConnections
    },
    {
        label: 'RD Connections',
        value: ProjectExportPart.RDConnections
    },
    {
        label: 'Instruct Workflows',
        value: ProjectExportPart.InstructWorkflows
    }
];

export const optionValues = options.map(o => o.value);

export default class ProjectExportStore {
    @observable
    projectId: string | null = null;

    @observable
    dialogVisible: boolean = false;

    @observable
    selectedOptions: string[] = optionValues;

    @observable
    selectAll: boolean = true;

    @computed
    get exportParts() {
        const exportParts = [...this.selectedOptions];

        if (exportParts.includes(ProjectExportPart.FormTemplates)) {
            exportParts.push(ProjectExportPart.PackagesForFormTemplates);
        }

        return exportParts;
    }

    constructor(private readonly service: ProjectsService) {}

    @action.bound
    setProjectId(projectId: string | null) {
        this.projectId = projectId;
    }

    @action.bound
    setDialogVisible(dialogVisible: boolean) {
        this.dialogVisible = dialogVisible;
    }

    @action.bound
    setSelectedOptions(selectedOptions: string[]) {
        this.selectedOptions = selectedOptions;
    }

    @action.bound
    setSelectAll(selectAll: boolean) {
        this.selectAll = selectAll;
    }

    @action.bound
    dispose() {
        this.setProjectId(null);
        this.setDialogVisible(false);
        this.setSelectedOptions(optionValues);
        this.setSelectAll(true);
    }

    @action.bound
    async exportProject() {
        if (!this.projectId) {
            return;
        }

        await this.service.exportProject(this.projectId, this.exportParts);

        this.dispose();
    }
}
