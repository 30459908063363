/* eslint-disable @typescript-eslint/member-ordering */
import { computed, action, observable, runInAction, reaction, IReactionDisposer } from 'mobx';
import RulePreviewVisualStore from './RulePreviewVisualStore';
import PipelineVisualStore from './PipelineVisualStore';
import { ProjectsRootVisualStore, RouterStore } from '../../common/stores';
import RulesPipelineVisualStore from './RulesPipelineVisualStore';
import { ElasticSearchMatchPhraseRule, ElasticSearchQueryRule, ElasticSearchRawRule, InferenceRule, NamedEntitiesRecognitionRule, PipelineStep } from '../models';
import type { RuleTypes } from '../models';
import { RefDataRule, ElasticSearchMatchRule } from '../models';
import RulesStore from './RulesStore';
import { RulesPagesNavigation } from '../routes';
import PipelineStepType from '../types/PipelineStepType';
import { FieldData } from 'rc-field-form/lib/interface';
import { InferenceModelsService } from '../services';
import type RuleType from '../types/RuleType';
import { message } from 'antd';
import _ from 'lodash';
// import { INFERENCE_MODEL_TYPES } from '../components/ParametersModelEditor';
import InferenceModel from '../types';
import SmartIndexRule from '../models/SmartIndexRule';

export default class RuleEditVisualStore {
    @observable
    editableRule?: RuleTypes;

    @observable
    pipelineStore?: PipelineVisualStore<PipelineStepType>;

    @observable
    rule?: RuleTypes;

    @observable
    previewVisualStore: RulePreviewVisualStore | null;

    @observable
    isLoading: boolean;

    @observable
    allowPreview: boolean = false;

    @observable
    currentTabsKey: string = '1';

    @observable
    error: string[] = [];

    @observable
    saveAsDialogVisible: boolean = false;

    @observable
    isEditSettingsDialogVisible: boolean = false;

    @observable
    mlModels: InferenceModel[] = [];

    @observable
    isRuleEdited: boolean = false;

    @observable
    isDrawerOpen: boolean = false;

    @observable
    isPreviewPoppedOut: boolean = false;

    isCopy: string = 'false';

    currentGroupId: string | null;

    currentTagId: string | null = null;

    modelService: InferenceModelsService;

    private setTypeReaction: IReactionDisposer | null = null;

    @computed
    get pipeline() {
        return this.editableRule && this.editableRule.pipeline || [];
    }

    @computed
    get connections() {
        return this.store.connections;
    }

    @computed
    get currentProject() {
        return this.projectStore.currentProject;
    }

    constructor(private store: RulesStore, 
                private readonly projectStore: ProjectsRootVisualStore,
                private readonly routerStore: RouterStore, modelService: InferenceModelsService) {
        this.modelService = modelService;
    }

    @action.bound
    setIsPreviewPoppedOut(isPoppedOut: boolean) {
        this.isPreviewPoppedOut = isPoppedOut;
    }

    @action.bound
    setIsDrawerOpen(isOpen: boolean) {
        this.isDrawerOpen = isOpen;
    }

    @action.bound
    async loadModels() {
        if (!this.currentProject) {
            return;
        }

        const modelsResp = await this.modelService.getModels(this.currentProject.id);
        runInAction(() => {
            this.mlModels = modelsResp;
        });
    }

    @action.bound
    setSaveAsDialogVisible(visible: boolean) {
        this.saveAsDialogVisible = visible;
    }

    @action.bound
    setIsEditSettingsDialogVisible(visible: boolean) {
        this.isEditSettingsDialogVisible = visible;
    }

    @action
    async save(rule?: RuleTypes | undefined, navigate: boolean = false) {
        this.error = this.editableRule!.validateState();
        let savedRuleId: string | null = null;
        if (this.error.length) {
            return;
        }
        runInAction(() => this.isLoading = true);

        if (!rule && this.editableRule && this.pipelineStore?.pipeline) {
            this.editableRule.pipeline = this.pipelineStore.pipeline;
        }

        const resp  = await this.store.save(rule ?? this.editableRule!, this.isCopy);
        if (resp?.isOk()) {
            this.editableRule = undefined;
            this.setPreviewState();
            await this.store.getRules();
            this.setIsRuleEdited(false);
            if (navigate) {
                this.routerStore.pushToHistory(RulesPagesNavigation.RulesListPage.replace(':projectId', this.projectStore.currentProject!.id));  
            }
            this.isCopy = 'false';
            savedRuleId = resp.value.id;
        } else {
            message.error('Error while saving rule');
        }
        runInAction(() => {
            this.error = [];
            this.isLoading = false;
        });

        return savedRuleId;
    }

    @action.bound
    resetError() {
        this.error = [];
    }

    @action
    async saveRuleAs(name: string) {
        this.error = this.editableRule!.validateState();
        if (this.error.length || !this.editableRule) {
            return;
        }
        runInAction(() => this.isLoading = true);
        let newRule = this.editableRule.clone();
        newRule.name = name;
        newRule.id = null;
        newRule.tagId = null;
        newRule.groupId = this.store.checkIfTagExists(this.editableRule) || null;
        await this.store.save(newRule);
        this.routerStore.pushToHistory(RulesPagesNavigation.RulesListPage.replace(':projectId', this.projectStore.currentProject!.id));
        runInAction(() => this.isLoading = false);
    }

    @action.bound
    cancel(navigate: boolean = false) {
        this.setPreviewState();
        this.editableRule = undefined;
        this.setIsRuleEdited(false);
        
        if (navigate) {
            setTimeout(() => {
                this.routerStore.pushToHistory(RulesPagesNavigation.RulesListPage.replace(':projectId', this.projectStore.currentProject!.id));
            }, 0);   
        }
        this.isCopy = 'false';
    }

    @action.bound
    setIsRuleEdited(isEdited: boolean) {
        this.isRuleEdited = isEdited;
    }
    
    @action.bound
    applyFields(fields: FieldData[]) {
        if (!this.editableRule) {
            return;
        }

        const rule = _.cloneDeep(this.editableRule);
        const dict = {} as RuleFormFields;
        fields.forEach(f => dict[f.name[0]] = {value: f.value});
        const computedValues = ['connectionId'];
        Object.getOwnPropertyNames(dict).filter(f => !computedValues.includes(f)).forEach(f => rule[f] = dict[f].value);

        if ('connectionId' in dict && 'sqlQuery' in rule) {
            rule.connection = this.connections.find(x => x.id === dict.connectionId!.value) || null;
        } 

        this.setRule(rule);
        this.setPreviewState();
        this.setIsRuleEdited(true);

        if (this.isPreviewPoppedOut) {
            this.refreshRuleInStorage();
        }
    }

    @action.bound
    refreshRuleInStorage() {
        if (this.editableRule) {
            const ruleToPreview = _.cloneDeep(this.editableRule);
            ruleToPreview.pipeline = this.pipelineStore?.pipeline || [];
            localStorage.setItem(`rule-preview-${ruleToPreview.id!}`, JSON.stringify(ruleToPreview));
        }
    }

    @action
    loadData() {
        console.log('Load');
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCurrentGroupId(m: any) {
        this.currentGroupId = m.groupId;
    }

    clearPreselecedData() {
        this.currentGroupId = null;
        this.currentTagId = null;
    }

    setPreselectedData(m: {groupId: string; tagId: string}) {
        this.currentGroupId = m.groupId;
        this.currentTagId = m.tagId;
    }

    @action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async setMatch(m: any) {
        if (this.store.rules.length === 0) {
            reaction(() => this.store.rules, (rules, r) => {
                this.setMatch(m);
                r.dispose();
            });
        } else {   
            this.previewVisualStore = null;
            this.rule = this.store.rules.find(x => x.id === m.id || x.id === m.ruleId);
            if (!this.rule) {
                await this.store.getRules();
                await this.store.loadConnections();
            }

            runInAction(() => {
                if (this.rule) {
                    this.editableRule = this.rule.clone();
                    this.pipelineStore = new RulesPipelineVisualStore(this.editableRule, () => this.setPreviewState(), this.store);
                    this.setPreviewState();
                    this.setIsRuleEdited(false);
                    this.setPreselectedData({groupId: this.rule.groupId!, tagId: this.rule.tagId!});
                    this.isCopy = m.isCopy;
                }
            });
        }
    }

    @action
    setRule(rule?: RuleTypes) {
        this.rule = rule;

        if (this.rule) {
            this.editableRule = this.rule.clone();
            if (!this.pipelineStore) {
                this.pipelineStore = new RulesPipelineVisualStore(this.editableRule, () => this.setPreviewState(), this.store);
            } else {
                const rulesPipelineStore = this.pipelineStore as RulesPipelineVisualStore;
                rulesPipelineStore.setRule(this.editableRule);
            }

            this.setPreviewState();
            this.setIsRuleEdited(false);
        } else {
            this.editableRule = undefined;
            this.pipelineStore = undefined;
            this.previewVisualStore = null;
        }
    }

    @action.bound
    handleTablsChange(key: string) {
        if (key === '2') { 
            this.pipelineStore!.submitPipelineForms();
            if (!this.pipelineStore!.isValidationFailed()) {
                this.setCurrentTabsKey('2');
                this.activatePreview();
                this.resetError();
            } else {
                this.setCurrentTabsKey('1');
            }
           
        } else {
            this.setCurrentTabsKey('1');
            this.activateEdit();
        }
    }

    @action
    activatePreview() {
        if (!this.previewVisualStore) {
            this.previewVisualStore = new RulePreviewVisualStore(this.editableRule!, this.store, this.projectStore);
        } else {
            const rule = this.editableRule!;
            rule.pipeline = this.pipelineStore!.pipeline!;
            this.setRule(rule);
            this.previewVisualStore.setRule(this.editableRule!);

            if (this.isPreviewPoppedOut && this.editableRule) {
                const ruleToPreview = _.cloneDeep(this.editableRule);
                ruleToPreview.pipeline = this.pipelineStore?.pipeline || [];
                localStorage.setItem(`rule-preview-${ruleToPreview.id!}`, JSON.stringify(ruleToPreview));
            }
        }
    }

    @action
    activateEdit() {
        console.debug('Switch to edit');
    }

    @action
    setCurrentTabsKey(key: string) {
        this.currentTabsKey = key;
    }

    async submitPipelineForms() {
        await this.pipelineStore!.submitPipelineForms();
    }

    isValidationFailed() {
        return this.pipelineStore!.isValidationFailed();
    }

    ruleExists(name: string) {
        return !!this.store.rules.find(r=> r.name === name && r.id !== this.editableRule?.id); // && (!this.editableRule?.id && this.editableRule?.name === name);
    }

    private setPreviewState() {
        if (this.editableRule) {            
            this.setIsRuleEdited(true);
            this.allowPreview = !this.editableRule!.validateState().length;
        } else {
            this.allowPreview = false;
        }
    }

    @action.bound
    getRuleOfNewType = (pipeline: PipelineStep<PipelineStepType>[], type: RuleType, rule?: RuleTypes) => { 
        const currentGroupId  = rule ? rule.groupId : this.currentGroupId;
        const projectId = this.store.currentProject && this.store.currentProject.id;
        const ruleName = rule ? rule.name : '';
        const description = rule?.description;
        const overridePriority = rule?.overridePriority;
        const priority = rule?.priority;

        
        let tagId = rule ? rule.tagId : this.currentTagId;
        const tag = tagId ? this.store.ruleTags.find(x => x.id === tagId) : undefined;
        let tagName = tag ? tag.name : rule ? rule.tag : '';

        // In case tag name was changed when switching rule type - reset tagId
        if (rule && tagName !== rule?.tag) {
            tagId = null;
            tagName = rule.tag;
        }

        let newRule: RuleTypes | undefined;

        switch (type) {
        case 'ElasticSearchMatchPhraseRule':
            newRule = new ElasticSearchMatchPhraseRule(
                this.rule?.id ?? null, 
                projectId, 
                ruleName, 
                tagId, 
                tagName, 
                '', 
                0, 
                pipeline, 
                currentGroupId,
                undefined,
                priority, 
                description, 
                undefined, 
                undefined, 
                overridePriority
            );                
            break;
        case 'ElasticSearchMatchRule':
            newRule = new ElasticSearchMatchRule(
                this.rule?.id ?? null, 
                projectId, 
                ruleName, 
                tagId, 
                tagName, 
                '', 
                100, 
                'Or', 
                pipeline, 
                currentGroupId, 
                undefined, 
                priority,
                description,
                undefined, 
                undefined, 
                overridePriority
            );
            break;
        case 'ElasticSearchQueryRule':
            newRule = new ElasticSearchQueryRule(
                this.rule?.id ?? null, 
                projectId, 
                ruleName, 
                tagId, 
                tagName, 
                '', 
                100, 
                'Or', 
                0, 
                pipeline, 
                currentGroupId, 
                undefined, 
                priority, 
                description,
                undefined, 
                undefined,
                overridePriority
            );
            break;
        case 'ElasticSearchRawRule':
            newRule = new ElasticSearchRawRule(
                this.rule?.id ?? null, projectId, ruleName, tagId, tagName, '', pipeline, currentGroupId, undefined, priority, description, undefined, undefined, overridePriority
            );
            break;
        case 'RefDataRule':
            newRule = new RefDataRule(
                this.rule?.id ?? null, 
                projectId, 
                ruleName, 
                tagId, 
                tagName, 
                '', 
                null, 
                pipeline, 
                currentGroupId, 
                undefined, 
                priority, 
                description, 
                undefined, 
                undefined, 
                overridePriority
            );
            break;
        case 'InferenceRule':
            newRule = new InferenceRule(
                this.rule?.id ?? null, 
                projectId, 
                ruleName, 
                tagId, 
                tagName, 
                '', 
                [], 
                pipeline, 
                currentGroupId, 
                '0-4', 
                'LINE_BLOCK', 
                75,
                undefined, 
                priority, 
                description,
                undefined,
                undefined,
                overridePriority
            );
            break;
        case 'NamedEntitiesRecognitionRule':
            newRule = new NamedEntitiesRecognitionRule(
                this.rule?.id ?? null, 
                projectId, 
                ruleName, 
                tagId, 
                tagName, 
                '', 
                [], 
                pipeline, 
                currentGroupId, 
                '0-4', 
                'LINE_BLOCK',
                undefined,
                priority, 
                description,
                undefined,
                undefined,
                overridePriority
            );
            break;
        case 'SmartIndexRule':
            newRule = new SmartIndexRule(
                this.rule?.id ?? null,
                projectId,
                ruleName,
                tagId,
                tagName,
                '',
                '',
                pipeline,
                currentGroupId,
                undefined,
                priority,
                description,
                undefined,
                undefined,
                overridePriority,
                undefined
            );                
            break;
        default:
            newRule = undefined;
        }

        return newRule;
    };

    @action
    selectNewType(pipeline: PipelineStep<PipelineStepType>[], v?: RuleType) {
        if (!v) {
            this.editableRule = undefined;
            this.setRule(undefined);
            return;
        }

        if (!this.store.ruleTags?.length && this.currentTagId) {
            if (this.setTypeReaction) {
                return;
            }

            this.setTypeReaction = reaction(() => this.store.ruleTags, (tags, r) => {
                console.log('reaction');
                if (tags?.length) {
                    this.selectNewType(pipeline, v);
                    r.dispose();
                }
            });
            return;
        }
        this.editableRule = this.getRuleOfNewType(pipeline, v, this.editableRule);
        this.setRule(this.editableRule);
    }
}

type FormField = {
    value: string
};

type RuleFormFields = Readonly<{
    [P in keyof RefDataRule]?: FormField        
}> | Readonly<{
    [P in keyof ElasticSearchMatchRule]?: FormField        
}>;