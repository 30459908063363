import { computed, action, observable } from 'mobx';
import { ProjectsRootVisualStore } from '../../common/stores';
import { ProjectsService } from '../../common/services';
import { InstructWorkflowDto } from '../../instruct_workflows/types';
import InstructWorkflowService from '../../instruct_workflows/services/InstructWorkflowService';

export default class ProjectFormVisualStore {
    @observable
    instructWorkflows: InstructWorkflowDto[] = [];

    @computed
    get project() {
        return this.rootStore.currentProject;
    }

    constructor(
        private readonly rootStore: ProjectsRootVisualStore,
        private readonly projectsService: ProjectsService,
        private readonly instructWorkflowService: InstructWorkflowService
    ) {}

    @action.bound
    setInstructWorkflows(instructWorkflows: InstructWorkflowDto[]) {
        this.instructWorkflows = instructWorkflows;
    }

    @action.bound
    async getInstructWorkflows() {
        if (!this.project) {
            return;
        }

        this.setInstructWorkflows(await this.instructWorkflowService.getWorkflows(this.project.id));
    }

    @action.bound
    async exportProjectFeautureFlags() {
        if (!this.project) {
            return;
        }

        await this.projectsService.exportProjectFeautureFlags(this.project.id);
    }

    saveProject(
        name: string,
        keywords: string[],
        color: string,
        featureFlags: string | null,
        smartIndexSettings: string | null
    ) {
        this.rootStore.updateCurrentProject(name, keywords, color, featureFlags, smartIndexSettings);
    }

    reset() {
        this.rootStore.setProjectEditDialogVisible(false);
    }
}
