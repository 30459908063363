import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { IotaMetadataInput } from '../types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import Checkbox from 'antd/lib/checkbox/Checkbox';

interface Props {
    form: FormInstance;
    input: IotaMetadataInput;
    initialValue: unknown;
    disabled?: boolean
}

export const ApplicationSettingsCheckboxInput: React.FC<Props> = ({ input, initialValue, disabled = false }) => {

    const renderInput = () => {
        return (<Checkbox disabled={disabled} data-id={`iota-edit-application-settings-input-${input.id}`} />);
    };

    return (
        <FormItem
            label={(
                <span>
                    {input.name}&nbsp;
                    <Tooltip title={input.description}>
                        <QuestionCircleOutlined/>
                    </Tooltip>
                </span>
            )}
            name={input.id}
            initialValue={!!initialValue}
            valuePropName="checked"

        >
            {renderInput()}
        </FormItem>
    );
};

export default observer(ApplicationSettingsCheckboxInput);