import { Button, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectApplicationDefinitionsVisualStore } from '../stores';
import WrappedApplicationDefinitionEdit from '../components/ProjectApplicationDefinitionEdit';

type Props = {
    store: ProjectApplicationDefinitionsVisualStore | undefined
};

const EditIotaApplicationDialog: React.FC<Props> = ({store}) => {
    if (!store || !store.currentAppDef) {
        return null;
    }

    const handleCancel = () => {
        store.setEditAppDialogVisible(false);
    };

    return (
        <Modal
            width={600}
            visible={store.editAppDialogVisible}
            title="Edit application"
            maskClosable={false}
            destroyOnClose
            closable={false}
            className="alpha-portal-dialog"
            centered
            footer={[
                <Button className="light" data-id="iota-application-edit-cancel" size="large" key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button form="edit-iota-app-form" data-id="iota-application-edit-submit" size="large" key="submit" type="primary" htmlType="submit">
                  Save changes
                </Button>
            ]}
        >
            <WrappedApplicationDefinitionEdit projectApplicationDefinitionsUI={store} />
        </Modal>
    );
};

export default observer(EditIotaApplicationDialog);