import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore, FormTypesVisualStore } from '../stores';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore
};

const FormTemplateRegionEditDialog: React.FC<Props> = ({store, visualStore}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.formRegionToEdit) {
            form.setFieldsValue({
                name: store.formRegionToEdit.name,
                id: store.formRegionToEdit.regionId
            });
        } else {
            form.resetFields();
        }
    }, [form, store.formRegionToEdit]);

    const handleCancel = () => {
        visualStore.setIsFormRegionEditDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            const formPart = store.formParts.find(part => part.regions.find(region => region.regionId === store.formRegionToEdit?.regionId));

            if (!formPart) {
                return;
            }

            const updatedSuccessfully = await store.updateFormTemplateRegion(formPart.id, values.id, values.name);

            if (updatedSuccessfully) {
                handleCancel();
            }
        }).catch(() => {
            // do nothing
        });
    };

    const validateName = (name: string) => {
        const formPart = store.formParts.find(part => part.regions.find(region => region.regionId === store.formRegionToEdit?.regionId));
        const regions = store.formParts.find(part => part.id === formPart?.id)?.regions.filter(region => region.regionId !== store.formRegionToEdit?.regionId);

        if (regions?.length && regions.find(region => region.name === name)) {
            return Promise.reject('Form region with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            wrapProps={{'data-id': 'Edit form template region'}}
            className="alpha-portal-dialog"
            title="Edit form template region"
            visible={visualStore.isFormRegionEditDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button data-id="form-types-edit-region-dialog-cancel" className="light" key="back" size="large" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button data-id="form-types-edit-region-dialog-submit" key="submit" size="large" type="primary" onClick={handleSubmit}>
                    Save
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item 
                    name="name" 
                    label="Name" 
                    rules={[
                        {required: true, message: 'Provide name for the form type, please'},
                        {validator: (__, value) => validateName(value)}
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="id"
                    hidden
                >
                    <Input type='hidden' />
                </Form.Item>
            </Form>
        </Modal>
    );
};
    

export default observer(FormTemplateRegionEditDialog);