import { HistoryRecord, HistoryAction, HistoryField } from '../services/types';
import { hasNameMeta } from '../misc/helpers';

export default abstract class BaseHistoryRecordModel {
    readonly id: string;
    readonly projectId: string;
    readonly entityId: string;
    readonly entityName: string;
    readonly objectName: string;
    readonly action: HistoryAction;
    readonly fields: HistoryField[];
    readonly userId: string;
    readonly userName: string;
    readonly createDate: string;
    readonly meta: unknown;
    readonly metaFields: HistoryField[] = [];

    constructor(data: HistoryRecord) {
        this.id = data.id;
        this.projectId = data.projectId;
        this.entityId = data.entityId;
        this.entityName = data.entityName;
        this.objectName = data.objectName;
        this.action = data.action;
        this.fields = data.fields;
        this.userId = data.userId;
        this.userName = data.userName;
        this.createDate = data.createDate;
        this.meta = data.meta;

        if (hasNameMeta(this.meta)) {
            this.metaFields.push({ name: 'EntityName', value: this.meta.name });
        }
    }

    abstract get objectLabel(): string;
}
