import * as React from 'react';
import { Tooltip, Input, Button, Form, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import FormItem from 'antd/lib/form/FormItem';
import { ReferenceDataVisualStore } from '../stores';
import { STORE_REFERENCE_DATA } from '../constant';
import { inject } from 'mobx-react';
import { TablefieldValdationRules } from './NewTableModal';

type Props =  {   
    [STORE_REFERENCE_DATA]: ReferenceDataVisualStore
};

export const EditTableModal: React.FC<Props> = ({  ReferenceDataUI: store }) => {
    const [form] = Form.useForm();
    React.useEffect(() => {
        form.setFieldsValue({name: store.editableTable});
    }, [form, store, store.editTableDialogVisible]);

    
    const handleSubmit = (e: React.FormEvent<object>) => {
        e.preventDefault();
        form.validateFields().then((values) => {
            store!.handleTableRename(values.name);
        }).catch((err) => {
            console.log(err);
        });
    };
    
    return(
        <Modal
            title="Edit reference data table"
            visible={store!.editTableDialogVisible}
            maskClosable={false}
            destroyOnClose
            closable={false}
            centered
            footer={[
                <Button  data-id="edit-reference-data-dialog-cancel" key="back" onClick={() => store.setEditTableDialogVisible(false)} size="large" className="light">
                    Cancel
                </Button>,
                <Button  data-id="edit-reference-data-dialog-submit" className="dark" key="submit" size="large" type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            ]}
            onCancel={() => store.setEditTableDialogVisible(false)}
            getContainer={false}
        >
            <Form form={form} layout="vertical"  data-id="edit-reference-data-dialog-form">
                <FormItem
                    label={(
                        <span>
                            Table name&nbsp;
                            <Tooltip title="Reference table name">
                                <i className="alpha-icon md question-icon" style={{verticalAlign: 'middle'}} />
                            </Tooltip>
                        </span>
                    )}
                    name="name"
                    rules={TablefieldValdationRules}
                    initialValue={store.editableTable}
                    
                >
                    <Input />
                </FormItem>
            </Form>
        </Modal>
    );

};

export default inject(STORE_REFERENCE_DATA)(observer(EditTableModal));