/* eslint-disable @typescript-eslint/member-ordering */
import { action, observable, reaction, runInAction, IReactionDisposer, computed } from 'mobx';
import { RouterStore } from '../../../modules/common/stores';
import { RulesStore } from '.';
import type { TagsGroupModel } from '../models/TagsGroupModel';
import { RulesPagesNavigation } from '../routes';
import _ from 'lodash';

type DeleteConfirmationText = {
    [key: string]: string
};

const DELETE_CONFIRMATION_TEXT = 'All tags and rules under this Group will be removed too. Are you sure?';
export default class TagsGroupVisualStore {
    @observable
    addGroupDialogVisible: boolean;

    @observable
    tagsGroups: TagsGroupModel[] = [];

    @observable
    isLoading: boolean;

    @observable
    isTableLoding: boolean;

    @observable
    editableTagsGroup: TagsGroupModel | null = null;

    @observable
    expandedKeys: string[] = [];
    
    @observable
    deleteConfirmationText: DeleteConfirmationText = {};

    disposer: IReactionDisposer;

    @computed
    get rules() {
        return this.rulesStore.rules;
    }

    @computed
    get emptyGroupIds() {
        return this.rulesStore.hiddenTagGroupIds;
    }

    constructor(private rulesStore: RulesStore, private routerStore: RouterStore) {
        reaction(() => this.rulesStore.currentProject, () => {
            this.loadTagsGroups();
        });

        this.rulesStore.newRulesSubject.subscribe((rule) => {            
            if (rule.id) {
                let tagGroup = this.tagsGroups.find(g => g.id === rule.groupId);
                if (tagGroup) {
                    this.setExpandedKeys(true, tagGroup);
                }                
            }
        });

        this.disposer = reaction(() => this.rulesStore.usedTags, () => {
            this.setDeleteConfirmationText();
        });

    }

    @action
    setAddGroupDialogVisible(visible: boolean) {
        this.addGroupDialogVisible = visible;
        if (!visible) {
            this.editableTagsGroup = null;
        }
    }

    @action.bound
    async loadTagsGroups() {
        if (!this.rulesStore.currentProject) {
            return;
        }

        const projectId = this.rulesStore.currentProject!.id;
        this.isLoading = true;
        const groups = await this.rulesStore.getTagsGroups(projectId);
        runInAction(() => {
            this.tagsGroups = groups!;
            this.isLoading = false; 
        });
        this.setDeleteConfirmationText();
    }

    @action.bound
    setExpandedKeys(expanded: boolean, record: TagsGroupModel) {
        if (expanded) {
            this.expandedKeys.push(record.id!);
        } else {
            this.expandedKeys = this.expandedKeys.filter(k => k !== record.id!);
        }
    }

    @action.bound
    async update(tagsGroup: TagsGroupModel) {
        const projectId = this.rulesStore.currentProject!.id;
        await this.rulesStore.createGroup(projectId, tagsGroup.name, tagsGroup.description, tagsGroup.id!);
        await this.loadTagsGroups();
    }

    @action
    edit(record: TagsGroupModel) {
        this.editableTagsGroup = record;
        this.setAddGroupDialogVisible(true);
    }

    @action.bound
    cancel() {
        const projectId = this.rulesStore.currentProject!.id;
        this.routerStore.pushToHistory(RulesPagesNavigation.RulesListPage.replace(':id', projectId));
    }

    @action
    setDeleteConfirmationText() {
        if (this.rulesStore!.usedTags) {
            const tags = Object.keys(this.rulesStore!.groupedByTag);
            this.tagsGroups.forEach(g => {
                const tagsWithinGroup = tags.filter(t => 
                    !!this.rulesStore.groupedByTag[t].find(r => r.groupId === g.id)
                );
                if (tagsWithinGroup && tagsWithinGroup.length && _.intersection(this.rulesStore.usedTags, tagsWithinGroup).length) {
                    // eslint-disable-next-line max-len
                    this.deleteConfirmationText[g.id!] = `You are trying to delete the Rule assigned to Active Application. It will be deactivated... ${DELETE_CONFIRMATION_TEXT} `;
                } else {
                    this.deleteConfirmationText[g.id!] = DELETE_CONFIRMATION_TEXT;
                }
                
            });
        }
    }

    async createGroup(name: string, description: string) {
        const projectId = this.rulesStore.currentProject!.id;
        await this.rulesStore.createGroup(projectId, name, description);
        this.loadTagsGroups();
    }

    @action
    async delete(id: string) {
        await this.rulesStore.deleteGroup(id);
        await this.loadTagsGroups();
        this.rulesStore.groupDeletionSubject.next(id);
        await this.rulesStore.getProjectTagsVersion();
    }

    @action
    async updateGroupRowPosition(dragIndex: number, dropIndex: number) {
        this.isTableLoding = true;
        this.tagsGroups.forEach( r => {
            if (r.position === dragIndex) {
                r.position = dropIndex;
            } else {
                if (dropIndex < dragIndex && r.position! <= dragIndex && r.position! >= dropIndex) {
                    r.position = r.position! + 1;
                } else if (dropIndex > dragIndex && r.position! >= dragIndex && r.position! <= dropIndex) {
                    r.position = r.position! - 1;
                }
            }
        });
        await this.rulesStore.updateGroupRowPosition(dragIndex, dropIndex);
        runInAction(() =>  this.isTableLoding = false);
    }

    @action.bound
    getTagGroupById(id: string) {
        return this.tagsGroups.find(g => g.id === id);
    }
    
    groupHasBrokenRules(id: string) {
        const tags = this.rulesStore.ruleTags.filter(x => x.groupId === id);
        return !!tags.map(x => this.rulesStore.tagHasBrokenRules(x.id!)).find(x => x);
    }
    
}
