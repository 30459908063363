import { observable, action } from 'mobx';
import { InputMeta, BindingType, InputBinding } from '../../iota_applications/types';

interface Binding extends InputBinding {}

export default class InputModel {
    @observable
    bindings: Binding[] = [];

    constructor(readonly meta: InputMeta, bindings: Binding[]) {
        this.bindings = bindings.length
            ? bindings
            : [{ inputId: this.meta.id, type: BindingType.constant, value: null }];
    }

    @action.bound
    updateBindings(bindings: Array<Omit<Binding, 'inputId'>>) {
        this.bindings = bindings.map(b => ({ inputId: this.meta.id, type: b.type, value: b.value }));
    }
}
