import { ProjectsRootVisualStore } from '../../common/stores';
import { ConnectionsDefinitionsService } from '../services';
import { computed, action, observable, runInAction, reaction } from 'mobx';
import { RcFile } from 'antd/lib/upload/interface';
import { ConnectionsDefinition, ConnectionsDefinitionCreateModel } from '../types';
import { IotaExtension, SettingsMetadata } from '../../iota_applications/types';
import { Utils } from '../../common/services/Utils';

export default class ConnectionsDefinitionsVisualStore {
    @observable
    current: ConnectionsDefinition | undefined;

    @observable
    definitions: ConnectionsDefinition[];

    @observable
    iotaExtensions: IotaExtension[];

    @observable
    addDefinitionDialogVisible: boolean;

    @observable
    editDefinitionDialogVisible: boolean;

    @observable
    selectedSettingsMetadata: SettingsMetadata | undefined;

    @observable
    newIcon: RcFile | undefined;

    @observable
    isLoading: boolean = false;

    @observable
    isModalVisible: boolean = false;

    @observable
    connectionSettingsModalVisible: boolean = false;
    
    @observable
    fileImportActionHeaders: {};

    connExecutionResponse: JSON;

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    constructor(private rootStore: ProjectsRootVisualStore, private service: ConnectionsDefinitionsService) {
        this.definitions = [];
    }

    @action.bound
    setCurrent(appDef: ConnectionsDefinition | undefined) {
        this.current = appDef;
    }

    @action.bound
    setConnectionSettingsModalVisible(visible: boolean) {
        this.connectionSettingsModalVisible = visible;
    }

    @action.bound
    setDefinitions(defs: ConnectionsDefinition[]) {
        this.definitions = defs;
    }

    @action.bound
    setIotaExensions(iotaExtensions: IotaExtension[]) {
        this.iotaExtensions = iotaExtensions;
    }

    @action.bound
    setAddDefinitionDialogVisible(visible: boolean) {
        this.addDefinitionDialogVisible = visible;
    }

    @action.bound
    setEditDefinitionDialogVisible(visible: boolean) {
        this.editDefinitionDialogVisible = visible;
    }

    @action.bound
    setSelectedSettingsMetadata(settings: SettingsMetadata | undefined) {
        this.selectedSettingsMetadata = settings;
    }

    @action.bound
    async loadDefinitions() {
        if (this.currentProject) {
            const defs = await this.service.getForProject(this.currentProject.id);
            this.setDefinitions(defs);
        } else {
            reaction(() => this.currentProject, (p, r) => {
                this.loadDefinitions();
                r.dispose();
            });
        }        
    }

    @action
    async executeConnection(definitionId: string, connectionId: string) {
        this.isLoading = true;
        const resp = await this.service.executeConnection(this.currentProject!.id, definitionId, connectionId);
        resp.map(r => {
            this.connExecutionResponse = r;
        });        
        runInAction(() => this.isLoading = false);
        runInAction(() => this.isModalVisible = true);
    }

    @action.bound
    async loadIotaInputsMetadata() {
        const extensions = await this.service.getMetadata();
        this.setIotaExensions(extensions);
    }

    @action
    checkIfNameIsUnique(name: string) {
        if (this.current) {
            return this.definitions.filter(f => f.name.toLowerCase().trim() === name.toLowerCase().trim()).length === 0 || name === this.current.name;
        } else {
            return this.definitions.filter(f => f.name.toLowerCase().trim() === name.toLowerCase().trim()).length === 0;
        }      
    }

    @action
    async createDefinition(model: ConnectionsDefinitionCreateModel) {
        const response = await this.service.create(model, this.currentProject!.id);
        response.map(id => {
            const newAppDef = new ConnectionsDefinition(
                id,
                model.name,
                model.applicationId,
                model.workflowId,
                model.projectId,
                model.extension,
                model.settings,
                undefined,
                model.connections
            );

            this.setDefinitions([newAppDef, ...this.definitions]);
            this.setAddDefinitionDialogVisible(false);
        });
        return response;
    }

    @action
    async delete(id: string) {
        const resp = await this.service.delete(id, this.currentProject!.id);
        resp.map(() => {
            runInAction(() => {
                const toDelete = this.definitions.filter(a => a.id === id);
    
                if (toDelete.length > 0) {
                    let newList = this.definitions.slice();
                    const index = newList.indexOf(toDelete[0]);
                    newList.splice(index, 1);
                    this.setDefinitions(newList);
                }
            });
        });
    }

    @action
    async edit(id: string, newName: string) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('newName', newName);

        const response = await this.service.edit(formData, id, this.currentProject!.id);
        response.map(() => {
            const appDefToRename = this.definitions.filter(a => a.id === id);

            if (appDefToRename.length > 0) {
                let newList = this.definitions.slice();
                const index = newList.indexOf(appDefToRename[0]);
                newList[index].name = newName;
    
                this.setDefinitions(newList);
            }

            this.setEditDefinitionDialogVisible(false);
            this.setCurrent(undefined);
        });

        return response;
    }

    @action.bound
    editInputs(id: string) {
        this.rootStore.navigateToEditConnectionsDefinitionPage(this.rootStore.currentProject!, id);
    }

    @action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async updateSettings(id: string, settings: { [id: string]: any }) {
        const response = await this.service.updateSettings(id, settings, this.currentProject!.id);
        await response.asyncMap(async () => {
            // this.rootStore.navigateToConnectionsDefinitionsPage(this.currentProject!);
            this.setConnectionSettingsModalVisible(false);
            await this.loadDefinitions();
            this.setCurrent(undefined);
        });
    }


    @action
    goToSettingsPage(appDef: ConnectionsDefinition) {
        this.setCurrent(appDef);
        // this.rootStore.navigateToConnectionsDefinitionsSettingsPage(this.currentProject!, appDef.id);
        this.setConnectionSettingsModalVisible(true);
    }

    @action
    goToList() {
        this.rootStore.navigateToConnectionsDefinitionsPage(this.currentProject!);
    }

    @action
    setIsModalVisible(isVisible: boolean) {
        this.isModalVisible = isVisible;
    }

    @action
    async exportConnection(id: string) {
        this.isLoading = true;
        await this.service.exportConnection(id, this.currentProject!.id);
        this.isLoading = false;
    }

    async setHeaders() {
        const token =  await Utils.getAuthToken();
        this.fileImportActionHeaders  = {Authorization: 'Bearer ' + token};
    }
}
