import { gql } from 'apollo-boost';
import { ProjectAssignment, ProjectAssignmentsResult } from '../models/ProjectAssignment';
import { execQuery } from '../../../modules/common/services/DataService';
import { CodeSnippetGroup, CodeSnippet } from '../models';
import {
    AlphaSettingsModel,
    CodeSnippetGroupsResult,
    CodeSnippetsResult,
    CodeSnippetType,
    CustomRole,
    ProjectDocumentCountModel,
    CreateUpdateUserProjectAssignmentsModel,
    CreateUpdateGroupProjectAssignmentsModel
} from '../types';
import { ProcessType } from '../../../modules/common/types';
import { UsersGroup } from '../types/GroupModel';
import { UserModel } from '../types/UserModel';
import { RoleModel } from '../types/RoleModel';
import appClient from '../../common/services/AppClient';
import { ResponseData } from '../../common/services/AppClient';
import { AlphaConfig } from '../models/AlphaConfig';
import { AlphaConfigResult } from '../models/AlphaConfig';
import { ResultApi } from '../../common/services/AppClient';

enum PackageStatus {
    All = 'All',
    Read = 'Ready',
    Importing = 'Importing',
    Broken = 'Broken'
}

export type Group = {
    users: UserModel[];
    usersGroup: UsersGroup
}; 

export class GlobalAdministrationService {

    async processByStatus(packageStatus: string, processType: ProcessType) {
        const url = processType === ProcessType.Reparse ?  process.env.REACT_APP_MANAGE_URL! + 'admin/packages/parse' : 
            process.env.REACT_APP_MANAGE_URL! + 'admin/packages/reindex' 
        ;
        let data: Partial<{ 'options': string; 'value': string }>;

        if (packageStatus === PackageStatus.All) {
            data = { 'options': 'All'};
        } else {
            data = { 'options': 'ByState', 'value': packageStatus };
        }

        const resp = await appClient.post(url, data);
        resp.mapErr(() => {
            console.error(`Error occured while reindexing packages with status ${packageStatus}`);
        });
    }

    async getRoles(): Promise<RoleModel[]> {
        let url = process.env.REACT_APP_USERS_URL + 'roles';
        const resp = await appClient.get<RoleModel[]>(url);

        return resp.unwrapOr(Array<RoleModel>());

    }

    async getUserCount(searchKey?: string): Promise<number> {
        let url = process.env.REACT_APP_USERS_URL + 'count';
        if (searchKey) {
            url += `?searchKey=${searchKey}`;
        }
        const resp = await appClient.get<number>(url);

        return resp.unwrapOr(0);
    }

    async getGroups(): Promise<Group[]> {
        let url = `${process.env.REACT_APP_USERS_URL}groups`;
        const resp = await appClient.get<Group[]>(url);

        return resp.unwrapOr(Array<Group>());
    }

    async getAppUsers(includeRoles: boolean = false): Promise<UserModel[]> {
        let url = `${process.env.REACT_APP_USERS_URL}?includeRoles=${includeRoles}`;
        const resp = await appClient.get<UserModel[]>(url);
        return resp.unwrapOr(Array<UserModel>());
    }

    async getAppUsersPage(offset: number = 0, limit: number = 100, searchKey: string | null = null ): Promise<UserModel[]> {
        const request = {
            offset: offset,
            limit: limit,
            searchKey: searchKey
        };
        let url = `${process.env.REACT_APP_USERS_URL}user-pages`;
        const resp = await appClient.post<UserModel[]>(url, request);
        return resp.unwrapOr(Array<UserModel>());
    }

    async getProjectAssignments(): Promise<ProjectAssignment[] | null> {
        const result = await execQuery<ProjectAssignmentsResult>({
            query: gql`query getProjectAssignments {
                getProjectAssignments {
                    id
                    userId
                    projects
                    defaultProject
                    type
                }
            }`,
        
            fetchPolicy: 'network-only'
        });
        return result.data.getProjectAssignments;
    }

    async getCodeSnippetGroups(): Promise<CodeSnippetGroup[] | null> {
        const result = await execQuery<CodeSnippetGroupsResult>({            
            query: gql`query getCodeSnippetGroups {
                getCodeSnippetGroups {
                    id
                    name
                    description
                    parent
                }
            }`,
        
            fetchPolicy: 'network-only'
        });
        return result.data.getCodeSnippetGroups;
    }

    async getCodeSnippets(): Promise<CodeSnippet[] | null> {
        const result = await execQuery<CodeSnippetsResult>({
            query: gql`query getCodeSnippets {
                getCodeSnippets {
                    id
                    name
                    type
                    code
                    updateDate
                    description
                    groupId
                }
            }`,
        
            fetchPolicy: 'network-only'
        });
        return result.data.getCodeSnippets;
    }

    async getCodeSnippetsByType(type: CodeSnippetType): Promise<CodeSnippet[] | null> {
        const result = await execQuery<CodeSnippetsResult>({
            query: gql`query getCodeSnippets($type: String!) {
                getCodeSnippets(type: $type) {
                    id
                    name
                    type
                    code
                    updateDate
                    description
                    groupId
                }
            }`,
            variables: {
                type: type
            },
            fetchPolicy: 'network-only'
        });
        return result.data.getCodeSnippets;
    }

    
    async getAlphaConfigs(): Promise<AlphaConfig[]> {
        const result = await execQuery<AlphaConfigResult>({
            query: gql`query getAlphaConfigs {
                getAlphaConfigs {
                    id
                    name
                    value
                }
            }`,
        
            fetchPolicy: 'network-only'
        });
        return result.data.getAlphaConfigs;
    }

    async createUpdateGroupProjectAssignments(model: CreateUpdateGroupProjectAssignmentsModel) {
        const url = process.env.REACT_APP_MANAGE_URL + 'admin/projects/group-project-assignments';
        await appClient.post(url, model);
    }

    async createUpdateUserProjectAssignments(model: CreateUpdateUserProjectAssignmentsModel) {
        const url = process.env.REACT_APP_MANAGE_URL + 'admin/projects/user-project-assignments';
        await appClient.post(url, model);
    }

    async saveCodeSnippetGroup(group: CodeSnippetGroup) {
        const url = process.env.REACT_APP_MANAGE_URL! + 'admin/projects/snippet-group';
        await appClient.post(url, group);
    }

    async deleteCodeSnippetGroup(groupId: string) {
        const url = process.env.REACT_APP_MANAGE_URL! + `admin/projects/snippet-group/${groupId}`;
        await appClient.delete(url);
    }

    async saveCodeSnippet(snippet: CodeSnippet) {
        const url = process.env.REACT_APP_MANAGE_URL! + 'admin/projects/code-snippet';
        await appClient.post(url, snippet);
    }

    async deleteCodeSnippet(snippetId: string) {
        const url = process.env.REACT_APP_MANAGE_URL! + `admin/projects/code-snippet/${snippetId}`;
        await appClient.delete(url);
    }

    async createUpdateUser(userData: UserModel): Promise<ResponseData>  {
        const url = `${process.env.REACT_APP_USERS_URL!}`;
        return appClient.postRaw(url, userData);
    }

    async createUpdateGroup(name: string, id: string | null): Promise<ResponseData>  {
        const url = `${process.env.REACT_APP_USERS_URL!}group`;
        const data = {name: name, id: id};
        return appClient.postRaw(url, data);

    }

    async deleteGroup(id: string): Promise<boolean> {
        const url = `${process.env.REACT_APP_USERS_URL!}group/${id}`;
        const resp = await appClient.delete<boolean>(url);
        return resp.unwrapOr(false);
    }

    async updateDomainUser(userId: string, roles: string, groupId: string): Promise<ResponseData>  {
        const url = `${process.env.REACT_APP_USERS_URL!}domainUser`;
        const data = {groupId, userId, roles};
        return appClient.postRaw(url, data);

    }
    
    async changeUserGroup(userId: string, currentGroupId: string | undefined, newGroup: UsersGroup | undefined): Promise<ResponseData>  {
        const url = `${process.env.REACT_APP_USERS_URL!}group/change`;
        const data = {userId: userId, currentGroupId: currentGroupId, newGroup: newGroup};
        return appClient.postRaw(url, data);
    }

    async deleteUser(id: string, isDomainUser: boolean): Promise<ResponseData> {
        const url = `${process.env.REACT_APP_USERS_URL!}${id}/${isDomainUser}`;
        return appClient.deleteRaw(url);
    }
    
    async resetPassword(password: string, userId?: string): Promise<ResponseData> {
        const data = {password: password, userId: userId};
        const url = process.env.REACT_APP_USERS_URL + 'userProfile/password';
        return appClient.postRaw(url, data);
    }

    async changePassword(newPassword: string, oldPassword: string, userId?: string | null): Promise<ResponseData> {
        const data = {userId, oldPassword, newPassword};
        const url = process.env.REACT_APP_USERS_URL + 'userProfile/password_change';
        return appClient.postRaw(url, data);
    }

    async changeObjectRoleAssignments(userId: string, objectId: string, objectType: string, roles: string[]): Promise<ResponseData> {
        const data = {userId, objectId, objectType, roles};
        const url = process.env.REACT_APP_USERS_URL + 'object-role-assignments/change';
        return appClient.postRaw(url, data);
    }

    async getCustomRoles(): Promise<CustomRole[]> {
        const url = process.env.REACT_APP_MANAGE_URL + 'customRolesManagement';
        var resp = await appClient.get<CustomRole[]>(url);
        return resp.unwrapOr(Array<CustomRole>());
    }

    async createCustomRole(name: string, permissions: string[]) {
        const data = {name, permissions};
        const url = process.env.REACT_APP_MANAGE_URL + 'customRolesManagement';
        return appClient.postRaw(url, data);
    }

    async updateCustomRole(id: string, name: string, permissions: string[]) {
        const data = {id, name, permissions};
        const url = process.env.REACT_APP_MANAGE_URL + 'customRolesManagement';
        return appClient.updateRaw(url, data);
    }

    async deleteCustomRole(id: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `customRolesManagement/${id}`;
        return appClient.deleteRaw(url);
    }
    
    async updateAlphaConfigValue(data: {}) {
        let url = `${process.env.REACT_APP_MANAGE_URL}alpha-configs`;
        return appClient.post(url, {data});
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getAlphaSettings(): Promise<AlphaSettingsModel | undefined> {
        const url = `${process.env.REACT_APP_MANAGE_URL}alpha-settings`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = await appClient.get<AlphaSettingsModel | undefined>(url);
        return result.unwrapOr(undefined);
    }

    async updateAlphaSettingsValue(key: string, value: unknown): Promise<ResultApi<string | undefined>> {
        const data = {key, value};
        const url = `${process.env.REACT_APP_MANAGE_URL}alpha-settings`;
        return appClient.post(url, data);
    }
    
    async deleteAlphaSettingsValue(key: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}alpha-settings/${key}`;
        return appClient.delete(url);
    }
    
    async getProjectDocumentsCount() {
        const url = `${process.env.REACT_APP_MANAGE_URL}system/projects/info`;
        return appClient.get<ProjectDocumentCountModel[]>(url);
    }

    async getJobsCount() {
        const url = `${process.env.REACT_APP_MANAGE_URL}system/jobs/info`;
        return appClient.get<number>(url);
    }

    async clearQueue(): Promise<ResultApi<number>>  {
        const url = `${process.env.REACT_APP_MANAGE_URL}admin/queue/clear`;
        return appClient.post(url);
    }
} 
