import { observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { flatten } from 'lodash';
import { ApplicationDefinitionConditionalEditStore } from '../stores';
import { InputGroupDto } from '../types';
import { SectionModel } from '.';

export default class InputGroupModel {
    @observable
    name: string;

    @observable
    enabled: boolean;

    @observable
    isDefault: boolean;

    @observable
    hasLayers: boolean;

    @observable
    hasSections: boolean;

    @observable
    editable: boolean = false;

    @observable
    sections: SectionModel[] = [];

    @observable
    openSectionIds: string[] = [];

    @computed
    get editableSection() {
        return this.sections.find(s => s.editable);
    }

    @computed
    get inputs() {
        return flatten(this.sections.map(s => s.inputs));
    }

    @computed
    get tabKey() {
        return `${this.id}_${this.isDefault}`;
    }

    get dto(): InputGroupDto {
        return {
            inputGroupId: this.id,
            name: this.name,
            enabled: this.enabled,
            isDefault: this.isDefault,
            hasLayers: this.hasLayers,
            hasSections: this.hasSections,
            sections: this.sections.map(s => s.dto)
        };
    }

    readonly id: string;

    constructor(private readonly store: ApplicationDefinitionConditionalEditStore, data: InputGroupDto) {
        this.id = data.inputGroupId;
        this.name = data.name;
        this.enabled = data.enabled;
        this.isDefault = data.isDefault;
        this.hasLayers = data.hasLayers;
        this.hasSections = data.hasSections;
        this.sections = this.store.applicationDefinitionSectionNames.map(name => {
            const existingSection = data.sections.find(s => s.name === name);

            if (existingSection) {
                return new SectionModel(this.store.applicationDefinitionInputs, { ...existingSection });
            }

            return new SectionModel(this.store.applicationDefinitionInputs, {
                sectionId: uuidv4(),
                name,
                nameOverride: null,
                enabled: true,
                bindings: []
            });
        });
    }

    @action.bound
    update(data: Partial<InputGroupDto>) {
        this.name = data.name ? data.name.trim() : this.name;
        this.enabled = data.enabled ?? this.enabled;
        this.isDefault = data.isDefault ?? this.isDefault;
        this.hasLayers = data.hasLayers ?? this.hasLayers;
        this.hasSections = data.hasSections ?? this.hasSections;
        this.store.setIsDataChanged(true);
    }

    @action.bound
    setEditable(editable: boolean) {
        this.editable = editable;
    }

    @action.bound
    setOpenSectionIds(openSectionIds: string[]) {
        this.openSectionIds = openSectionIds;
    }

    delete() {
        this.store.removeInputGroup(this);
        this.store.setIsDataChanged(true);
    }
}
