import { AutoComplete, Collapse, Drawer, Input, List } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { getFriendlyRuleTypeName } from '../../common/Utils';
import { RuleTypes } from '../models';
import { TagsGroupModel } from '../models/TagsGroupModel';
import { RulesListVisualStore } from '../stores';
import TagsGroupVisualStore from '../stores/TagsGroupVisualStore';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    groupsStore: TagsGroupVisualStore;
    rulesListStore: RulesListVisualStore;
    editableRule: RuleTypes | undefined
};

const RuleTagsDrawer: React.FC<Props> = ({open, setOpen, groupsStore, rulesListStore, editableRule}) => {
    const [searchValue, setSearchValue] = React.useState('');

    const getTagRulesCountLabel = (tagName: string | null) => {
        if (!tagName || !rulesListStore.data[tagName]) {
            return '';
        }

        const rulesCount = rulesListStore.data[tagName].length;

        return `${rulesCount} ${rulesCount === 1 ? 'rule' : 'rules'}`;
    };

    const handleNaviagtion = (rule: RuleTypes) => {
        rulesListStore.edit(rule.id!);
    };

    const getRuleListItem = (rule: RuleTypes) => {
        if (searchValue && searchValue.trim() !== '' && !rule.name.toLowerCase().includes(searchValue.toLowerCase())) {
            return null;
        }

        const description = `${getFriendlyRuleTypeName(rule.ruleType)}; Priority: ${rule.priority}`;
        const titlePrefix = rule.status === 'Broken' ? <i className="alpha-icon sm rule-problem-icon" style={{verticalAlign: 'text-top', marginRight: 5}} /> : null;

        return (
            <List.Item 
                className={`${rule.state === 'Disabled' ? 'disabled' : ''} ${editableRule?.id === rule.id ? 'active' : ''}`}
                key={rule.id} 
                data-id={rule.id}
                onClick={() => handleNaviagtion(rule)}>
                <List.Item.Meta 
                    title={<>{titlePrefix}{rule.name}</>}
                    description={description}
                />
            </List.Item>
        );
    };

    const collapseTitle = (title: string, subtitle: string, isBroken: boolean) => (
        <div className="panel-title-wrapper">
            <div className="panel-title">{isBroken ? <i className="alpha-icon sm rule-problem-icon" style={{verticalAlign: 'text-top'}} /> : null} {title}</div>
            <div className="panel-subtitle">{subtitle}</div>
        </div>
    );

    const haveBrokenPinnedRules = () => {
        if (!rulesListStore.pinnedRulesInProject?.length) {
            return false;
        }

        return rulesListStore.pinnedRulesInProject.some((ruleId) => {
            const rule = rulesListStore.rules.find((r) => r.id === ruleId);

            if (!rule) {
                return false;
            }

            return rule.status === 'Broken';
        });
    };

    const groupContainsRuleWithSearchValue = (group: TagsGroupModel) => {
        if (!searchValue || searchValue.trim() === '') {
            return true;
        }

        return rulesListStore.rules.some(r => r.tagId && group.tagIds.includes(r.tagId) && r.name.toLowerCase().includes(searchValue.toLowerCase()));
    };

    const tagHasRuleWithSearchValue = (tagId: string) => {
        if (!searchValue || searchValue.trim() === '') {
            return true;
        }

        return rulesListStore.rules.some(r => r.tagId === tagId && r.name.toLowerCase().includes(searchValue.toLowerCase()));
    };

    return (
        <Drawer
            className="rule-tags-drawer"
            visible={open}
            mask={false}
            width={280}
            onClose={() => setOpen(false)}
            title="Tags"
        >
            <AutoComplete
                style={{width: '100%'}}
                options={_.uniqBy(rulesListStore.rules.map((rule) => ({ value: rule.name})), 'value')}
                onSearch={setSearchValue}
                onSelect={setSearchValue}
                filterOption={(inputValue, option) => option?.value.toLowerCase().includes(inputValue.toLowerCase())}
            >
                <Input.Search className="rule-search" placeholder="Search..." enterButton />
            </AutoComplete>
            {rulesListStore.pinnedRulesInProject?.length ? (
                <Collapse className="rule-tags-collapse">
                    <Collapse.Panel 
                        header={collapseTitle(
                            'Pinned rules', 
                            `${rulesListStore.pinnedRulesInProject.length} rule${rulesListStore.pinnedRulesInProject?.length > 1 ? 's' : ''}`,
                            haveBrokenPinnedRules()
                        )} 
                        key="pinned"
                    >
                        <List>
                            {rulesListStore.pinnedRulesInProject.map((ruleId) => {
                                const rule = rulesListStore.rules.find((r) => r.id === ruleId);

                                if (!rule) {
                                    return null;
                                }

                                return getRuleListItem(rule);
                            })
                            }
                        </List>    
                    </Collapse.Panel>
                </Collapse>
            ) : null}
            <Collapse defaultActiveKey={editableRule?.groupId ?? undefined}>
                {groupsStore.tagsGroups.slice()
                    .filter(g => !groupsStore.emptyGroupIds.find(gId => gId === g.id) && groupContainsRuleWithSearchValue(g))
                    .sort((a, b) => a.position - b.position).map((group) => (
                        <Collapse.Panel header={collapseTitle(group.name, group.description, groupsStore.groupHasBrokenRules(group.id || 'temp-id'))} key={group.id || 'temp-id'}>
                            <Collapse defaultActiveKey={editableRule?.tagId ?? undefined}>
                                {rulesListStore.tags
                                    .filter(t => t.groupId === group.id && tagHasRuleWithSearchValue(t.id || 'temp-tag-id'))
                                    .slice()
                                    .sort((a, b) => a.position! - b.position!)
                                    .map((tag) => (
                                        <Collapse.Panel 
                                            header={collapseTitle(tag.name, getTagRulesCountLabel(tag.name), !!rulesListStore.tagHasBrokenRules(tag.id || 'temp-tag-id'))} 
                                            key={tag.id || 'temp-tag-id'}
                                        >
                                            <List>
                                                {rulesListStore.data[tag.name]?.map(getRuleListItem)}
                                            </List>
                                        </Collapse.Panel>
                                    ))}
                            </Collapse>

                        </Collapse.Panel>
                    ))}
            </Collapse>
        </Drawer>
    );
};

export default observer(RuleTagsDrawer);