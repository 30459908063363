import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { RulesListVisualStore } from '../stores';

type Props = {
    store: RulesListVisualStore
};

const TagsVersionEditDialog: React.FC<Props> = ({store}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.tagsVersionDialogVisible && store.currentProject?.tagsVersion) {
            const obj = {
                ...store.currentProject.tagsVersion
            };
            form.setFieldsValue(obj);
        } else {
            form.resetFields();
        }
    }, [store.tagsVersionDialogVisible, store.currentProject, form]);

    const handleCancel = () => {
        store.setTagsVersionDialogVisible(false);     
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            store.updateTagsVersion(values.version);
        });
    };

    return (
        <Modal
            title="Project tags version"
            visible={store.tagsVersionDialogVisible}
            onCancel={handleCancel}
            className="alpha-portal-dialog"
            maskClosable={false}
            destroyOnClose
            closable={false}
            centered
            footer={[
                <React.Fragment key="tag-version-dialog-buttons">     
                    <Button data-id="edit-tags-version-dialog-cancel" key="tag-rename-back" size="large" className="light"  onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button data-id="edit-tags-version-dialog-submit" key="tag-rename-submit" size="large"  type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </React.Fragment>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Version"
                    name="version"
                    required
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Version hash"
                    name="versionHash"
                >
                    <Input readOnly/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(TagsVersionEditDialog);