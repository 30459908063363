import * as React from 'react';
import ProjectSettingsPage from '../screens/ProjectSettingsPage';
import { ProtectedRoute } from '../../common/components';

export const ProjectSettingsNavigation = {
    ProjectSettingsPage: '/projects/:projectId/settings',
};

export default () => {
    return <ProtectedRoute component={ProjectSettingsPage} path={ProjectSettingsNavigation.ProjectSettingsPage} />;
};
