import { ApiOperationParameter } from '.';

export default class ApiApplicationCreateModel {
    name: string;
    applicationId: string;
    workflowId: string;
    extension: string;
    projectId: string;
    state: string;
    parameters: ApiOperationParameter[];
    requestBodyParameters: ApiOperationParameter[];
    settings: string;
    apiUrl: string;
    apiMethod: string;
}