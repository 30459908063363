import { Button, Checkbox, Collapse, Form, Input, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AlphaConfigurations } from '../../common/constants';
import { FeatureFlags } from '../../common/models';
import { UploadedPackagesVisualStore } from '../stores';

const FormItem = Form.Item;

type Props = {
    visible: boolean;
    changeVisible: (visible: boolean) => void;
    currentFeatureFlags: FeatureFlags;
    currentPackageId: string;
    store: UploadedPackagesVisualStore
};

const FeatureFlagsModal: React.FC<Props> = ({visible, changeVisible, currentFeatureFlags, store, currentPackageId}) => {

    const [form] = Form.useForm();
    const [overrideFeatureFlags, setOverrideFeatureFlags] = React.useState(false);
    const [overrideTextFeatureFlags, setOverrideTextFeatureFlags] = React.useState(false);
    const [overrideImageFeatureFlags, setOverrideImageFeatureFlags] = React.useState(false);
    const [openPanelKeys, setOpenPanelKeys] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (visible) {
            mapFeatureFlagsToForm();
        } else {
            setOverrideFeatureFlags(false);
            setOverrideTextFeatureFlags(false);
            setOverrideImageFeatureFlags(false);
            setOpenPanelKeys([]);
            form.resetFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const handleSubmit = () => {
        const featureFlags = transformFeatureFlags();
        store.updatePackageFeatureFlags(currentPackageId, featureFlags).then(result => {
            if (result) {
                changeVisible(false);
            }
        });
    };

    const handleCancel = () => {
        changeVisible(false);
    };

    const transformFeatureFlags = () => {   
        const obj = {};

        AlphaConfigurations.forEach(c => {
            if (overrideFeatureFlags) {
                obj['GENERAL::' + c.key] = form.getFieldValue(c.key);
            }
            
            if (overrideTextFeatureFlags && (!overrideFeatureFlags || 
                form?.getFieldValue('TEXT::' + c.key) !== obj['GENERAL::' + c.key])) {
                obj['TEXT::' + c.key] = form?.getFieldValue('TEXT::' + c.key);
            }

            if (overrideImageFeatureFlags && (!overrideFeatureFlags || 
                form?.getFieldValue('IMAGE::' + c.key) !== obj['GENERAL::' + c.key])) {
                obj['IMAGE::' + c.key] = form?.getFieldValue('IMAGE::' + c.key);
            }
        });

        // If obj is empty return null.
        return Object.keys(obj).length === 0 ? null : obj;
    };

    const mapFeatureFlagsToForm = () => {
        let formValues = {};
        if (currentFeatureFlags.general) {
            for (const [key, value] of Object.entries(currentFeatureFlags.general)) {
                formValues[key] = value;
            }
            setOverrideFeatureFlags(Object.keys(currentFeatureFlags.general).length > 0);
        }

        if (currentFeatureFlags.text) {
            for (const [key, value] of Object.entries(currentFeatureFlags.text)) {
                formValues['TEXT::' + key] = value;
            }
            setOverrideTextFeatureFlags(Object.keys(currentFeatureFlags.text).length > 0);
        }

        if (currentFeatureFlags.image) {
            for (const [key, value] of Object.entries(currentFeatureFlags.image)) {
                formValues['IMAGE::' + key] = value;
            }
            setOverrideImageFeatureFlags(Object.keys(currentFeatureFlags.image).length > 0);
        }

        form.setFieldsValue(formValues);
    };
    
    const featureFlagsPanelExtra = (onCheckboxChange: (e: CheckboxChangeEvent) => void, checked: boolean) => {
        return(
            <div 
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <label>
                    Override flags:
                    <Checkbox 
                        style={{marginLeft: 12}}
                        checked={checked}
                        onChange={onCheckboxChange}
                    />
                </label>
            </div>
        );
    };

    const showExportButton = React.useMemo(
        () => Object.values(currentFeatureFlags).some(flag => Object.keys(flag).length > 0),
        [currentFeatureFlags]
    );

    return(
        <Modal 
            title="Feature flags" 
            className="alpha-portal-dialog two-columns"
            visible={visible}
            centered
            maskClosable={false}
            closable={false}
            footer={[
                <Button
                    key="feature-flags-dialog-close"
                    data-id="feature-flags-dialog-close"
                    className="light"
                    size="large"
                    onClick={handleCancel}
                >
                    Close
                </Button>,
                <Button
                    key="feature-flags-dialog-save"
                    data-id="feature-flags-dialog-save"
                    type="primary"
                    size="large"
                    onClick={handleSubmit}
                >
                    Save
                </Button>   
            ]}
            onCancel={handleCancel}
        >
            <>  
                {showExportButton && (
                    <div className="feature-flag-import-export-container">
                        <span
                            className="feature-flag-import-export-button export"
                            onClick={() => store.exportPackageFeautureFlags(currentPackageId)}
                        >
                            <i className="alpha-icon xs arrow-up" />
                            <span>Export feature flags</span>
                        </span>
                    </div>
                )}
                
                <Form form={form} layout="vertical">
                    <Collapse activeKey={openPanelKeys} onChange={(key) => setOpenPanelKeys(key as string[])}>
                        <Collapse.Panel 
                            key="1" 
                            header="General feature flags" 
                            forceRender 
                            extra={featureFlagsPanelExtra((e) => {
                                setOverrideFeatureFlags(e.target.checked);
                            }, overrideFeatureFlags)}
                        >
                            {store.defaultAlphaConfigs.map((config) => (
                                <FormItem
                                    key={config.name}
                                    name={config.name}
                                    initialValue={config.value}
                                    label={config.name}
                                >
                                    <Input disabled={!overrideFeatureFlags} />
                                </FormItem>
                            ))}
                        </Collapse.Panel>
                        <Collapse.Panel 
                            key="2" 
                            header="Text feature flags" 
                            forceRender 
                            extra={featureFlagsPanelExtra((e) => {
                                setOverrideTextFeatureFlags(e.target.checked);
                            }, overrideTextFeatureFlags)}
                        >
                            {store.defaultAlphaConfigs.map((config) => (
                                <FormItem
                                    key={'TEXT::' + config.name}
                                    name={'TEXT::' + config.name}
                                    initialValue={config.value}
                                    label={config.name}
                                >
                                    <Input disabled={!overrideTextFeatureFlags} />
                                </FormItem>
                            ))}
                        </Collapse.Panel>
                        <Collapse.Panel 
                            key="3" 
                            header="Images feature flags" 
                            forceRender 
                            extra={featureFlagsPanelExtra((e) => {
                                setOverrideImageFeatureFlags(e.target.checked);
                            }, overrideImageFeatureFlags)}
                        >
                            {store.defaultAlphaConfigs.map((config) => (
                                <FormItem
                                    key={'IMAGE::' + config.name}
                                    name={'IMAGE::' + config.name}
                                    initialValue={config.value}
                                    label={config.name}
                                >
                                    <Input disabled={!overrideImageFeatureFlags} />
                                </FormItem>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                </Form>
            </>
        </Modal>
    );
};

export default observer(FeatureFlagsModal);