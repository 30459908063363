import { computed } from 'mobx';
import { ChatbotStore } from '../stores';
import { QuestionDto } from '../types';
import { LocalStorageService } from '../../common/services';
import { ChatbotQuestionModel } from '../models';

interface QuestionHistoryItem {
    packageId: string;
    questions: QuestionDto[]
}

export default class ChatbotHistoryService {
    private readonly service: LocalStorageService;

    @computed
    get packageQuestionsKey() {
        if (!this.store.project) {
            return null;
        }

        return `${this.store.project.id}_chatbot-dialog-question-history`;
    }

    private readonly lastSentMessageKey = 'chatbot-dialog-last-sent-message';

    constructor(private readonly store: ChatbotStore) {
        this.service = new LocalStorageService();
    }

    getPackageQuestions(packageId: string) {
        if (!this.packageQuestionsKey) {
            return [];
        }

        const historyItem = this.service
            .getItem<QuestionHistoryItem[]>(this.packageQuestionsKey, [])
            .find(i => i.packageId === packageId);

        if (!historyItem) {
            return [];
        }

        return historyItem.questions;
    }

    addPackageQuestion(question: ChatbotQuestionModel) {
        if (!this.packageQuestionsKey) {
            return;
        }

        const questionHistoryItems = this.service.getItem<QuestionHistoryItem[]>(this.packageQuestionsKey, []);
        const historyItem = questionHistoryItems.find(i => i.packageId === question.packageId);

        if (historyItem) {
            historyItem.questions.push(question.dto);
        } else {
            questionHistoryItems.push({ packageId: question.packageId, questions: [question.dto] });
        }

        this.service.setItem(this.packageQuestionsKey, questionHistoryItems);
    }

    removePackageQuestions(packageId: string) {
        if (!this.packageQuestionsKey) {
            return;
        }

        const questionHistoryItems = this.service
            .getItem<QuestionHistoryItem[]>(this.packageQuestionsKey, [])
            .filter(i => i.packageId !== packageId);

        this.service.setItem(this.packageQuestionsKey, questionHistoryItems);
    }

    removeAllPackagesQuestions() {
        if (!this.packageQuestionsKey) {
            return;
        }

        this.service.removeItem(this.packageQuestionsKey);
    }

    getLastSentMessage() {
        return this.service.getItem<string>(this.lastSentMessageKey, '');
    }

    updateLastSentMessage(lastSentMessage: string) {
        this.service.setItem(this.lastSentMessageKey, lastSentMessage);
    }
}
