import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Card } from 'antd';
import { WorkflowInputProps } from '../../types';
import { WorkflowInputControlRegistry } from '..';

const WorkflowInput: React.FC<WorkflowInputProps> = ({ store, workflow, input }) => {
    const InputControl = React.useMemo(() => WorkflowInputControlRegistry[input.control], [input]);

    if (!InputControl) {
        console.error(`Input control '${input.control}' is not registered`);
        return null;
    }

    return (
        <Card key={input.id} title={input.name} type="inner">
            {input.description ? <Card.Meta description={input.description} /> : null}
            
            <InputControl input={input} store={store} workflow={workflow} />
        </Card>
    );
};

export default observer(WorkflowInput);
