import { Button, Form, Input, Modal, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore, FormTypesVisualStore } from '../stores';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore
};

const FormTypeVariationEditDialog: React.FC<Props> = ({store, visualStore}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.variationToEdit) {
            form.setFieldsValue({
                name: store.variationToEdit.name,
                packageId: store.variationToEdit.packageId,
                id: store.variationToEdit.variationId
            });
        } else {
            form.resetFields();
        }
    }, [form, store.variationToEdit]);


    const handleCancel = () => {
        visualStore.setIsFormVariationEditDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            const formType = store.formTypes.find(type => type.variations.find(variation => variation.variationId === store.variationToEdit?.variationId));

            if (!formType) {
                return;
            }

            const updatedSuccessfully = await store.updateFormVariation(formType.id, values.id, values.name, values.packageId);
            if (updatedSuccessfully) {
                handleCancel();
            }
        }).catch(() => {
            // do nothing
        });
    };

    const validateName = (name: string) => {
        const currentGroup = store.formTypes.find(type => type.variations.find(variation => variation.variationId === store.variationToEdit?.variationId));

        if (!currentGroup) {
            return Promise.reject('Select form type, please');
        }

        if (currentGroup.variations.find(variation => variation.name === name && variation.variationId !== store.variationToEdit?.variationId)) {
            return Promise.reject('Form variation with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            wrapProps={{'data-id': 'Edit form type variation'}}
            className="alpha-portal-dialog"
            title="Edit form type variation"
            visible={visualStore.isFormVariationEditDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button data-id="form-variations-edit-dialog-cancel" className="light" key="back" size="large" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button data-id="form-variations-edit-dialog-submit" key="submit" size="large" type="primary" onClick={handleSubmit}>
                    Save
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item 
                    name="name" 
                    label="Name" 
                    rules={[
                        {required: true, message: 'Provide name for the form type, please'},
                        {validator: (_, value) => validateName(value)}
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="packageId" label="Package" rules={[{required: true, message: 'Provide name for the form type, please'}]}>
                    <Select
                        loading={store.loadingPackages}
                        options={store.packages?.map((p) => ({title: p.name, label: p.name, value: p.id}))}
                        showSearch
                        filterOption={(input, option) => option?.title && option.title.toLowerCase().includes(input.toLowerCase())}   
                    />
                </Form.Item>
                <Form.Item
                    name="id"
                    hidden
                >
                    <Input type='hidden' />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FormTypeVariationEditDialog);