import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input } from 'antd';
import { WorkflowInputProps } from '../../types';

const WorkflowInputTextArea: React.FC<WorkflowInputProps> = ({ input, workflow }) => {
    const existingInputValue = React.useMemo(() => workflow.getInputValueById(input.id), [workflow, input]);

    return (
        <Form.Item
            label="Text"
            name={['inputValues', input.id]}
            initialValue={existingInputValue ? existingInputValue.value : null}
            rules={[{ required: true, message: 'Please enter value!' }]}
        >
            <Input.TextArea rows={4} />
        </Form.Item>
    );
};

export default observer(WorkflowInputTextArea);
