import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Empty, Modal, Spin, Collapse, Tooltip } from 'antd';
import { ReferenceDataUsageStore } from '../../stores';
import './SingleProjectRefDataUsageDialog.less';

type Props = {
    connectionName: string;
    store: ReferenceDataUsageStore;
    onExport: (tableName: string) => void
};

const SingleProjectRefDataUsageDialog: React.FC<Props> = ({ connectionName, store, onExport }) => {
    React.useEffect(() => {
        if (store.singleProjectDialogVisible) {
            store.getSingleProjectRefDataUsage();
        }
    }, [store, store.singleProjectDialogVisible]);

    const onClose = () => {
        store.setSingleProjectDialogVisible(false);
        store.setSingleProjectDataUsage([]);
    };

    const extra = (tableName: string) => (
        <Tooltip title="Export">
            <Button
                data-testid="export-refdata"
                size="small"
                type="link"
                icon={<i className="alpha-icon lg export-gray-icon" />}
                onClick={event => {
                    event.stopPropagation();
                    onExport(tableName);
                }}
            />
        </Tooltip>
    );

    const content = () => {
        if (store.singleProjectDataLoading) {
            return (
                <div className="spin">
                    <Spin />
                </div>
            );
        }

        if (!store.singleProjectDataUsage.length) {
            return (
                <Empty
                    className="empty"
                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                    imageStyle={{ display: 'block' }}
                    description="No usage data found"
                />
            );
        }

        return (
            <Collapse>
                {store.singleProjectDataUsage.map(item => (
                    <Collapse.Panel header={item.tableName} key={item.tableName} extra={extra(item.tableName)}>
                        <Collapse>
                            {item.groups.map(group => (
                                <Collapse.Panel header={group.groupName} key={group.groupId}>
                                    <Collapse>
                                        {item.tags.filter(tag => tag.groupId === group.groupId).map(tag => (
                                            <Collapse.Panel header={tag.tagName} key={tag.tagId}>
                                                {item.rules.filter(rule => rule.tagId === tag.tagId).map(rule => (
                                                    <div key={rule.ruleId}>
                                                        {store.projects.map(p => p.id).includes(item.projectId) ? (
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href={`${window.location.origin}/projects/${item.projectId}/rules/edit/${rule.ruleId}/false`}
                                                            >
                                                                {rule.ruleName}
                                                            </a>
                                                        ) : (
                                                            rule.ruleName
                                                        )}
                                                    </div>
                                                ))}
                                            </Collapse.Panel>
                                        ))}
                                    </Collapse>
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </Collapse.Panel>
                ))}
            </Collapse>
        );
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title={`Usage of ${connectionName}`}
            visible={store.singleProjectDialogVisible}
            onCancel={onClose}
            maskClosable={false}
            closable={false}
            destroyOnClose
            getContainer={false}
            centered
            footer={[
                <Button
                    data-id="reference-data-usage-dialog-close"
                    key="back"
                    size="large"
                    className="light"
                    onClick={onClose}
                >
                    Close
                </Button>
            ]}
        >
            <div className="single-project-ref-data-usage-content">{content()}</div>
        </Modal>
    );
};

export default observer(SingleProjectRefDataUsageDialog);
