import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { STORE_GLOBAL_ADMINISTRATION } from '../../../modules/administration/constants';
import { AdministrationStores } from '../../../modules/administration/stores';
import GlobalIndexer from '../components/GlobalIndexer';
import { AuthConsumer } from '../../authorization/AuthContext';

type Props =  AdministrationStores;
@inject(STORE_GLOBAL_ADMINISTRATION)
@observer
export default class GlobalAdministrationPage extends React.Component<Props, object> {
    
    render() {
        const globalAdminStore = this.props.GlobalAdministration!;
        const content = (
            <AuthConsumer>
                {() => (
                    <GlobalIndexer GlobalAdministration={globalAdminStore} />
                )}
            </AuthConsumer>
        );

        return (
            <FlexContentContainer content={content}/>
        );
    }
}