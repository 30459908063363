import * as React from 'react';
import { Modal, Row, Col, Button, Dropdown, Menu, Upload, message, Table, Tooltip } from 'antd';
import AceEditor from 'react-ace';
import { STORE_REFERENCE_DATA } from '../constant';
import { ReferenceDataVisualStore } from '../stores';
import { observer } from 'mobx-react-lite';
import { UploadChangeParam } from 'antd/lib/upload';
import { ColumnProps } from 'antd/lib/table';
import type { ValueMapping } from '../services/ReferenceDataService';

type Props = {
    [STORE_REFERENCE_DATA]: ReferenceDataVisualStore
};

export const ImportRefData: React.FC<Props> = ({ ReferenceDataUI: store }) => {
    const action = `${process.env.REACT_APP_MANAGE_URL}projects/${store.currentProject?.id}/rules/refdata/import`;

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status === 'done') {        
            const resp = info.file.response;
            store.setImportResult(resp);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

    };

    const allowDebug = () => {
        return store.editorValue && store.editorValue.trim() !== '';
    };

    const debug = async() => {
        const resp = await store!.debug(store.editorValue);
        resp.map(d => {
            store.setQueryResults(d as {}[]);
            store.setError('');
            store.setShowResults(true);            
        }).mapErr(error => {
            store.setError(error.data!.title);
            store.setShowResults(false);
        });
    };

    const columns: ColumnProps<ValueMapping>[] = [
        ...store.columns.map((k: string) => {
            const width = 1000 / store.columns.length;
            return ({ title: k, key: k, dataIndex: k, width: width });
        }
        )
    ];

    const importMenu = (
        <Menu> 
            <Menu.Item 
                key="1" 
                onClick={() => {
                    store.setSqlEditorModalVisible(true); 
                }}
            >Import using SQL editor
            </Menu.Item>
            <Menu.Item key="2">
                <Upload 
                    action={action} 
                    onChange={onChange}
                    headers={store.fileImportActionHeaders} 
                    beforeUpload={() => store.setHeaders()} 
                    showUploadList={false}
                >Import from CSV
                </Upload>
            </Menu.Item>
        </Menu>
    );

    const sqlEditor = (    
        <AceEditor
            mode="mysql"
            theme="xcode"
            editorProps={{$blockScrolling: true}}
            value={store.editorValue}
            width="calc(100% - 30px)"
            onChange={(code: string) => store.setEditorValue(code)}
        />
    );

    return (
        <>
            <Dropdown disabled={!store!.selectedTable || !store!.currentConnectionId} overlay={importMenu} trigger={['click']}>
                <Tooltip title="Import">
                    <Button 
                        size="small"
                        type="link" 
                    > 
                        <i className="alpha-icon lg import-gray-icon" />
                    </Button>
                </Tooltip>
            </Dropdown>
            <Modal 
                visible={store.showSqlEditorModal} 
                closable={false}
                onCancel={() =>  store.setSqlEditorModalVisible(false)} 
                width="70%"
                centered
                footer={(
                    <Row gutter={24}>
                        <Col span={12} style={{textAlign: 'left'}}>
                            <Button data-id="import-reference-data-dialog-execute" type="link" disabled={!allowDebug()} onClick={debug}>Execute</Button>
                        </Col>
                        <Col span={12}>
                            <Button 
                                data-id="import-reference-data-dialog-cancel"
                                size="large" 
                                className="light" 
                                onClick={() => store.setSqlEditorModalVisible(false)} 
                            >
                                Cancel
                            </Button>
                            <Button data-id="import-reference-data-dialog-run" type="primary" size="large" onClick={() => store.executeQuery(store.editorValue)}>Run</Button>
                        </Col>
                    </Row>
                )}
            >                                
                {sqlEditor}
                {store.showResults && (                                                            
                    <Table columns={columns} dataSource={store.queryResults} pagination={{pageSize: 5}} size="small"/>                                  
                )}
                {store.queryError && store.queryError.trim() !== '' && (
                    <div style={{color: 'red'}}>{store.queryError}</div>
                )}
            </Modal>
        </>
    );
};

export default observer(ImportRefData);