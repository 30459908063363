import * as React from 'react';
import { Col, Button, Input, Form, FormItemProps } from 'antd';
import { observer, Observer } from 'mobx-react';
import { RulesStores } from '../stores';
import { SearchRuleAutoCompleteSourceItem } from '../types/SearchRuleAutoCompleteSourceItem';
import { AutocompleteWithTextSelection } from '../../common/components/AutocompleteWithTextSelection';
import SearchRuleAutoCompleteOptionsRenderer from './SearchRuleAutoCompleteOptionsRenderer';

const FormItem = Form.Item;

type Props = RulesStores & {
    formItemLayout: FormItemProps
};

type State = {
    focused: boolean
};

class RuleSearch extends React.Component<Props, State> {
    private rulesOptionRenderer: (item: SearchRuleAutoCompleteSourceItem, idx: number) => React.ReactNode;

    constructor(props: Props) {
        super(props);

        this.rulesOptionRenderer = SearchRuleAutoCompleteOptionsRenderer(props.RulesListUI!);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state = {
            focused: false,
        };
    }

    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyUp(e: KeyboardEvent) {
        const store = this.props.RulesListUI!;

        if (e.key === 'Enter' && this.state.focused) {
            store.setRulesFilteredValue(store.currentRulesInputValue);
        }
    }

    onFocus() {
        this.setState({ focused: true });
    }

    onBlur() {
        this.setState({ focused: false });
    }

    render() {
        const store = this.props.RulesListUI!;

        return (
            <Col span={6} style={{ display: 'block' }}>
                <FormItem {...this.props.formItemLayout} name="searchRule">
                    <Observer>
                        {() => (
                            <div className="global-search-wrapper" style={{ width: '100%' }}>
                                <AutocompleteWithTextSelection
                                    allowClear
                                    className="global-search alpha-autocomplete"
                                    style={{ width: '100%', boxShadow: 'none' }}
                                    options={store.filteredRulesAutocompletSource
                                        .slice()
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((m, i) => ({
                                            value: m.name,
                                            label: this.rulesOptionRenderer(m, i),
                                            key: `${m.name}-${i}`,
                                        }))}
                                    onSearch={(v) => store.handleRulesOnSearch(v)}
                                    defaultActiveFirstOption={false}
                                    showSearch
                                    filterOption
                                    value={store.currentRulesInputValue || ''}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onSelect={(v) => {
                                        const value = v as string;
                                        store.setRulesFilteredValue(value);
                                    }}
                                >
                                    <Input
                                        allowClear
                                        onChange={(e) => store.handleRulesOnSearch(e.target.value)}
                                        placeholder="Search for rule..."
                                        data-id="input-filter-rule"
                                        suffix={
                                            <Button
                                                onClick={() =>
                                                    store.setRulesFilteredValue(store.currentRulesInputValue)
                                                }
                                                data-id="input-filter-rule-search-icon"
                                                className="search-btn"
                                                type="ghost"
                                            >
                                                <i
                                                    className="alpha-icon md search-icon"
                                                    style={{ verticalAlign: 'middle' }}
                                                />
                                            </Button>
                                        }
                                    />
                                </AutocompleteWithTextSelection>
                            </div>
                        )}
                    </Observer>
                </FormItem>
            </Col>
        );
    }
}

export default observer(RuleSearch);
