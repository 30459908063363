import { Button, Col, Dropdown, Form, FormInstance, Input, Menu, Popconfirm, Row, Select, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import CodeBusinessRulePreview from '../../iota_applications/components/CodeBusinessRulePreview';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';
import { FieldBindingsStore } from '../stores';
import { ImportOutlined, MoreOutlined } from '@ant-design/icons';

const { Option, OptGroup } = Select;
const InputGroup = Input.Group;

type Props = {
    store: FieldBindingsStore;
    bindingId: string;
    form: FormInstance;
    saveHandler: (showMsg: boolean) => Promise<void>
};

enum FieldBindingType {
    Constant = 'constant',
    Tag = 'tag',
    Variable = 'variable',
    CodeRule = 'coderule'
}

const FieldBindingInput: React.FC<Props> = ({store, bindingId, form, saveHandler}) => {
    React.useEffect(() => {
        if (store.selectedGroup) {
            const fields = store.selectedGroup.fields.filter(f => f.inputId === bindingId);
            const obj = {};
            obj[bindingId] = fields;
            form.setFieldsValue(obj);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selectedGroup]);

    const handleChange = (value: string, index: number) => {
        const val = form.getFieldValue(bindingId);
        const obj = Object.create(null);
        val[index].value = null;
        obj[bindingId] = val;
        form.setFieldsValue(obj);
    };

    const getDropdownForTypes = (isDisabled: boolean, i: number) => {
        return (
            // eslint-disable-next-line max-len
            <SelectWhenScroll 
                disabled={isDisabled} 
                data-id={`binding-inputs-type-${bindingId}-${i}`} 
                style={{ width: 175 }} 
                onChange={(val) => handleChange(val as string, i)}
            >
                <Option value={FieldBindingType.Constant} data-id={`binding-inputs-type-${bindingId}-${i}-constant`}>Constant</Option>
                <Option value={FieldBindingType.Tag} data-id={`binding-inputs-type-${bindingId}-${i}-tag`}>Rule Tag</Option>
                <Option value={FieldBindingType.Variable} data-id={`binding-inputs-type-${bindingId}-${i}-variable`}>Environment Variable</Option>
                <Option value={FieldBindingType.CodeRule} data-id={`binding-inputs-type-${bindingId}-${i}-coderule`}>Code Business Rule</Option>
            </SelectWhenScroll>
        );
    };

    const addInput = () => {
        if (store.selectedGroup) {
            const currentVal = form.getFieldValue(bindingId);
            const field = store.selectedGroup.fields.filter(f => f.inputId === bindingId)[0];
            const obj = {};
            let newFields = [...currentVal];
            newFields.push({
                name: field.name,
                inputId: bindingId,
                groupId: field.groupId,
                sectionid: field.sectionId,
                type: FieldBindingType.Constant,
                projectId: field.projectId,
                value: null
            });
            obj[bindingId] = newFields;
            form.setFieldsValue(obj);
        }
    };

    const getValueInput = (index: number) => {
        const binding = form.getFieldValue(bindingId)[index];

        let valueInput = (
            <Input
                // disabled={isInputDisabled}
                style={{width: '100%'}}
                data-id={`binding-inputs-value-${bindingId}-${index}`}
            />);

        if (binding.type === FieldBindingType.Tag) {
            const groups = store!.tagGroups;
            valueInput = (
                <SelectWhenScroll
                    // disabled={isInputDisabled}
                    data-id={`binding-inputs-value-${bindingId}-${index}`}
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) => option?.title != null && option.title.toLowerCase().includes(input.toLowerCase())}
                    // onChange={() => setDisabledTag(!disabledTag)}
                >
                    {groups.map(g => (
                        <OptGroup key={g.id} label={g.name}>
                            {store!.getTagsForGroup(g.id).map(x => (
                                <Option data-id={`binding-inputs-value-${bindingId}-${index}-${x}`} key={x.id!} value={x.id!} title={x.name}>{x.name}</Option>
                            ))}
                        </OptGroup>
                    ))}
                </SelectWhenScroll>
            );
        }

        if (binding.type === FieldBindingType.Variable) {
            valueInput = (
                <SelectWhenScroll
                    // disabled={isInputDisabled}
                    data-id={`iota-inputs-value-${bindingId}-${index}`}
                    style={{ width: '100%' }}
                >
                    {store!.environmentVariables.map(x => (
                        <Option data-id={`binding-inputs-value-${bindingId}-${index}-${x.id}`} key={x.id} value={x.id}>{x.name}</Option>
                    ))}
                </SelectWhenScroll>
            );
        }

        if (binding.type === FieldBindingType.CodeRule) {
            valueInput = (
                <CodeBusinessRulePreview
                    // isDisabled={!!isInputDisabled}
                    isDisabled={false}
                    code={binding.value as string}
                    dataId={`binding-inputs-value-${bindingId}-${index}`}
                    onSave={c => {
                        const vals = form.getFieldValue(binding.inputId);
                        vals[index].value = c;
                        const obj = Object.create(null);
                        obj[binding.inputId] = vals;
                        form.setFieldsValue(obj);
                        saveHandler(true);
                    }}
                />);
        }

        return valueInput;
    };

    const renameField = () => {
        if (!store.selectedGroup) {
            return;
        }

        const field = store.selectedGroup.fields.find(f => f.inputId === bindingId);

        if (!field) {
            return;
        }
        
        saveHandler(false);
        store.setCurrentSectionId(field.sectionId);
        store.setCurrentFieldBinding(field);
        store.setIsRenameInputDialogVisible(true);
    };

    const deleteField = async () => {
        await saveHandler(false);
        await store.deleteField(bindingId);
    };

    const actionsMenu = () => {
        if (!store.selectedGroup) {
            return null;
        }

        const field = store.selectedGroup.fields.filter(f => f.inputId === bindingId)[0];
        const menu = (
            <Menu>
                <Menu.Item
                    data-id-type="popup-menu-list-item"
                    data-id-name="Rename field"
                    key="1" 
                    onClick={renameField}
                >
                    Rename field
                </Menu.Item>
                <Popconfirm
                    title={`Are you sure that you want to delete ${field.name}?`}
                    onConfirm={async () => await deleteField()}
                    placement="top"
                >
                    <Menu.Item
                        data-id-type="popup-menu-list-item"
                        data-id-name="Delete field"
                        key="1" 
                        danger
                    >
                        Delete field
                    </Menu.Item>
                </Popconfirm>
            </Menu>
        );

        return (
            <Dropdown overlay={menu} trigger={['click']}>
                <Button style={{verticalAlign: 'text-bottom', width: 40}} type="link" size="small"><MoreOutlined /></Button>
            </Dropdown>
        );
    };

    const handleOrientationChange = (e: React.MouseEvent, index: number) => {
        const orientation = store.getBindingOrienation(bindingId, index);
        const result = store.updateBindingOrientation(bindingId, index, orientation === 'Vertical' ? 'Horizontal' : 'Vertical');
        if (result) {
            const rotation = orientation === 'Vertical' ? -180 : -90;
            let el = e.currentTarget.children.item(0)?.children.item(0) as HTMLElement;
            el.style.transform = `rotate(${rotation}deg)`;
            el.title = orientation === 'Vertical' ? 'Horizontal' : 'Vertical';
        }
    };

    const getLabel = (index: number) => {
        const label = store.getFieldNamebyId(bindingId);
        return (
            <div className="field-binding-label" title={label}>
                <Row> 
                    <Col style={{visibility: index === 0 ? 'visible' : 'hidden', maxWidth: '90%'}}>
                        {label}
                       
                    </Col>
                </Row>
            </div>);
    };

    const getBindings = () => {
        return (
            <Form.List name={bindingId}>
                {(fields, {remove}) => (
                    <InputGroup>
                        {fields.map((field, i) => (
                            <div className="project-field-binding-container" key={field.key} >
                                <Form.Item 
                                    colon={false}
                                    style={{marginBottom: 5}}
                                    key={`${bindingId}-${i}-type`}  
                                    name={[field.name, 'type']} 
                                    label={getLabel(i)}
                                >
                                    {getDropdownForTypes(false, i)}
                                </Form.Item>
                                <Form.Item   
                                    key={`${bindingId}-${i}-value`}  
                                    name={[field.name, 'value']}
                                    className="project-field-value-input"
                                >
                                    {getValueInput(i)}
                                </Form.Item>
                                <div className="input-actions-menu">
                                    {i === 0 
                                        ? <Button 
                                            data-id={`binding-inputs-preview-${bindingId}-${i}`}
                                            type="link"
                                            size="small" onClick={() => store.previewField(bindingId)} 
                                        >
                                            <i className="alpha-icon lg play-icon green" /> 
                                        </Button> 
                                       
                                        : <Tooltip title="Tag result alignment">
                                            <Button 
                                                data-id={`binding-inputs-orientation-${bindingId}-${i}`}
                                                type="link"
                                                size="small"
                                                onClick={(e) => handleOrientationChange(e, i)}
                                                style={{width: 40}}
                                            >
                                                <ImportOutlined 
                                                    style={{cursor: 'pointer', fontSize: 16, color: '#9BA0AA', marginTop: 4}} 
                                                    rotate={store.getBindingOrienation(bindingId, i) === 'Vertical' ? -90 : -180} 
                                                />
                                            </Button>
                                        </Tooltip>
                                    }
                                    {fields.length > 1 && (
                                        <Button
                                            data-id={`binding-inputs-remove-${bindingId}-${i}`}
                                            type="link"
                                            disabled={fields.length === 1}
                                            onClick={() => {
                                                remove(i); 
                                            }}
                                            size="small"
                                        >
                                            <i className="alpha-icon lg minus-icon" />
                                        </Button>
                                    )}             
                                    {i === fields.length - 1 && (<Button
                                        data-id={`binding-inputs-add-${bindingId}`}
                                        type="link"
                                        onClick={() => {
                                            addInput(); 
                                        }}
                                        size="small"
                                    >
                                        <i className="alpha-icon lg plus-icon" />
                                    </Button>
                                    )}
                                    {i === 0 ? actionsMenu() : null}
                                </div>
                            </div>
                        ))}
                    </InputGroup>
                )}
            </Form.List>
        );
    };

    return (
        <>
            {getBindings()}
        </>
    );
};

export default observer(FieldBindingInput);