import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Form } from 'antd';
import { WorkflowImportFormValues } from '../../types';
import { WorkflowImportMapperTable } from '..';
import InstructWorkflowImportExportStore from '../../stores/InstructWorkflowImportExportStore';

interface Props {
    importExportStore: InstructWorkflowImportExportStore
}

const WorkflowImportDialog: React.FC<Props> = ({ importExportStore }) => {
    const [form] = Form.useForm<WorkflowImportFormValues>();

    return (
        <Modal
            title="Imported Results"
            visible={importExportStore.importDialogVisible}
            width={1300}
            maskClosable={false}
            closable={false}
            destroyOnClose
            centered
            footer={[
                <Button
                    form="import-workflow-form"
                    data-id="import-workflow-cancel"
                    key="import-workflow-cancel"
                    className="light"
                    size="large"
                    onClick={() => importExportStore.dispose()}
                >
                    Cancel
                </Button>,
                <Button
                    form="import-workflow-form"
                    data-id="import-workflow"
                    key="import-workflow"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={importExportStore.isCommiting}
                >
                    Import
                </Button>
            ]}
        >
            <Form
                id="import-workflow-form"
                form={form}
                onFinish={importExportStore.commitImportedWorkflow}
                preserve={false}
            >
                <WorkflowImportMapperTable importExportStore={importExportStore} form={form} />
            </Form>
        </Modal>
    );
};

export default observer(WorkflowImportDialog);
