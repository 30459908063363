import { Button, Modal } from 'antd';
import * as React from 'react';
import { ProjectsStore } from '../../common/stores';
import AddProjectForm from './AddProjectForm';

type Props = {
    projectsStore: ProjectsStore
};

const AddProjectDialog: React.FC<Props> = ({projectsStore}) => {
    const [dialogVisible, setDialogVisible] = React.useState(false);

    const closeDialog = () => {
        setDialogVisible(false);
    };

    const addProject = (name: string, type: string, keywords: string[], color: string, featureFlags: string | null) => {
        projectsStore.addProject({ id: '', title: name, type: type, keywords: keywords, color, featureFlags });
        closeDialog();
    };

    return (
        <>
            <Button 
                data-id="add-project-button" 
                className="add-project-button"
                type="link" 
                onClick={() => setDialogVisible(true)}
            >
                <i className="alpha-icon md plus-icon" style={{marginRight: 7, verticalAlign: 'middle'}} /> 
                <span style={{verticalAlign: 'middle'}}>Create new project</span>
            </Button>
            <Modal
                centered
                width={600}
                title="New project"
                destroyOnClose
                closable={false}
                maskClosable={false}
                visible={dialogVisible}
                className="alpha-portal-dialog"
                footer={[
                    <Button 
                        data-id="add-project-cancel" 
                        key="add-project-cancel" 
                        onClick={closeDialog} 
                        size="large"
                        className="light"
                    >
                        Cancel
                    </Button>,
                    <Button 
                        data-id="add-project-submit" 
                        key="add-project-submit" 
                        size="large"
                        type="primary"
                        htmlType="submit"
                        form="add-project-form"
                    >
                        Create new project
                    </Button>
                ]}
            >
                <AddProjectForm
                    onAddProject={addProject}
                    lockSubmit={projectsStore.lockNewProjectsSubmitting}
                    onSubmitCallback={closeDialog}
                />
            </Modal>
        </>
    );
};

export default AddProjectDialog;