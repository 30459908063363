import { DownloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { ColumnProps  } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../../common/services/Utils';
import { TestProjectDashboardStore } from '../../stores';
import { TestProjectRunResults } from '../../types';

type Props = {
    store: TestProjectDashboardStore
};

const TestProjectRuns: React.FC<Props> = ({store}) => {
    React.useEffect(() => {
        if (store.currentProject && store.currentTestProjectId) {
            store.loadTestRuns();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject, store.currentTestProjectId]);

    const testRunsContent = () => {
        const columns: ColumnProps<TestProjectRunResults>[] = [{
            title: 'Date time',
            dataIndex: 'runTime',
            defaultSortOrder: 'descend',
            sorter: (a: TestProjectRunResults, b: TestProjectRunResults) => Utils.safeDateStringCompare(a.runTime, b.runTime),
            render: (runTime: string, run: TestProjectRunResults) => run.id !== 'inProgress' ? (
                <Button
                    style={{marginLeft: -16}}
                    type="link"
                    className="link-button"
                    onClick={() => store.goToTestResults(run.id)}
                >
                    {Utils.formatDateStringShort(runTime)}
                </Button>
            ) : (<span style={{color: '#B80C02'}}>Running...</span>)
        }, {
            title: 'Result vs. Baseline',
            dataIndex: 'result',
            render: (_: unknown, run: TestProjectRunResults) => run.id !== 'inProgress' ? calculateResultVsBaseline(run) : null

        }, {
            dataIndex: 'error',
            key: 'error',
            render: (error: string) => error ? <Tooltip title={error}><WarningOutlined style={{color: 'red'}} /></Tooltip> : null
        }, {
            title: 'Ran by',
            dataIndex: 'userId',
            render: (_: unknown, run: TestProjectRunResults) => run.id !== 'inProgress' && !store.isLoadingUsers ? (
                run.userId ? store.getUserNameById(run.userId) : null
            ) : null
        }, {
            title: 'Report',
            dataIndex: 'report',
            render: (_: unknown, run: TestProjectRunResults) => run.id !== 'inProgress' ? (
                <Tooltip title="Download test run report">
                    <Button icon={<DownloadOutlined />} type="link" onClick={() => store.exportTestProjectRunToExcel(run.id)} />
                </Tooltip>
            ) : null
        }];
        return (
            <Table
                columns={columns}
                dataSource={store.testRunsDisplayList}
                rowKey={r => r.id}
                pagination={false}
                className="alpha-portal-table"
                loading={store.isLoadingTestRuns}
                data-id="test-project-runs-table"
            />
        );
    };

    const calculateResultVsBaseline = (run: TestProjectRunResults) => {
        const totalTopics = run.topics.length;
        const totalPassed = run.topics.filter((t) => t.baselineFuzzy <= t.fuzzy).length;
        const successPercent = totalPassed / totalTopics * 100;
        return `${Math.round((successPercent + Number.EPSILON))}%`;
    };

    return (
        <div className="test-project-dashboard-item">
            <div className="item-title">Test runs</div>
            <div className="item-content runs-table">
                {testRunsContent()}
            </div>
        </div>
    );
};

export default observer(TestProjectRuns);