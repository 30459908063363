import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FieldBindingsStore } from '../stores';

type Props = {
    store: FieldBindingsStore
};

const FieldBindingSectionCreateDialog: React.FC<Props> = ({store}) => {
    const [form] = Form.useForm();

    const handleCancel = () => {   
        store.setIsCreateSectionDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            var createdSuccessfully = await store.createFieldGroupSection(values.name);
            if (createdSuccessfully) {
                handleCancel();
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title="New field binding section"
            visible={store!.isCreateSectionDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button data-id="field-binding-add-section-dialog-cancel" className="light" key="back" size="large" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button data-id="field-binding-add-group-section-submit" key="submit" size="large" type="primary" onClick={handleSubmit}>
                    Add section
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item name="name" label="Name" rules={[{required: true, message: 'Provide name for the project field group section, please'}]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FieldBindingSectionCreateDialog);