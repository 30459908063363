import * as React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Parameter, DisplayCondition } from '../types';
import { PipelineVisualStore } from '../stores';

const hasDisplayConditions = (param: Parameter): param is Parameter & { displayConditions: DisplayCondition[] } =>
    !!(param.displayConditions && param.displayConditions.length);

type Args<T> = {
    store: T;
    param: Parameter;
    form: FormInstance
};

export default function useDisplayConditions<T extends PipelineVisualStore<S>, S extends string>(args: Args<T>) {
    const { param, form, store } = args;

    const [isVisible, setIsVisible] = React.useState(true);

    const useHook = React.useMemo(() => hasDisplayConditions(param), [param]);

    const checkConditions = React.useCallback(() => {
        if (!hasDisplayConditions(param)) {
            return;
        }

        const allConditionsMatch = param.displayConditions.every(displayCondition => {
            const fieldValue = form.getFieldValue(displayCondition.parameterName);
            const hasValue = !!fieldValue;
            const hasParameterValue = displayCondition.parameterValue !== undefined;

            if (typeof displayCondition.condition === 'boolean') {
                if (displayCondition.condition === true) {
                    return hasParameterValue ? fieldValue === displayCondition.parameterValue : hasValue;
                }

                if (displayCondition.condition === false) {
                    return hasParameterValue ? fieldValue !== displayCondition.parameterValue : !hasValue;
                }
            }

            return false;
        });

        if (!allConditionsMatch && form.getFieldValue(param.name) !== undefined) {
            form.setFieldsValue({ [`${param.name}`]: undefined });
        }

        setIsVisible(allConditionsMatch);
    }, [param, form]);

    React.useEffect(() => {
        if (!useHook) {
            return;
        }

        checkConditions();

        const subject = store.formFieldsSubject.subscribe(checkConditions);

        return () => {
            subject.unsubscribe();
        };
    }, [store, param, useHook, checkConditions]);

    return { isVisible };
}
