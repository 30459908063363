import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import InstructWorkflowsPage from '../screens/InstructWorkflowsPage';

export const InstructWorkflowsNavigation = {
    InstructWorkflowsPage: '/projects/:projectId/instruct-workflows'
};

export default () => {
    return (
        <>
            <ProtectedRoute
                component={InstructWorkflowsPage}
                path={InstructWorkflowsNavigation.InstructWorkflowsPage}
            />
        </>
    );
};
