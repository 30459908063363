import { STORE_RULES, STORE_TAGS_GROUP } from '../../rules/constants';
import { STORE_PROJECTS_ROOT_UI, STORE_ROUTER } from '../../common/constants';
import { RootStores } from '../../common/stores';
import { FieldBindingsService } from '../services';
import { STORE_PROJECT_FIELD_BINDINGS, STORE_PROJECT_FIELD_BINDINGS_PREVIEW } from './constants';
import FieldBindingsStore from './FieldBindingsStore';
import { EnvironmentVariablesService } from '../../iota_applications/services';
import FieldPreviewVisualStore from './FieldPreviewVisualStore';

export { default as FieldBindingsStore } from './FieldBindingsStore';

export function injectFieldBindingsStores(obj: RootStores) {
    const service = new FieldBindingsService();
    const envVarsService = new EnvironmentVariablesService();
    obj[STORE_PROJECT_FIELD_BINDINGS_PREVIEW] = new FieldPreviewVisualStore(obj[STORE_PROJECTS_ROOT_UI], service, obj[STORE_RULES]);
    obj[STORE_PROJECT_FIELD_BINDINGS] = new FieldBindingsStore(obj[STORE_PROJECTS_ROOT_UI], service, obj[STORE_RULES], 
        obj[STORE_TAGS_GROUP], obj[STORE_ROUTER], obj[STORE_PROJECT_FIELD_BINDINGS_PREVIEW], envVarsService);
}

export type FieldBindingsStores = {
    [STORE_PROJECT_FIELD_BINDINGS]?: FieldBindingsStore;
    [STORE_PROJECT_FIELD_BINDINGS_PREVIEW]?: FieldPreviewVisualStore
};