import PackageLine from './PackageLine';
import PackageLineState from './PackageLineState';

export default class ChangedPackageLine {
    constructor(public readonly line: PackageLine) {

    }

    get id() {
        return `${this.line.pkg.id}-${this.line.rowId}`;
    }

    get prevValue() {
        switch (this.line.state) {
        case PackageLineState.ChangedLabel:
            return this.line.initialLabel && this.line.initialLabel.text || 'None';
        default:
            return null;
        }
    }

    get curValue() {
        switch (this.line.state) {
        case PackageLineState.ChangedLabel:
            return this.line.label && this.line.label.text || null;
        default:
            return null;
        }
    }

    get shortText() {
        return this.line.shortText;
    }

    get state() {
        return this.line.state;
    }

    get text() {
        return this.line.text;
    }
}