import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import { FormPartPreviewPage, FormTypesPage } from '../screens';

export const FormTypesPageNavigation = {
    FormTypesPage: '/projects/:projectId/form-types',
    FormPartPreviewPage: '/projects/:projectId/form-parts/:formPartId/preview',
};

export default () => {
    return (
        <React.Fragment>
            <ProtectedRoute exact component={FormTypesPage} path={FormTypesPageNavigation.FormTypesPage} /> 
            <ProtectedRoute exact component={FormPartPreviewPage} path={FormTypesPageNavigation.FormPartPreviewPage} /> 
        </React.Fragment>
    );
};