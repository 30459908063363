export default class FeatureFlags {
    general: { [key: string]: string };
    text: { [key: string]: string };
    image: { [key: string]: string };

    constructor() {
        this.general = {};
        this.text = {};
        this.image = {};
    }
}