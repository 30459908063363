import { gql } from 'apollo-boost';
import { isRefData,
    isElasticMatch,
    isElasticMatchPhrase,
    isElasticQuery,
    isElasticRaw,
    isInferenceRule,
    isNamedEntitiesRecognitionRule,
    isSmartIndexRule
} from './helpers';
import { InferenceRule, ElasticSearchRawRule, ElasticSearchQueryRule,
    ElasticSearchMatchPhraseRule, PipelineStep, ElasticSearchMatchRule,
    NamedEntitiesRecognitionRule, RefDataRule, Connection, RuleTypes } from '../models';
import { PipelineStepResult, RuleResult, GetRulesResult, GetConnectionsResult, 
    PreviewResult, PreviewResultWithPackageId, ConnectionEditResult, GetConnectionResult, EditorModel } from '../types';
import security from '../../common/services/SecurityService';
import { execQuery } from '../../common/services/DataService';
import PipelineStepType from '../types/PipelineStepType';
import RuleType from '../types/RuleType';
import { TagsGroupModel, TagsGroupModelsResult } from '../models/TagsGroupModel';
import { TagModelsResult, TagModel } from '../models/TagModel';
import { RulesImportMappingData, RulesToUploadData } from '../types';
import { Utils } from '../../common/services/Utils';
import appClient, { ResultApi } from '../../common/services/AppClient';
import _ from 'lodash';
import { ProjectTagsVersionInfo } from '../../common/types/ProjectTagsVersionInfo';
import { PinnedRules } from '../../common/types/PinnedRules';
import SmartIndexRule from '../models/SmartIndexRule';
export default class RulesService {
    async getRules(type: RuleType, projectId?: string): Promise<RuleTypes[]> {
        let result;
        if (projectId) {
            result = await this.getRulesByTypeForProject(type, projectId);
        } else {
            result = await this.execute(type);
        }
        
        if (result.errors) {
            throw result.errors[0];
        }

        function makePipeline(pip: PipelineStepResult<PipelineStepType>[]) {
            return (pip || []).map(p => new PipelineStep(p.name, p.type, p.parameters || {}, p.stepId, undefined, p.isDisabled));
        }

        return result.data.getRules.map(d => {
            const pipeline = makePipeline(d.pipeline);

            if ('sqlQuery' in d) {
                const con = new Connection(d.connection);
                // eslint-disable-next-line max-len
                return new RefDataRule(d.id, d.projectId, d.name, d.tagId, d.tag, d.sqlQuery, con, pipeline, d.groupId, d.updateDate, d.priority || 1, d.description, d.state, d.status, d.overridePriority ?? false);
            }      
            
            if (isElasticMatch(d)) {
                // eslint-disable-next-line max-len
                return new ElasticSearchMatchRule(d.id, d.projectId, d.name, d.tagId,  d.tag, d.query, d.minimumPercentageShouldMatch, d.operator, pipeline,  d.groupId, d.updateDate, d.priority || 1, d.description, d.state,  d.status, d.overridePriority ?? false, d.excludedBlockTypes);
            }

            if (isElasticMatchPhrase(d)) {
                return new ElasticSearchMatchPhraseRule(d.id, d.projectId, d.name, d.tagId,  d.tag, d.query, d.slope, pipeline,
                    d.groupId, d.updateDate, d.priority || 1, d.description, d.state,  d.status, d.overridePriority ?? false, d.excludedBlockTypes);
            }

            if (isElasticQuery(d)) {
                return new ElasticSearchQueryRule(d.id, d.projectId, d.name, d.tagId,  d.tag, d.query, 
                    d.minimumShouldMatch || 100, d.operator || 'Or', d.phraseSlope || 0, pipeline,  d.groupId, 
                    d.updateDate, d.priority || 1, d.description, d.state,  d.status, d.overridePriority ?? false, d.excludedBlockTypes);
            }

            if (isElasticRaw(d)) {
                // eslint-disable-next-line max-len
                return new ElasticSearchRawRule(d.id, d.projectId, d.name, d.tagId,  d.tag, d.query, pipeline,  d.groupId, d.updateDate, d.priority || 1, d.description, d.state,  d.status, d.overridePriority ?? false);
            }

            if (isInferenceRule(d)) {
                return new InferenceRule(d.id, d.projectId, d.name, d.tagId,  d.tag, d.modelId, d.labels, pipeline,  d.groupId,
                    d.pageRange, d.blockType,  d.threshold, d.updateDate, d.priority || 1, d.description, d.state,  d.status, d.overridePriority ?? false);
            }

            if (isNamedEntitiesRecognitionRule(d)) {
                return new NamedEntitiesRecognitionRule(d.id, d.projectId, d.name, d.tagId,  d.tag, d.nerModelId, d.entities, pipeline,  d.groupId,
                    d.pageRange, d.blockType,  d.updateDate, d.priority || 1, d.description, d.state, d.status, d.overridePriority ?? false);
            }

            if (isSmartIndexRule(d)) {
                return new SmartIndexRule(d.id, d.projectId, d.name, d.tagId,  d.tag, d.instructWorkflowId, d.prompt, pipeline,  d.groupId, d.updateDate,
                    d.priority || 1, d.description, d.state,  d.status, d.overridePriority ?? false, d.outputSchemeName);
            }

            throw new Error('Not implemented');
        });
    }

    async getConnections(projectId?: string): Promise<Connection[]> {
        let result;
        if (projectId) {
            result  = await this.executeConnectionQueryForProject(projectId);
        } else {
            result  = await this.executeConnectionQuery();
        }

        if (result.errors) {
            throw result.errors[0];
        }

        return result.data.getConnections.map(x => new Connection(x));
    }

    async getConnectionById(id: string): Promise<Connection | null> {
        const result  = await this.executeConnectionQueryById(id);
        if (result.errors) {
            throw result.errors[0];
        }

        if (result.errors) {
            throw result.errors[0];
        }

        const res = result.data.getConnection;
        if (!res) {
            return null;
        } 

        return new Connection(res);
    }

    async getTagsGroupsByProject(projectId: string): Promise<TagsGroupModel[] | null> {
        const result = await execQuery<TagsGroupModelsResult>({
            /* eslint-disable max-len */
            query: gql`query getTagsGroups($projectId:String!) {
                getTagsGroups(projectId: $projectId) {
                    id
                    tagIds
                    name
                    projectId
                    description
                    position
                    updateDate
                }
            }`,
            variables: {
                projectId: projectId,
            },
            fetchPolicy: 'network-only'
        });
        return result.data.getTagsGroups;
    }

    async getTagsByProject(projectId: string): Promise<TagModel[] | null> {
        const result = await execQuery<TagModelsResult>({
            /* eslint-disable max-len */
            query: gql`query getTags($projectId:String!) {
                getTags(projectId: $projectId) {
                    id
                    name
                    projectId
                    position
                    groupId
                }
            }`,
            variables: {
                projectId: projectId,
            },
            fetchPolicy: 'network-only'
        });
        return result.data.getTags;
    }

    async saveRule(rule: RuleResult) {
        const url = this.getRequestString(rule);
        return appClient.post<RuleTypes>(url, rule);
    }

    updateRulePriority(projectId: string, ruleId: string, priority: number) {
        const request = {
            ruleId: ruleId,
            priority: priority
        };
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/updateRulePriority`;
        return appClient.update(url, request);
    }

    delete(projectId: string, id: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/${id}`;
        return appClient.delete(url);
    }

    deleteTag(projectId: string, groupId: string,  tagId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/tag/${tagId}`;
        return appClient.delete(url);
    }

    async executeRule(projectId: string, rule: RuleResult, packagesId: string[]) {
        const result: PreviewResultWithPackageId[] = [];

        function makeUrl(packageId: string) {
            if (isElasticMatch(rule)) {
                return process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/elasticsearch/match/preview/${packageId}`;
            }

            if (isElasticQuery(rule)) {
                return process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/elasticsearch/query/preview/${packageId}`;
            }

            if (isElasticRaw(rule)) {
                return process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/elasticsearch/raw/preview/${packageId}`;
            }

            if (isElasticMatchPhrase(rule)) {
                return process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/elasticsearch/match_phrase/preview/${packageId}`;
            }

            if (isRefData(rule)) {
                return process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/refdata/preview/${packageId}`;
            }

            if (isInferenceRule(rule)) {
                return process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/inference/preview/${packageId}`;
            }

            if (isNamedEntitiesRecognitionRule(rule)) {
                return process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/namedentitiesrecognition/preview/${packageId}`;
            }

            if (isSmartIndexRule(rule)) {
                return process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/smart_index/preview/${packageId}`;
            }

            throw new Error('Not implemented');
        }
        rule.pipeline = rule.pipeline.filter(x => !x.isDisabled);

        const jobs = new Array<Promise<ResultApi<unknown>>>();
        for (const packageId of packagesId) {
            const url = makeUrl(packageId);
            jobs.push(appClient.post(url, rule));
        }       

        const responses = await Promise.all(jobs);
        _.zip(responses, packagesId).forEach(d => {
            d[0]!.map(data => {
                result.push({ packageId: d[1]!, previewResult: <PreviewResult>data});
            }).mapErr(err => {
                const errorMessage = err.data ? err.data.title : err.text;
                result.push({ packageId: d[1]!, previewResult: {
                    rule: {...rule, pipelineExecutionErrorMsg: errorMessage},
                    entries: []
                }});
            });
        }); 
        
        return result;
    }

    validateConnection(projectId: string, connectionString: string, connectionType: string) {
        let url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/refdata/connections/validate`;
        let request = {ConnectionString: connectionString, ConnectionType: connectionType};
        return appClient.post(url, request);        
    }

    async updateConnection(projectId: string, connection: ConnectionEditResult) {
        let url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/refdata/connections`;
        return appClient.post(url, connection);
    }

    async exportConnection(id: string, projectId: string) {
        return Utils.CreateTabForDownload(id, `projects/${projectId}/rules/refData/connections/export`);
    }   

    async deleteConnection(projectId: string, id: string) {
        let url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/refdata/connections/${id}`;
        return appClient.delete(url);
    }

    async getEditorModels(projectId: string) {
        let url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/editorModels`;
        const resp = await appClient.get<EditorModel<PipelineStepType> | undefined>(url);
        return resp.unwrapOr(undefined);
    }
    
    async exportRules(projectId: string, rulesIds: string[]) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'rulePostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';
        rulesIds.forEach(r => {
            const mapRuleIdInput = document.createElement('input');
            mapRuleIdInput.type = 'text';
            mapRuleIdInput.name = 'rulesIds[]';
            mapRuleIdInput.value = r;
            mapForm.appendChild(mapRuleIdInput);
        });
       
        await security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url =  process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/export${t}`;
            Utils.downloadFile(url, mapForm, 'rulePostForm');
            return Promise.resolve();
        });
    }

    async exportAllProjectRules(projectId: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'rulePostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';
       
        await security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url =  process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/export-project-rules${t}`;
            Utils.downloadFile(url, mapForm, 'rulePostForm');
            return Promise.resolve();
        });
    }

    async importRules(data: FormData, projectId: string) {
        let url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/rules/import`;
        const resp = await appClient.post<RulesToUploadData | undefined>(url, data);
        return resp.unwrapOr(undefined);

    }

    commitImportedRules(
        ruleIdGroupDict: {},
        tagActionDict: {},
        rulesImportMappingData: RulesImportMappingData[],
        action: string
    ) {
        const data = {
            ruleIdGroupDict: ruleIdGroupDict,
            tagActionDict: tagActionDict,
            rulesImportMappingData: rulesImportMappingData
        };
        return appClient.post(action, data);
    }

    async createGroup(projectId: string, name: string, description: string, id?: string) {
        const data = {
            name: name,
            description: description,
            id: id
        };
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/groups`;
        return appClient.post(url, data);
    }

    async deleteGroup(projectId: string, groupId: string): Promise<void>  {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/groups/${groupId}`;
        await appClient.delete(url);
    }

    async updateTagRowPosition(projectId: string, groupId: string, dragIndex: number, dropIndex: number, lastModified: string | null) {
        const data = {
            groupId: groupId,
            dragIndex: dragIndex,
            dropIndex: dropIndex,
            lastModified: lastModified
        };
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/updateTagRowPosition`;
        const resp = await appClient.post<string | null>(url, data);
        return resp.unwrapOr(null);
    }

    async moveTagToAnotherGroup(projectId: string, destGroupId: string, tagId: string, orinGroupLastModified: string | null, destGroupLastModified: string | null) {
        const data = {
            groupId: destGroupId,
            tagId: tagId,
            orinGroupLastModified: orinGroupLastModified,
            destGroupLastModified: destGroupLastModified
        };
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/moveTagToAnotherGroup`;
        const resp = await appClient.post<string | null>(url, data);
        return resp.unwrapOr(null);
    }

    async updateGroupRowPosition(projectId: string, dragIndex: number, dropIndex: number, lastModified: string | null) {
        const data = {
            dragIndex: dragIndex,
            dropIndex: dropIndex,
            lastModified: lastModified
        };
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/updateGroupRowPosition`;
        const resp = await appClient.post<string | null>(url, data);
        return resp.unwrapOr(null);
    }

    async getTagRulesPreview(projectId: string, tagId: string, packageId: string): Promise<ResultApi<PreviewResult[]>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/previewTag/${tagId}/${packageId}`;
        const resp = await appClient.get<PreviewResult[]>(url);  

        return resp;
    }

    async updateRuleState(projectId: string, ruleId: string, state: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/updateRuleState/${ruleId}/${state}`;
        return appClient.post(url);  
    }

    async updateRuleTag(projectId: string, tagId: string, tagName: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/tag/${tagId}`;
        const data = {tagName: tagName};
        return appClient.update(url, data);  
    }

    async updateTagsVersion(projectId: string, version: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/version`;
        const data = {projectId, version};
        return appClient.post(url, data);  
    }

    getAuthToken() {
        return security.invoke((token) => {
            return Promise.resolve(token);
        });
    }

    async getProjectTagsVersion(projectId: string): Promise<ProjectTagsVersionInfo | undefined> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/tags-version`;
        const resp = await appClient.get<ProjectTagsVersionInfo | undefined>(url);  

        return resp.unwrapOr(undefined);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async validateRegexPatternValue(projectId: string, value: string, regex: string,  stepType: PipelineStepType, parameters: Map<string, any>, ruleId: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/pipeline_step/${stepType}/regex_validate`;
        const request = {
            value,
            regex,
            stepType,
            parameters,
            ruleId
        };
        const resp = await appClient.post<string>(url, request);  
        return resp.unwrapOr('');
    }

    async getPinnedRules(projectId: string): Promise<PinnedRules | undefined> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/pinned-rules`;
        const resp = await appClient.get<PinnedRules | undefined>(url);  

        return resp.unwrapOr(undefined);
    }

    async pinRule(projectId: string, ruleId: string): Promise<ResultApi<unknown>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/pin/${ruleId}`;
        return appClient.post(url);  
    }

    async unpinRule(projectId: string, ruleId: string): Promise<ResultApi<unknown>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/unpin/${ruleId}`;
        return appClient.update(url);  
    }
 
    private getRequestString(rule: RuleResult) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${rule.projectId}/rules/`;

        if (isRefData(rule)) {
            return `${url}refdata`;
        }

        if (isElasticMatch(rule)) {
            return`${url}elasticsearch/match`;
        }

        if (isElasticMatchPhrase(rule)) {
            return `${url}elasticsearch/match_phrase`;
        }

        if (isElasticQuery(rule)) {
            return `${url}elasticsearch/query`;
        }

        if (isElasticRaw(rule)) {
            return `${url}elasticsearch/raw`;
        }

        if (isInferenceRule(rule)) {
            return `${url}inference`;
        }

        if (isNamedEntitiesRecognitionRule(rule)) {
            return `${url}namedentitiesrecognition`;
        }

        if (isSmartIndexRule(rule)) {
            return `${url}smart_index`;
        }

        throw new Error('Not implemented!');
    }

    private execute(type: RuleType) {
        return execQuery<GetRulesResult>({
            /* eslint-disable max-len */
            query: gql`query getRulesByType($ruleType:RuleType) {
                getRules(ruleType:$ruleType) {
                        id
                        projectId
                        groupId
                        priority
                        overridePriority
                        description
                        name
                        tag
                        tagId
                        ruleType
                        state
                        status
                        ... on RefDataRule {
                            sqlQuery,
                            connection
                        }
                        ... on ElasticSearchMatchRule {
                            query,
                            operator,
                            minimumPercentageShouldMatch,
                            excludedBlockTypes
                        }
                        ... on ElasticSearchMatchPhraseRule {
                            query,
                            slope,
                            excludedBlockTypes
                        }
                        ... on ElasticSearchQueryRule {
                            query,
                            phraseSlope,
                            minimumShouldMatch,
                            operator,
                            excludedBlockTypes

                        }
                        ... on ElasticSearchRawRule {
                            query
                        }
                        ... on InferenceRule {
                            modelId,
                            labels,
                            blockType,
                            pageRange
                        }
                        ... on NamedEntitiesRecognitionRule {
                            nerModelId,
                            entities,
                            blockType,
                            pageRange
                        }
                        ... on SmartIndexRule {
                            instructWorkflowId,
                            prompt,
                            outputSchemeName
                        }
                        pipeline {
                            stepId
                            type
                            name
                            parameters
                            isDisabled
                        }
                }
            }`,
            variables: {
                ruleType: type,
            },
            fetchPolicy: 'network-only'
        });
    }

    private getRulesByTypeForProject(type: RuleType, projectId: string) {
        return execQuery<GetRulesResult>({
            /* eslint-disable max-len */
            query: gql`query getRulesByType($ruleType:RuleType, $projectId:String) {
                 getRules(ruleType:$ruleType, projectId:$projectId) {
                     id
                     groupId
                     priority
                     overridePriority
                     description
                     name
                     tag
                     tagId
                     ruleType
                     updateDate
                     state
                     status
                     projectId
                     ... on RefDataRule {
                         sqlQuery,
                         connection
                     }
                     ... on ElasticSearchMatchRule {
                         query,
                         operator,
                         minimumPercentageShouldMatch,
                         excludedBlockTypes
                     }
                     ... on ElasticSearchMatchPhraseRule {
                         query,
                         slope,
                         excludedBlockTypes,
                         excludedBlockTypes
                     }
                     ... on ElasticSearchQueryRule {
                         query,
                         phraseSlope,
                         minimumShouldMatch,
                         operator,
                         excludedBlockTypes
                     }
                      ... on ElasticSearchRawRule {
                         query
                     }
                     ... on InferenceRule {
                         modelId,
                         labels,
                         blockType,
                         pageRange,
                         threshold
                     }
                     ... on NamedEntitiesRecognitionRule {
                         nerModelId,
                         entities,
                         blockType,
                         pageRange
                     }
                     ... on SmartIndexRule {
                         instructWorkflowId,
                         prompt,
                         outputSchemeName
                     }
                     pipeline {
                         stepId
                         type
                         name
                         parameters
                         isDisabled
                     }
                 }
             }`,
            variables: {
                ruleType: type,
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });
    }

    private executeConnectionQuery() {
        return execQuery<GetConnectionsResult>({
            /* eslint-disable max-len */
            query: gql`query getConnections {   
                getConnections {
                    id,
                    name,
                    connectionType,
                    connectionString                    
                }
            }`,
            fetchPolicy: 'network-only'
        });
    }      

    private executeConnectionQueryById(id: string) {
        return execQuery<GetConnectionResult>({
            /* eslint-disable max-len */
            query: gql`query getConnection($id:String) {   
                 getConnection(id: $id) {
                     id,
                     name,
                     connectionType,
                     connectionString
                 }
             }`,
            variables: {
                id: id
            },
            fetchPolicy: 'network-only'
        });
    }      
    
    private executeConnectionQueryForProject(projectId: string) {
        return execQuery<GetConnectionsResult>({
            /* eslint-disable max-len */
            query: gql`query getConnections($projectId:String) {   
                 getConnections(projectId:$projectId) {
                     id,
                     name,
                     connectionType,
                     connectionString
                 }
             }`,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });
    }   
}