import { Button, Input, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/services/Utils';
import { AppRoles } from '../../authorization/Permissions';
import UsersManagerStore from '../stores/UsersManagerStore';
import { RoleModel } from '../types/RoleModel';
import { UserModel } from '../types/UserModel';
import { safeStringLocaleCompare } from '../../common/Utils';

type Props = {
    store: UsersManagerStore
};

const UsersList: React.FC<Props> = ({store}) => {
  
    React.useEffect(() => {
        store.getUsersCount();
        return () => {
            store.setUserSearchString('');
        };
    }, [store]);

    const calcRoleWeights = (roles: RoleModel[]) => {
        const clientAppUserRoleWeight = 1;
        const userRoleWeight = 2;
        const editorRoleWeight = 4;
        const adminRoleWeight = 8;

        let cumulativeWeight = 0;

        const roleNames = roles.map(r => r.name);
        if (roleNames.includes(AppRoles.admin)) {
            cumulativeWeight += adminRoleWeight;
        }

        if (roleNames.includes(AppRoles.editor)) {
            cumulativeWeight += editorRoleWeight;
        }

        if (roleNames.includes(AppRoles.user)) {
            cumulativeWeight += userRoleWeight;
        }

        if (roleNames.includes(AppRoles.client_app)) {
            cumulativeWeight += clientAppUserRoleWeight;
        }

        return cumulativeWeight;
    };

    const sortByRoles = (a: RoleModel[], b: RoleModel[]) => {
        const rolesWeight1 = calcRoleWeights(a);
        const rolesWeight2 = calcRoleWeights(b);

        return rolesWeight1 > rolesWeight2 ? 1 : rolesWeight1 < rolesWeight2 ? -1 : 0;
    };

    const editUser = (user: UserModel) => {
        store!.handleNodeSelection(user.id);
        store.setIsEditUserDialogVisible(true);
    };

    const columns: ColumnsType<UserModel> = [{
        key: 'firstName',
        dataIndex: 'firstName',
        title: 'First name',
        defaultSortOrder: 'descend',
        sorter: (a: UserModel, b: UserModel) => safeStringLocaleCompare(a.firstName, b.firstName),
        render: (firstName: string) => {
            return {
                children: firstName,
                props: {
                    'data-id-value': firstName,
                    'data-id-type': 'user-list',
                    'data-id-cells': 'First name'
                }
            };
        }
    }, {
        key: 'lastName',
        dataIndex: 'lastName',
        title: 'Last name',
        sorter: (a: UserModel, b: UserModel) => safeStringLocaleCompare(a.lastName, b.lastName),
        render: (lastName: string) => {
            return {
                children: lastName,
                props: {
                    'data-id-value': lastName,
                    'data-id-type': 'user-list',
                    'data-id-cells': 'Last name'
                }
            };
        }
    },
    {
        key: 'userName',
        dataIndex: 'userName',
        title: 'Username',
        sorter: (a: UserModel, b: UserModel) => safeStringLocaleCompare(a.userName, b.userName),
        render: (userName: string) => {
            return {
                children: userName,
                props: {
                    'data-id-value': userName,
                    'data-id-type': 'user-list',
                    'data-id-cells': 'Username'
                }
            };
        }
    }, {
        key: 'email',
        dataIndex: 'email',
        title: 'Email',
        sorter: (a: UserModel, b: UserModel) => safeStringLocaleCompare(a.email, b.email),
        render: (email: string) => {
            return {
                children: email,
                props: {
                    'data-id-value': email,
                    'data-id-type': 'user-list',
                    'data-id-cells': 'Email'
                }
            };
        }
    }, {
        key: 'groups',
        dataIndex: 'groups',
        title: 'Group',
        sorter: (a: UserModel, b: UserModel) => safeStringLocaleCompare(a.groups[0], b.groups[0]),
        render: (groups: string[]) => {
            const groupName = groups.length ? store.getGroupNameById(groups[0]) : '';
            return {
                children: groupName,
                props: {
                    'data-id-value': groupName,
                    'data-id-type': 'user-list',
                    'data-id-cells': 'Group'
                }
            };
        }
    }, {
        key: 'roles',
        dataIndex: 'roles',
        title: 'Roles',
        sorter: (a: UserModel, b: UserModel) => sortByRoles(a.roles, b.roles),
        render: (roles: RoleModel[]) => {
            return {
                children: (<div>{roles && roles.map(r => (
                    <Tag className="alpha-portal-tag" color={r.name === AppRoles.admin ? '#273C75' : '#9BA0AA'} key={r.id}>{Utils.getAppRoleDisplayName(r.name)}</Tag>
                ))}</div>),
                props: {
                    'data-id-value': roles.map(r => r.name).join(','),
                    'data-id-type': 'user-list',
                    'data-id-cells': 'Roles'
                }
            };
        }
    }, {
        key: 'actions',
        title: null,
        render: (val: unknown, record: UserModel) => (
            <div className="row-actions-wrapper">
                <Tooltip title="Edit user">
                    <Button 
                        type="link" 
                        onClick={() => editUser(record)} 
                        size="small"
                        data-id-cells="Edit user"
                        data-id-name={record.userName} 
                    >
                        <i className="alpha-icon lg edit-icon" />
                    </Button>
                </Tooltip>
            </div>
        )
    }];

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13 || event.key === 'Enter') {
            store.performSearch(event.currentTarget.value);
        }
    };

    return (
        <>
            <div className="table-search-wrapper">
                <Input.Search 
                    data-id="input-search"
                    className="alpha-search-input" 
                    style={{width: 200}} 
                    allowClear
                    placeholder="Search"
                    onKeyPress={handleEnterPress}
                    onSearch={(val) => store.performSearch(val)}
                />
            </div>
            <Table 
                rowKey={(r) => r.id}
                className="alpha-portal-table users"
                columns={columns}
                pagination={{
                    total: store.usersTotalCount,
                    pageSize: store.usersPageSize,
                    current: store.usersCurrentPage,
                    hideOnSinglePage: true,
                    onChange: (page) => store.handlePageChange(page),
                    showSizeChanger: false
                }}
                dataSource={store.users}
                loading={store.tableIsLoading || store.isLoading || store.isLoadingUsersCount}
            />
        </>
    );
};

export default observer(UsersList);