import { AntTreeNodeExpandedEvent } from 'antd/lib/tree';
import _ from 'lodash';
import { observable, computed, action, runInAction } from 'mobx';
import { Project, PackageState } from '../../common/models';
import { Utils } from '../../common/services/Utils';
import { ProjectsRootVisualStore } from '../../common/stores';
import { PackageChange } from '../../common/types';
import { PreviewVisualStore } from '../../pipeline_base/stores';
import { FILTER_DEBOUNCE_TIME } from '../../pipeline_base/stores/PreviewVisualStore';
import { TagPreviewResultWithPackageId, TagPreviewResultWithConditionName } from '../types';
import RulesStore from './RulesStore';
import { Subscription } from 'rxjs';

type PreviewResult = TagPreviewResultWithPackageId | TagPreviewResultWithConditionName;

export default abstract class  PreviewVisualStoreBase extends PreviewVisualStore {
    @observable
    selectedItemId: string | undefined;

    @observable
    selectedItemLabel: string | undefined;
    
    @observable
    previewResults: PreviewResult[] = [];

    @observable
    selectedPackageIds: string[] = [];

    @observable
    isLoading: boolean;

    @observable
    isExecuting: boolean = false;

    @observable
    filter: string | null = null;

    @observable
    documentsFilter: string | null = null;

    @observable
    inputValue: string = '';

    @observable
    entriesExpandedKeys: { [id: string]: string[] } = {};

    @observable
    checkAllKeys: boolean;

    @observable
    totalPackagesCount: number = 0;

    @observable
    packagesListPage: number = 1;

    currentCollapsedNodeKey: string;

    private debounceFilterDocuments: Function;

    private debounceFilterNodes: Function;

    private debounceSearchDocuments: Function;

    private subscription: Subscription | null = null;

    abstract isEditable: boolean;

    @computed
    get readyPackages() {
        return this.packages.filter(p => p.serverState === PackageState.Ready);
    }

    @computed
    get selectAllIndeterminate() {
        return this.filteredDocumentsForCurrentProject !== null
            && this.filteredDocumentsForCurrentProject !== null
            && this.filteredDocumentsForCurrentProject.length > 0
            && this.selectedPackageIds.length > 0
            && this.selectedPackageIds.length !== this.filteredDocumentsForCurrentProject.length;
    }

    @computed
    get project() {
        return this.projectStore.currentProject;
    }

    @computed
    get filteredDocumentsForCurrentProject() {
        if (this.projectStore.currentProject) {
            let currProject = this.projectStore.currentProject;
            let filter = this.documentsFilter ? this.documentsFilter.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, '\\$&') : null;
            if (this.loadedPackages[currProject.id]) {
                let packages = filter && filter.length
                    ? this.loadedPackages[currProject.id].filter(s => s.name.trim().match(new RegExp(filter!, 'i')))
                    : this.loadedPackages[currProject.id];

                if (this.currentTags && this.currentTags.length) {
                    packages = packages.filter(p => _.intersection(p.userTags, this.currentTags).length === this.currentTags.length);
                }
                packages = packages.sort(Utils.sortPackagesByDate);
                return packages;
            }
        }
        return null;
    }

    constructor(public projectStore: ProjectsRootVisualStore, public rulesStore: RulesStore) {
        super(projectStore);
        this.debounceFilterNodes = _.debounce(this.filterNodes, FILTER_DEBOUNCE_TIME);
        this.debounceFilterDocuments = _.debounce(this.filterDocuments, FILTER_DEBOUNCE_TIME);
        this.debounceSearchDocuments =  _.debounce(this.searchDocuments , FILTER_DEBOUNCE_TIME);
    }

    get currentProject() {
        return this.projectStore.currentProject;
    }

    @action.bound
    async loadProject(proj: Project) {
        if (!proj.isPackagesLoaded) {
            await this.rootStore.getPackages(proj);
        }

        runInAction(() => {
            this.loadedPackages[proj.id] = proj.packages;
        });
    }

    @action.bound
    filterNodes(query: string) {
        this.filter = query;
    }

    @action.bound
    filterDocuments(query: string) {
        this.documentsFilter = query;
    }

    @action.bound
    setInputValue(query: string) {
        this.inputValue = query;
    }

    @action.bound
    debouncedFilterNodes(query: string) {
        runInAction(() => {
            this.debounceFilterNodes(query);
        });
    }

    @action.bound
    debouncedFilterDocuments(query: string) {
        runInAction(() => {
            this.debounceFilterDocuments(query);
        });
    }

    
    @action
    debouncedSearchDocuments(query: string) {
        runInAction(() => {
            this.debounceSearchDocuments(query);
        });
    }

    @action.bound
    handlePackageCheck(pkg: string, checked: boolean) {
        if (checked) {
            this.selectedPackageIds = [...this.selectedPackageIds, pkg];
        } else {
            this.selectedPackageIds = this.selectedPackageIds.filter(x => x !== pkg);
        }
    }

    @action.bound
    editRule(ruleId: string | null) {
        if (ruleId) {
            this.rulesStore.rulesEditSubject.next(ruleId);
        }
    }

    @action.bound
    setCheckAllKeys(check: boolean) {
        const selectAll = check && !!this.readyPackages;
        const checkedPackages = selectAll ? this.readyPackages.map(p => p.id) : [];
        this.checkAllKeys = check;
        this.selectedPackageIds = checkedPackages;
    }

    @action.bound
    setEntriesExpandedKeys(id: string, n: string[], event: AntTreeNodeExpandedEvent | undefined) {
        this.entriesExpandedKeys[id] = n;
        if (event && event.expanded === false && event.node) {
            this.currentCollapsedNodeKey = event.node.props.eventKey!;
        }
    }

    @action.bound
    addExpandedKey(id: string, key: string) {
        if (!this.entriesExpandedKeys[id]) {
            this.entriesExpandedKeys[id] = [];
        }
        this.entriesExpandedKeys[id].push(key);
    }

    @action.bound
    setPackagesListPage(page: number) {
        this.packagesListPage = page;
    }

    @action.bound
    handlePackageChanges(packageChange: PackageChange) {
        if (!this.project) {
            return;
        }
        
        if (this.project.id === packageChange.projectId && packageChange.state === PackageState.Ready) {
            this.loadProjectPackages();
        }
    }

    @action.bound
    async loadProjectPackages() {
        try {
            this.setIsLoadingPackages(true);
            const response = await this.loadPackages(this.packagesListPage - 1, undefined, undefined, this.documentsFilter);
            
            runInAction(() => {
                this.packages = response.lines;
                this.totalPackagesCount = response.total;

                const projectId = response.lines[0]?.project.id;
                if (projectId) {
                    this.loadedPackages[projectId] = response.lines;
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setIsLoadingPackages(false);
        }
    }

    @action.bound
    searchDocuments(query: string) {
        this.filterDocuments(query);
        this.setPackagesListPage(1);
        this.loadProjectPackages();
    }

    @action.bound
    resetPreview() {
        this.previewResults = [];
        this.entriesExpandedKeys = {};
        this.selectedPackageIds = [];
        this.checkAllKeys = false;
    }

  
    @action.bound
    setSelectedPackageIds(packageIds: string[]) {
        this.selectedPackageIds = packageIds;        
        this.checkAllKeys = this.filteredDocumentsForCurrentProject != null && this.selectedPackageIds.length === this.filteredDocumentsForCurrentProject.length;        
    }

    @action.bound
    setIsLoading(loading: boolean) {
        this.isLoading = loading;
    }

    @action.bound
    setDocumentFilter(filter: string | null) {
        this.documentsFilter = filter;
    }

    setUrl() {
        return;
    }

    getPackageNameById(id: string) {
        if (this.project && this.loadedPackages[this.project.id] && this.loadedPackages[this.project.id].find(p => p.id === id)) {
            return this.loadedPackages[this.project.id].find(p => p.id === id)!.name;
        }

        return id;
    }

    subscribeToPackageChanges() {
        this.subscription = this.projectsVisualStore.projectsStore.packageChanges.subscribe(p => this.handlePackageChanges(p));
    }

    unsubscribeFromPackageChanges() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    abstract loadPreviewResults(groupId?: string): Promise<void>;

    abstract goToInitialList(): void;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    abstract setItem(m: any): void;

    abstract initUsingProjectId(projectId: string): void;
}
