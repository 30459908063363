/* eslint-disable no-undef */
import $ from 'jquery';
import '@progress/kendo-ui/js/kendo.spreadsheet';
import '@progress/kendo-ui/css/web/kendo.common.min.css';
import '@progress/kendo-ui/css/web/kendo.default-v2.min.css';

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { DocumentViewVisualStore } from '../stores';

type Props = {
    store: DocumentViewVisualStore
};

const ExcelViewer: React.FC<Props> = ({ store }) => {
    const [spreadsheet, setSpreadsheet] = React.useState<kendo.ui.Spreadsheet | null>(null);

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        const kendoSpreadsheet = $(ref.current)
            .kendoSpreadsheet({
                sheetsbar: false,
                toolbar: false
            })
            .data('kendoSpreadsheet');

        setSpreadsheet(kendoSpreadsheet);
    }, []);

    React.useEffect(() => {
        if (!spreadsheet || !store.fileBlob) {
            return;
        }

        spreadsheet.fromFile(store.fileBlob);
    }, [spreadsheet, store.fileBlob]);

    return <div ref={ref} style={{ width: 'calc(100% - 5px)', height: 'calc(100vh - 5px)' }} />;
};

export default observer(ExcelViewer);
