import * as React from 'react';
import  { DragDropContainer, DropTarget, OnHitEventArgs }  from 'react-drag-drop-container';
import { observer } from 'mobx-react';
import PipelineVisualStore from '../../rules/stores/PipelineVisualStore';

type Props<T extends PipelineVisualStore<TPipelineStep>, TPipelineStep extends string> = {
    store: T;
    index: number;
    children: JSX.Element
};

// eslint-disable-next-line max-len
export const StepDraggableItem: <T extends PipelineVisualStore<TPipelineStep>, TPipelineStep extends string>(props: Props<T, TPipelineStep>) => React.ReactElement<Props<T, TPipelineStep>> = ({index, store, children}) => {      
    return(
        <div className="draggableStepComponent">
            <DragDropContainer
                targetKey="stepItem"
                dragData={{pipelineStep: children, index: index}}
                onDrop={() => store.swapPipelineStepIndex = index}
                disappearDraggedElement
                dragHandleClassName="ant-card-head"
            >
                <DropTarget
                    onHit={(p: OnHitEventArgs) => store.swapPipeLineSteps(p, index)}
                    targetKey="stepItem"
                >
                    <div style={{width: '300px'}}>
                        {children}
                    </div>
                
                </DropTarget>
            </DragDropContainer>
        </div>
    );
};

export default observer(StepDraggableItem);