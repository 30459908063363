import { Button, Col, Modal, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectsRootVisualStore } from '../../../modules/common/stores';
import { ProjectFormVisualStore } from '../stores';
import EditProjectForm from './EditProjectForm';

type Props = {
    store: ProjectsRootVisualStore;
    formStore: ProjectFormVisualStore;
    showDeleteProjectModal: () => void
};

const EditProjectDialog: React.FC<Props> = ({store, formStore, showDeleteProjectModal}) => {
    return (
        <Modal
            title="Edit project"
            centered
            closable={false}
            maskClosable={false}
            className="alpha-portal-dialog"
            width={600}
            visible={store.projectEditDialogVisible}
            destroyOnClose
            footer={[
                <Row key="edit-project-buttons" style={{width: '100%'}}>
                    <Col span={6} style={{textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                        <Button 
                            data-id="edit-project-cancel" 
                            key="edit-project-cancel" 
                            onClick={showDeleteProjectModal} 
                            size="small"
                            type="link"
                        >
                            <Tooltip title="Delete project">
                                <i className="alpha-icon lg delete-icon" />
                            </Tooltip>
                        </Button>
                    </Col>
                    <Col span={18}>
                        <Button 
                            data-id="edit-project-cancel" 
                            key="edit-project-cancel" 
                            onClick={() => formStore.reset()}
                            size="large"
                            className="light"
                        >
                        Cancel
                        </Button>
                        <Button 
                            data-id="edit-project-submit" 
                            key="edit-project-submit" 
                            size="large"
                            type="primary"
                            htmlType="submit"
                            form="edit-project-form"
                        >
                        Save changes
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <EditProjectForm store={formStore} resetOnSubmit />
        </Modal>);
};

export default observer(EditProjectDialog);