import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import { ReferenceDataVisualStore } from '../stores';
import ReferenceDataService from '../services/ReferenceDataService';

export type RefDataTableGroupDto = {
    id: string;
    projectId: string;
    name: string;
    refDataConnectionId: string;
    tableNames: string[]
};

export default class RefDataTableGroupModel {
    @observable
    name: string;

    @observable
    tableNames: string[];

    @observable
    isEditable: boolean = false;

    readonly id: string;

    readonly projectId: string;

    readonly refDataConnectionId: string;

    constructor(
        dto: RefDataTableGroupDto,
        private readonly store: ReferenceDataVisualStore,
        private readonly service: ReferenceDataService
    ) {
        this.id = dto.id;
        this.projectId = dto.projectId;
        this.refDataConnectionId = dto.refDataConnectionId;
        this.name = dto.name;
        this.tableNames = dto.tableNames;
    }

    @action.bound
    setIsEditable(isEditable: boolean) {
        this.isEditable = isEditable;
    }

    @action.bound
    async update({ name, tableNames }: Partial<RefDataTableGroupDto>) {
        // Tables can be updated and deleted outside the portal - make sure old names are filtered out
        runInAction(() => {
            this.name = name ?? this.name;
            this.tableNames = (tableNames ?? this.tableNames).filter(n => this.store.tableNames.includes(n));
        });

        const resp = await this.service.updateTableGroup({
            id: this.id,
            projectId: this.projectId,
            name: this.name,
            tableNames: this.tableNames
        });

        if (resp.isOk()) {
            return true;
        }

        message.error(`Error: ${resp.error.data ? resp.error.data.title : resp.error.text}`);

        const tableGroup = (await this.service.getTableGroups(this.projectId, this.refDataConnectionId)).find(g => g.id === this.id);

        if (tableGroup) {
            runInAction(() => {
                this.name = tableGroup.name;
                this.tableNames = tableGroup.tableNames;
            });
        }

        return false;
    }

    @action.bound
    async addTable(tableName: string) {
        await this.update({ tableNames: [...this.tableNames, tableName] });
    }

    @action.bound
    async updateTable(newTableName: string, oldTableName: string) {
        await this.update({ tableNames: [...this.tableNames.filter(n => n !== oldTableName), newTableName] });
    }

    @action.bound
    async removeTable(tableName: string) {
        await this.update({ tableNames: this.tableNames.filter(n => n !== tableName) });
    }
}
