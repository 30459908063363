import { observable, action, computed } from 'mobx';
import DocumentViewService from '../services/DocumentViewService';

type FileType = 'pdf' | 'text' | 'excel';

export class DocumentViewVisualStore {
    @observable
    pageWidth: number | null = null;

    @observable
    scale: number = 1;

    @observable
    fileBlob: Blob | null = null;

    @observable
    isLoading: boolean = false;

    @computed
    get file(): File | null {
        if (!this.fileBlob) {
            return null;
        }

        return new File([this.fileBlob], 'fileName', { type: this.fileBlob.type });
    }

    @computed
    get fileType(): FileType | null {
        if (!this.file) {
            return null;
        }

        switch (this.file.type) {
        case 'application/pdf':
            return 'pdf';
        case 'text/plain':
            return 'text';
        case 'application/vnd.ms-excel.sheet.macroenabled.12':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'excel';
        default:
            return null;
        }
    }

    constructor(private readonly service: DocumentViewService) {}

    @action
    setPageWidth(width: number) {
        if (width === this.pageWidth) {
            return;
        }

        this.pageWidth = width;
    }

    @action
    setScale(scale: number) {
        this.scale = scale / 100;
    }

    @action
    async getDocument(path: string) {
        this.isLoading = true;

        try {
            const fileBlob = await this.service.getDocument(path);

            if (!fileBlob) {
                return;
            }

            this.fileBlob = fileBlob;
        } finally {
            this.isLoading = false;
        }
    }
}
export default DocumentViewVisualStore;
