import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { ChatbotStore } from '../../stores';
import { ChatbotQuestionModel } from '../../models';
import './ChatbotDisplayItems.less';

type Props = {
    store: ChatbotStore;
    question: ChatbotQuestionModel;
    addRef: (questionId: string, questionRef: React.RefObject<HTMLDivElement>) => void
};

const ChatbotQuestion: React.FC<Props> = ({ store, question, addRef }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        addRef(question.id, ref);
    }, [question.id, addRef]);

    return (
        <div className="item-container" ref={ref}>
            <div className="item message">
                <span>{question.message}</span>
            </div>

            {question.processing && <div className="item-processing">...</div>}

            {question.response && (
                <div className="item answer">
                    <span>{question.response.message}</span>

                    <ul className="package-field-list">
                        {question.response.packageFields.map(packageField => (
                            <li key={packageField.field.id}>
                                <span className="package-field" onClick={() => store.onFieldClick(packageField)}>
                                    {packageField.label}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default observer(ChatbotQuestion);
