import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PdfDocumentPreviewWithRegions } from '.';
import { FormTypesStore, FormTypesVisualStore } from '../stores';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore;
    pages: number;
    setPages: (pages: number) => void;
    scale: number;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    documentLoaded: boolean;
    setDocumentLoaded: (loading: boolean) => void
};

const FormLabelingContent: React.FC<Props> = ({store, pages, setPages, scale, pageNumber, setPageNumber, visualStore, documentLoaded, setDocumentLoaded}) => {
    if (!store.currentRegionPackageId) {
        return null;
    }

    return (
        <PdfDocumentPreviewWithRegions 
            documentId={store.currentRegionPackageId} 
            store={store}
            pages={pages}
            setPages={setPages} 
            scale={scale} 
            pageNumber={pageNumber} 
            setPageNumber={setPageNumber} 
            visualStore={visualStore}
            documentLoaded={documentLoaded}
            setDocumentLoaded={setDocumentLoaded}
        />
    );
};

export default observer(FormLabelingContent);