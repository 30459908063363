import { observable, action, reaction } from 'mobx';
import { LocalStorageService } from '../../common/services';
import { ChatbotStore } from '../stores';
import { ChatbotSettingsValues } from '../types';

interface Settings extends ChatbotSettingsValues {}

const defaultSettings: Settings = {
    instructWorkflowId: null,
    instructWorkflowOutputSchemeName: null,
    instructWorkflowCustomOutputSchemeType: null,
    instructWorkflowCustomOutputScheme: null,
    useContext: false
};

export default class ChatbotSettingsModel {
    @observable
    values: Settings = { ...defaultSettings };

    private readonly localStorageService = new LocalStorageService();

    constructor(private readonly store: ChatbotStore) {
        reaction(
            () => this.store.project,
            () => this.getSettingsFromLocalStorage()
        );

        reaction(
            () => this.values,
            () => this.updateLocalStorageSettings()
        );
    }

    @action.bound
    setValues(values: Partial<Settings>) {
        this.values = Object.entries(values).reduce(
            (acc, [key, value]) => {
                acc[key] = value;
                return acc;
            },
            {
                ...defaultSettings
            }
        );
    }

    @action.bound
    resetInstructWorkflow() {
        this.values = { ...this.values, instructWorkflowId: null, instructWorkflowOutputSchemeName: null };
    }

    private getSettingsFromLocalStorage() {
        if (this.store.project) {
            const localStorageKey = this.getLocalStorageKey(this.store.project.id);
            this.setValues(this.localStorageService.getItem<Partial<ChatbotSettingsValues>>(localStorageKey, {}));
        }
    }

    private updateLocalStorageSettings() {
        if (this.store.project) {
            this.localStorageService.setItem(this.getLocalStorageKey(this.store.project.id), this.values);
        }
    }

    private getLocalStorageKey(projectId: string) {
        return `${projectId}_chatbot-dialog-settings`;
    }
}
