import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Card, Modal, Button } from 'antd';
import { ApplicationDefinitionConditionalEditStore } from '../../stores';
import { ProjectApplicationDefinitionEditVisualStore } from '../../../iota_applications/stores';
import { CreateEditConditionFormValues } from '../../types';
import { ConditionFormField } from '..';
import './ConditionDetailsRenderer.less';

type EditableFormValues = Partial<Omit<CreateEditConditionFormValues, 'name'>>;

type EditableFieldKey = keyof EditableFormValues | null;

interface Props {
    store: ApplicationDefinitionConditionalEditStore;
    applicationDefinitionEditStore: ProjectApplicationDefinitionEditVisualStore
}

const ConditionDetailsRenderer: React.FC<Props> = ({ store, applicationDefinitionEditStore }) => {
    const [form] = Form.useForm<EditableFormValues>();

    const [editableFieldKey, setEditableFieldKey] = React.useState<EditableFieldKey>(null);

    if (!store.selectedCondition) {
        return null;
    }

    const onFinish = (data: EditableFormValues) => {
        store.selectedCondition?.update(data);
        onClose();
    };

    const onClose = () => setEditableFieldKey(null);

    const tagElement = () => {
        const ruleTag = applicationDefinitionEditStore.ruleTags.find(t => t.id === store.selectedCondition?.tagId);
        return <a onClick={() => setEditableFieldKey('tagId')}>{ruleTag?.name || ''}</a>;
    };

    const searchTextElement = () => {
        return (
            <a onClick={() => setEditableFieldKey('searchText')}>
                {store.selectedCondition?.searchText.replace('\n', ' OR ')}
            </a>
        );
    };

    const inputGroupElement = () => {
        return <a onClick={() => setEditableFieldKey('inputGroupId')}>{store.selectedCondition?.inputGroup?.name}</a>;
    };

    const getModalTitle = () => {
        switch (editableFieldKey) {
        case 'tagId':
            return 'Update Tag';
        case 'searchText':
            return 'Update Search Text';
        case 'inputGroupId':
            return 'Update Input Group';
        default:
            return '';
        }
    };

    const getFormField = () => {
        switch (editableFieldKey) {
        case 'tagId':
            return (
                <ConditionFormField
                    formFieldKey={editableFieldKey}
                    label="Select tag"
                    store={store}
                    applicationDefinitionEditStore={applicationDefinitionEditStore}
                    condition={store.selectedCondition}
                />
            );
        case 'searchText':
            return (
                <ConditionFormField
                    formFieldKey={editableFieldKey}
                    label="Specify words or phrases to search"
                    store={store}
                    applicationDefinitionEditStore={applicationDefinitionEditStore}
                    condition={store.selectedCondition}
                />
            );
        case 'inputGroupId':
            return (
                <ConditionFormField
                    formFieldKey={editableFieldKey}
                    label="Select input group"
                    store={store}
                    applicationDefinitionEditStore={applicationDefinitionEditStore}
                    condition={store.selectedCondition}
                />
            );
        default:
            return null;
        }
    };

    return (
        <Card className="condition-details-renderer">
            <Modal
                className="edit-condition-dialog"
                title={getModalTitle()}
                visible={!!editableFieldKey}
                width={600}
                onCancel={onClose}
                maskClosable={false}
                closable={false}
                destroyOnClose
                centered
                footer={[
                    <Button
                        form="edit-condition-form"
                        data-id="edit-condition-cancel"
                        key="edit-condition-cancel"
                        className="light"
                        size="large"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>,
                    <Button
                        form="edit-condition-form"
                        data-id="edit-condition"
                        key="edit-condition"
                        size="large"
                        type="primary"
                        htmlType="submit"
                    >
                        Update
                    </Button>
                ]}
            >
                <Form id="edit-condition-form" layout="vertical" form={form} onFinish={onFinish} preserve={false}>
                    {getFormField()}
                </Form>
            </Modal>

            <span>
                Apply this condition when the following {tagElement()} result contains {searchTextElement()} and apply
                the {inputGroupElement()} inputs
            </span>
        </Card>
    );
};

export default observer(ConditionDetailsRenderer);
