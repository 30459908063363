import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import { UserProfileResult, UserProfile } from './types';
import type { UserModel } from '../../administration/types/UserModel';
import appClient from '../../common/services/AppClient';

export default class UserProfileService {
    async getUserProfile(): Promise<UserProfile> {
        const result = await execQuery<UserProfileResult>({
            query: gql`query getUserProfile{
                  getUserProfile {
                    userName
                    name
                    timezone
                    avatarIconFileId
                  }
            }`,
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getUserProfile;
    }

    updateUserProfile(data: FormData) {
        const url = process.env.REACT_APP_USERS_URL + 'userProfile';
        return appClient.post(url, data);
    }

    async getAvatarIcon(fileId: string): Promise<Blob | undefined> {
        const url = `${process.env.REACT_APP_USERS_URL}userProfile/icon/${fileId}`;
        const resp = await appClient.get<Blob | undefined>(url, 'blob');
        return resp.mapErr(err => console.log(err)).unwrapOr(undefined);
    }

    getUserByName(name: string)  {
        const url = `${process.env.REACT_APP_USERS_URL}${name}`;
        return appClient.get<UserModel>(url);        
    }
}