import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Input, Tooltip, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { IotaMetadataInput } from '../types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

interface Props {
    form: FormInstance;
    input: IotaMetadataInput;
    initialValue: unknown;
    disabled?: boolean
}

const Option = Select.Option;

export const ApplicationSettingsNumberInput: React.FC<Props> = ({ form, input, initialValue, disabled = false }) => {

    const renderSelectValues = () => {
        let options: JSX.Element[] = [];
        for (let key in input.values!) {
            if (input.values![key]) {
                let val = input.values![key];
                options.push(<Option data-id={`iota-edit-application-settings-input-${input.id}-${val}`} value={val}>{key}</Option>);
            }
        }
        return options;
    };

    const renderInput = () => {
        if (input.behavior && input.behavior.multiselect) {
            return (
                <Select disabled={disabled} data-id={`iota-edit-application-settings-input-${input.id}`} mode="tags">
                    {input.values && renderSelectValues()}
                </Select>
            );
        } else {
            return (<Input disabled={disabled} data-id={`iota-edit-application-settings-input-${input.id}`} type="number" />);
        }
    };

    return (
        <FormItem
            className='required-with-tooltip'
            label={(
                <span>
                    {input.name}&nbsp;
                    {
                        input.description &&
                        <Tooltip title={input.description}>
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    }
                </span>
            )}
            name={input.id}
            getValueFromEvent={(e: React.FormEvent<HTMLInputElement> | string[]) => {
                if ('currentTarget' in e && e.currentTarget instanceof HTMLInputElement) {
                    const convertedValue = Number(e.currentTarget.value);
                    if (isNaN(convertedValue)) {
                        return Number(form.getFieldValue(input.id.replace('.', '-')));
                    } else {
                        return convertedValue;
                    }
                } else {
                    var values = e as string[];
                    let convertedValues: number[] = [];
                    values.forEach(v => {
                        const convertedVal = Number(v);
                        if (!isNaN(convertedVal)) {
                            convertedValues.push(Number(v));
                        }
                    });
                    return convertedValues;
                }
            }}
            rules={[{
                ...input.validation,
                message: 'Please input number value!',
                whitespace: true,
                type: input.behavior && input.behavior.multiselect ? 'array' : 'number'
            }]}
            initialValue={initialValue}

        >
            {renderInput()}
        </FormItem>
    );
};

export default observer(ApplicationSettingsNumberInput);