import * as React from 'react';
import { Modal, Select, Tooltip } from 'antd';
import Package from '../../common/models/Package';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { UploadedPackagesVisualStore } from '../stores';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

const { Option } = Select;

type Props = {
    store: UploadedPackagesVisualStore;
    line: Package
};

export const PackageTagsEditor: React.FC<Props> = ({ store, line }) => {
    const tags = !line.userTags || !line.userTags.length ? ['Not assigned'] : line.userTags;
    const [isModal, setVisible] = useState(false);
    const [currentTags, setTags] = useState(line.userTags || []);

    const closeModal = () => {
        line.setUserTags(currentTags);
        store.updatePackageUserTags(line, currentTags);
        setVisible(false);
    };

    const tagsRenderer = <Tooltip title={tags.join(', ')}>{tags.join(', ')}</Tooltip>;

    return (
        <>
            <HasPermission 
                entityId={store?.currentProject?.id}
                permissionClaim={AppPermissions.CanEditProcessDocuments}
                yes={() => (<a onClick={() => setVisible(true)}>{tagsRenderer}</a>)}
                no={() => (tagsRenderer)}
            />
            <Modal
                title="Tags Editor"
                visible={isModal}
                onOk={closeModal}
                okButtonProps={{id: `tags-editor-modal-confirm-${line.id}`}}
                onCancel={() => setVisible(false)}
                centered
            >
                <Select data-id={`tags-editor-modal-select-${line.id}`} style={{ width: '100%' }} mode="tags" value={currentTags} onChange={(v: string[]) => setTags(v)}>
                    {store.tags.map((k, i) => (
                        <Option data-id={`tags-editor-modal-select-${line.id}-${k}`} key={`kw-${i}`} value={k}>{k}</Option>
                    ))}
                </Select>
            </Modal>
        </>
    );
};

export default observer(PackageTagsEditor);