import * as React from 'react';
import { ProtectedRoute, monitorRouterMatch } from '../../common/components';
import ProjectApplicationDefinitionsPage from '../screens/ProjectApplicationDefinitionsPage';
import ProjectApplicationDefinitionEditPage from '../screens/ProjectApplicationDefinitionEditPage';
import ProjectEditApplicationSettingsPage from '../screens/ProjectEditApplicationSettingsPage';
import { STORE_PROJECT_APPLICATION_DEFINITION_EDIT, STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW } from '../stores/constants';
import { STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW } from '../../application_definition_conditional/stores/constants';
import EditIotaApplicationPage from '../screens/EditIotaApplicationPage';
import ApplicationDefinitionBindingPreviewPage from '../screens/ApplicationDefinitionBindingPreviewPage';

export const IotaAppPagesNavigation = {
    ProjectApplicationDefinitionsPage: '/projects/:id/applicationDefinitions',
    ApplicationDefinitionEditPage: '/projects/:id/applicationDefinitions/:appId',
    ProjectApplicationDefinitionsSettings: '/projects/:id/applicationSettings/:appId',
    EditIotaApplicationPage: '/projects/:id/application/:appId',
    ApplicationDefinitionPreview: '/projects/:id/applicationDefinitions/:appId/applicationPreview',
    ApplicationDefinitionInputBindingPreview: '/projects/:id/applicationDefinitions/:appId/bindingPreview/:inputId/:index',
    ApplicationDefinitionConditionalPreview: '/projects/:id/applicationDefinitionConditionalPreview/:appId/applicationPreview',
    ApplicationDefinitionConditionalInputGroupPreview: '/projects/:id/applicationDefinitionConditionalPreview/:appId/inputGroupPreview/:inputGroupId',
    ApplicationDefinitionConditionalInputBindingPreview: '/projects/:id/applicationDefinitionConditionalPreview/:appId/:inputGroupId/bindingPreview/:inputId/:index'
};

export default () => {
    return (
        <React.Fragment>
            <ProtectedRoute exact component={ProjectApplicationDefinitionsPage} path={IotaAppPagesNavigation.ProjectApplicationDefinitionsPage} />          
            <ProtectedRoute component={ProjectEditApplicationSettingsPage} path={IotaAppPagesNavigation.ProjectApplicationDefinitionsSettings} />              
            <ProtectedRoute 
                exact
                component={monitorRouterMatch(STORE_PROJECT_APPLICATION_DEFINITION_EDIT, 
                    (s, m) => s[STORE_PROJECT_APPLICATION_DEFINITION_EDIT].setMatch(m!.params))
                // eslint-disable-next-line no-unexpected-multiline
                (ProjectApplicationDefinitionEditPage)} 
                path={IotaAppPagesNavigation.ApplicationDefinitionEditPage} 
            />
            <ProtectedRoute component={EditIotaApplicationPage}  path={IotaAppPagesNavigation.EditIotaApplicationPage} />
            <ProtectedRoute
                component={monitorRouterMatch(STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW, (s, m) =>
                    s[STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW].setItem(m!.params)
                )(ApplicationDefinitionBindingPreviewPage)}
                path={[IotaAppPagesNavigation.ApplicationDefinitionPreview, IotaAppPagesNavigation.ApplicationDefinitionInputBindingPreview]}
            />
            <ProtectedRoute
                component={monitorRouterMatch(STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW, (s, m) =>
                    s[STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW].setItem(m!.params)
                )(ApplicationDefinitionBindingPreviewPage)}
                path={[
                    IotaAppPagesNavigation.ApplicationDefinitionConditionalPreview,
                    IotaAppPagesNavigation.ApplicationDefinitionConditionalInputGroupPreview,
                    IotaAppPagesNavigation.ApplicationDefinitionConditionalInputBindingPreview
                ]}
            />
        </React.Fragment>
    );
};