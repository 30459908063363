import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Popconfirm } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { ChatbotStore } from '../../stores';
import { ChatbotSettingsValues } from '../../types';
import ChatbotResizeButton from './ChatbotResizeButton';
import ChatbotSettings from './ChatbotSettings';
import './ChatbotHeader.less';

type Props = {
    store: ChatbotStore;
    isResizing: boolean;
    settingsForm: FormInstance<ChatbotSettingsValues>;
    handleResize: (event: React.MouseEvent) => void
};

const ChatbotHeader: React.FC<Props> = ({ store, isResizing, settingsForm, handleResize }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => setIsHovered(true);

    const handleMouseLeave = () => setIsHovered(false);

    return (
        <div className="chatbot-header">
            <div
                className="chatbot-header-left-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <ChatbotResizeButton isVisible={isHovered || isResizing} onMouseDown={handleResize} />
                <span className="chatbot-header-title">Ask Alpha</span>
            </div>

            <div className="chatbot-header-right-container">
                <ChatbotSettings store={store} settingsForm={settingsForm} />

                <Popconfirm
                    placement="topRight"
                    title="Are you sure you want to clear the chat?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => store.clearChat(true)}
                >
                    <DeleteOutlined className="header-button" />
                </Popconfirm>

                <CloseCircleOutlined className="header-button" onClick={() => store.setDialogVisible(false)} />
            </div>
        </div>
    );
};

export default observer(ChatbotHeader);
