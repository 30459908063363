import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Select, { SelectProps } from 'antd/lib/select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectWhenScroll: React.FC<SelectProps<any>> = observer((props) => {
    return (
        <Select virtual={false} {...props} getPopupContainer={trigger => trigger.parentNode as HTMLElement}>
            {props.children}
        </Select>);
});
