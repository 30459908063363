import TagRuleBase from './TagRuleBase';
import { observable } from 'mobx';
import { ElasticSearchRawRuleResult } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export default class ElasticSearchRawRule extends TagRuleBase<ElasticSearchRawRule, ElasticSearchRawRuleResult> {
    @observable
    query: string;

    constructor(id: string | null, projectId: string | null, name: string, tagId: string | null, tag: string,
                query: string, pipeline: PipelineStep<PipelineStepType>[] = [], groupId: string | null,
                // eslint-disable-next-line max-len
                updateDate?: Date, priority: number = 1, description?: string, state?: 'Enabled' | 'Disabled',  status: RuleStatus = RuleStatus.Valid, overridePriority: boolean = false) {
        super(id, projectId, name, tagId, tag, 'ElasticSearchRawRule', pipeline, groupId, updateDate, priority, description, state, status, overridePriority);
        this.query = query;
    }

    clone(): ElasticSearchRawRule {
        return new ElasticSearchRawRule(
            this.id, 
            this.projectId, 
            this.name, 
            this.tagId, 
            this.tag,
            this.query, 
            this.clonePipeline(), 
            this.groupId, 
            this.updateDate, 
            this.priority,
            this.description,
            this.state,
            this.status,
            this.overridePriority);
    }

    validateState(): string[] {
        if (!this.name || !this.query || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): ElasticSearchRawRuleResult {
        const json: ElasticSearchRawRuleResult = {
            ruleType: this.ruleType,
            id: this.id,
            groupId: this.groupId,
            name: this.name,            
            query: this.query,
            tagId: this.tagId,
            tag: this.tag,
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority
        };

        return json;
    }
}