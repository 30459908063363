import { observable, action, computed } from 'mobx';
import { flatten } from 'lodash';
import { InputMeta } from '../../iota_applications/types';
import { SectionDto } from '../types';
import { InputModel } from '.';

export default class SectionModel {
    @observable
    nameOverride: string | null = null;

    @observable
    enabled: boolean;

    @observable
    inputs: InputModel[] = [];

    @observable
    editable: boolean = false;

    @observable
    openInputIds: string[] = [];

    @computed
    get displayName() {
        return this.nameOverride ?? this.name;
    }

    get dto(): SectionDto {
        return {
            sectionId: this.id,
            name: this.name,
            nameOverride: this.nameOverride,
            enabled: this.enabled,
            bindings: flatten(this.inputs.map(i => i.bindings))
        };
    }

    readonly id: string;

    readonly name: string;

    constructor(applicationDefinitionInputs: InputMeta[], data: SectionDto) {
        this.id = data.sectionId;
        this.name = data.name;
        this.nameOverride = data.nameOverride;
        this.enabled = data.enabled;
        this.inputs = applicationDefinitionInputs
            .filter(i => i.section === this.name)
            .map(
                inputMeta =>
                    new InputModel(
                        inputMeta,
                        data.bindings.filter(b => b.inputId === inputMeta.id)
                    )
            );
    }

    @action.bound
    setNameOverride(nameOverride: string) {
        this.nameOverride = nameOverride.trim();
    }

    @action.bound
    setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    @action.bound
    setEditable(editable: boolean) {
        this.editable = editable;
    }

    @action.bound
    setOpenInputIds(openInputIds: string[]) {
        this.openInputIds = openInputIds;
    }
}
