import { Button, Layout, message, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/services/Utils';
import LayoutHeader from '../../../components/LayoutHeader';
import { FieldBindingsStore } from '../stores';
import { 
    FieldBindingGroupCreateDialog, 
    FieldBindingGroupLayoutDialog, 
    FieldBindingGroupUpdateDialog, 
    FieldBindingInputCreateDialog,
    FieldBindingInputRenameDialog,
    FieldBindingSectionCreateDialog, 
    FieldBindingSectionUpdateDialog, 
    FieldBindingGroupExportDialog,
    FieldBindingsGroupContent, 
    FieldBindingsTree
} from '.';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { RulesImportVisualStore } from '../../rules/stores';
import { UploadChangeParam } from 'antd/lib/upload';
import RulesImportDialog from '../../rules/screens/RulesImportDialog';
import { BindingGroup } from '../types';

type Props = {
    store: FieldBindingsStore;
    rulesImportStore: RulesImportVisualStore
};

const FieldBindingsList: React.FC<Props> = ({store, rulesImportStore}) => {
    const [updateLayoutDialogVisible, setUpdateLayoutDialogVisible] = React.useState(false);
    const [exportDialogVisible, setExportDialogVisible] = React.useState(false);

    React.useEffect(() => {
        if (store.currentProject) {
            store.getFieldGroups();
        }
    }, [store, store.currentProject]);

    const addFieldBindingGroup = () => {
        store.setIsCreateGroupDialogVisible(true);
    };

    const addFieldBindingSection = () => {
        store.setIsCreateSectionDialogVisible(true);
    };

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status === 'done') {        
            const rulesResponse = info.file.response;
            rulesImportStore!.setTableDataForProjectFieldsImport(rulesResponse);
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            if (info.file?.response?.status === 409) {
                message.warning(info.file.response.title);
                return;
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const importCallback = async() => {
        const promises = [store!.getFieldGroups()];
        await Promise.all(promises);
        message.success('Project field bindings imported successfully.');
    };

    const openUpdateGroupLayoutDialog = () => {
        setUpdateLayoutDialogVisible(true);
    };

    const saveFieldBindingGroupLayout = (group: BindingGroup) => {
        store.updateGroup(group.name, true, group.fields, group.sections).then(() => {
            setUpdateLayoutDialogVisible(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    return (
        <Layout className="screen-size" style={{...{height: '100%', background: 'white'}}}>
            <LayoutHeader 
                subtitle={Utils.getSubtitle(store.currentProject)}
                title="Field Bindings"
                helpMessage="Define and customize field bindings"
                buttons={[
                    
                    <React.Fragment key="field-binding-import-export-buttons" >
                        {!!store.bindingGroups.length && (
                            <HasPermission
                                entityId={store.currentProject?.id}
                                key="rules-export"
                                permissionClaim={AppPermissions.CanEditImportExportRules}
                            >
                                <span
                                    data-id="button-project-fields-Export"
                                    key="export-button-container"
                                    className={'headerButton rule-export'}
                                    onClick={() => setExportDialogVisible(true)}
                                >
                                    <i className="alpha-icon xs arrow-up" style={{ verticalAlign: 'middle' }} />
                                    <span className={'rule-export-label'}>Export</span>
                                </span>
                            </HasPermission>
                        )}
                        <HasPermission entityId={store.currentProject?.id} key="rules-import" permissionClaim={AppPermissions.CanEditImportExportRules}>
                            <Upload 
                                showUploadList={false}
                                className="headerButton"
                                key="file-uploader" 
                                name="file" 
                                onChange={onChange} 
                                action={`${process.env.REACT_APP_MANAGE_URL}projectFields/${store.currentProject?.id}/import`} 
                                headers={store.fileImportActionHeaders} 
                                beforeUpload={() => store.setHeaders()}
                            >
                                <span onClick={rulesImportStore.handleBeforeUpload} data-id="button-project-fields-Import" key="import-button-container" >
                                    <i className="alpha-icon xs arrow-down" style={{verticalAlign: 'middle'}}/>
                                    <span className= {'rule-import-label'} style={{marginLeft: 11}}>Import</span>
                                </span>
                            </Upload>
                        </HasPermission>
                    </React.Fragment>,
                    <Button 
                        key="field-binding-add-group-button" 
                        data-id="field-binding-add-group-button" 
                        className="light"
                        size="large" 
                        onClick={addFieldBindingGroup} 
                        style={{marginBottom: 10}}
                    >
                        Add Group
                    </Button>,
                    <React.Fragment key="manage-group-buttons">
                        {store.selectedGroupId && (
                            <Button
                                key="field-binding-add-section-button" 
                                data-id="field-binding-add-section-button"  
                                className="light"
                                size="large" 
                                onClick={addFieldBindingSection} 
                                style={{marginBottom: 10}}
                            >
                                Add Section
                            </Button>
                        )}
                        {store.selectedGroupId && (
                            <Button 
                                key="field-binding-edit-layout-button" 
                                data-id="field-binding-edit-layout-button"  
                                className="light"
                                size="large" 
                                onClick={openUpdateGroupLayoutDialog} 
                                style={{marginBottom: 10}}
                            >
                                Edit layout
                            </Button>
                        )}
                        {store.selectedGroupId && (
                            <Button 
                                key="field-binding-save-group-button" 
                                data-id="field-binding-save-group-button" 
                                type="primary" 
                                size="large" 
                                htmlType="submit"
                                form="project-field-bindings-form"
                                style={{marginBottom: 10}}
                            >
                                Save changes
                            </Button>
                        )}
                    </React.Fragment>
                ]}
            />
            <Layout> 
                <RulesImportDialog importCallback={importCallback} warningMsg="All your current field bindings will be removed and new ones will be imported."/>
                <FieldBindingGroupCreateDialog store={store} />
                <FieldBindingGroupUpdateDialog store={store} />
                <FieldBindingSectionCreateDialog store={store} />
                <FieldBindingSectionUpdateDialog store={store} />
                <FieldBindingInputCreateDialog store={store} />
                <FieldBindingInputRenameDialog store={store} />
                <FieldBindingGroupLayoutDialog 
                    store={store}
                    isVisible={updateLayoutDialogVisible} 
                    setIsVisible={setUpdateLayoutDialogVisible} 
                    saveLayout={saveFieldBindingGroupLayout}
                    fieldBindingGroup={store.selectedGroup}
                />
                <FieldBindingGroupExportDialog
                    store={store}
                    isVisible={exportDialogVisible}
                    setIsVisible={setExportDialogVisible}
                />
                <Layout.Sider data-id="field-bindings-tree-container" width={300} style={{background: 'white', overflow: 'auto', paddingRight: 15}}>
                    <FieldBindingsTree store={store} />
                </Layout.Sider>
                <Layout.Content data-id="field-binding-list-container" style={{background: 'white', maxHeight: 'calc(100vh - 90px)', overflow: 'auto'}}> 
                    <FieldBindingsGroupContent store={store} />
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default observer(FieldBindingsList);