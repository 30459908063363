/* eslint-disable @typescript-eslint/member-ordering */
import { computed, action, observable, runInAction, reaction } from 'mobx';
import { ProjectsRootVisualStore, RouterStore } from '../../common/stores';
import { ProjectsService } from '../../common/services';
import { PackageProblemReport, ProblemReport } from '../../../modules/common/types';
import _ from 'lodash';
import { InteractivePagesNavigation } from '../../../modules/interactive_labels/routes';

export enum ReportedProblemState {
    Active = 'Active',
    Assigned = 'Assigned',
    InProgress = 'InProgress',
    Resolved = 'Resolved'
}

type ProblemStatus = {
    [key: string]: string
};
export class PackagesProblemsReportVisualStore {
    allPackages = 'All packages';

    allUsers = 'All users';

    problemReports: PackageProblemReport[] | null = [];

    @observable
    filteredProblemReports: PackageProblemReport[] | null = [];

    @observable
    isLoading: boolean = false;

    packageNames: string[] = [];

    // TODO: replace with actual users 
    userNames: string[] = ['dummy user', 'admin', 'Andrei'];

    statuses: ReportedProblemState[] = [ReportedProblemState.Active, ReportedProblemState.Assigned, ReportedProblemState.InProgress, ReportedProblemState.Resolved];

    @observable
    currentPackageName: string = this.allPackages;

    @observable
    currentUserName: string = this.allUsers;

    @observable
    problemStatusDict: ProblemStatus = {};

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    constructor(private rootStore: ProjectsRootVisualStore, private routerStore: RouterStore, private projectsService: ProjectsService) {
        reaction(() => this.currentProject, (p) => {
            if (p) {
                this.packageNames = [];
                this.currentPackageName = this.allPackages;
                this.currentUserName = this.allUsers;
                this.loadProblemsReports();
            }
        });
    }

    @action.bound
    setCurrentPackageName(name: string) {
        this.currentPackageName = name;
    }

    @action.bound
    setCurrentUserName(name: string) {
        this.currentUserName = name;
    }

    @action
    async setProblemStatus(status: string, problemId: string) {
        const saveModelStatus = status === 'In Progress' ? ReportedProblemState.InProgress : status;
        this.problemStatusDict[problemId] = saveModelStatus;
        var recordId = this.problemReports!.find(p => p.reports.findIndex(r => r.problemId === problemId) > -1)!.id;
        await this.projectsService.setProblemStatus(saveModelStatus, recordId, problemId);
    }

    @action
    clearFilter() {
        this.currentPackageName = this.allPackages;
        this.currentUserName = this.allUsers;
    }

    @action
    async loadProblemsReports() {
        if (!this.currentProject) {
            return;
        }
        this.isLoading = true;
        this.packageNames = [];
        const problemReports = await this.projectsService.getPackageProblemMessages(this.currentProject!.id);
        if (problemReports) {
            problemReports.forEach(p => {
                p.packageName = this.getPackageName(p.packageId); p.lastModified = this.getLastModified(p.reports); 
                p.reports.forEach(r => this.problemStatusDict[r.problemId] = r.status);
            });
        }
        runInAction(() => this.problemReports = problemReports);
        runInAction(() => this.filteredProblemReports = problemReports);
        runInAction(() => this.isLoading = false);
    }

    @computed
    get filteredReports() {
        let reports = this.problemReports;
        if (this.currentPackageName !== this.allPackages) {
            reports =  this.problemReports!.filter(p => p.packageName === this.currentPackageName);
        }
        
        if (this.currentUserName !== this.allUsers) {
            reports = reports!.filter(r => r.reports.some(p => p.user === this.currentUserName))
                .map(el => {
                    return Object.assign({}, el, {reports: el.reports.filter(p => p.user === this.currentUserName)});
                });
        } 
        return reports;
    }

    handleViewButtonClick(problemId: string) {
        const packageId = this.problemReports!.find(p => !!p.reports.find(r => r.problemId === problemId))!.packageId;
        const pageNumber = _.flatten(this.problemReports!.map(p => p.reports)).find(p => p.problemId === problemId)!.page;
        console.log(pageNumber, packageId);
        this.routerStore.pushToHistory(InteractivePagesNavigation.ProjectInteractiveLabelPage
            .replace(':id', this.currentProject!.id)
            .replace(':packageId', packageId)
            .replace(':pageNumber', pageNumber));
    }

    private getPackageName(id: string): string {
        const name = this.currentProject!.packages.find(p => p.id === id)!.name;
        this.packageNames.push(name);
        return name;
    }

    private getLastModified(reports: ProblemReport[]) {
        const dates = reports.map( r => r.createDate);
        return new Date(_.max(dates!)!).toLocaleString();
    }

}

export default PackagesProblemsReportVisualStore;