import * as React from 'react';
import { Divider } from 'antd';

const AlphaVersionLabel: React.FC = () => (
    <div className="alpha-version-wrapper">
        <Divider />
        <div style={{ color: '#ffffff59', margin: '10px 0 12px 18px', textAlign: 'center' }}>
            Alpha version: {process.env.REACT_APP_VERSION ?? '0.0.0'}
        </div>
    </div>
);

export default AlphaVersionLabel;
