import * as React from 'react';
import RuleNewPage from '../screens/RuleNewPage';
import RulesEditPage from '../screens/RulesEditPage';
import { ProtectedRoute, monitorRouterMatch } from '../../common/components';
import WrappedRuleConnectionEdit from '../screens/ConnectionEditPage';
import RulesListPage from '../screens/RulesListPage';
import { STORE_RULE_EDIT, STORE_RULES_CONNECTIONS, STORE_TAG_PREVIEW } from '../constants';
import ConnectionListPage from '../screens/ConnectionListPage';
import RulesCopiedPipelinePage from '../screens/RulesCopiedPipelinePage';
import WrappedRuleConnectionAdd from '../screens/ConnectionAddPage';
import TagPreviewPage from '../screens/TagPreviewPage';
import RulePreviewPage from '../screens/RulePreviewPage';

export const RulesPagesNavigation = {
    RulesListPage: '/projects/:projectId/rules/list',
    RulesNewPage: '/projects/:projectId/:groupId/rules/new',
    RulesNewPageFromTag: '/projects/:projectId/:groupId/:tagId/rules/new',
    RulesNewPageWithCopiedPipeline: '/projects/:projectId/rules/copied/:groupId/:ruleId',
    RulesEditPage: '/projects/:projectId/rules/edit/:id/:isCopy',
    ConnectionsAddPage: '/projects/:projectId/rules/connections/add',
    ConnectionsEditPage: '/projects/:projectId/rules/connections/edit/:id',
    ConnectionsListPage: '/projects/:projectId/rules/connections/list',
    TagPreviewPage: '/projects/:id/tagPreview/:tag',
    RulesPreviewPage: '/projects/:projectId/rules/preview/:id/',
};

export default () => {
    return (
        <>
            <ProtectedRoute component={RulesListPage} path={RulesPagesNavigation.RulesListPage} />
            {/* <ProtectedRoute component={RuleNewPage} path={RulesPagesNavigation.RulesNewPage} /> */}
            <ProtectedRoute 
                component={monitorRouterMatch(STORE_RULE_EDIT, (s, m) => s[STORE_RULE_EDIT].setCurrentGroupId(m!.params))(RuleNewPage)} 
                path={RulesPagesNavigation.RulesNewPage} 
            />
            <ProtectedRoute 
                component={monitorRouterMatch(STORE_RULE_EDIT, (s, m) => s[STORE_RULE_EDIT].setPreselectedData(m!.params))(RuleNewPage)} 
                path={RulesPagesNavigation.RulesNewPageFromTag} 
            />
            <ProtectedRoute 
                component={monitorRouterMatch(STORE_RULE_EDIT, (s, m) => {
                    s[STORE_RULE_EDIT].setMatch(m!.params); s[STORE_RULE_EDIT].setPreselectedData(m!.params); 
                })(RulesCopiedPipelinePage)
                }
                path={RulesPagesNavigation.RulesNewPageWithCopiedPipeline} 
            />
            <ProtectedRoute 
                component={monitorRouterMatch(STORE_RULE_EDIT, (s, m) => s[STORE_RULE_EDIT].setMatch(m!.params))(RulesEditPage)}
                path={RulesPagesNavigation.RulesEditPage} 
            />
            <ProtectedRoute component={ConnectionListPage} path={RulesPagesNavigation.ConnectionsListPage} />
            <ProtectedRoute 
                component={monitorRouterMatch(STORE_RULES_CONNECTIONS, (s, m) => s[STORE_RULES_CONNECTIONS].setMatch(m!.params))(WrappedRuleConnectionEdit)}  
                path={RulesPagesNavigation.ConnectionsEditPage} 
            />
            <ProtectedRoute 
                component={monitorRouterMatch(STORE_RULES_CONNECTIONS, () => {
                    return; 
                })(WrappedRuleConnectionAdd)}  
                path={RulesPagesNavigation.ConnectionsAddPage} 
            />
            <ProtectedRoute 
                component={monitorRouterMatch(STORE_TAG_PREVIEW, (s, m) => s[STORE_TAG_PREVIEW].setItem(m!.params))(TagPreviewPage)} 
                path={RulesPagesNavigation.TagPreviewPage} 
            />
            <ProtectedRoute 
                component={monitorRouterMatch(STORE_RULE_EDIT, (s, m) => s[STORE_RULE_EDIT].setMatch(m!.params))(RulePreviewPage)}
                path={RulesPagesNavigation.RulesPreviewPage} 
            />
        </>
    );
};