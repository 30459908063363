import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Input, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { IotaMetadataInput } from '../types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { EncryptedInput } from '../../common/components';

interface Props {
    form: FormInstance;
    input: IotaMetadataInput;
    initialValue: unknown;
    disabled?: boolean
}

export const ApplicationSettingsPasswordInput: React.FC<Props> = ({ form, input, initialValue, disabled = false }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [inputVal, setInputVal] = React.useState<any>('');

    const renderInput = () => {
        const isEncrypted = input.behavior && input.behavior.encrypted;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onChangeHandler = isEncrypted ? (val: any) => {
            let valObj = {};
            const newVal = Array.isArray(val) || typeof(val) === 'string' ? val : val.target.value;
            valObj[input.id] = newVal;
            form.setFieldsValue(valObj);
            setInputVal(newVal);
        } : undefined;

        if (isEncrypted) {
            return (
                <EncryptedInput changeHandler={onChangeHandler!} initialValue={initialValue}>
                    <Input 
                        value={isEncrypted ? inputVal : undefined} 
                        disabled={disabled}
                        data-id={`iota-edit-application-settings-input-password${input.id}`} 
                        size="large" 
                        onChange={onChangeHandler}
                    />
                </EncryptedInput>);
        }

        return (<Input.Password 
            disabled={disabled}
            data-id={`iota-edit-application-settings-input-password${input.id}`} 
            size="large" 
            iconRender={visible => (visible 
                ? <span style={{height: 22}}><i className="alpha-icon lg eye-visible" /></span> 
                : <span style={{height: 22}}><i className="alpha-icon lg eye-invisible" /></span>)
            }
        />);
    };

    return (
        <FormItem
            className='required-with-tooltip'
            label={(
                <span>
                    {input.name}&nbsp;
                    <Tooltip title={input.description}>
                        <QuestionCircleOutlined/>
                    </Tooltip>
                </span>
            )}
            name={input.id}
            rules={[{
                ...input.validation,
                message: 'Please input password!',
                whitespace: true,
                type: 'string'
            }]}
            initialValue={initialValue}
        >
            {renderInput()}
        </FormItem>
    );
};

export default observer(ApplicationSettingsPasswordInput);