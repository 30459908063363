import appClient, { ResultApi } from '../../common/services/AppClient';
import { QuestionResponseDto } from '../types';
import { ChatbotSettingsValues } from '../types';

export default class ChatbotService {
    async askQuestion(
        message: string,
        packageId: string,
        nodeIds: string[],
        settings: Omit<ChatbotSettingsValues, 'useContext'>
    ): Promise<ResultApi<QuestionResponseDto>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}chatbot/ask-question`;

        const data = {
            message,
            packageId,
            nodeIds,
            ...settings
        };

        return appClient.post(url, data);
    }
}
