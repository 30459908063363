import ProjectMLModelsVisualStore from './ProjectMLModelsVisualStore';
import { RootStores } from '../../common/stores';
import { MLService } from '../services';
import { STORE_PROJECT_ML_MODELS } from '../constants';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';

export function injectMlStorageStores(obj: RootStores) {
    const service = new MLService();
    obj[STORE_PROJECT_ML_MODELS] = new  ProjectMLModelsVisualStore(obj[STORE_PROJECTS_ROOT_UI], service);
}

export type MLStorageStores = {
    [STORE_PROJECT_ML_MODELS]?: ProjectMLModelsVisualStore
};