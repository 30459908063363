import { Checkbox, Collapse, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { AlphaConfig } from '../../administration/models/AlphaConfig';
import { FeatureFlagsOverride, FeatureFlagsOverrideKey } from '../FeatureFlagsHelpers';
import { isEmpty } from 'lodash';
import { FeatureFlags } from '../models';

type Props = {
    alphaConfigs: AlphaConfig[];
    featureFlags: FeatureFlagsOverride;
    storedFeatureFlags?: FeatureFlags | null;
    isNewProject?: boolean
};

const FeatureFlagsRenderer: React.FC<Props> = ({alphaConfigs, featureFlags, storedFeatureFlags, isNewProject}) => {
    const featureFlagsPanelExtra = (name: FeatureFlagsOverrideKey, flags?: Record<string, string>) => {
        return (
            <div
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <FormItem
                    name={name}
                    className="feature-flag-override"
                    valuePropName="checked"
                    initialValue={!isEmpty(flags)}
                >
                    <Checkbox>Override flags</Checkbox>
                </FormItem>
            </div>
        );
    };

    const getValue = (prefix: string, name: string) => {
        if (isNewProject) {
            return null;
        }
        const flags = storedFeatureFlags;
        return flags ? flags[prefix][name] : null;
    };
    
    return (
        <Observer>{() => (
            <Collapse>
                <Collapse.Panel 
                    key="1" 
                    header="General feature flags" 
                    forceRender
                    extra={featureFlagsPanelExtra(
                        FeatureFlagsOverrideKey.OverrideFeatureFlags,
                        storedFeatureFlags?.general
                    )}
                >
                    {alphaConfigs.map((config) => (
                        <FormItem
                            key={config.name}
                            name={config.name}
                            initialValue={getValue('general', config.name) || config.value}
                            label={config.name}
                        >
                            <Input disabled={!featureFlags.overrideFeatureFlags} />
                        </FormItem>
                    ))}
                </Collapse.Panel>
                <Collapse.Panel 
                    key="2" 
                    header="Text feature flags"
                    forceRender
                    extra={featureFlagsPanelExtra(
                        FeatureFlagsOverrideKey.OverrideTextFeatureFlags,
                        storedFeatureFlags?.text
                    )}
                >
                    {alphaConfigs.map((config) => (
                        <FormItem
                            key={'TEXT::' + config.name}
                            name={'TEXT::' + config.name}
                            initialValue={getValue('text', config.name) || config.value}
                            label={config.name}
                        >
                            <Input disabled={!featureFlags.overrideTextFeatureFlags} />
                        </FormItem>
                    ))}
                </Collapse.Panel>
                <Collapse.Panel 
                    key="3" 
                    header="Images feature flags" 
                    forceRender
                    extra={featureFlagsPanelExtra(
                        FeatureFlagsOverrideKey.OverridePagesFeatureFlags,
                        storedFeatureFlags?.image
                    )}
                >
                    {alphaConfigs.map((config) => (
                        <FormItem
                            key={'IMAGE::' + config.name}
                            name={'IMAGE::' + config.name}
                            initialValue={ getValue('image', config.name) || config.value}
                            label={config.name}
                        >
                            <Input disabled={!featureFlags.overridePagesFeatureFlags} />
                        </FormItem>
                    ))}
                </Collapse.Panel>
            </Collapse>)}
        </Observer>


    );

};

export default observer(FeatureFlagsRenderer);