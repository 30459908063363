import * as React from 'react';
import { Page } from 'react-pdf';
import { observer, Observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import { PackageViewerVisualStoreBase } from '../../viewer_base/stores';
import { Block } from '../../interactive_labels/stores/InteractiveLabelsVisualStore';
import InteractiveBlock from '../../interactive_labels/components/InteractiveBlock';
import { isNumber } from 'lodash';

type PageRendererData = {
    scale: number;
    numPages: number;
    triggerResize: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pages: Map<any, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageNumbers: Map<any, any>;
    renderBlocks: boolean;    
    store: PackageViewerVisualStoreBase
};

type Props = {
    index: number;
    style: Object;
    data: PageRendererData
};

type State = {
    pageBlocks: Block[]
};

class PageRenderer extends React.Component<Props, State> {

    isMultipleBlocksMode: boolean;

    constructor(props: Props) {
        super(props);
        this.state = { pageBlocks: [] };
    }

    render() {
        const { index, data, style } = this.props;
        const { scale, numPages, triggerResize, store } = data;
        const pageNumber = index + 1;
        let shouldRender = store!.pagesLoaded.indexOf(pageNumber) >= 0;
        
        if (shouldRender) {
            return (
                <div {...{ style }}>
                    <div
                        ref={ref => {
                            const { pages, pageNumbers } = this.props.data;

                            if (!pageNumbers.has(pageNumber)) {
                                const key = { pageNumber };
                                pageNumbers.set(pageNumber, key);
                            }

                            pages.set(pageNumbers.get(pageNumber), ref);
                        }}
                    >
                        <AutoSizer style={{width: 'unset', height: 'unset'}}>
                            {({ width }) => (
                                <Observer>{() =>
                                    <Page
                                        {...{ pageNumber }}
                                        {...{ scale }}
                                        width={(width * store!.scale)}
                                        renderTextLayer={false}
                                        renderAnnotations={false}
                                        onRenderSuccess={() => this.handleLoadSuccess(width)}
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        onLoadSuccess={(page: any) => {
                                            // This is necessary to ensure the row heights of
                                            // the variable list are correctly initialised.
                                            if (page.pageNumber === numPages) {
                                                triggerResize();
                                            }
                                        }} 
                                    />
                                }</Observer>
                            )}
                        </AutoSizer>
                        {
                            this.renderBlocks()
                        }
                    </div>
                </div >
            );
        } else {
            return (<div />);
        }
    }

    renderBlocks() {
        const { store } = this.props.data;
        if (this.props.data.renderBlocks && this.state.pageBlocks && this.state.pageBlocks.length > 0) {
            return (this.state.pageBlocks.map((b, i) =>
                (
                    <InteractiveBlock key={`${this.props.index + 1}_page_${i}_block`} block={b}/>
                )));
        } else if (store.highlightBlockProps 
            && store.highlightBlockProps.page === this.props.index
            && store.selectedPackage 
            && store.selectedPackage.id === store.highlightBlockProps.packageId) {
            return (          
                <div 
                    className="highlighted-block"
                    style={{
                        background: '#33f14a52',
                        zIndex: 1000,
                        height: store.highlightBlockProps.height,
                        width: store.highlightBlockProps.width,
                        bottom: store.highlightBlockProps.bottom,
                        left: store.highlightBlockProps.left,
                        visibility: store.highlightBlockProps.visibility,
                        position: store.highlightBlockProps.position
                    }}
                />
            );
        } else {        
            if (store.highlightedBlock) {                
                let blockStyle = {};
                blockStyle = {
                    backgroundColor: '#33f14a52',
                    top: this.parseCssNumberValue(store.highlightedBlock.y), 
                    left: this.parseCssNumberValue(store.highlightedBlock.x),
                    position: 'absolute',
                    width: this.parseCssNumberValue(store.highlightedBlock.width), 
                    height: this.parseCssNumberValue(store.highlightedBlock.height)
                };
                return (
                    <div
                        className="interactiveBlock"
                        style={{ ...blockStyle }}
                    />
                );

            }
        }
        return null;
    }

    handleLoadSuccess(width: number) {
        const { store } = this.props.data;
        store!.setPageWidth(width * store!.scale);
        this.setState({ pageBlocks: store!.getBlocksForPage(this.props.index) });
        if (store.highlightBlockProps 
            && store.highlightBlockProps.page === this.props.index
            && store.selectedPackage 
            && store.selectedPackage.id === store.highlightBlockProps.packageId) {
            setTimeout(() => {
                store.highlightBlockRedrawSubject.next(store.selectedPackage?.id);
            }, 100);
            
        }
    }

    parseCssNumberValue (num: Number | string) {
        if (!!num && (isNumber(num) || num.toString().indexOf('px') >= 0)) {
            return num;
        }

        return 'unset';
    }
}

export default observer(PageRenderer);
