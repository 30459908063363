import { Button, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectApplicationDefinitionEditVisualStore } from '../../stores';

type Props = {
    store: ProjectApplicationDefinitionEditVisualStore
};

const BindingImportModal: React.FC<Props> = ({store}) => {
    return (
        <Modal
            title="Import bindings"
            className="alpha-portal-dialog"
            visible={store.isImportConfirmDialogVisible}
            onCancel={store.handleImportDialogCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button 
                    data-id="iota-confirm-bindings-import-dialog-cancel" 
                    className="light" 
                    key="back" 
                    size="large" 
                    onClick={store.handleImportDialogCancel}
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="iota-confirm-bindings-import-dialog-proceed"  
                    key="submit" 
                    size="large" 
                    type="primary"
                    onClick={store.handleImportDialogConfirm}
                    disabled={store.isImportingBindings}
                    loading={store.isImportingBindings}
                >
                    Proceed
                </Button>
            ]}
        >
            You are attempting to import application input bindings, but some tags are not present in the project. 
            Are you sure you want to continue?
        </Modal>
    );
};

export default observer(BindingImportModal);