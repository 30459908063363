import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { FlexContentContainer } from '../../common/components';
import { RulesStores } from '../stores';
import { STORE_RULE_EDIT } from '../constants';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { RulePreviewForm } from '../components';
import { reaction } from 'mobx';
import { PipelineStep } from '../models';
import { PipelineStepResult } from '../types';
import PipelineStepType from '../types/PipelineStepType';
import _ from 'lodash';
type Props = RulesStores;

@inject(STORE_RULE_EDIT)
@observer
export default class RulePreviewPage extends React.Component<Props, object> {
    componentDidMount() {
        const store = this.props.RuleEditUI!;
        document.body.classList.add('rule-preview-page');
        reaction(() => store.editableRule, (rule, r) => {
            if (rule) {
                store.activatePreview();
                
                window.addEventListener('storage', () => this.handleRuleUpdateFromStorage(store.editableRule!.id!));
                this.handleRuleUpdateFromStorage(store.editableRule!.id!);
                r.dispose();
            }
        });
        const projectLayoutWrapper = document.getElementById('project-layout-wrapper');
        if (projectLayoutWrapper) {
            projectLayoutWrapper.classList.add('no-padding');
        }
    }

    componentWillUnmount() {
        const store = this.props.RuleEditUI!;
        const projectLayoutWrapper = document.getElementById('project-layout-wrapper');
        if (projectLayoutWrapper) {
            projectLayoutWrapper.classList.remove('no-padding');
        }

        if (store!.editableRule) { 
            localStorage.removeItem(`rule-preview-${store!.editableRule!.id!}`);
            window.removeEventListener('storage', () => this.handleRuleUpdateFromStorage(store!.editableRule!.id!));
        }
    }  

    handleRuleUpdateFromStorage(ruleId: string) {
        const ruleJson = localStorage.getItem(`rule-preview-${ruleId}`);

        if (!ruleJson) {
            return;
        }

        const makePipeline = (pip: PipelineStepResult<PipelineStepType>[]) => {
            return (pip || []).map(p => new PipelineStep(p.name, p.type, p.parameters || {}, p.stepId, undefined, p.isDisabled));
        };

        const rule = JSON.parse(ruleJson);
        if (rule && this.props.RuleEditUI!.previewVisualStore) {
            let newRule = this.props.RuleEditUI!.getRuleOfNewType(makePipeline(rule.pipeline), rule.ruleType, rule);
            newRule = _.merge(newRule, rule);
            this.props.RuleEditUI!.previewVisualStore?.setRule(newRule!);
        }
    }

    render() {
        const store = this.props.RuleEditUI!;

        const lines = !store.isLoading && store.previewVisualStore && store.editableRule != null ?
            (
                <RulePreviewForm RulePreviewUI={this.props.RuleEditUI!.previewVisualStore!} />
            ) : <LoadingIndicator/>;

        return (
            <FlexContentContainer style={{overflow: 'hidden'}}
                content={lines}
            />
        );
    }
}