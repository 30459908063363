import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import { IotaAuditResponse, IotaProjectPayloadsResult, SearchAuditRequest, AuditInput } from '../types/IotaAuditResult';

export default class IotaAuditService {

    async searchAudit(request: SearchAuditRequest): Promise<IotaAuditResponse> {
        let { projectId, searchTerm, pageSize, page, dates } = request;

        const fieldsSearch = {dateFilter: null as {} | null};

        if (dates.length) {
            fieldsSearch.dateFilter = { dates: {first: dates[0], second: dates[1] } };
        } 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var result = await execQuery<any>({
            /* eslint-disable max-len */
            query: gql`query searchIotaAuditForProject($projectId:String!, $searchTerm: String, $pageSize: Int!, $page: Int = 0, $fieldsSearch: Json = null) {
                searchIotaAuditForProject(projectId:$projectId, searchTerm:$searchTerm, pageSize:$pageSize, page:$page, fieldsSearch: $fieldsSearch) {
                    result {
                        id
                        applicationName
                        packageName
                        date
                        sessionId
                      },
                        pageInfo {
                        total
                      }
                }
            }`,
            variables: {
                projectId: projectId,
                searchTerm: !searchTerm || searchTerm.length === 0 ? null : searchTerm,
                pageSize,
                page: page || 0,
                fieldsSearch: fieldsSearch || null,
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors || !result.data.searchIotaAuditForProject.result) {
            if (result.errors) {
                throw new Error(result.errors!.toString()); 
            }

            return new IotaAuditResponse();
        }

        var response = new IotaAuditResponse();
        response.total = result.data.searchIotaAuditForProject.pageInfo.total;
        response.lines = result.data.searchIotaAuditForProject.result;
        return response;
    }


    async getIotaProjectPayloads(sessionId: string, packageName: string): Promise<AuditInput[]> {
        var result = await execQuery<IotaProjectPayloadsResult>({
            query: gql`query getIotaProjectPayloads($sessionId:String!, $packageName:String) {
                getIotaProjectPayloads(sessionId: $sessionId, packageName: $packageName) {
                    field
                    value
                    isAuto
                    comments {
                        userId
                        value
                        date
                        }
                    auditInputsMeta {
                        source
                        page
                        user
                        date
                    }
                }   
            }`,
            variables: {
                sessionId: sessionId,
                packageName: packageName
            },
            fetchPolicy: 'network-only'
        });

        return result.data.getIotaProjectPayloads || [];
    }
}