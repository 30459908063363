import InteractiveLabelsVisualStore from './InteractiveLabelsVisualStore';
import ChatbotStore from './ChatbotStore';
import { ProjectsService } from '../../common/services';
import { RootStores } from '../../common/stores';
import { STORE_PROJECT_INTERACTIIVE_LABELS, STORE_INTERACTIVE_LABELS_CHATBOT } from '../constants';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import ChatbotService from '../services/ChatbotService';
import InstructWorkflowService from '../../instruct_workflows/services/InstructWorkflowService';

export { default as InteractiveLabelsVisualStore } from './InteractiveLabelsVisualStore';
export { default as ChatbotStore } from './ChatbotStore';

export function injectIntLabelsStores(obj: RootStores) {
    const service = new ProjectsService();
    const chatbotService = new ChatbotService();
    const instructWorkflowService = new InstructWorkflowService();
    obj[STORE_PROJECT_INTERACTIIVE_LABELS] = new InteractiveLabelsVisualStore(obj[STORE_PROJECTS_ROOT_UI], service);
    obj[STORE_INTERACTIVE_LABELS_CHATBOT] = new ChatbotStore(
        chatbotService,
        instructWorkflowService,
        obj[STORE_PROJECT_INTERACTIIVE_LABELS],
        obj[STORE_PROJECTS_ROOT_UI]
    );
}

export type IntLabelsStores = {
    [STORE_PROJECT_INTERACTIIVE_LABELS]?: InteractiveLabelsVisualStore;
    [STORE_INTERACTIVE_LABELS_CHATBOT]?: ChatbotStore
};