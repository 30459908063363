import * as React from 'react';
import { observer } from 'mobx-react-lite';
import CodeBusinessRuleVisualStore from '../stores/CodeBusinessRuleVisualStore';
import { Button } from 'antd';
import CodeBusinessRule from './CodeBusinessRule';
import BusinessRulesService from '../services/BusinessRulesService';

type Props = {
    code: string;
    onSave: (code: string) => void;
    dataId?: string;
    isDisabled: boolean
};

export const CodeBusinessRulePreview: React.FC<Props> = observer(({ code, onSave, dataId, isDisabled }) => {   
    const [store] = React.useState(() => new CodeBusinessRuleVisualStore(code, onSave, new BusinessRulesService()));

    React.useEffect(() => {
        if (!store.codeSnippets || !store.codeSnippets.length) {
            store.loadCodeSnippets();
        }  
        
        if (!store.codeSnippetGroups || !store.codeSnippetGroups.length) {
            store.loadCodeSnippetGroups();
        } 
        return store.dispose;
    }, [store]);

    let control = (
        <Button 
            style={{width: '100%'}}
            className="light"
            disabled={isDisabled} 
            data-id={dataId ? `${dataId}-open-modal` : 'code-editor-open-modal'} 
            onClick={() => store.showModal()}
        >
                        Edit
        </Button>);

    if (store.isOpened) {
        control = (<CodeBusinessRule dataId={dataId} store={store} />);
    }

    return control;
});

// const WrappedAddApplicationDefinition = Form.create()(AddApplicationDefinition);
// export default WrappedAddApplicationDefinition;
export default CodeBusinessRulePreview;