import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Form, Select, Tooltip } from 'antd';
import HistoryStore from '../../stores/HistoryStore';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
    store: HistoryStore
}

const HistoryFilters: React.FC<Props> = ({ store }) => {
    return (
        <Form className="history-filter-form" layout="inline">
            <Form.Item>
                <Select
                    allowClear
                    style={{ width: 300 }}
                    placeholder="Select area..."
                    value={store.selectedAreaOption ?? undefined}
                    onChange={(value?: string) => {
                        store.setSelectedAreaOption(value ?? null);
                        store.setPage(0);
                        store.searchHistory();
                    }}
                    options={store.historyAreaOptions}
                />
            </Form.Item>
            <Form.Item>
                <Checkbox
                    checked={store.searchOnlyProjectRelated}
                    onChange={(e) => {
                        store.setSearchOnlyProjectRelated(e.target.checked);
                        store.setPage(0);
                        store.searchHistory();
                    }}
                >
                    Project changes only
                    <Tooltip 
                        title="Selecting the 'project changes only' option will hide any modifications made within commonly shared areas,
                            such as Reference Tables, that are utilized by multiple projects. 
                            This option ensures that only changes specific to the current project are displayed."
                        className="history-filter-tooltip"
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Checkbox>
            </Form.Item>
        </Form>
    );
};

export default observer(HistoryFilters);
