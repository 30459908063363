import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { ApplicationDefinitionConditionalEditStore } from '../../stores';
import './ConditionsActionsRenderer.less';

interface Props {
    store: ApplicationDefinitionConditionalEditStore
}

const ConditionsActionsRenderer: React.FC<Props> = ({ store }) => {
    return (
        <div className="conditions-actions-renderer">
            <Button type="primary" onClick={() => store.setCreateEditConditionDialogVisible(true)}>
                New Condition
            </Button>
        </div>
    );
};

export default observer(ConditionsActionsRenderer);
