
import * as React from 'react';
import SessionsGrid from '../components/SessionsGrid';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { IotaSessionsStores } from '../stores';
import { STORE_SESSIONS } from '../constants';
type Props = IotaSessionsStores;

const SessionsPage: React.FC<Props> = ({SessionsUI: store }) => {
    React.useEffect(() => {
        store!.getApplicationNames(); 
    } , [store]);
    return (
        <FlexContentContainer 
            content={<SessionsGrid store={store!} /> }  
        />);

};
  
export default inject(STORE_SESSIONS)(observer(SessionsPage));