import GenericHistoryRecordModel from '../models/GenericHistoryRecordModel';
import { HistoryRecord } from '../services/types';
import { ProjectHistoryRecordModel } from '../models/project';
import {
    AppDefHistoryRecordModel,
    AppDefBindingHistoryRecordModel,
    AppDefInputGroupHistoryRecordModel,
    AppDefSectionHistoryRecordModel
} from '../models/application_definition';
import { RuleHistoryRecordModel } from '../models/rules';
import { RuleTagHistoryRecordModel } from '../models/rule_tag';
import { ConnDefHistoryRecordModel } from '../models/connections_definition';
import { TagsGroupHistoryRecordModel } from '../models/tags_group';
import { RefDataConnHistoryRecordModel } from '../models/ref_data_connection';
import { RefDataTableHistoryRecordModel, RefDataTableRecordHistoryRecordModel } from '../models/ref_data';
import { FormPartHistoryModel } from '../models/form_part';
import { FormTypeHistoryModel, FormVariationHistoryModel } from '../models/form_type';
import { MLModelHistoryRecordModel, MLModelRevisionHistoryRecordModel } from '../models/ml_model';
import {
    ProjectFieldGroupHistoryRecordModel,
    ProjectFieldHistoryRecordModel,
    ProjectFieldSectionHistoryRecordModel
} from '../models/project_field_group';
import { TestProjectHistoryRecordModel, TestProjectTopicHistoryRecordModel } from '../models/test_project';
import { TestProjectBaselineHistoryRecordModel } from '../models/test_project_baseline';
import { InstructWorkflowHistoryModel } from '../models/instruct_workflow';
import PackageHistoryRecordModel from '../models/package/PackageHistoryRecordModel';

export default class HistoryRecordFactory {
    static createHistoryRecord(historyRecord: HistoryRecord) {
        switch (historyRecord.objectName) {
        case 'Project':
            return new ProjectHistoryRecordModel(historyRecord);
        case 'ApplicationDefinitionBase':
            return new AppDefHistoryRecordModel(historyRecord);
        case 'ApplicationDefinitionInputBinding':
            return new AppDefBindingHistoryRecordModel(historyRecord);
        case 'ApplicationDefinitionInputGroup':
            return new AppDefInputGroupHistoryRecordModel(historyRecord);
        case 'ApplicationDefinitionSection':
            return new AppDefSectionHistoryRecordModel(historyRecord);
        case 'RuleBase':
            return new RuleHistoryRecordModel(historyRecord);
        case 'RuleTag':
            return new RuleTagHistoryRecordModel(historyRecord);
        case 'ConnectionsDefinition':
            return new ConnDefHistoryRecordModel(historyRecord);
        case 'TagsGroup':
            return new TagsGroupHistoryRecordModel(historyRecord);
        case 'RefDataConnection':
            return new RefDataConnHistoryRecordModel(historyRecord);
        case 'FormPart':
            return new FormPartHistoryModel(historyRecord);
        case 'FormType':
            return new FormTypeHistoryModel(historyRecord);
        case 'FormVariation':
            return new FormVariationHistoryModel(historyRecord);
        case 'MLModel':
            return new MLModelHistoryRecordModel(historyRecord);
        case 'MLModelRevision':
            return new MLModelRevisionHistoryRecordModel(historyRecord);
        case 'ProjectFieldGroup':
            return new ProjectFieldGroupHistoryRecordModel(historyRecord);
        case 'ProjectFieldSection':
            return new ProjectFieldSectionHistoryRecordModel(historyRecord);
        case 'ProjectField':
            return new ProjectFieldHistoryRecordModel(historyRecord);
        case 'TestProject':
            return new TestProjectHistoryRecordModel(historyRecord);
        case 'TestProjectTopic':
            return new TestProjectTopicHistoryRecordModel(historyRecord);
        case 'TestProjectBaseline':
            return new TestProjectBaselineHistoryRecordModel(historyRecord);
        case 'ReferenceDataTable':
            return new RefDataTableHistoryRecordModel(historyRecord);
        case 'ReferenceDataTableRecord':
            return new RefDataTableRecordHistoryRecordModel(historyRecord);
        case 'InstructWorkflow':
            return new InstructWorkflowHistoryModel(historyRecord);
        case 'Package':
            return new PackageHistoryRecordModel(historyRecord);
        default:
            return new GenericHistoryRecordModel(historyRecord);
        }
    }
}
