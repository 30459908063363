import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { STORE_CODE_SNIPPETS_UI } from '../../../modules/administration/constants';
import { AdministrationStores } from '../../../modules/administration/stores';
import CodeSnippets from '../components/CodeSnippets';
import { AuthConsumer } from '../../authorization/AuthContext';

type Props =  AdministrationStores;
@inject(STORE_CODE_SNIPPETS_UI)
@observer
export default class CodeSnippetsPage extends React.Component<Props, object> {
    
    render() {
        const codeSnippetsStore = this.props.CodeSnippetsUI!;
        const content = (
            <AuthConsumer>
                {() => (
                    <CodeSnippets store={codeSnippetsStore}/>
                )}
            </AuthConsumer>
        );

        return (
            <FlexContentContainer content={content}/>
        );
    }
}