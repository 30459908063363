import { CodeSnippetType } from '../types/CodeSnippetType';

export default class CodeSnippet {
    constructor(
        public id: string | undefined, 
        public name: string, 
        public type: CodeSnippetType,
        public code: string,        
        public updateDate?: string,
        public description?: string,
        public groupId?: string,
        public fullSnippetGroupPath?: string) {
        
        this.id = id;
        this.name = name;
        this.type = type;
        this.code = code;
        this.updateDate = updateDate;
        this.description = description;
        this.groupId = groupId;
        this.fullSnippetGroupPath = fullSnippetGroupPath;
    }
}