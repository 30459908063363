import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import './TestProjectSortingActions.less';

type Props = {
    sortingDirection: 'ascend' | 'descend';
    setSortingDirection: (direction: 'ascend' | 'descend') => void;
    fields: string[];
    fieldName: string;
    setFieldName: (fieldName: string) => void;
    storageKey?: string
};

const TestProjectSortingActions: React.FC<Props> = ({sortingDirection, setSortingDirection, fields, fieldName, setFieldName, storageKey}) => {
    React.useEffect(() => {
        initSorting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
        
    const initSorting = () => {
        if (!storageKey) {
            return;
        }

        const direction = sessionStorage.getItem(storageKey);
        if (direction) {
            setSortingDirection(direction as 'ascend' | 'descend');
        }

        const field = sessionStorage.getItem(`${storageKey}-field`);
        if (field) {
            setFieldName(field);
        }
    };

    const toggleSorting = () => {
        if (sortingDirection === 'descend') {
            // Toggle field after descending sort
            changeField();
        }

        let newDirection: 'ascend' | 'descend' = sortingDirection === 'ascend' ? 'descend' : 'ascend';
        setSortingDirection(newDirection);

        if (storageKey) {
            sessionStorage.setItem(`${storageKey}-direction`, newDirection);
        }
    };

    const changeField = () => {
        if (fields.length === 1) {
            return;
        }

        const currentIndex = fields.indexOf(fieldName);
        const newIndex = currentIndex === fields.length - 1 ? 0 : currentIndex + 1;
        setFieldName(fields[newIndex]);

        if (storageKey) {
            sessionStorage.setItem(`${storageKey}-field`, fields[newIndex]);
        }
    };

    return (
        <div className="sort-actions-wrapper">
            <Button type="link" onClick={toggleSorting}>
                Order by: {fieldName} {sortingDirection === 'ascend' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
            </Button>
        </div>  
    );
};


export default observer(TestProjectSortingActions);