import { FormInstance } from 'antd';
import { AlphaConfigurations } from './constants';
import { RcFile } from 'antd/lib/upload/interface';
import { FeatureFlags, Project } from './models';
import { isEmpty } from 'lodash';

export enum FeatureFlagsOverrideKey {
    OverrideFeatureFlags = 'overrideFeatureFlags',
    OverridePagesFeatureFlags = 'overridePagesFeatureFlags',
    OverrideTextFeatureFlags = 'overrideTextFeatureFlags'
}

export type FeatureFlagsOverride = {
    [FeatureFlagsOverrideKey.OverrideFeatureFlags]: boolean;
    [FeatureFlagsOverrideKey.OverridePagesFeatureFlags]: boolean;
    [FeatureFlagsOverrideKey.OverrideTextFeatureFlags]: boolean
};

export const featureFlagsOverrideKeys = Object.values(FeatureFlagsOverrideKey) as string[];

const alphaConfigFormDefaults = AlphaConfigurations.reduce<Record<string, string>>((keys, config) => {
    keys[config.key] = config.defaultValue;
    keys['TEXT::' + config.key] = config.defaultValue;
    keys['IMAGE::' + config.key] = config.defaultValue;
    return keys;
}, {});

export const transformFeatureFlags = (form: FormInstance) => {
    const obj = {};

    const overrideFeatureFlags = form.getFieldValue(FeatureFlagsOverrideKey.OverrideFeatureFlags);
    const overrideTextFeatureFlags = form.getFieldValue(FeatureFlagsOverrideKey.OverrideTextFeatureFlags);
    const overridePagesFeatureFlags = form.getFieldValue(FeatureFlagsOverrideKey.OverridePagesFeatureFlags);

    AlphaConfigurations.forEach(c => {
        if (!overrideFeatureFlags) {
            form.resetFields([c.key]);
        }

        if (overrideFeatureFlags) {
            obj['GENERAL::' + c.key] = form.getFieldValue(c.key);
        }

        if (
            overrideTextFeatureFlags &&
            (!overrideFeatureFlags || form.getFieldValue('TEXT::' + c.key) !== obj['GENERAL::' + c.key])
        ) {
            obj['TEXT::' + c.key] = form.getFieldValue('TEXT::' + c.key);
        }

        if (
            overridePagesFeatureFlags &&
            (!overrideFeatureFlags || form.getFieldValue('IMAGE::' + c.key) !== obj['GENERAL::' + c.key])
        ) {
            obj['IMAGE::' + c.key] = form.getFieldValue('IMAGE::' + c.key);
        }
    });

    // If obj is empty return null.
    return Object.keys(obj).length === 0 ? null : JSON.stringify(obj);
};

export const resetFormFeatureFlags = (form?: FormInstance) => {
    if (!form) {
        return;
    }

    const featureFlagFieldsKeys = Object.keys(alphaConfigFormDefaults);

    form.resetFields([...featureFlagsOverrideKeys, ...featureFlagFieldsKeys]);
};

export const importFeatureFlags = (
    file: RcFile,
    form?: FormInstance,
    onImportSuccess?: (featureFlags: FeatureFlagsOverride) => void
) => {
    if (!form) {
        return;
    }

    const reader = new FileReader();

    reader.onload = e => {
        if (!e.target || typeof e.target.result !== 'string') {
            return;
        }

        const data: { featureFlags: FeatureFlags } | null = JSON.parse(e.target.result);

        if (!data || !data.featureFlags) {
            return;
        }

        form.setFieldsValue({ ...alphaConfigFormDefaults });
        const {overrideFeatureFlags, overrideTextFeatureFlags, overridePagesFeatureFlags} = setFormFeatureFlags(form, data.featureFlags);

        form.setFieldsValue({
            overrideFeatureFlags,
            overrideTextFeatureFlags,
            overridePagesFeatureFlags
        });

        if (onImportSuccess) {
            onImportSuccess({
                overrideFeatureFlags,
                overrideTextFeatureFlags,
                overridePagesFeatureFlags
            });
        }
    };

    reader.readAsText(file);
};

export const setProjectFeatureFlags = (form?: FormInstance, project?: Project, callback?: (featureFlags: FeatureFlagsOverride) => void) => {
    if (!form || !project?.storedFeatureFlags) {
        return;
    }

    form.setFieldsValue({ ...alphaConfigFormDefaults });

    const featureFlags = project.storedFeatureFlags;
    
    const {overrideFeatureFlags, overrideTextFeatureFlags, overridePagesFeatureFlags} = setFormFeatureFlags(form, featureFlags);
    form.setFieldsValue({
        overrideFeatureFlags,
        overrideTextFeatureFlags,
        overridePagesFeatureFlags
    });

    if (callback) {
        callback({
            overrideFeatureFlags,
            overrideTextFeatureFlags,
            overridePagesFeatureFlags
        });
    }
};

const setFormFeatureFlags = (form: FormInstance, featureFlags: FeatureFlags) => {
    const overrideFeatureFlags = !isEmpty(featureFlags.general);
    const overrideTextFeatureFlags = !isEmpty(featureFlags.text);
    const overridePagesFeatureFlags = !isEmpty(featureFlags.image);

    if (overrideFeatureFlags) {
        Object.entries(featureFlags.general).forEach(([flag, value]) => {
            form.setFieldsValue({ [flag]: value });
        });
    }

    if (overrideTextFeatureFlags) {
        Object.entries(featureFlags.text).forEach(([flag, value]) => {
            form.setFieldsValue({ [`TEXT::${flag}`]: value });
        });
    }

    if (overridePagesFeatureFlags) {
        Object.entries(featureFlags.image).forEach(([flag, value]) => {
            form.setFieldsValue({ [`IMAGE::${flag}`]: value });
        });
    }

    return {overrideFeatureFlags, overrideTextFeatureFlags, overridePagesFeatureFlags};
};
