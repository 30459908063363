import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { FlexContentContainer } from '../../common/components';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { TagPreview } from '../../rules/components';
import {
    STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW,
    STORE_PROJECT_APPLICATION_DEFINITION_EDIT
} from '../stores/constants';
import { STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW } from '../../application_definition_conditional/stores/constants';
import { IotaAppStores } from '../stores';
import { ApplicationDefinitionConditionalStores } from '../../application_definition_conditional/stores';
import { ApplicationDefinitionConditional } from '../types/ApplicationDefinition';

type Props = IotaAppStores & ApplicationDefinitionConditionalStores;

const ApplicationDefinitionBindingPreviewPage: React.FC<Props> = ({
    projectApplicationDefinitionsBindingPreviewUI: applicationDefinitionsBindingPreviewStore,
    projectApplicationDefinitionEditUI: applicationDefinitionEditStore,
    applicationDefinitionConditionalBindingPreviewUI: applicationDefinitionConditionalBindingPreviewStore
}) => {
    if (
        !applicationDefinitionsBindingPreviewStore ||
        !applicationDefinitionEditStore ||
        !applicationDefinitionConditionalBindingPreviewStore
    ) {
        return null;
    }

    const getStore = () => {
        if (
            applicationDefinitionEditStore?.currentAppDef instanceof ApplicationDefinitionConditional ||
            window.location.pathname.includes('applicationDefinitionConditionalPreview')
        ) {
            return applicationDefinitionConditionalBindingPreviewStore;
        }

        return applicationDefinitionsBindingPreviewStore;
    };

    const store = getStore();

    return (
        <FlexContentContainer
            content={store.isLoading ? <LoadingIndicator /> : <TagPreview store={store} isAplicatonBindingPreview />}
        />
    );
};

export default inject(
    STORE_PROJECT_APPLICATION_DEFINITION_BINDING_PREVIEW,
    STORE_PROJECT_APPLICATION_DEFINITION_EDIT,
    STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW
)(observer(ApplicationDefinitionBindingPreviewPage));
