import { observable, action, computed, reaction } from 'mobx';
import { message } from 'antd';
import { ProjectsRootVisualStore } from '../../common/stores';
import ReferenceDataVisualStore from './ReferenceDataVisualStore';
import ReferenceDataService from '../services/ReferenceDataService';
import ReferenceDataUsageModel from '../models/ReferenceDataUsageModel';

export default class ReferenceDataUsageStore {
    @observable
    singleProjectDialogVisible: boolean = false;

    @observable
    mutipleProjectsDialogVisible: boolean = false;

    @observable
    singleProjectDataUsage: ReferenceDataUsageModel[] = [];

    @observable
    mutipleProjectsDataUsage: ReferenceDataUsageModel[] = [];

    @observable
    singleProjectDataLoading: boolean = false;

    @observable
    mutipleProjectsDataLoading: boolean = false;

    @computed
    get project() {
        return this.rootStore.currentProject!;
    }

    @computed
    get projects() {
        return this.rootStore.projects;
    }

    constructor(
        private readonly service: ReferenceDataService,
        private readonly referenceDataStore: ReferenceDataVisualStore,
        private readonly rootStore: ProjectsRootVisualStore
    ) {
        reaction(
            () => this.project,
            () => {
                this.setSingleProjectDataUsage([]);
                this.setMutipleProjectsDataUsage([]);
            }
        );
    }

    @action.bound
    setSingleProjectDialogVisible(visible: boolean) {
        this.singleProjectDialogVisible = visible;
    }

    @action.bound
    setMutipleProjectsDialogVisible(visible: boolean) {
        this.mutipleProjectsDialogVisible = visible;
    }

    @action.bound
    setSingleProjectDataUsage(data: ReferenceDataUsageModel[]) {
        this.singleProjectDataUsage = data;
    }

    @action.bound
    setMutipleProjectsDataUsage(data: ReferenceDataUsageModel[]) {
        this.mutipleProjectsDataUsage = data;
    }

    @action.bound
    setSingleProjectDataLoading(loading: boolean) {
        this.singleProjectDataLoading = loading;
    }

    @action.bound
    setMutipleProjectsDataLoading(loading: boolean) {
        this.mutipleProjectsDataLoading = loading;
    }

    @action.bound
    async getSingleProjectRefDataUsage() {
        const { currentConnectionId, tableNames } = this.referenceDataStore;

        if (!currentConnectionId) {
            return;
        }

        try {
            this.setSingleProjectDataLoading(true);

            const resp = await this.service.getRefDataUsage(this.project.id, currentConnectionId, tableNames, true);

            resp.map(data => this.setSingleProjectDataUsage(data));
        } catch (e) {
            message.error(e.message);
        } finally {
            this.setSingleProjectDataLoading(false);
        }
    }

    @action.bound
    async getMutipleProjectsRefDataUsage() {
        const { currentConnectionId, selectedTable } = this.referenceDataStore;

        if (!currentConnectionId || !selectedTable) {
            return;
        }

        try {
            this.setMutipleProjectsDataLoading(true);

            const resp = await this.service.getRefDataUsage(this.project.id, currentConnectionId, [selectedTable]);

            resp.map(data => this.setMutipleProjectsDataUsage(data));
        } catch (e) {
            message.error(e.message);
        } finally {
            this.setMutipleProjectsDataLoading(false);
        }
    }
}
