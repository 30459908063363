export const STORE_TEST_PROJECT = 'projectTestProjectsUI';
export type STORE_TEST_PROJECT = typeof STORE_TEST_PROJECT;

export const STORE_TEST_PROJECT_WIZARD = 'projectTestProjectsWizardUI';
export type STORE_TEST_PROJECT_WIZARD = typeof STORE_TEST_PROJECT_WIZARD;

export const STORE_TEST_PROJECT_DASHBOARD = 'projectTestProjectsDashboardUI';
export type STORE_TEST_PROJECT_DASHBOARD = typeof STORE_TEST_PROJECT_DASHBOARD;

export const STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW = 'projectTestProjectsRunTopicPreviewUI';
export type STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW = typeof STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW;
