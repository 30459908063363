import * as React from 'react';
import { Input, Select, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { Connection } from '../models';
import { RulesStores } from '../stores';
import { ConnectionResult } from '../types';
import { FormInstance } from 'antd/lib/form';
import { EncryptedInput } from '../../common/components';

type Props = {
    form: FormInstance
} & RulesStores;
const TextArea =  Input.TextArea;
export const ConnectionEditPage: React.FC<Props> = ({RuleConnection, form }) => {
    const store = RuleConnection;

    const [connStringValue, setConnStringValue] = React.useState(store?.editableConnection?.connectionString);

    const handleSubmit = (values: ConnectionResult) => {  
        const connection = new Connection(values);
        store!.save(connection, store!.editableConnection!.id);
    };

    if (!store!.editableConnection) {
        return (<Form form={form} />);
    }

    const handleConnectionStringChange = (val: string) => {
        setConnStringValue(val);
        form.setFieldsValue({
            connectionString: val
        });
    };

    const conn = store!.editableConnection!;
    return (
        <Form 
            form={form} 
            onFinish={handleSubmit} 
            layout="vertical"
            id="edit-rd-connection-form"
            data-id="edit-rd-connection-dialog-form"
        >
            <Form.Item
                label="Id"
                name="id"
                initialValue={conn.id}
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                initialValue={conn.name}
                label="Name"
                name="name"
                rules={[{
                    required: true, message: 'Please input connection name!',
                }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                initialValue={conn.connectionType}
                label="Connection Type"
                name="connectionType"
                rules={[{
                    required: true, message: 'Please input connection type!',
                }]}
            >
                <Select>
                    <Select.Option value={'SqlServer'}>SqlServer</Select.Option>
                    <Select.Option value={'MongoDb'}>MongoDb</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                initialValue={conn.connectionString}
                label="Connection String"
                name="connectionString"
                rules={[{
                    required: true, message: 'Please input connection string!',
                }]}
            >
                <EncryptedInput changeHandler={handleConnectionStringChange} initialValue={store?.editableConnection.connectionString}>
                    <TextArea onChange={(e) => handleConnectionStringChange(e.target.value)} value={connStringValue}/>
                </EncryptedInput>
            </Form.Item>               
            {/* <Form.Item {...tailFormItemLayout}>
              <Button  
                  style={{backgroundColor: 'green', borderColor: 'green'}}
                  type="primary" 
                  onClick={() => {store!.validateConnection(id, form.getFieldValue('connectionString')); }}
                  loading={store!.isLoading}
              >Validate
              </Button>
              <Button  style={{marginLeft: 10}}  type="primary" htmlType="submit">Save</Button>              
              <Button style={{marginLeft: 10}} danger={true} onClick={handleCancel}>Cancel</Button>
              {   store!.validationFailed[id] === true ? 
              (<h4 style={{color: 'red'}}>Validation failed</h4>) : store!.validationFailed[id] === false ? (<h4 style={{color: 'green'}}>Validation passed </h4>) : <></>}
          </Form.Item> */}
        </Form>
    );
    
};

export default observer(ConnectionEditPage);
