import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Form, Checkbox, Select } from 'antd';
import { ProjectFormVisualStore } from '../../stores';
import './SmartIndexSettingsEditor.less';

type Props = {
    store: ProjectFormVisualStore;
    instructWorkflowRequired: boolean
};

const SmartIndexSettingsEditor: React.FC<Props> = ({ store, instructWorkflowRequired }) => {
    if (!store.project) {
        return null;
    }

    const { smartIndexSettings } = store.project;

    return (
        <Card title="Smart index" type="inner" className="smart-index-settings-editor">
            <Form.Item
                valuePropName="checked"
                name={['smartIndexSettings', 'enabled']}
                initialValue={smartIndexSettings ? smartIndexSettings.enabled : false}
            >
                <Checkbox>Enabled</Checkbox>
            </Form.Item>

            <Form.Item
                label={<span>Instruct workflow</span>}
                name={['smartIndexSettings', 'instructWorkflowId']}
                initialValue={smartIndexSettings ? smartIndexSettings.instructWorkflowId : null}
                rules={[
                    {
                        required: instructWorkflowRequired,
                        message: 'Please select instruct workflow!'
                    }
                ]}
            >
                <Select
                    allowClear
                    className={`instruct-workflow-select ${
                        smartIndexSettings?.instructWorkflowId &&
                        !store.instructWorkflows.some(f => f.id === smartIndexSettings.instructWorkflowId)
                            ? 'hide-selection-item'
                            : ''
                    }`}
                    placeholder="Select workflow"
                    options={store.instructWorkflows.map(workflow => ({
                        value: workflow.id,
                        label: workflow.name
                    }))}
                />
            </Form.Item>
        </Card>
    );
};

export default observer(SmartIndexSettingsEditor);
