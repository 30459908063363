import { observable, action, computed } from 'mobx';
import { ApplicationDefinitionConditionalEditStore } from '../stores';
import { ConditionDto, CreateEditConditionFormValues } from '../types';

interface ConditionFormValues extends CreateEditConditionFormValues {}

export default class ConditionModel {
    @observable
    name: string;

    @observable
    tagId: string;

    @observable
    searchText: string;

    @observable
    inputGroupId: string;

    @observable
    priority: number;

    @observable
    editable: boolean = false;

    @observable
    selected: boolean = false;

    readonly id: string;

    @computed
    get inputGroup() {
        return this.store.inputGroups.find(g => g.id === this.inputGroupId);
    }

    get dto(): ConditionDto {
        return {
            conditionId: this.id,
            name: this.name,
            tagId: this.tagId,
            searchText: this.searchText,
            inputGroupId: this.inputGroupId,
            priority: this.priority
        };
    }

    constructor(private readonly store: ApplicationDefinitionConditionalEditStore, data: ConditionDto) {
        this.id = data.conditionId;
        this.name = data.name;
        this.tagId = data.tagId;
        this.searchText = data.searchText;
        this.inputGroupId = data.inputGroupId;
        this.priority = data.priority;
    }

    @action.bound
    setPriority(priority: number) {
        this.priority = priority;
        this.store.setIsDataChanged(true);
    }

    @action.bound
    setEditable(editable: boolean) {
        this.editable = editable;
    }

    @action.bound
    setSelected(selected: boolean) {
        this.selected = selected;
    }

    @action.bound
    update(data: Partial<ConditionFormValues>) {
        this.name = data.name ?? this.name;
        this.tagId = data.tagId ?? this.tagId;
        this.searchText = data.searchText ?? this.searchText;
        this.inputGroupId = data.inputGroupId ?? this.inputGroupId;
        this.store.setIsDataChanged(true);
    }

    delete() {
        this.store.removeCondition(this);
        this.store.setIsDataChanged(true);
    }
}
