import * as Highlight from 'react-highlighter';
import * as React from 'react';
import { RulesListVisualStore } from '../stores';
import { SearchRuleAutoCompleteSourceItem } from '../types/SearchRuleAutoCompleteSourceItem';


export default function (store: RulesListVisualStore) {
    // eslint-disable-next-line no-unused-vars
    return function(item: SearchRuleAutoCompleteSourceItem, idx: number) {
        return (
            <div data-id={`rules-list-search-rule-option-${item.name}`} className="global-search-item-container">
                <div className="global-search-item-result">
                    <Highlight search={store.rulesFilteredValue}>
                        {item.name}
                    </Highlight>
                </div>
            </div>
        );
    };
}