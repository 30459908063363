import { observable, action } from 'mobx';
import { ProjectsService } from '../../common/services';

export default class ProjectImportStore {
    @observable
    dialogVisible: boolean = false;

    @observable
    customizeProjectName: boolean = false;

    @observable
    importIntoExistingProject: boolean = false;

    @observable
    importInProgress: boolean = false;

    constructor(private readonly service: ProjectsService) {}

    @action.bound
    setDialogVisible(dialogVisible: boolean) {
        this.dialogVisible = dialogVisible;
    }

    @action.bound
    setCustomizeProjectName(customizeProjectName: boolean) {
        this.customizeProjectName = customizeProjectName;
    }

    @action.bound
    setImportIntoExistingProject(importIntoExistingProject: boolean) {
        this.importIntoExistingProject = importIntoExistingProject;
    }

    @action.bound
    setImportInProgress(importInProgress: boolean) {
        this.importInProgress = importInProgress;
    }

    @action.bound
    async importProject(formData: FormData) {
        return await this.service.importProject(formData);
    }
}
