import { message } from 'antd';
import { action, observable, runInAction } from 'mobx';
import { AlphaConfigurations } from '../../common/constants';
import ErrorStore from '../../common/stores/ErrorStore';
import { AlphaConfig } from '../models/AlphaConfig';
import { GlobalAdministrationService } from '../service/GlobalAdministrationService';
import { AlphaSettingsModel } from '../types';

export type ConfigTableModel = {
    id: string | null;
    name: string;
    value: string
};

export class AlphaConfigVisualStore {

    @observable
    isLoading: boolean = false;

    @observable
    isLoadingSettings: boolean = false;

    @observable
    tableDataSource: ConfigTableModel[] = [];

    @observable
    isNewAlphaSettingModalVisible: boolean = false;

    @observable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    alphaSettings: AlphaSettingsModel | undefined = undefined;

    alphaConfig: AlphaConfig[];

    constructor(private service: GlobalAdministrationService, private errorStore: ErrorStore) {
        this.getAlphaConfig();
    }

    @action.bound
    setIsNewAlphaSettingModalVisible(isVisible: boolean) {
        this.isNewAlphaSettingModalVisible = isVisible;
    }

    @action.bound
    async getAlphaSettings() {
        this.isLoadingSettings = true;
        try {
            const response = await this.service.getAlphaSettings();
            runInAction(() => {
                this.alphaSettings = response;
            });
        } catch (err) {
            message.error('Error while loading settings');
            console.error(err);
        }

        this.isLoadingSettings = false;
    }

    @action.bound
    async updateAlphaSettings(key: string, value: unknown) {
        try {
            const response = await this.service.updateAlphaSettingsValue(key, value);
            if (response.isErr()) {
                message.error('Error while updating settings');
                console.error(response.error);
            } else {
                message.success('Settings updated');
                runInAction(() => {
                    if (this.alphaSettings) {
                        this.alphaSettings.values[key] = value;
                    } else {
                        response.map((alphaSettingsId) => {
                            if (alphaSettingsId) {
                                this.alphaSettings = {
                                    id: alphaSettingsId,
                                    values: {
                                        [key]: value
                                    }
                                };
                            }
                        });
                    }
                });
            }
        } catch (err) {
            message.error('Error while updating settings');
            console.error(err);
        }
    }

    @action.bound
    async deleteAlphaSettings(key: string) {
        try {
            const response = await this.service.deleteAlphaSettingsValue(key);
            if (response.isErr()) {
                message.error('Error while deleting settings');
                console.error(response.error);
            } else {
                message.success('Settings deleted');
                if (this.alphaSettings) {
                    delete this.alphaSettings.values[key];
                }
            }
        } catch (err) {
            message.error('Error while deleting settings');
            console.error(err);
        }
    }

    async getAlphaConfig() {
        this.isLoading = true;
        this.alphaConfig = (await this.service.getAlphaConfigs()) || [];
        this.createTableModel(this.alphaConfig);
        this.isLoading = false;
    }

    async handleConfigSave (data: {}) {
        const resp = await this.service.updateAlphaConfigValue(data);
        resp.mapErr(error => {
            this.errorStore.addError(error.data);
            return;
        });
        this.getAlphaConfig();
    }

    private createTableModel(alphaConfig: AlphaConfig[]) {
        this.tableDataSource = AlphaConfigurations.map(c => {
            const config = alphaConfig.find(i => i.name === c.key);
            return ({
                id: config?.id || null,
                name:  c.key,
                value:  config?.value || c.defaultValue
            });
        });
    }
}