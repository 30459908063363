import { ALPHA_CONFIG_VARIABLES } from '../administration/types/AlphaConfigModel';

export const STORE_PROJECTS = 'projects';

export const STORE_PROJECTS_ROOT_UI = 'projectsRootUI';

export const STORE_ROUTER = 'router';

export const STORE_ERRORS = 'error';

export const STORE_SECURITY = 'security';

export const STORE_ALPHA_CONFIG = 'AlphaConfig';

export const AlphaConfigurations = [{
    key: ALPHA_CONFIG_VARIABLES.IotaInputReadonly, defaultValue: 'true'    
}, { 
    key: ALPHA_CONFIG_VARIABLES.OcrParseTablesUsingML, defaultValue: 'false'    
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrParseCheckboxes, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrParseTablesForceLattice, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrEnableRatesTables, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrEnableAcordForms, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrRefineTablesWithTabula, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrParseTablesFastLattice, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrLang, defaultValue: 'eng'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrParseTablesLatticeLineScale, defaultValue: '18'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrParseTablesMlFixRotation, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrParseTablesMlMissingLineScale, defaultValue: '10'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrParseTablesDisabled, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrImagePreprocess, defaultValue: ''
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrUseExternalEngine, defaultValue: ''
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrTextReplacements, defaultValue: ''
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrDisabledFixPagesRotation, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrIgnoreTextLayer, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrEnableScannedDocumentsDetector, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrSkipDuplicatePackageLookup, defaultValue: 'false'
},{
    key: ALPHA_CONFIG_VARIABLES.OcrParseTablesApplyImageProcessing, defaultValue: 'false'
},{
    key: ALPHA_CONFIG_VARIABLES.OcrTablesUseWordsForImageCells, defaultValue: 'false'
}, {
    key: ALPHA_CONFIG_VARIABLES.OcrDisableDefaultImagePreprocessing, defaultValue: 'false'
},{
    key: ALPHA_CONFIG_VARIABLES.OcrBlocksCustom, defaultValue: ''
}];
