import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import { FieldBindingsPage } from '../screens';
import FieldPreviewPage from '../screens/FieldPreviewPage';

export const FieldBindingsPageNavigation = {
    ProjectFieldBindingsPage: '/projects/:projectId/fieldBindings',
    BindingResultsPreviewPage: '/projects/:id/binding-preview/:bindingId'
};

export default () => {
    return (
        <React.Fragment>
            <ProtectedRoute exact component={FieldBindingsPage} path={FieldBindingsPageNavigation.ProjectFieldBindingsPage} /> 
            <ProtectedRoute exact component={FieldPreviewPage} path={FieldBindingsPageNavigation.BindingResultsPreviewPage} /> 
        </React.Fragment>
    );
};