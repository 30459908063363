import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_TEST_PROJECT, STORE_TEST_PROJECT_WIZARD } from '../stores/constants';
import { TestProjectStores } from '../stores';
import { TestProjectsList } from '../components';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';


const TestProjectsPage: React.FC<TestProjectStores> = ({projectTestProjectsUI, projectTestProjectsWizardUI}) => {
    if (!projectTestProjectsUI || !projectTestProjectsWizardUI) {
        return null;
    }

    return(
        <HasPermission permissionClaim={AppPermissions.CanManageTestProjects}>
            <TestProjectsList store={projectTestProjectsUI} wizardStore={projectTestProjectsWizardUI}/>
        </HasPermission>
    );
};

export default inject(STORE_TEST_PROJECT, STORE_TEST_PROJECT_WIZARD)(observer(TestProjectsPage));