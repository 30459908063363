import { message } from 'antd';
import _ from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { Subject, Subscription } from 'rxjs';
import { Package, PackageLine, PackageState } from '../../common/models';
import { Utils } from '../../common/services/Utils';
import { PackagesRequest, SearchPackagesRequest, PackageChange } from '../../common/types';
import { ProjectsService } from '../../common/services';
import { ProjectsRootVisualStore } from '../../common/stores';
import { FormTypesService } from '../services';
import { 
    FormBlock, 
    FormPart, 
    FormPartCoordinatesReference, 
    FormRegionBlockBbox, 
    FormTemplateRegion, 
    FormType, 
    FormTypeImportTableData, 
    FormVariation, 
    UpdateFormRegionBlockModel,
    FormTypePackageLinesModel,
    BlockInfo
} from '../types';
import { AdministrationService } from '../../project_management/services/AdministrationService';
import stc from 'string-to-color';
import { PAGE_SIZE } from '../../common/stores/Pager';

export default class FormTypesStore {
    @observable
    formTypes: FormType[] = [];

    @observable
    formParts: FormPart[] = [];

    @observable
    editableFormPart: FormPart | undefined = undefined;

    @observable
    selectedFormType: string | undefined;

    @observable
    selectedFormVariation: string | undefined;

    @observable
    selectedFormTemplateRegion: string | undefined;

    @observable
    selectedFormPart: string | undefined;

    @observable
    currentRegionBlocks: { [regionId: string]: { [page: number]: FormBlock[] } } = {};

    @observable
    lastSavedBlocksState: { [regionId: string]: { [page: number]: FormBlock[] } } = {};

    @observable
    isLoadingBlocks: boolean = false;

    @observable
    packageLines: PackageLine[] = [];

    @observable
    packages: Package[] = [];

    @observable
    isLoadingPackageLines: boolean = false;

    @observable
    selectedBlockInfo: BlockInfo | undefined = undefined;

    @observable
    fileImportActionHeaders: {};

    @observable
    formTypeImportData: FormTypeImportTableData | undefined = undefined;

    @observable
    isFormTypeImportDialogVisible: boolean = false;

    @observable
    formTypeToEdit: FormType | undefined = undefined;

    @observable
    variationToEdit: FormVariation | undefined = undefined;

    @observable
    formRegionToEdit: FormTemplateRegion | undefined = undefined;

    @observable
    refernceFormPart: FormPart | undefined = undefined;

    @observable
    referenceFormVariation: FormVariation | undefined = undefined;

    @observable
    loadingPackages: boolean = false;

    @observable
    loadingPackagesProgress: number = 0;

    @observable
    loadingPackagesErrorMessage: string | undefined = undefined;

    @observable
    selectedTags: string[] = [];

    @observable
    formTypePackageLines: FormTypePackageLinesModel[] = [];

    @observable
    formTypeFilter: string = '';

    @observable
    isImportingFormType: boolean = false;

    newBlockInitialValues: Object | undefined;

    newBlockIdsSubject: Subject<string> = new Subject();

    newEntinetiesSubject: Subject<string> = new Subject();

    saveTriggerSubject: Subject<boolean> = new Subject();

    subscription: Subscription | null = null;

    @computed
    get selectedBlock() {
        if (!this.selectedFormTemplateRegion || !this.selectedBlockInfo) {
            return undefined;
        }

        return this.currentRegionBlocks[this.selectedFormTemplateRegion]?.[this.selectedBlockInfo.page]?.[this.selectedBlockInfo?.index];
    }

    @computed
    get isFormPartEditDialogVisible() {
        return this.editableFormPart != null;
    }

    @computed
    get currentProject() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get currentRegionPackageId() {
        if (!this.selectedFormTemplateRegion && !this.selectedFormPart && !this.selectedFormVariation) {
            return undefined;
        }

        if (this.selectedFormVariation) {
            const variation = _.flatten(this.formTypes.map(t => t.variations)).find(v => v.variationId === this.selectedFormVariation);
            return variation?.packageId;
        }

        if (this.selectedFormPart) {
            const formPart = this.formParts.find(p => p.id === this.selectedFormPart);
            const variation = _.flatten(this.formTypes.map(t => t.variations)).find(v => v.variationId === formPart?.formVariationId);
            return variation?.packageId;
        }

        if (this.selectedFormTemplateRegion) {
            const formPart = this.formParts.find(p => p.regions.find(r => r.regionId === this.selectedFormTemplateRegion) != null);
            const variation = _.flatten(this.formTypes.map(t => t.variations)).find(v => v.variationId === formPart?.formVariationId);
            return variation != null ? variation.packageId : undefined;
        }

        return undefined;
    }

    @computed
    get currentRegionPackageName() {
        return this.currentRegionPackageId && this.currentProject ? this.currentProject.packages.find(p => p.id === this.currentRegionPackageId)?.name : undefined;
    }

    @computed
    get currentFormPart() {
        if (!this.selectedFormTemplateRegion && !this.selectedFormPart) {
            return undefined;
        }

        if (this.selectedFormPart) {
            return this.formParts.find(p => p.id === this.selectedFormPart);
        }

        if (this.selectedFormTemplateRegion) {
            return this.formParts.find(p => p.regions.find(r => r.regionId === this.selectedFormTemplateRegion) != null);
        }

        return undefined;
    }

    @computed
    get editableFormPartPackageId() {
        if (!this.editableFormPart) {
            return undefined;
        }

        const formPart = this.formParts.find(p => p.id === this.editableFormPart?.id);
        const variation = _.flatten(this.formTypes.map(t => t.variations)).find(v => v.variationId === formPart?.formVariationId);
        return variation?.packageId;
    }

    @computed
    get tags() {
        return this.projectsRootStore.tags;
    }
    
    @computed
    get broken() {
        return this.formTypePackageLines.reduce<{
            formTypeIds: string[];
            formVariationIds: string[];
            formPartIds: string[]
        }>(
            (acc, type) => {
                const formVariationIds: string[] = [];

                type.formVariations.forEach(v => {
                    const formPartIds: string[] = [];

                    v.formParts.forEach(p => {
                        if (!p.packageFieldId) {
                            formPartIds.push(p.formPartId);
                        }
                    });

                    if (formPartIds.length) {
                        formVariationIds.push(v.formVariationId);
                        acc.formPartIds = [...acc.formPartIds, ...formPartIds];
                    }
                });

                if (formVariationIds.length) {
                    acc.formTypeIds.push(type.formTypeId);
                    acc.formVariationIds = [...acc.formVariationIds, ...formVariationIds];
                }

                return acc;
            },
            {
                formTypeIds: [],
                formVariationIds: [],
                formPartIds: []
            }
        );
    }

    @computed
    get allFormTypeParts() {
        const variations = _.flatten(this.formTypePackageLines.map(t => t.formVariations));

        return _.flatten(variations.map(v => v.formParts));
    }

    @computed
    get filteredFormTypes() {
        if (!this.formTypeFilter.trim().length) {
            return this.formTypes;
        }

        return this.formTypes.filter(t => t.name.toLowerCase().includes(this.formTypeFilter.toLowerCase()));
    }

    // eslint-disable-next-line max-len
    public crossedBg = 'linear-gradient(to top left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1.2px), rgba(0, 0, 255, 0.6) 50%, rgba(0, 0, 0, 0) calc(50% + 1.2px), rgba(0, 0, 0, 0) 100%), linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1.2px), rgba(0, 0, 255, 0.6) 50%, rgba(0, 0, 0, 0) calc(50% + 1.2px), rgba(0, 0, 0, 0) 100%)';
   
    constructor(
        private projectsRootStore: ProjectsRootVisualStore, 
        private service: FormTypesService, 
        private projectsService: ProjectsService, 
        private adminService: AdministrationService
    ) {
    }

    @action.bound
    setIsImportingFormType(isImporting: boolean) {
        this.isImportingFormType = isImporting;
    }

    @action.bound
    setRefernceFormPart(formPart: FormPart | undefined) {
        this.refernceFormPart = formPart;
    }

    @action.bound
    setRefernceFormVariation(variation: FormVariation | undefined) {
        this.referenceFormVariation = variation;
    }

    @action.bound
    setIsFormTypeImportDialogVisible(isVisible: boolean) {
        this.isFormTypeImportDialogVisible = isVisible;
    }

    @action.bound
    setFormTypeFilter(formTypeFilter: string) {
        this.formTypeFilter = formTypeFilter;
    }

    @action.bound
    async updateMapperTableData(val: string, name: string, triggerPackageReparse: boolean) {
        if (!this.formTypeImportData) {
            return;
        }

        const data = this.formTypeImportData.mapperTableData.find(x => x.name === name);

        if (!data) {
            return;
        }

        data.bestValue = val;
        data.triggerPackageReparse = triggerPackageReparse;

        const pkg = this.formTypeImportData.packageSelectionOptions.find(p => p.value === data.bestValue);

        if (!pkg) {
            return;
        }

        const resp = await this.service.calculateFuzzyScore(data.name, pkg.label);

        if (!resp.isOk()) {
            return;
        }

        resp.map(fuzzyScore => {
            runInAction(() => {
                data.fuzzyScore = fuzzyScore;
                this.formTypeImportData = { ...this.formTypeImportData! };
            });
        });
    }

    @action.bound
    handleSelectAllPackagesForReparse(triggerPackageReparse: boolean) {
        if (!this.formTypeImportData) {
            return;
        }

        this.formTypeImportData.mapperTableData.forEach(x => x.triggerPackageReparse = triggerPackageReparse);
    }

    @action.bound
    setFormTypeImportData(data: FormTypeImportTableData | undefined) {
        this.formTypeImportData = data;
    }

    @action.bound
    setFormTypeToEdit(formType: FormType | undefined) {
        this.formTypeToEdit = formType;
    }

    @action.bound
    setFormVaritaionToEdit(variation: FormVariation | undefined) {
        this.variationToEdit = variation;
    }

    @action.bound
    setFormRegionToEdit(region: FormTemplateRegion | undefined) {
        this.formRegionToEdit = region;
    }

    @action.bound
    setSelectedBlockInfo(info: BlockInfo | undefined) {
        this.selectedBlockInfo = info;
    }

    @action.bound
    setEditableFormPart(formPartId: FormPart | undefined) {
        this.editableFormPart = formPartId;
    }

    @action.bound
    setSelectedTags(selectedTags: string[]) {
        this.selectedTags = selectedTags;
    }

    @action.bound
    async setHeaders() {
        const tk = await Utils.getAuthToken();
        runInAction(() => {
            this.fileImportActionHeaders = {
                'Authorization': 'Bearer ' + tk
            };
        });
    }


    @action.bound
    async getLineBlocksForPackage() {
        if ((!this.currentRegionPackageId && !this.editableFormPart) || !this.currentProject) {
            return;
        }

        runInAction(() => {
            this.isLoadingPackageLines = true;
        });
        
        let packageId = this.currentRegionPackageId;

        if (this.editableFormPart) {
            const formPart = this.formParts.find(p => p.id === this.editableFormPart?.id);
            const variation = _.flatten(this.formTypes.map(t => t.variations)).find(v => v.variationId === formPart?.formVariationId);
            packageId = variation?.packageId;
        }

        if (!packageId) {
            return;
        }

        try {
    
            const pkg = this.packages.find(p => p.id === packageId);

            if (!pkg) {
                return;
            }

            if (pkg.state === PackageState.Broken) {
                message.error(`Package '${pkg.name}' is broken. Please reparse it first.`);
                runInAction(() => {
                    this.packageLines = [];
                    this.isLoadingPackageLines = false;
                });
                return;
            }

            const request: PackagesRequest = {
                pkgId: packageId,
                blockTypes: ['HORIZONTAL_LINE_BLOCK'],
                page: 0,
                pageSize: 999999,
                projectId: this.currentProject.id
            };

            const { lines } = await this.projectsService.getPackageLines(this.currentProject, request, pkg);

            runInAction(() => {
                this.packageLines = _.sortBy(lines, ['blockType', 'coordinates.page', 'coordinates.y', 'coordinates.x']);
                this.isLoadingPackageLines = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoadingPackageLines = false;
            });
            console.error(err);
        }
    }

    @action.bound
    setCurrentRegionBlocks(blocks: { [regionId: string]: { [page: number]: FormBlock[] } }, triggerSave: boolean = false) {
        this.currentRegionBlocks = blocks;

        if (triggerSave) {
            this.saveTriggerSubject.next(true);
        }
    }

    @action.bound
    setSelectedFormType(formTypeId: string | undefined) {
        this.selectedFormType = formTypeId;
    }

    @action.bound
    setSelectedFormVariation(variationId: string | undefined) {
        this.selectedFormVariation = variationId;
    }

    @action.bound
    setSelectedFormPart(formPartId: string | undefined) {
        this.selectedFormPart = formPartId;
    }
    
    @action.bound
    setSelecteFormTemplateRegion(regionId: string | undefined) {
        this.selectedFormTemplateRegion = regionId;
    }

    @action.bound
    async getFormTypesForProject() {
        if (!this.currentProject) {
            return;
        }

        try {
            const formTypes = await this.service.getProjectFormTypes(this.currentProject.id);

            runInAction(() => {
                this.formTypes = formTypes;
            });
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async getFormPartsForProject() {
        if (!this.currentProject) {
            return;
        }

        try {
            const formParts = await this.service.getProjectFormParts(this.currentProject.id);
            const regions = _.flatten(formParts.map(p => p.regions));
            runInAction(() => {
                this.formParts = formParts;

                this.currentRegionBlocks = {};
                regions.forEach(r => {
                    const pageNumbers = [... new Set(r.regionBlocks.map(b => b.blockPage))];

                    pageNumbers.forEach(p => {
                        const blocksOnPage = r.regionBlocks.filter(b => b.blockPage === p);
                        if (!this.currentRegionBlocks[r.regionId]) {
                            this.currentRegionBlocks[r.regionId] = {};
                        }

                        if (!this.currentRegionBlocks[r.regionId][p]) {
                            this.currentRegionBlocks[r.regionId][p] = [];
                        }

                        for (let blockOnPage of blocksOnPage) {
                            blockOnPage.bboxes.forEach((box, i) => {
                                if (!this.currentRegionBlocks[r.regionId][p]) {
                                    this.currentRegionBlocks[r.regionId][p] = [];
                                }

                                const blockData = {index: i, blockId: blockOnPage.blockId, blockPage: p, regionStyle: this.getRegionStyle(blockOnPage.blockId, box.useContours)};
                                const block = new FormBlock(box.bbox[0], box.bbox[1], box.bbox[2], box.bbox[3], r.regionId, blockOnPage.blockId, blockData, box.useContours, false);
                                this.currentRegionBlocks[r.regionId][p].push(block);
                            });
                        }
                    });
                });

                this.setLastSavedBlockState(_.cloneDeep(this.currentRegionBlocks));
            });
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async createFormType(name: string) {
        if (!this.currentProject) {
            return false;
        }

        try {
            const resp = await this.service.createFormType(name, this.currentProject.id);
            if (!resp.isOk()) {
                message.error('Failed to create form type');
                return false;
            } else {
                message.success('Form type created');
                this.getFormTypesForProject();
                return true;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    @action.bound
    async createFormVariation(name: string, packageId: string) {
        if (!this.selectedFormType) {
            return false;
        }

        try {
            const variationId = this.referenceFormVariation?.variationId;
            const resp = await this.service.createFormVariation(name, this.selectedFormType, packageId, variationId);
            if (!resp.isOk()) {
                message.error('Failed to create form variation');
                return false;
            } else {
                const msg = this.referenceFormVariation ? 'Form variation created from reference. Please check coordinate references and detectors.' : 'Form variation created';
                message.success(msg);
                
                if (this.referenceFormVariation) {
                    await this.getFormPartsForProject();
                }

                this.getFormTypesForProject().then(() => {
                    if (variationId) {
                        const formType = this.formTypes.find(t => t.variations.some(v => v.variationId === variationId));

                        if (formType) {
                            this.getFormTypePackageLines(formType.id);
                        }
                    }
                    
                    this.newEntinetiesSubject.next(this.selectedFormType);
                });
                return true;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    @action.bound
    async createFormTemplateRegion(name: string) {
        if (!this.currentProject || !this.selectedFormPart) {
            return false;
        } 

        try {
            const resp = await this.service.createFormTemplateRegion(this.selectedFormPart, name, this.currentProject.id);
            if (!resp.isOk()) {
                message.error('Failed to create form template region');
                return false;
            } else {
                message.success('Form template region created');
                await this.getFormPartsForProject();
                await this.getFormTypesForProject();
                return true;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    @action.bound
    setIsLoadingBlocks(isLoading: boolean) {
        this.isLoadingBlocks = isLoading;
    }

    @action.bound
    async createFormRegionBlock(name: string, blockPage: number, bBox: FormRegionBlockBbox[]) {
        if (!this.selectedFormTemplateRegion) {
            return false;
        }

        try {
            const resp = await this.service.createFormRegionBlock(this.selectedFormTemplateRegion, name, blockPage, bBox);
            
            if (!resp.isOk()) {
                message.error('Failed to create form region block');
                return false;
            } else {
                message.success('Form region block created');
                this.setIsLoadingBlocks(true);
                
                const blockId = resp.unwrapOr('');
                this.newBlockIdsSubject.next(blockId);

                this.getFormPartsForProject().then(() => {
                    this.getFormTypesForProject();
                    this.setIsLoadingBlocks(false);
                });
                return true;
            }
        } catch (err) {
            console.error(err);
            
            this.setIsLoadingBlocks(false);
            return false;
        }
    }

    @action.bound
    async createFormPart(name: string, detectorParts?: string[], ignoreParts?: string[], packageLineId?: string) {
        if (!this.currentProject || !this.selectedFormVariation) {
            return false;
        }

        try {
            let coordinatesReference: FormPartCoordinatesReference | undefined;
            const packageLine = packageLineId ? this.packageLines.find(p => p.id === packageLineId) : undefined;

            if (packageLine && packageLine.coordinates) {
                coordinatesReference = {
                    height: packageLine.coordinates.pageHeight,
                    width: packageLine.coordinates.pageWidth,
                    page: packageLine.coordinates.page,
                    phrase: {
                        text: packageLine.normalizedText,
                        x1: Math.trunc(packageLine.coordinates.x),
                        y1: Math.trunc(packageLine.coordinates.pageHeight - packageLine.coordinates.y),
                        x2: Math.trunc(packageLine.coordinates.x + packageLine.coordinates.width),
                        y2: Math.trunc((packageLine.coordinates.pageHeight - packageLine.coordinates.y) + packageLine.coordinates.height)
                    }
                };
            }

            const resp = await this.service.createFormPart(
                name, this.currentProject.id, this.selectedFormVariation, detectorParts, ignoreParts, coordinatesReference
            );
            
            if (!resp.isOk()) {
                message.error('Failed to create form part');
                return false;
            } else {
                message.success('Form part created');
                await this.getFormPartsForProject();
                await this.getFormTypesForProject();
                this.newEntinetiesSubject.next(this.selectedFormVariation);
                return true;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    @action.bound
    async updateFormRegionBlock(blockId: string, name: string, blockPage: number, bBoxes: FormRegionBlockBbox[]) {
        if (!this.selectedFormTemplateRegion) {
            return false;
        }

        try {
            const resp = await this.service.updateFormRegionBlock(this.selectedFormTemplateRegion, name, blockId, blockPage, bBoxes);
            if (!resp.isOk()) {
                message.error('Failed to update form region block');
            } else {
                return true;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
        
        return false;
    }

    @action.bound
    async updateFormPart(name: string, detectorParts: string[], ignoreParts?: string[], packageLineId?: string) {
        if (!this.editableFormPart && !this.currentFormPart) {
            return false;
        }

        try {
            let coordinatesReference: FormPartCoordinatesReference | undefined;
            const packageLine = packageLineId ? this.packageLines.find(p => p.id === packageLineId) : undefined;

            if (packageLine && packageLine.coordinates) {
                coordinatesReference = {
                    height: packageLine.coordinates.pageHeight,
                    width: packageLine.coordinates.pageWidth,
                    page: packageLine.coordinates.page,
                    phrase: {
                        text: packageLine.normalizedText,
                        x1: Math.trunc(packageLine.coordinates.x),
                        y1: Math.trunc(packageLine.coordinates.pageHeight - packageLine.coordinates.y),
                        x2: Math.trunc(packageLine.coordinates.x + packageLine.coordinates.width),
                        y2: Math.trunc((packageLine.coordinates.pageHeight - packageLine.coordinates.y) + packageLine.coordinates.height)
                    }
                };
            }

            if (!coordinatesReference && !packageLineId) {
                coordinatesReference = this.currentFormPart?.coordinatesReference;
            }

            if (!coordinatesReference) {
                message.error('Failed to find package line');
                return false;
            }

            const formPartId = this.editableFormPart?.id || this.currentFormPart?.id;

            const resp = await this.service.updateFormPart(
                name, formPartId!, detectorParts, coordinatesReference, ignoreParts
            );

            const formType = this.formTypes.find(p => p.variations.find(v => v.variationId === this.editableFormPart?.formVariationId));

            if (formType) {
                this.getFormTypePackageLines(formType.id);
            }

            if (!resp.isOk()) {
                message.error('Failed to update form part');
            } else {
                this.setIsLoadingBlocks(true);

                this.getFormPartsForProject().then(() => {
                    this.getFormTypesForProject();
                    this.setIsLoadingBlocks(false);
                });
                return true;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
        
        return false;
    }

    @action.bound
    async deleteFormType(formTypeId: string) {
        try {
            const resp = await this.service.deleteFormType(formTypeId);
            if (!resp.isOk()) {
                message.error('Failed to delete form type');
            } else {
                message.success('Form type deleted');
                this.getFormTypesForProject();
                this.setSelectedFormType(undefined);
                this.filterFormTypePackageLines(formTypeId, 'formType');
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async deleteFormVariation(formVariationId: string) {
        const formTypeId = this.formTypes.find(ft => ft.variations.find(v => v.variationId === formVariationId))?.id;

        if (!formTypeId) {
            console.warn(`Form type not found for variation ${formVariationId}`);
            return;
        }

        try {
            const resp = await this.service.deleteFormVariation(formVariationId, formTypeId);
            if (!resp.isOk()) {
                message.error('Failed to delete form variation');
            } else {
                message.success('Form variation deleted');
                this.getFormTypesForProject();
                this.setSelectedFormVariation(undefined);
                this.filterFormTypePackageLines(formVariationId, 'formVariation');
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async deleteFormPart(formPartId: string) {
        try {
            const resp = await this.service.deleteFormPart(formPartId);
            if (!resp.isOk()) {
                message.error('Failed to delete form part');
            } else {
                message.success('Form part deleted');
                this.getFormPartsForProject();
                this.setSelectedFormPart(undefined);
                this.filterFormTypePackageLines(formPartId, 'formPart');
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async deleteFormTemplateRegion(formTemplateRegionId: string) {
        const formPartId = this.formParts.find(fp => fp.regions.find(r => r.regionId === formTemplateRegionId))?.id;

        if(!formPartId) {
            console.warn(`Form part not found for region ${formTemplateRegionId}`);
            return;
        }

        try {
            const resp = await this.service.deleteFormRegion(formTemplateRegionId, formPartId);
            if (!resp.isOk()) {
                message.error('Failed to delete form template region');
            } else {
                message.success('Form template region deleted');
                this.getFormPartsForProject();
                this.setSelecteFormTemplateRegion(undefined);
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async deleteBlockFromRegion(blockId: string, blockPage: number, blockIndex: number) {
        if (!this.selectedFormTemplateRegion) {
            return false;
        }
        let currentBlocks = {...this.currentRegionBlocks};
        const prevBlocks = _.flatten(
            Object.values(currentBlocks[this.selectedFormTemplateRegion][blockPage])).filter(b => b.blockId === blockId
        );
        const prevBlock = _.flatten(_.flatten(this.formParts.map(p => p.regions)).map(r => r.regionBlocks)).find(b => b.blockId === blockId);

        if (!prevBlock) {
            console.error('Previous block not found');
            message.error('Failed to update blocks');
            return false;
        }

        return await this.updateFormRegionBlock(
            prevBlock.blockId, 
            prevBlock.blockTitle as string, 
            blockPage, 
            prevBlocks.filter((v, i) => i !== blockIndex).map(b => ({
                useContours: b.useContours,
                bbox: [b.x1, b.y1, b.x2, b.y2]
            }))
        );    
    }

    @action.bound
    getBlockTitleById(blockId: string | undefined) {
        if (!blockId) { 
            return '';
        }

        const allBlocks = _.flatten(_.flatten(this.formParts.map(p => p.regions)).map(r => r.regionBlocks));
        return allBlocks.find(b => b.blockId === blockId)?.blockTitle ?? blockId;
    }

    @action.bound
    async exportFormType(formTypeId: string) {
        await this.service.exportFormType(formTypeId);
    }

    @action.bound
    async importFormType(data: FormData) {
        return this.service.importFormType(data);
    }

    @action.bound
    resetImportParams() {
        this.setIsFormTypeImportDialogVisible(false);
        this.setFormTypeImportData(undefined);
    }

    @action.bound
    async importRules() {
        if (!this.currentProject || !this.formTypeImportData) {
            return;
        }

        try {
            this.setIsImportingFormType(true);
            const resp = await this.service.commitImportedFormType(this.currentProject.id, this.formTypeImportData);

            if (resp.isOk()) {
                this.getFormTypesForProject();
                this.resetImportParams();
    
                this.getFormPartsForProject().then(() => {
                    this.getFormTypesForProject();
                    this.resetImportParams();
                    message.success('Form type imported');
                    resp.map(formTypeId => this.getFormTypePackageLines(formTypeId));
                });
            } else {
                message.error(`Failed to import rules. Error: ${resp.error.text}`);
            }
        } catch (err) {
            message.error(`Failed to import rules. Error: ${err}`); 
            console.error(err);
        } finally {
            this.setIsImportingFormType(false);
        }
    }

    @action.bound
    async updateFormType(formTypeId: string, name: string) {
        try {
            const resp = await this.service.updateFormType(formTypeId, name);
            if (!resp.isOk()) {
                message.error('Failed to update form type');
            } else {
                message.success('Form type updated');
                this.getFormTypesForProject();
                // Reload form parts as well probably (?)
                return true;
            }
        } catch (err) {
            console.error(err);
        }

        return false;
    }

    @action.bound
    async updateFormVariation(formTypeId: string, formVariationId: string, name: string, packageId: string) {
        try {
            const resp = await this.service.updateFormVariation(formTypeId, formVariationId, name, packageId);
            if (!resp.isOk()) {
                message.error('Failed to update form variation');
            } else {
                message.success('Form variation updated');
                this.getFormTypesForProject();
                return true;
            }
        } catch (err) {
            console.error(err);
        }

        return false;
    }

    @action.bound
    async updateFormTemplateRegion(formPartId: string, formTemplateRegionId: string, name: string) {
        try {
            const resp = await this.service.updateFormRegion(formPartId, formTemplateRegionId, name);
            if (!resp.isOk()) {
                message.error('Failed to update form template region');
            } else {
                message.success('Form template region updated');
                this.getFormPartsForProject();
                return true;
            }
        } catch (err) {
            console.error(err);
        }

        return false;
    }

    @action.bound
    async reparsePackage(packageId: string) {
        try {
            const resp = await this.adminService.parseByPackage(packageId);
            if (!resp.isOk()) {
                message.error('Failed to reparse package');
            } else {
                message.success('Package reparse started successfully');
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async saveAllBlocksOnPage(page: number) {
        if (!this.selectedFormTemplateRegion) {
            return false;
        }

        const blocksToSave = this.currentRegionBlocks[this.selectedFormTemplateRegion][page];
        const uniqueBlockIds = _.uniq(blocksToSave.filter(b => b.blockId).map(b => b.blockId));
        let updateModels: UpdateFormRegionBlockModel[] = uniqueBlockIds.map(blockId => {
            const blocks = blocksToSave.filter(b => b.blockId === blockId);
            return {
                blockId,
                blockTitle: this.getBlockTitleById(blockId), 
                blockPage: page,
                templateRegionId: this.selectedFormTemplateRegion!,
                bboxes: blocks.map(b => ({
                    useContours: b.useContours,
                    bbox: [b.x1, b.y1, b.x2, b.y2]
                }))
            };
        });

        const response = await this.service.updateMultipleFormRegionBlocks(this.selectedFormTemplateRegion, page, updateModels);

        if (!response.isOk()) {
            message.error('Failed to save blocks');
            return false;
        } else {
            this.getFormPartsForProject().then(() => {
                this.getFormTypesForProject(); 
                message.success('Blocks saved successfully');
            });
           
            return true;
        }
    }

    @action.bound
    setLastSavedBlockState(blocks: { [regionId: string]: { [page: number]: FormBlock[] } }) {
        this.lastSavedBlocksState = blocks;
    }

    @action.bound
    linesForPackageAreLoaded(packageId: string | undefined) {
        return packageId ? this.packageLines?.length && this.packageLines.every(p => p.pkg.id === packageId) : undefined;
    }

    @action.bound
    async loadAllProjectPackages() {
        if (!this.currentProject) {
            return;
        }

        try {
            runInAction(() => {
                this.loadingPackages = true;
                this.loadingPackagesProgress = 0;
            });

            let request: SearchPackagesRequest = {
                page: 0,
                pageSize: PAGE_SIZE,
                projectId: this.currentProject.id,
                allSources: true,
                uploadedBy: this.projectsRootStore.hasAccessToAllEntities ? undefined : this.projectsRootStore.currentUserId
            };
            const firstResponse = await this.projectsService.searchPackages(this.currentProject, request);

            runInAction(() => {
                this.packages = firstResponse.lines;
            });

            if (firstResponse.lines.length < firstResponse.total) {
                const pages = Math.ceil(firstResponse.total / PAGE_SIZE);
                for (let i = 1; i < pages; i++) {
                    request.page = i;
                    const response = await this.projectsService.searchPackages(this.currentProject, request);
                    runInAction(() => {
                        this.packages = this.packages.concat(response.lines);
                        this.loadingPackagesProgress = Math.floor((i / pages) * 100);
                    });
                }
            }

        } catch(err) {
            console.error(err);
            message.error(err);
            runInAction(() => {
                this.loadingPackagesErrorMessage = err;
            });
        } finally {
            runInAction(() => {
                this.loadingPackagesProgress = 100;

                setTimeout(() => {
                    this.loadingPackages = false;
                    this.loadingPackagesErrorMessage = undefined;
                }, 1500);
            });
        }
    }

    @action.bound
    async getFormTypePackageLines(formTypeId?: string) {
        if (!this.currentProject) {
            return;
        }

        try {
            const resp = await this.service.getFormTypePackageLines(this.currentProject.id, formTypeId);

            if (!resp.isOk()) {
                return;
            }

            resp.map(formTypePackageLines => {
                runInAction(() => {
                    if (formTypeId && formTypePackageLines.length) {
                        const index = this.formTypePackageLines.findIndex(t => t.formTypeId === formTypeId);

                        if (index === -1) {
                            this.formTypePackageLines = [...this.formTypePackageLines, ...formTypePackageLines];
                        } else {
                            this.formTypePackageLines[index] = formTypePackageLines[0];
                            this.formTypePackageLines = [...this.formTypePackageLines];
                        }
                    } else {
                        this.formTypePackageLines = formTypePackageLines;
                    }
                });
            });
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    filterFormTypePackageLines(id: string, filterBy: 'formType' | 'formVariation' | 'formPart') {
        this.formTypePackageLines = this.formTypePackageLines.filter(type => {
            if (filterBy === 'formType') {
                return type.formTypeId !== id;
            }

            if (filterBy === 'formVariation') {
                type.formVariations = type.formVariations.filter(v => v.formVariationId !== id);
            }

            if (filterBy === 'formPart') {
                type.formVariations = type.formVariations.filter(variation => {
                    variation.formParts = variation.formParts.filter(p => p.formPartId !== id);

                    return variation.formParts.length > 0;
                });
            }

            return type.formVariations.length > 0;
        });
    }

    @action.bound
    handlePackageChanges(packageChange: PackageChange) {
        if (!this.currentProject) {
            return;
        }

        if (this.currentProject.id === packageChange.projectId && packageChange.state === PackageState.Ready) {
            const formType = this.formTypes.find(t => t.variations.some(v => v.packageId === packageChange.id));

            if (formType) {
                this.getFormTypePackageLines(formType.id);
            }
        }
    }

    subscribeToPackageChanges() {
        this.subscription = this.projectsRootStore.projectsStore.packageChanges.subscribe(p => this.handlePackageChanges(p));
    }

    unsubscribeFromPackageChanges() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public getRegionStyle(blockId?: string, useContours: boolean = false) {
        let color: string;
        let bgColor: string;
        if (blockId && blockId.length > 0) {
            // color = Utils.stringToColourRgb(blockId);
            color = stc(blockId);
            bgColor = color + '1A';
        } else {
            const r = Math.floor(Math.random()*(256));
            const g = Math.floor(Math.random()*(256));
            const b = Math.floor(Math.random()*(256));

            const rgbColor = `${r}, ${g}, ${b}`;
            color = `rgb(${rgbColor})`;
            bgColor = `rgba(${rgbColor}, 0.1)`;
        }
       
        const style = {
            border: `1px solid ${color}`,
            backgroundColor: `${bgColor}`,
            backgroundImage: useContours ? this.crossedBg : undefined,
            zIndex: 1000,
        };

        return style;
    }

    public getUnsavedRegionBlocksInfo(page: number): BlockInfo[] {
        if (!this.selectedFormTemplateRegion) {
            return [];
        }

        const regionBlocks = this.currentRegionBlocks[this.selectedFormTemplateRegion][page];

        return regionBlocks.filter(b => !b.blockId).map(b => ({ page, index: regionBlocks.indexOf(b) }));
    }
}