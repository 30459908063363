import { Layout, Table as AntdTable } from 'antd';
import { Content } from 'antd/lib/layout/layout';
// import { reaction } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { AutoSizer, Column, defaultTableRowRenderer, Index, InfiniteLoader, Table, TableCellProps, TableHeaderProps } from 'react-virtualized';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';
import { STORE_IOTA_AUDIT } from '../constants';
import { IotaAuditVisualStore } from '../stores';
import { AuditInput, FieldComment } from '../types/IotaAuditResult';
import  IotaAuditFilter  from './IotaAuditFIlter';
import ResultMetaTable from './ResultMetaTable';

type Props = {
    [STORE_IOTA_AUDIT]: IotaAuditVisualStore
};
const COLUMN_HEIGHT = 48;
export const IotaAuditTable: React.FC<Props> = ({ IotaAuditUI: store }) => {
    const tableRef = React.createRef() as React.MutableRefObject<Table | null>;
    React.useEffect(() => {
        if (tableRef.current) {           
            tableRef.current.recomputeRowHeights();
        }
    },  [store.selectedSessionIndex, tableRef, store.expandedInputs.length]);

    React.useEffect(() => {
        if (tableRef.current) {           
            tableRef.current.recomputeRowHeights();
        }
    },  [store.loadedIotaProjectPayloads, tableRef]);

    React.useEffect(() => {
        store.getUsers();
    },      [store]);


    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let lastPromise = new Promise<boolean>(() => {});
    /* eslint-disable @typescript-eslint/no-explicit-any */
    let promiseResolver = (val: boolean) => Promise.resolve(val) as any;
    const expandRowRenderer = (props: TableCellProps) => {
        return props.rowIndex === store.selectedSessionIndex ?  
            <i className="alpha-icon xs expand-row arrow-expand expanded"/> : 
            <i className="alpha-icon xs expand-row arrow-expand"/>;
    };

    const headerRenderer = (props: TableHeaderProps) => {
        return (
            <Observer>{() =>
                <>
                    <span>{props.label}</span>
                </>}
            </Observer>
        );
    };

    const mainColumns = () => {
        const tail = [
            (
                <Column
                    key="expandRow"
                    width={45}
                    dataKey=""
                    label=""
                    className={'tableColumn'}
                    cellRenderer={expandRowRenderer}

                />),
            (
                <Column
                    key="applicationName"
                    width={500}
                    dataKey="applicationName"
                    label="Application"
                    className={'tableColumn'}
                    headerRenderer={headerRenderer}
                />),
            (
                <Column
                    key="date"
                    width={600}
                    dataKey="date"
                    label="Date/Time"
                    className={'tableColumn'}
                    headerRenderer={headerRenderer}
                    cellRenderer={(props: TableCellProps) => {
                        return (Utils.formatDateStringShort(props.cellData, true));
                    }}
                />),
            (
                <Column
                    key="packageName"
                    width={900}
                    dataKey="packageName"
                    label="Packages"
                    className={'tableColumn'}
                    style={{ whiteSpace: 'pre' }}
                    headerRenderer={headerRenderer}
                />)
              
        ];

        return tail;
    };
    const { hasNextPage, isNextPageLoading } = store.pager;
    const sessionsCount =  store.auditEvents ?  store.auditEvents.length : 0;

    if (!isNextPageLoading && promiseResolver) {
        promiseResolver(true);
    }

    const rowCount = hasNextPage ? sessionsCount + 1 : sessionsCount;
    const isRowLoaded = ({ index }: Index) => index < sessionsCount;
    const loadMoreRows = isNextPageLoading
        ? () => {
            return lastPromise; 
        }
        : () => {
            store.loadNextPage();
            lastPromise = new Promise<boolean>(resolve => promiseResolver = resolve);
            return lastPromise;
        };

    const resultMetaTable = (payload: AuditInput) => {
        return (<ResultMetaTable inputsMeta={payload.auditInputsMeta}/>);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowRenderer = (props: any) => {
        const { index, style, className, key, rowData } = props;
        const columns = [
            {
                title: 'Field',
                dataIndex: 'field',
                key: 'field',
            },
            {
                title: 'Input',
                dataIndex: 'value',
                key: 'value',
                render: (value: string) => {
                    return(
                        <div className='input-value'>
                            {value}
                        </div>
                    );
                }
            },
            {
                title: 'Auto/Manual',
                dataIndex: 'isAuto',
                key: 'isAuto',
                render: (isAuto: boolean) => {
                    return(
                        <div>
                            {isAuto ? 'Auto' : 'Manual' }
                        </div>
                    );
                }

            },
            {
                title: 'Comments',
                dataIndex: 'comments',
                key: 'comments',
                render: (comments: FieldComment[]) => {
                    return(
                        <div className='comments'>
                            {comments.map(c=> `[${Utils.formatDateStringShort(c.date)}] ${store.getUserById(c.userId)} ${c.value};  `)}
                        </div>
                    );
                }
            }];

        if (index === store.selectedSessionIndex) {
            return (
                <div
                    style={{ ...style, display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}
                    className={className}
                    key={key}
                >
                    {defaultTableRowRenderer({
                        ...props,
                        style: { width: style.width, height: COLUMN_HEIGHT, backgroundColor: '#F5F6F8'}
                    })}
                    <AntdTable 
                        className="ant-sm-header alpha-portal-table nested"
                        style={{ marginLeft: '64px', backgroundColor: 'white', width: '96%' }}
                        dataSource={store.loadedIotaProjectPayloads[rowData.sessionId]}
                        columns={columns}
                        rowKey={p => `${p.field!}-${p.auditInputsMeta[0].date}`}
                        pagination={false}
                        expandedRowRender={resultMetaTable}
                        rowClassName='audit-input-row'
                        expandable={{expandRowByClick: true, onExpand: store.handleInputExpand.bind(store)}}
                        expandIcon={(prop) => <i className="alpha-icon xs expand-row arrow-expand" onClick={(e) => {
                            prop.onExpand(prop.record, e); 
                        }}/>}
                    />
                </div>
            );
        }
        return defaultTableRowRenderer(props);
    };

    const rowGetter = ({ index }: Index) => {
        const { auditEvents } = store;
        return auditEvents[index % auditEvents.length];
    };

    return (
        <Layout className="screen-size" style={{...{height: '100%', background: 'white', overflow: 'hidden'}}}>
            <LayoutHeader  
                subtitle={Utils.getSubtitle(store!.currentProject)}
                title="Audit" 
                helpMessage="Track system activity for monitoring and accountability"
                helpMessageTooltipPlacement="leftBottom"
            />
            <Layout>
                <Content>
                    <IotaAuditFilter store={store} />
                    {!store.isLoading ?
                        <InfiniteLoader
                            isRowLoaded={isRowLoaded}
                            loadMoreRows={loadMoreRows}
                            rowCount={rowCount}
                        >
                            {({ onRowsRendered }) => (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <Observer>{() =>
                                            <Table
                                                headerHeight={44}
                                                headerClassName={'tableHeaderColumn'}
                                                height={height - 100}
                                                overscanRowCount={2}
                                                rowClassName={'tableRow'}
                                                onRowsRendered={onRowsRendered}
                                                rowRenderer={rowRenderer}
                                                onRowClick={store.handleSessionClick}
                                                rowHeight={store.getRowHeight}
                                                rowGetter={rowGetter}
                                                rowCount={rowCount}
                                                width={width}
                                                ref={tableRef}
                                            >
                                                {mainColumns()}
                                            </Table>}
                                        </Observer>
                                    )}
                                </AutoSizer>
                            )}
                        </InfiniteLoader> :
                        <LoadingIndicator/>}
                </Content>
            </Layout>
        </Layout> 
    );
};

export default observer(IotaAuditTable);
