import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore, FormTypesVisualStore } from '../stores';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore
};

const FormTypeEditDialog: React.FC<Props> = ({store, visualStore}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.formTypeToEdit) {
            form.setFieldsValue({
                name: store.formTypeToEdit.name,
                id: store.formTypeToEdit.id
            });
        } else {
            form.resetFields();
        }
    }, [form, store.formTypeToEdit]);

    const handleCancel = () => {
        visualStore.setIsFormTypeEditDialogVisible(false);
        store.setFormTypeToEdit(undefined);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            const updatedSuccessfully = await store.updateFormType(values.id, values.name);

            if (updatedSuccessfully) {
                handleCancel();
            }
        }).catch(() => {
            // do nothing
        });
    };

    const validateName = (name: string) => {
        const currentGroup = store.formTypes.find(type => type.name === name && type.id !== store.formTypeToEdit?.id);

        if (currentGroup) {
            return Promise.reject('Form type with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            wrapProps={{'data-id': 'Edit form type'}}
            className="alpha-portal-dialog"
            title="Edit form type"
            visible={visualStore.isFormTypeEditDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button data-id="form-types-edit-dialog-cancel" className="light" key="back" size="large" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button data-id="form-types-edit-dialog-submit" key="submit" size="large" type="primary" onClick={handleSubmit}>
                    Save
                </Button>
            ]}
        >
            <Form form={form} layout="vertical" preserve={false}>
                <Form.Item 
                    name="name" 
                    label="Name" 
                    rules={[
                        {required: true, message: 'Provide name for the form type, please'},
                        {validator: (_, name) => validateName(name)}
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="id"
                    hidden
                >
                    <Input type='hidden' />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FormTypeEditDialog);