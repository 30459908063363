import * as React from 'react';
import { ArrowsAltOutlined } from '@ant-design/icons';

type Props = {
    isVisible: boolean;
    onMouseDown: (event: React.MouseEvent) => void
};

const ChatbotResizeButton: React.FC<Props> = ({ isVisible, onMouseDown }) => (
    <ArrowsAltOutlined className={`resize-button ${!isVisible ? 'hidden' : ''}`} onMouseDown={onMouseDown} />
);

export default ChatbotResizeButton;
