import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import HistoryPage from '../screens/HistoryPage';

export const HistoryNavigation = {
    HistoryPage: '/projects/:projectId/history'
};

export default () => {
    return (
        <>
            <ProtectedRoute component={HistoryPage} path={HistoryNavigation.HistoryPage} />
        </>
    );
};
