import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Form, Button } from 'antd';
import {
    WorkflowInputs,
    WorkflowTypeSelect,
    WorkflowMessagesTemplates,
    WorkflowsOutputSchemes,
    WorkflowName
} from '..';
import { CreateEditWorkflowFormValues } from '../../types';
import InstructWorkflowStore from '../../stores/InstructWorkflowStore';
import './CreateEditWorkflowDialog.less';

interface Props {
    store: InstructWorkflowStore
}

const CreateEditWorkflowDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm<CreateEditWorkflowFormValues>();

    const editMode = React.useMemo(
        () => store.editableWorkflow && !store.editableWorkflow.isNew,
        [store.editableWorkflow]
    );

    const onFinish = async (formValues: CreateEditWorkflowFormValues) => {
        if (!store.editableWorkflow) {
            return;
        }

        const completed = editMode
            ? await store.editableWorkflow.update(formValues)
            : await store.editableWorkflow.create(formValues);

        if (completed) {
            onClose();
        }
    };

    const addNewWorkflow = (workflowType: string) => {
        const workflow = store.addNewWorkflow(workflowType);

        if (workflow) {
            workflow.setIsEditable(true);
        }
    };

    const onValuesChange = () => {
        const { workflowType } = form.getFieldsValue();

        if (!workflowType) {
            store.removeUnsavedWorkflows();
            return;
        }

        if (!store.editableWorkflow) {
            addNewWorkflow(workflowType);
            return;
        }

        if (store.editableWorkflow.workflowType && store.editableWorkflow.workflowType.type !== workflowType) {
            store.removeUnsavedWorkflows();
            form.resetFields();
            form.setFieldsValue({ workflowType });
            addNewWorkflow(workflowType);
            return;
        }
    };

    const onClose = () => {
        store.setCreateWorkflowDialogVisible(false);
        store.removeUnsavedWorkflows();

        if (store.editableWorkflow) {
            store.editableWorkflow.resetModifiedOutputSchemes();
            store.editableWorkflow.setIsEditable(false);
        }
    };

    return (
        <Modal
            className="create-edit-workflow-dialog"
            title={editMode ? 'Update Workflow' : 'Create Workflow'}
            visible={store.createWorkflowDialogVisible}
            width={600}
            onCancel={onClose}
            maskClosable={false}
            closable={false}
            destroyOnClose
            centered
            footer={[
                <Button
                    form="create-edit-workflow-form"
                    data-id="create-edit-workflow-cancel"
                    key="create-edit-workflow-cancel"
                    className="light"
                    size="large"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    form="create-edit-workflow-form"
                    data-id="create-workflow"
                    key="create-workflow"
                    size="large"
                    type="primary"
                    htmlType="submit"
                >
                    {editMode ? 'Update' : 'Create'}
                </Button>
            ]}
        >
            <Form
                id="create-edit-workflow-form"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                preserve={false}
            >
                <div className="create-edit-workflow-dialog-content">
                    <WorkflowTypeSelect store={store} workflow={store.editableWorkflow} />

                    {store.editableWorkflow && (
                        <>
                            <WorkflowName workflow={store.editableWorkflow} />
                            <WorkflowMessagesTemplates form={form} workflow={store.editableWorkflow} />
                            <WorkflowInputs store={store} workflow={store.editableWorkflow} />
                            <WorkflowsOutputSchemes form={form} workflow={store.editableWorkflow} />
                        </>
                    )}
                </div>
            </Form>
        </Modal>
    );
};

export default observer(CreateEditWorkflowDialog);
