import { PackageLine } from '../../common/models';

export function getDetectorAutocompleteOptions (pl: PackageLine) {
    const label = `${pl.text ?? ''} (${pl.coordinates?.page})`;
    const value = `${pl.text ?? ''}`;

    return {
        label,
        value
    };
}