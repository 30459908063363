import * as React from 'react';
import { observer, inject, Observer } from 'mobx-react';
import { Row, Col, Button, Input, AutoComplete, Select, Form } from 'antd';
import PackagesList from '../../project_management/components/PackagesList';
import autocompleteOptionRenderer from '../../common/components/AutocompleteOptionRenderer';
import { IReactionDisposer, reaction } from 'mobx';
import { BLOCK_TYPE } from '../../common/types/BlockType';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ListLabelsStores, ProjectLabelsVisualStore } from '../stores';
import { AutoCompleteSourceItem } from '../../common/types';
import { STORE_PROJECT_LABELS } from '../constants';
import { SearchOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

const FormItem = Form.Item;
const Option = Select.Option;

type Props = ListLabelsStores;
type SearchObject = {
    ['search']: string
};
@inject(STORE_PROJECT_LABELS)
@observer
class LabelsFilter extends React.Component<Props, object> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private optionRenderer: (item: AutoCompleteSourceItem, idx: number) => any;
    private searchForAutocomplete: (s: string) => void;
    private performSearch: (s: string) => void;
    private disposer: IReactionDisposer;
    private formRef: React.RefObject<FormInstance>;

    constructor(props: Props) {
        super(props);        
        const { projectLabelsUI } = props;
        this.searchForAutocomplete = projectLabelsUI!.searchForAutocomplete.bind(projectLabelsUI);
        this.performSearch = projectLabelsUI!.performSearch.bind(projectLabelsUI);
        this.optionRenderer = autocompleteOptionRenderer(projectLabelsUI!);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.disposer = reaction(() => this.props.projectLabelsUI!.searchTerm, s => {
            if (s !== this.formRef.current!.getFieldsValue(['search'])[0]) {
                this.formRef.current!.setFieldsValue({ 'search': s });
            }
        },                       {
            fireImmediately: true
        });
    }

    componentWillUnmount() {
        this.disposer();
    }

    handleOnChange(checkedValue: CheckboxChangeEvent) {
        this.props.projectLabelsUI!.filterMarkedPackages(checkedValue.target.checked);
    }

    getInitialValues() {
        const store = this.props.projectLabelsUI!;
    
        return {
            blockType: store.blockTypes[0],
            tags: store.currentTags
        };
        
    }
  
    render() {
        const store = this.props[STORE_PROJECT_LABELS] as ProjectLabelsVisualStore;
        const tags = store.tags;
        return (
            <Form
                className="ant-advanced-search-form"
                initialValues={this.getInitialValues()}
                ref={this.formRef}
            >
                <Row gutter={10} style={{marginBottom: 10}}>
                    <Col span={4} style={{ display: 'block' }}>
                        <FormItem name="package">
                            <PackagesList
                                dataId="label-data-package-select"
                                size="default"
                                currentPackage={store.selectedPackage!}
                                packages={store.filteredPackages}
                                onPackageSelected={p => store.selectPackage(p)}
                                loading={store.isLoadingProject}
                            />
                        </FormItem>
                    </Col>
                    <Col span={5} style={{ display: 'block' }}>
                        <FormItem name="search">
                            <Observer>{() => 
                                <div className="global-search-wrapper" style={{ width: '100%' }}>
                                    <AutoComplete
                                        data-id="label-data-filter-autocomplete"
                                        className="global-search alpha-autocomplete"
                                        style={{ width: '100%' }}
                                        options={store.autocompleteSource.map((m, i) => ({value: m.text, label: this.optionRenderer(m, i)}))}
                                        onSearch={this.searchForAutocomplete}
                                        placeholder="Search by keywords..."
                                        defaultActiveFirstOption={false}
                                        disabled={store.isLoadingProject}
                                        showSearch
                                        value={store.searchTerm || ''}
                                        allowClear
                                        onSelect={v => {
                                            const value = v as string;
                                            this.performSearch(value);
                                        }}
                                        onClear={store.clearSearch}
                                    >
                                        <Input
                                            suffix={(
                                                <Button 
                                                    className="search-btn" 
                                                    type="ghost" 
                                                    onClick={() => {
                                                        let search  = this.formRef.current!.getFieldsValue(['search']);
                                                        this.performSearch((search as SearchObject).search); 
                                                    }}
                                                >
                                                    <SearchOutlined/>
                                                </Button>
                                            )}
                                            // eslint-disable-next-line max-len
                                            onPressEnter={e => {
                                                e.currentTarget.blur(); this.performSearch(e.currentTarget.value); 
                                            }}
                                        />
                                    </AutoComplete>
                                </div>
                            }
                            </Observer>
                        </FormItem>
                    </Col>
                    <Col span={4} style={{ display: 'block' }}>
                        <FormItem name="blockType">
                            <Select data-id="label-data-block-type-select" onChange={(v: BLOCK_TYPE) => store.changeBlockType(v)} virtual={false}>
                                <Option value="LINE_BLOCK">LINE</Option>
                                <Option value="TEXTBOX_BLOCK">TEXTBOX</Option>
                                <Option value="TABLE_BLOCK">TABLE</Option>
                                <Option value="CELL_BLOCK">CELL</Option>
                                <Option value="HORIZONTAL_LINE_BLOCK">HORIZONTAL</Option>
                                <Option value="CLUSTER_BLOCK">CLUSTER</Option>
                                <Option value="HORIZONTAL_MULTILINE_BLOCK">HORIZONTAL_MULTILINE</Option>
                                <Option value="PARAGRAPH_BLOCK">PARAGRAPH</Option>
                                <Option value="PAGE_BLOCK">PAGE</Option>
                                <Option value="WORD_BLOCK">WORD</Option>
                                <Option value="DOCUMENT_TABLE_BLOCK">DOCUMENT_TABLE</Option>
                                <Option value="FORM_ITEM_BLOCK">FORM_ITEM_BLOCK</Option>
                                <Option value="SMART_BLOCK">SMART</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            name="tags"
                        >
                            <Select placeholder="Tags..." data-id="label-data-tags-filter-select" mode="tags" onChange={store.setTags}>
                                {tags.map((k, i) => (
                                    <Option data-id={`label-data-tags-filter-select-${k}`} key={`kw-${i}`} value={k}>{k}</Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={2}>
                        <FormItem >
                            <Checkbox data-id="checkbox-not-reviewed" onChange={(checkedValue) => this.handleOnChange(checkedValue)}>
                                Not reviewed
                            </Checkbox>
                        </FormItem>
                    </Col>
                    <Col span={3}>
                        <FormItem >
                            <Checkbox data-id="checkbox-source-portal" checked={store.isPackageSourceChecked} onChange={store.handlePackageSourceCheck}>
                                Source Portal (Only)
                            </Checkbox>
                        </FormItem>
                    </Col>
                </Row>
                {/* <Row gutter={24}>
                    <Col span={24} style={{ display: 'block' }}>
                        <Collapse bordered={false} style={{background: 'transparent'}}>
                            <Panel key="1" header="Columns configuration" style={{border: 0}}>
                                <FormItem label={`Parameters`} {...parametersFormItemLayout} >
                                    {getFieldDecorator(`parameters`)(
                                        <div>
                                        <div style={{ borderBottom: '1px solid #E9E9E9' }}>
                                            <Checkbox 
                                                indeterminate={indeterminate} 
                                                checked={visibleAll} 
                                                onChange={(e) => store.showAll(e.target.checked)}
                                            >
                                                Show all
                                            </Checkbox>                               
                                        </div>
                                            <CheckboxGroup 
                                                options={store.project.packageProperties} 
                                                value={store.visibleProperties} 
                                                onChange={(v) => store.showProperty(v as string[])} 
                                            />
                                        </div>
                                    )}
                                </FormItem>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row> */}
            </Form>
        );
    }
}

export default LabelsFilter;