import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AlphaConfigVisualStore } from '../stores/AlphaConfigVisualStore';

type Props = {
    store: AlphaConfigVisualStore
};

const AlphaSettingCreateDialog: React.FC<Props> = ({store}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        form.resetFields();
    }, [form, store.isNewAlphaSettingModalVisible]);

    const handleCancel = () => {
        store.setIsNewAlphaSettingModalVisible(false);
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            store.updateAlphaSettings(values.key, values.value).then(() => {
                handleCancel();
            });
        }).catch(() => {
            // Do nothing
        });
    };

    return (
        <Modal
            visible={store.isNewAlphaSettingModalVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            title="New alpha setting"
            destroyOnClose
            width={660}
            centered
            className="alpha-portal-dialog"
            footer={[
                <Button className="light" data-id="add-setting-dialog-cancel" size="large" key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button data-id="add-setting-dialog-submit" size="large" key="submit" type="primary" onClick={handleSubmit}>
                    Add setting
                </Button>
            ]}
        >
            <Form form={form} layout="vertical" colon={false}>
                <Form.Item
                    name="key"
                    label="Key"
                    rules={[{ required: true, message: 'Please input the key of the setting!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="value"
                    label="Value"
                    rules={[{ required: true, message: 'Please input the value of the setting!' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(AlphaSettingCreateDialog);