import './styles/index.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './modules/authorization/AuthContext';
import NoRolesError, { NetworkError, UnauthorizedError } from './modules/common/types/CustomErrors';
import { HasPermission } from './modules/authorization/components/HasPermission';
import { AppPermissions } from './modules/authorization/Permissions';
import UserInfoService from './modules/common/services/UserInfoService';
import security from  './modules/common/services/SecurityService';

const NO_PORTAL_ACCESS_MESSAGE = 'Sorry, you don\'t have permissions to access portal. Please report to Administrator...';
security.init().then(() => UserInfoService.getUserInfo().then((userInfo) => {
    if (!userInfo.permissions.length) {
        throw new NoRolesError();
    }

    ReactDOM.render(
        <AuthProvider value={{permissions: userInfo!.permissions, objectPermissions: userInfo!.objectPermissions}}>
            <HasPermission  
                permissionClaim={AppPermissions.CanAccessPortal}
                no={() => <div className="no-access-to-portal">{NO_PORTAL_ACCESS_MESSAGE}</div>}
            >
                <App />
            </HasPermission>
        </AuthProvider>
        ,
        document.getElementById('root') as HTMLElement
    );
})).catch(function(err: object) {
    if (err instanceof NoRolesError) {
        ReactDOM.render(
            (err as NoRolesError).errorScreen,
            document.getElementById('root') as HTMLElement
        );
    } else if (err instanceof NetworkError) {
        ReactDOM.render(
            (err as NetworkError).errorScreen,
            document.getElementById('root') as HTMLElement
        );
    } else if (err instanceof UnauthorizedError) {
        ReactDOM.render(
            (err as UnauthorizedError).errorScreen,
            document.getElementById('root') as HTMLElement
        );
    } else {
        console.log(err);
        alert('failed to initialize');
    }
});

if (module.hot) {
    module.hot.accept(['./App'], () => {
        const NextApp = require('./App').default;
        ReactDOM.render(
            <NextApp />,
            document.getElementById('root') as HTMLElement
        );
    });

}