import { observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { omit } from 'lodash';
import { ChatbotStore } from '../stores';
import { QuestionResponseDto, QuestionDto } from '../types';
import ChatbotService from '../services/ChatbotService';

export default class ChatbotQuestionModel implements QuestionDto {
    @observable
    response: QuestionResponseDto | null = null;

    @observable
    processing: boolean = false;

    readonly id: string;

    readonly message: string;

    readonly packageId: string;

    readonly createDate: Date;

    @computed
    get nodeIds() {
        if (!this.response || !this.response.packageFields.length) {
            return [];
        }

        return this.response.packageFields.map(f => f.field.id);
    }

    get dto(): QuestionDto {
        return {
            id: this.id,
            message: this.message,
            response: this.response,
            packageId: this.packageId,
            createDate: this.createDate
        };
    }

    constructor(
        private readonly store: ChatbotStore,
        private readonly service: ChatbotService,
        data: Partial<QuestionDto> & Pick<QuestionDto, 'message' | 'packageId'>
    ) {
        this.id = data.id ?? uuidv4();
        this.message = data.message;
        this.packageId = data.packageId;
        this.createDate = data.createDate ?? new Date();
        this.response = data.response ?? null;
    }

    @action.bound
    async ask(lastQuestionNodeIds: string[]) {
        try {
            this.processing = true;

            const resp = await this.service.askQuestion(
                this.message,
                this.packageId,
                lastQuestionNodeIds,
                omit(this.store.settings.values, ['useContext'])
            );

            if (!resp.isOk()) {
                const message = resp.error.data ? resp.error.data.title : resp.error.text;
                this.store.addError(message);
                return;
            }

            resp.map(response => (this.response = response));
        } catch (err) {
            console.error(err);
        } finally {
            this.processing = false;
        }
    }
}
